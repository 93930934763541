import { type ClassValue, clsx } from 'clsx';
import { customAlphabet } from 'nanoid';
import { twMerge } from 'tailwind-merge';
import { idToViemChain } from '../mappings';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// set timeout promise
export function setPromiseTimeOut(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const ipfsUrlReplace = (url: string) =>
  url.replace(
    /^(https?:\/\/[^/]+)(\/ipfs\/\w+)$/,
    'https://gateway.pinata.cloud$2',
  );

export const nanoid = customAlphabet(
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
  7,
); // 7-character random string

export async function fetcher<JSON = any>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<JSON> {
  const res = await fetch(input, init);

  if (!res.ok) {
    const json = await res.json();
    if (json.error) {
      const error = new Error(json.error) as Error & {
        status: number;
      };
      error.status = res.status;
      throw error;
    } else {
      throw new Error('An unexpected error occurred');
    }
  }

  return res.json();
}

export function formatDate(input: string | number | Date): string {
  const date = new Date(input);
  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
}

/**
 * Truncate string displaying the first 6 and last 4 characters
 */
export function truncateAddress(address: string) {
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
}

/**
 * Format a string number in wei to a more readable string with a fixed number of decimal places
 */
export function formatUnits(
  value: string | number,
  decimals = 18,
  options?: Intl.NumberFormatOptions,
): string {
  const formattedValue = Number(value) / Math.pow(10, decimals);
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    ...options,
  }).format(formattedValue);
}

export function getExplorerUrl(address: string, chainId: number) {
  const explorerUrl = idToViemChain[chainId].blockExplorers?.default.url;
  return `${explorerUrl}/address/${address}`;
}

export function isSolanaAddress(address?: string) {
  if (!address) return false;
  return /^[1-9A-HJ-NP-Za-km-z]{32,44}$/.test(address);
}

// Check if address isSolanaAddress and if it ends in "pump"
export function isPumpFunAddress(address?: string) {
  if (!address) return false;
  return isSolanaAddress(address) && address.endsWith('pump');
}
