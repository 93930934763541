import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const env = createEnv({
  server: {
    POSTGRES_PRISMA_URL: z.string().min(1),
    AUTH_SECRET: z.string().min(1),
    COVALENT_API_KEY: z.string().min(1),
    ONEINCH_API_KEY: z.string().min(1),
    OPENAI_API_KEY: z.string().min(1),
    RESEND_API_KEY: z.string().min(1),
    MORALIS_API_KEY: z.string().min(1),
    CLERK_SECRET_KEY: z.string().min(1),
    OPENROUTERAI_API_KEY: z.string().optional(),
    TELEGRAM_BOT_TOKEN: z.string().min(1),
    TELEGRAM_SECRET_TOKEN: z.string().min(1),
    JWT_PRIVATE_KEY: z
      .string()
      .min(1)
      .transform(e => Buffer.from(e, 'base64').toString())
      .default('c2VjcmV0'), // base64 encoded 'secret'
    SESSION_SECRET: z.string().min(1).default('secret'),
  },
  client: {
    NEXT_PUBLIC_SOLANA_RPC_URL: z.string().url(),
    NEXT_PUBLIC_SESSION_TIME: z.string().min(1).default('86400'),
    NEXT_PUBLIC_SWAPS_ROUTER_ADDRESS: z.string().min(1),
    NEXT_PUBLIC_WEB3_AUTH_CLIENT_ID: z.string().min(1),
    NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: z.string().min(1),
    NEXT_PUBLIC_MOONPAY_API_KEY: z.string().min(1).default('pk_test_123'),
    NEXT_PUBLIC_TELEGRAM_BOT_NAME: z
      .string()
      .min(1)
      .default('metamaxultrapro_tbot'),
    NEXT_PUBLIC_FEATURE_FLAG_TELEGRAM_LOGIN: z
      .string()
      .transform(val => val === 'true')
      .default('false'),
    NEXT_PUBLIC_ETH_RPC: z.string().url(),
  },
  // For Next.js >= 13.4.4, you only need to destructure client variables:
  experimental__runtimeEnv: {
    NEXT_PUBLIC_SOLANA_RPC_URL: process.env.NEXT_PUBLIC_SOLANA_RPC_URL,
    NEXT_PUBLIC_SESSION_TIME: process.env.NEXT_PUBLIC_SESSION_TIME,
    NEXT_PUBLIC_SWAPS_ROUTER_ADDRESS:
      process.env.NEXT_PUBLIC_SWAPS_ROUTER_ADDRESS,
    NEXT_PUBLIC_WEB3_AUTH_CLIENT_ID:
      process.env.NEXT_PUBLIC_WEB3_AUTH_CLIENT_ID,
    NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY:
      process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY,
    NEXT_PUBLIC_MOONPAY_API_KEY: process.env.NEXT_PUBLIC_MOONPAY_API_KEY,
    NEXT_PUBLIC_TELEGRAM_BOT_NAME: process.env.NEXT_PUBLIC_TELEGRAM_BOT_NAME,
    NEXT_PUBLIC_FEATURE_FLAG_TELEGRAM_LOGIN:
      process.env.NEXT_PUBLIC_FEATURE_FLAG_TELEGRAM_LOGIN,
    NEXT_PUBLIC_ETH_RPC: process.env.NEXT_PUBLIC_ETH_RPC,
  },
});
