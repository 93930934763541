// https://token.jup.ag/strict
export const solanaTokenToData: {
  [token: string]:
    | {
        address: string;
        name: string;
        decimals: number;
        chainId: number;
        symbol: string;
        logoURI: string;
        tags?: string[];
        extensions?: any;
      }
    | undefined;
} = {
  '2080': {
    address: 'Dwri1iuy5pDFf2u2GwwsH2MxjR6dATyDv9En9Jk8Fkof',
    chainId: 101,
    decimals: 9,
    name: '2080',
    symbol: '2080',
    logoURI:
      'https://raw.githubusercontent.com/resources-pub/token-info/main/fidelion_logo.png',
    tags: ['community'],
    extensions: { coingeckoId: '2080' },
  },
  SOL: {
    address: 'So11111111111111111111111111111111111111112',
    chainId: 101,
    decimals: 9,
    name: 'Wrapped SOL',
    symbol: 'SOL',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'wrapped-solana' },
  },
  USDC: {
    address: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    chainId: 101,
    decimals: 6,
    name: 'USD Coin',
    symbol: 'USDC',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'usd-coin' },
  },
  USDT: {
    address: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
    chainId: 101,
    decimals: 6,
    name: 'USDT',
    symbol: 'USDT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'tether' },
  },
  PYUSD: {
    address: '2b1kV6DkPAnxd5ixfnxCpjxmKwqjjaYmCZfHsFu24GXo',
    chainId: 101,
    decimals: 6,
    name: 'PayPal USD',
    symbol: 'PYUSD',
    logoURI:
      'https://424565.fs1.hubspotusercontent-na1.net/hubfs/424565/PYUSDLOGO.png',
    tags: ['community', 'token-2022'],
    extensions: { coingeckoId: 'paypal-usd' },
  },
  $WIF: {
    address: 'EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm',
    chainId: 101,
    decimals: 6,
    name: 'dogwifhat',
    symbol: '$WIF',
    logoURI:
      'https://bafkreibk3covs5ltyqxa272uodhculbr6kea6betidfwy3ajsav2vjzyum.ipfs.nftstorage.link',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'dogwifcoin' },
  },
  POPCAT: {
    address: '7GCihgDB8fe6KNjn2MYtkzZcRjQy3t9GHdC8uHYmW2hr',
    chainId: 101,
    decimals: 9,
    name: 'Popcat',
    symbol: 'POPCAT',
    logoURI:
      'https://bafkreidvkvuzyslw5jh5z242lgzwzhbi2kxxnpkic5wsvyno5ikvpr7reu.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'popcat' },
  },
  JitoSOL: {
    address: 'J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn',
    chainId: 101,
    decimals: 9,
    name: 'Jito Staked SOL',
    symbol: 'JitoSOL',
    logoURI: 'https://storage.googleapis.com/token-metadata/JitoSOL-256.png',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'jito-staked-sol' },
  },
  JUP: {
    address: 'JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN',
    chainId: 101,
    decimals: 6,
    name: 'Jupiter',
    symbol: 'JUP',
    logoURI: 'https://static.jup.ag/jup/icon.png',
    tags: ['community'],
    extensions: { coingeckoId: 'jupiter-exchange-solana' },
  },
  Bonk: {
    address: 'DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263',
    chainId: 101,
    decimals: 5,
    name: 'Bonk',
    symbol: 'Bonk',
    logoURI:
      'https://arweave.net/hQiPZOsRZXGXBJd_82PhVdlM_hACsT_q6wqwf5cSY7I?ext=png',
    tags: ['community'],
    extensions: { coingeckoId: 'bonk' },
  },
  JLP: {
    address: '27G8MtK7VtTcCHkpASjSDdkWWYfoqT6ggEuKidVJidD4',
    chainId: 101,
    decimals: 6,
    name: 'Jupiter Perps',
    symbol: 'JLP',
    logoURI: 'https://static.jup.ag/jlp/icon.png',
    tags: ['community'],
    extensions: { coingeckoId: 'jupiter-perpetuals-liquidity-provider-token' },
  },
  MUMU: {
    address: '5LafQUrVco6o7KMz42eqVEJ9LW31StPyGjeeu5sKoMtA',
    chainId: 101,
    decimals: 6,
    name: 'Mumu the Bull',
    symbol: 'MUMU',
    logoURI:
      'https://bafkreihszutctvdmdlyjtzfmj7rgvdorpc7jchj2td3feypc7veidbkpsu.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'mumu-the-bull-3' },
  },
  JupSOL: {
    address: 'jupSoLaHXQiZZTSfEWMTRRgpnyFm8f6sZdosWBjx93v',
    chainId: 101,
    decimals: 9,
    name: 'Jupiter Staked SOL',
    symbol: 'JupSOL',
    logoURI: 'https://static.jup.ag/jupSOL/icon.png',
    tags: ['community'],
    extensions: { coingeckoId: 'jupiter-staked-sol' },
  },
  mSOL: {
    address: 'mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So',
    chainId: 101,
    decimals: 9,
    name: 'Marinade staked SOL (mSOL)',
    symbol: 'mSOL',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'msol' },
  },
  $michi: {
    address: '5mbK36SZ7J19An8jFochhQS4of8g6BwUjbeCSxBSoWdp',
    chainId: 101,
    decimals: 6,
    name: 'michi',
    symbol: '$michi',
    logoURI:
      'https://i.ibb.co/GxG0314/5mb-K36-SZ7-J19-An8j-Fochh-QS4of8g6-Bw-Ujbe-CSx-BSo-Wdp.png',
    tags: ['community'],
    extensions: { coingeckoId: 'michicoin' },
  },
  BILLY: {
    address: '3B5wuUrMEi5yATD7on46hKfej3pfmd7t1RKgrsN3pump',
    chainId: 101,
    decimals: 6,
    name: 'BILLY',
    symbol: 'BILLY',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmdRU5EkGt1VsgLsXJdHK5kjojQqggQHjhyqeJwmApR53R',
    tags: ['community'],
    extensions: { coingeckoId: 'billy' },
  },
  MEW: {
    address: 'MEW1gQWJ3nEXg2qgERiKu7FAFj79PHvQVREQUzScPP5',
    chainId: 101,
    decimals: 5,
    name: 'cat in a dogs world',
    symbol: 'MEW',
    logoURI:
      'https://bafkreidlwyr565dxtao2ipsze6bmzpszqzybz7sqi2zaet5fs7k53henju.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'cat-in-a-dogs-world' },
  },
  INF: {
    address: '5oVNBeEEQvYi1cX3ir8Dx5n1P7pdxydbGF2X4TxVusJm',
    chainId: 101,
    decimals: 9,
    name: 'Infinity',
    symbol: 'INF',
    logoURI:
      'https://cloudflare-ipfs.com/ipfs/bafkreiflz2xxkfn33qjch2wj55bvbn33q3s4mmb6bye5pt3mpgy4t2wg4e',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'socean-staked-sol' },
  },
  WBTC: {
    address: '3NZ9JMVBmGAqocybic2c7LQCJScmgsAZ6vQqTDzcqmJh',
    chainId: 101,
    decimals: 8,
    name: 'Wrapped BTC (Portal)',
    symbol: 'WBTC',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/WBTC_wh.png',
    tags: ['wormhole', 'community', 'solana-fm'],
    extensions: { coingeckoId: 'wrapped-btc-wormhole' },
  },
  PONKE: {
    address: '5z3EqYQo9HiCEs3R84RCDMu2n7anpDMxRhdK8PSWmrRC',
    chainId: 101,
    decimals: 9,
    name: 'PONKE',
    symbol: 'PONKE',
    logoURI:
      'https://gateway.irys.xyz/YBMDVBvnfgO1gXCSmmc8p0RQhc69WWe8_kOkoT8w7nE',
    tags: ['community'],
    extensions: { coingeckoId: 'ponke' },
  },
  ETH: {
    address: '7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs',
    chainId: 101,
    decimals: 8,
    name: 'Ether (Portal)',
    symbol: 'ETH',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/ETH_wh.png',
    tags: ['wormhole', 'old-registry'],
    extensions: { coingeckoId: 'ethereum-wormhole' },
  },
  WOLF: {
    address: 'HmKqChBkZEvqFnH8sxja694n77ziYMBWaucZRKfJDRr2',
    chainId: 101,
    decimals: 5,
    name: 'WOLF SOLANA',
    symbol: 'WOLF',
    logoURI: 'https://arweave.net/vqdJ1M-3GLpOKuMKZLXNbd-odMLzGrXdgVis8wSEt-M',
    tags: ['community'],
    extensions: { coingeckoId: 'wolf-solana' },
  },
  JTO: {
    address: 'jtojtomepa8beP8AuQc6eXt5FriJwfFMwQx2v2f9mCL',
    chainId: 101,
    decimals: 9,
    name: 'JITO',
    symbol: 'JTO',
    logoURI: 'https://metadata.jito.network/token/jto/image',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'jito-governance-token' },
  },
  MOTHER: {
    address: '3S8qX1MsMqRbiwKg2cQyx7nis1oHMgaCuc9c4VfvVdPN',
    chainId: 101,
    decimals: 6,
    name: 'MOTHER IGGY',
    symbol: 'MOTHER',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmUFTFWsJiceS99iDMDm2NYuhvHXJVXTgmsDeR28X8njSn',
    tags: ['community'],
    extensions: { coingeckoId: 'mother-iggy' },
  },
  aura: {
    address: 'DtR4D9FtVoTX2569gaL837ZgrB6wNjj6tkmnX9Rdk9B2',
    chainId: 101,
    decimals: 6,
    name: 'aura',
    symbol: 'aura',
    logoURI:
      'https://ipfs.io/ipfs/QmSBgFtpXnxLMeyU1Czs1PW5Zv7nFjy8ZXe5ZDhA8fyyPD',
    tags: ['community'],
    extensions: { coingeckoId: 'aura-on-sol' },
  },
  MANEKI: {
    address: '25hAyBQfoDhfWx9ay6rarbgvWGwDdNqcHsXS3jQ3mTDJ',
    chainId: 101,
    decimals: 5,
    name: 'MANEKI',
    symbol: 'MANEKI',
    logoURI:
      'https://img.fotofolio.xyz/?url=https%3A%2F%2Fi.ibb.co%2FJnz56gp%2FManeki-PFP.png',
    tags: ['community'],
    extensions: { coingeckoId: 'maneki' },
  },
  SELFIE: {
    address: '9WPTUkh8fKuCnepRWoPYLH3aK9gSjPHFDenBq2X1Czdp',
    chainId: 101,
    decimals: 6,
    name: 'SelfieDogCoin',
    symbol: 'SELFIE',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmcrXYhDD6mcCWziAZfmMp9zwv1RvMAXaShBHEQzQjpaRj',
    tags: ['community'],
    extensions: { coingeckoId: 'selfiedogcoin' },
  },
  bSOL: {
    address: 'bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1',
    chainId: 101,
    decimals: 9,
    name: 'BlazeStake Staked SOL (bSOL)',
    symbol: 'bSOL',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'blazestake-staked-sol' },
  },
  WEN: {
    address: 'WENWENvqqNya429ubCdR81ZmD69brwQaaBYY6p3LCpk',
    chainId: 101,
    decimals: 5,
    name: 'Wen',
    symbol: 'WEN',
    logoURI:
      'https://shdw-drive.genesysgo.net/GwJapVHVvfM4Mw4sWszkzywncUWuxxPd6s9VuFfXRgie/wen_logo.png',
    tags: ['community'],
    extensions: { coingeckoId: 'wen-4' },
  },
  IO: {
    address: 'BZLbGTNCSFfoth2GYDtwr7e4imWzpR5jqcUuGEwr646K',
    chainId: 101,
    decimals: 8,
    name: 'IO',
    symbol: 'IO',
    logoURI:
      'https://bafkreicnqsbhpzxiasdm5esr7fqi3vcjvcbfefo4sq4y3ff747rfqf7w7i.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'io' },
  },
  RENDER: {
    address: 'rndrizKT3MK1iimdxRdWabcF7Zg7AR5T4nud4EkHBof',
    chainId: 101,
    decimals: 8,
    name: 'Render Token',
    symbol: 'RENDER',
    logoURI:
      'https://shdw-drive.genesysgo.net/5zseP54TGrcz9C8HdjZwJJsZ6f3VbP11p1abwKWGykZH/rndr.png',
    tags: ['community'],
    extensions: { coingeckoId: 'render-token' },
  },
  RETARDIO: {
    address: '6ogzHhzdrQr9Pgv6hZ2MNze7UrzBMAFyBBWUYp1Fhitx',
    chainId: 101,
    decimals: 6,
    name: 'RETARDIO',
    symbol: 'RETARDIO',
    logoURI:
      'https://bafkreidx64y72zvdmaysswocovwowtjlxjnh26qh62edql5gmp5rpo5gpm.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'retardio' },
  },
  GIGA: {
    address: '63LfDmNb3MQ8mw9MtZ2To9bEA2M71kZUUGq5tiJxcqj9',
    chainId: 101,
    decimals: 5,
    name: 'GIGACHAD',
    symbol: 'GIGA',
    logoURI:
      'https://bafybeifiyvpbr3kd6wepax4qxdlxbjrpz2de4lqsuwwuihirvaal6kqwba.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'gigachad-2' },
  },
  RAY: {
    address: '4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R',
    chainId: 101,
    decimals: 6,
    name: 'Raydium',
    symbol: 'RAY',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'raydium' },
  },
  DADDY: {
    address: '2J5uSgqgarWoh7QDBmHSDA3d7UbfBKDZsdy1ypTSpump',
    chainId: 101,
    decimals: 6,
    name: 'DADDY ANSEM',
    symbol: 'DADDY',
    logoURI: 'https://i.imgur.com/0iLIfUz.jpg',
    tags: ['community'],
  },
  bicho: {
    address: 'GkJxELgJXpQRm7dfc2yS18vBDRxP5SjVJgbrmTGgpump',
    chainId: 101,
    decimals: 6,
    name: 'bicho',
    symbol: 'bicho',
    logoURI:
      'https://ipfs.io/ipfs/QmRtQcJGtNs5qEREuMtez5DJKqUtAytCZq9M4RndonWMzR',
    tags: ['community'],
    extensions: { coingeckoId: 'bicho' },
  },
  hSOL: {
    address: 'he1iusmfkpAdwvxLNGV8Y1iSbj4rUy6yMhEA3fotn9A',
    chainId: 101,
    decimals: 9,
    name: 'Helius Staked SOL',
    symbol: 'hSOL',
    logoURI:
      'https://raw.githubusercontent.com/igneous-labs/lst-offchain-metadata/master/hSOL/hSOL.png',
    tags: ['community'],
    extensions: { coingeckoId: 'helius-staked-sol' },
  },
  SC: {
    address: 'A2GHnfpZvyeZX5Pr63jdMmo9uYbpaaKqHHuPD5xD2n6v',
    chainId: 101,
    decimals: 9,
    name: 'STAR CAT',
    symbol: 'SC',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63646e2e64657873637265656e65722e636f6d2f636d732f696d616765732f423167333567617850694e44772d6d723f77696474683d313238266865696768743d313238266669743d63726f70267175616c6974793d3935',
    tags: ['community'],
    extensions: { coingeckoId: 'star-cat' },
  },
  HNT: {
    address: 'hntyVP6YFm1Hg25TN9WGLqM12b8TQmcknKrdu1oxWux',
    chainId: 101,
    decimals: 8,
    name: 'Helium Network Token',
    symbol: 'HNT',
    logoURI:
      'https://shdw-drive.genesysgo.net/CsDkETHRRR1EcueeN346MJoqzymkkr7RFjMqGpZMzAib/hnt.png',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'helium' },
  },
  TRUMP: {
    address: '4h8LjZWUfUQVgbEZ29UzTuGXNW6rwrJis78ZU66ekkPV',
    chainId: 101,
    decimals: 9,
    name: 'Donald Trump',
    symbol: 'TRUMP',
    logoURI: 'https://ibb.co/6X2r4kB',
    tags: ['community'],
  },
  BOME: {
    address: 'ukHH6c7mMyiWCf1b9pnWe25TSpkDDt3H5pQZgZ74J82',
    chainId: 101,
    decimals: 6,
    name: 'BOOK OF MEME',
    symbol: 'BOME',
    logoURI:
      'https://bafybeidov7gddabmqke3fozpuvlllp3q2c537f2vfyyf6or4spbbao6cee.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'book-of-meme' },
  },
  mini: {
    address: '2JcXacFwt9mVAwBQ5nZkYwCyXQkRcdsYrDXn6hj22SbP',
    chainId: 101,
    decimals: 6,
    name: 'mini',
    symbol: 'mini',
    logoURI:
      'https://bafybeiemvdqnprcn5ndjvqha2kc4sgkl7uyt7c36qmgqidql4zygr2hzjm.ipfs.cf-ipfs.com/',
    tags: ['community'],
    extensions: { coingeckoId: 'minimini' },
  },
  NOS: {
    address: 'nosXBVoaCTtYdLvKY6Csb4AC8JCdQKKAaWYtx2ZMoo7',
    chainId: 101,
    decimals: 6,
    name: 'Nosana',
    symbol: 'NOS',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/nosXBVoaCTtYdLvKY6Csb4AC8JCdQKKAaWYtx2ZMoo7/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'nosana' },
  },
  ISC: {
    address: 'J9BcrQfX4p9D1bvLzRNCbMDv8f44a9LFdeqNE4Yk2WMD',
    chainId: 101,
    decimals: 6,
    name: 'International Stable Currency',
    symbol: 'ISC',
    logoURI:
      'https://raw.githubusercontent.com/theISCTeam/isc_meta/master/logo.png',
    tags: ['community'],
    extensions: { coingeckoId: 'international-stable-currency' },
  },
  USDY: {
    address: 'A1KLoBrKBde8Ty9qtNQUtq3C2ortoC3u7twggz7sEto6',
    chainId: 101,
    decimals: 6,
    name: 'Ondo US Dollar Yield',
    symbol: 'USDY',
    logoURI:
      'https://hq3wjgefwtje2kue7bvqlevhns2udaharnibucrgui3lhgy4aniq.arweave.net/PDdkmIW00k0qhPhrBZKnbLVBgOCLUBoKJqI2s5scA1E',
    tags: ['community'],
    extensions: { coingeckoId: 'ondo-us-dollar-yield' },
  },
  LOCKIN: {
    address: '8Ki8DpuWNxu9VsS3kQbarsCWMcFGWkzzA8pUPto9zBd5',
    chainId: 101,
    decimals: 9,
    name: 'LOCK IN',
    symbol: 'LOCKIN',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f697066732e696f2f697066732f516d6332534a5157344b37555959564c646f4b5366346347565a62754647544634645a694164527469764e6b7058',
    tags: ['community'],
    extensions: { coingeckoId: 'lock-in' },
  },
  tremp: {
    address: 'FU1q8vJpZNUrmqsciSjp8bAKKidGsLmouB8CBdf8TKQv',
    chainId: 101,
    decimals: 9,
    name: 'doland tremp',
    symbol: 'tremp',
    logoURI:
      'https://bafkreia5oynseyky72us6y7uzbas2uxoosujxvbnnuvscozetprbdinmey.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'donald-tremp' },
  },
  KMNO: {
    address: 'KMNo3nJsBXfcpJTVhZcXLW7RmTwTt4GVFE7suUBo9sS',
    chainId: 101,
    decimals: 6,
    name: 'Kamino',
    symbol: 'KMNO',
    logoURI: 'https://cdn.kamino.finance/kamino.svg',
    tags: ['community'],
    extensions: { coingeckoId: 'kamino' },
  },
  nub: {
    address: 'GtDZKAqvMZMnti46ZewMiXCa4oXF4bZxwQPoKzXPFxZn',
    chainId: 101,
    decimals: 9,
    name: 'nubcat',
    symbol: 'nub',
    logoURI:
      'https://bafkreieny7bfqv76t3pgaaktrrux6j2iflefncegqxmezqsqrzy7kjhhy4.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'sillynubcat' },
  },
  $MYRO: {
    address: 'HhJpBhRRn4g56VsyLuT8DL5Bv31HkXqsrahTTUCZeZg4',
    chainId: 101,
    decimals: 9,
    name: 'Myro',
    symbol: '$MYRO',
    logoURI: 'https://i.ibb.co/9nr3xFp/MYRO-200x200.png',
    tags: ['community'],
    extensions: { coingeckoId: 'myro' },
  },
  SMILEK: {
    address: '7x4FgiFfeqzs1tiUNvJzpt47GtLXAJ8Jfn8G1Hyyu6JH',
    chainId: 101,
    decimals: 6,
    name: 'Smilek',
    symbol: 'SMILEK',
    logoURI:
      'https://sapphire-lazy-macaw-504.mypinata.cloud/ipfs/QmZHBg3pzFBmmfAugfQqWt31YRUNFBcYshZdDbnuxRyxTf',
    tags: ['community'],
    extensions: { coingeckoId: 'eye-earn' },
  },
  SRMet: {
    address: 'xnorPhAzWXUczCP3KjU5yDxmKKZi5cSbxytQ1LgE3kG',
    chainId: 101,
    decimals: 6,
    name: 'Serum (Portal from Ethereum)',
    symbol: 'SRMet',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/SRMet_wh.png',
    tags: ['wormhole', 'old-registry'],
  },
  YARD: {
    address: '8RYSc3rrS4X4bvBCtSJnhcpPpMaAJkXnVKZPzANxQHgz',
    chainId: 101,
    decimals: 9,
    name: 'SolYard Finance',
    symbol: 'YARD',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8RYSc3rrS4X4bvBCtSJnhcpPpMaAJkXnVKZPzANxQHgz/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'solyard-finance' },
  },
  AABL: {
    address: 'ENoD8J2J6wNHkcJkvVBkwq5JMiR1oNBfBZRkoHCQogyT',
    chainId: 101,
    decimals: 6,
    name: 'Abble',
    symbol: 'AABL',
    logoURI:
      'https://sapphire-lazy-macaw-504.mypinata.cloud/ipfs/Qmf6i1Rzz82xayVFtmWN8U4hUWtE2cQVqVWKoNE8gmchMz',
    tags: ['community'],
    extensions: { coingeckoId: 'abble' },
  },
  JOHN: {
    address: 'BuxH23osRyFFLbWG3czrTsfBQYbxzVZ8f7QV4cjTHN5x',
    chainId: 101,
    decimals: 6,
    name: 'John',
    symbol: 'JOHN',
    logoURI:
      'https://nftstorage.link/ipfs/bafybeiauzhcl6duywrp45fj6pg3u56fbpvw6m3vphfwe7zpzko5cge63fm',
    tags: ['community'],
    extensions: { coingeckoId: 'john-the-coin' },
  },
  ket: {
    address: '2SsU91ZE2dni5aD8i7bfTR4Bvf6UcGFBbLsvDucWUdw3',
    chainId: 101,
    decimals: 9,
    name: 'ket',
    symbol: 'ket',
    logoURI:
      'https://bafkreibzsa7dyrh7tdy7iiqauyzvb6bw42lpivt642i3rv7wm5enyomdca.ipfs.nftstorage.link',
    tags: ['community'],
  },
  ZYN: {
    address: 'PzuaVAUH2tfxGZcbBR6kMxeJsBngnsPLFotGJNCtcsd',
    chainId: 101,
    decimals: 8,
    name: 'ZynCoin (Wormhole)',
    symbol: 'ZYN',
    logoURI:
      'https://pbs.twimg.com/profile_images/1734964928434294784/pjyf-A-E.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'zyncoin-2' },
  },
  whoren: {
    address: 'EF23Avq2cTPnMVTfHacZ3SG5Z8misHmFA2gbt2rKqiYH',
    chainId: 101,
    decimals: 9,
    name: 'elizabath whoren',
    symbol: 'whoren',
    logoURI:
      'https://bafkreibec5pa6bn24rqgkqfgyrqkjanbde4cz53xhxv4izol5pzhljpijm.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'elizabath-whoren' },
  },
  ANUS: {
    address: '9hjZ8UTNrNWt3YUTHVpvzdQjNbp64NbKSDsbLqKR6BZc',
    chainId: 101,
    decimals: 9,
    name: 'URANUS',
    symbol: 'ANUS',
    logoURI: 'https://anusexplorer.com/web-logo.png',
    tags: ['community'],
    extensions: { coingeckoId: 'uranus-sol' },
  },
  ANON: {
    address: '8VJ51bdE3xorQ1zB7FEa8CsHdM4kw77xCFiCgbnL2qbT',
    chainId: 101,
    decimals: 6,
    name: 'Anonymous',
    symbol: 'ANON',
    logoURI:
      'https://ipfs.io/ipfs/QmVwzQh6mv1cQ35axcyj52s183QpwrJzpooh5EeUgS5sV1',
    tags: ['community'],
    extensions: { coingeckoId: 'anonymous' },
  },
  JFI: {
    address: 'GePFQaZKHcWE5vpxHfviQtH5jgxokSs51Y5Q4zgBiMDs',
    chainId: 101,
    decimals: 9,
    name: 'Jungle DeFi',
    symbol: 'JFI',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GePFQaZKHcWE5vpxHfviQtH5jgxokSs51Y5Q4zgBiMDs/logo.png',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'jungle-defi' },
  },
  LFGO: {
    address: '2u98MM7DMtVmNG4iAKRNMtynjmkzgD6fXAzB3wVfhQvg',
    chainId: 101,
    decimals: 6,
    name: 'Lets Fuckin Go',
    symbol: 'LFGO',
    logoURI:
      'https://bafybeihigmjodulnlbn6dve7nmmwpkdgno5beid3hx5ku5irfy4wjmredy.ipfs.cf-ipfs.com/',
    tags: ['community'],
    extensions: { coingeckoId: 'lfgo' },
  },
  PEDRO: {
    address: '9SiKU8vnRiBYQSBff84K5zwG7habzwYVzn7KrtgCzNfg',
    chainId: 101,
    decimals: 9,
    name: 'Pedro the Raccoon',
    symbol: 'PEDRO',
    logoURI:
      'https://bafybeifphw5w3ygiod2abdd7oipul5uw4e5c74g2wo6btcou7yjosxapiq.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'pedro-the-raccoon' },
  },
  PULP: {
    address: '5YtGSyQ7YxFgDc9oj4SpZGTjoS5p5Fubkg8sMauQqXoS',
    chainId: 101,
    decimals: 9,
    name: 'PULP',
    symbol: 'PULP',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5YtGSyQ7YxFgDc9oj4SpZGTjoS5p5Fubkg8sMauQqXoS/logo.png',
    tags: ['old-registry'],
  },
  Labz: {
    address: '4VC7UYqBo9Siw8ZnkPXfw9D3dzYCiVrPDzs9XRtyRJMH',
    chainId: 101,
    decimals: 9,
    name: 'Insane Labz',
    symbol: 'Labz',
    logoURI:
      'https://ipfs.io/ipfs/QmUaVz9d9UfVHyfq2pskZQWCiE25LdRpwRXMeaM4VWYbWe',
    tags: ['community'],
    extensions: { coingeckoId: 'insane-labz' },
  },
  YOURAI: {
    address: 'FjK6rqU6QzUeNtmK6QQ78cCuS5AHAhcm4HgJkdsvXaep',
    chainId: 101,
    decimals: 8,
    name: 'YOUR AI (Wormhole)',
    symbol: 'YOURAI',
    logoURI:
      'https://s3.eu-central-2.wasabisys.com/your-production/yourai-ticker-50x50.png',
    tags: ['community'],
    extensions: { coingeckoId: 'your-ai' },
  },
  SEYLER: {
    address: '5g6b3eCLHC4WvmUJRiTvE5mNCUMiaHbKxQyWKuJdmDi',
    chainId: 101,
    decimals: 9,
    name: 'mechel seyler',
    symbol: 'SEYLER',
    logoURI:
      'https://bafybeiddrvfbic7v73sruaqxkapvbz73vxzp4ebljzhywhwlugu76p44by.ipfs.nftstorage.link',
    tags: ['community'],
  },
  NVX: {
    address: 'GtMtXoJiqSf8Gfp83cuunnDTiJTeTmv7cniVtJ6UAMWH',
    chainId: 101,
    decimals: 9,
    name: 'NovaDEX',
    symbol: 'NVX',
    logoURI:
      'https://pbs.twimg.com/profile_images/1722623481987428352/b4gDEuuT_400x400.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'novadex' },
  },
  Albärt: {
    address: 'EUroSPBddnvGhRGWj56S864XWG6wgJ42yLsHSNyAWBj',
    chainId: 101,
    decimals: 9,
    name: 'Albärt',
    symbol: 'Albärt',
    logoURI:
      'https://sx4dke57tosdo6nonr3ecrmgtsnzccgdtaeta3ym6wpbejgnx5ra.arweave.net/lfg1E7-bpDd5rmx2QUWGnJuRCMOYCTBvDPWeEiTNv2I',
    tags: ['community'],
  },
  DFTU: {
    address: '9hD434AapfbidD6hret16hHGMqKh9z3WrkicL5Cvcsz8',
    chainId: 101,
    decimals: 8,
    name: 'DFTU',
    symbol: 'DFTU',
    logoURI:
      'https://ipfs.io/ipfs/QmQnoSCfKDNs1KvUhYp2YvX2ffShwzG7M1t92mdPmXBaQR',
    tags: ['community'],
  },
  aeUSDT: {
    address: 'Bn113WT6rbdgwrm12UJtnmNqGqZjY4it2WoUQuQopFVn',
    chainId: 101,
    decimals: 9,
    name: 'Wrapped USDT (Allbridge from Ethereum)',
    symbol: 'aeUSDT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg',
    tags: ['old-registry'],
  },
  TBTC: {
    address: '6DNSN2BJsaPFdFFc1zP37kkeNe4Usc1Sqkzr9C9vPWcU',
    chainId: 101,
    decimals: 8,
    name: 'Threshold Bitcoin',
    symbol: 'TBTC',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/TBTC_wh.png',
    tags: ['wormhole', 'community'],
    extensions: { coingeckoId: 'tbtc' },
  },
  SPOOKY: {
    address: 'FdGoS1Dok5CLnS8fVSmj5A92uY1yhzdTC2ZxuLJdkwgs',
    chainId: 101,
    decimals: 6,
    name: 'Spooky The Phantom',
    symbol: 'SPOOKY',
    logoURI: 'https://i.ibb.co/mHzqCPP/Spooky-logo-rounded-100px.png',
    tags: ['community'],
    extensions: { coingeckoId: 'spooky-the-phantom' },
  },
  DAWG: {
    address: '3DHPqxdMXogNNnpqBMF8N4Zs4dn1WR31H7UjWq6FExwG',
    chainId: 101,
    decimals: 9,
    name: 'DAWG',
    symbol: 'DAWG',
    logoURI:
      'https://user-images.githubusercontent.com/15795037/143055147-72513c02-be9b-4639-bb30-3c1c037cb030.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'dawg' },
  },
  GRUMP: {
    address: 'Ch5JJQZspiJ9MCDURZAA8nnVvbiwD1tnPcfmkCcVPiDb',
    chainId: 101,
    decimals: 9,
    name: 'GRUMPY CAT',
    symbol: 'GRUMP',
    logoURI:
      'https://bafybeiclh3lsuq3bddebjez57c2ca3a52baxcqxhwuvscxf6j2y4dwwu2u.ipfs.nftstorage.link',
    tags: ['community'],
  },
  BYAT: {
    address: 'BYATmZ7ry2pewxW3213sczJYB7ZJzPr921uvcRcJYYZQ',
    chainId: 101,
    decimals: 9,
    name: 'Byat',
    symbol: 'BYAT',
    logoURI:
      'https://bafybeiebc4yrvojgwxcbhla4hgg6vswqapei4vos2wivdo5whpzh4kgybe.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'byat' },
  },
  CHEXBACCA: {
    address: '8J5e2FPmBJ1subEUeVkELpeBZv9aYUrMmfHys7sREeXr',
    chainId: 101,
    decimals: 6,
    name: 'CHEXBACCA',
    symbol: 'CHEXBACCA',
    logoURI: 'https://chexbacca.com/assets/images/slider/slider_img01.png',
    tags: ['community'],
    extensions: { coingeckoId: 'chexbacca' },
  },
  GENE: {
    address: 'GENEtH5amGSi8kHAtQoezp1XEXwZJ8vcuePYnXdKrMYz',
    chainId: 101,
    decimals: 9,
    name: 'Genopets',
    symbol: 'GENE',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GENEtH5amGSi8kHAtQoezp1XEXwZJ8vcuePYnXdKrMYz/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'genopets' },
  },
  SHEPI: {
    address: '2MMJXYvSJuY4C3sSj9eAWVroiLWnuxG7DQieB5PCMjKm',
    chainId: 101,
    decimals: 9,
    name: 'SHEPI',
    symbol: 'SHEPI',
    logoURI:
      'https://ipfs.io/ipfs/QmZTTfgNrCdTaaunQy7zJXS5MhcQSPxUEr1KiLdYWaKdKq',
    tags: ['community'],
  },
  RBT: {
    address: '65nTNuJGHme4PQvKQyJykKp1bJAkK4A8Q66sd2yBWugf',
    chainId: 101,
    decimals: 6,
    name: 'RIBBIT',
    symbol: 'RBT',
    logoURI:
      'https://bafkreia3t3g3ecvvq7i5mgivvelysuhn5s6hzrulxmbkwgj5tgpsjtufge.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'ribbit-2' },
  },
  SOLNIC: {
    address: 'DeaKMzAeZja3Mh5okZE6WUvygLP3Lfuvm6Rg78HqXTz9',
    chainId: 101,
    decimals: 6,
    name: 'Solnic',
    symbol: 'SOLNIC',
    logoURI:
      'https://gateway.irys.xyz/y0Hlgi1BbUfWCfJ2a407UrlViwN0Fa3cjLikgrVCpWI',
    tags: ['community'],
  },
  rFRAKT: {
    address: '7V5AaqHTwiySegaAmNPLekQfTAoK4WvEVgfi2R8V44tB',
    chainId: 101,
    decimals: 9,
    name: 'Random FRAKTs',
    symbol: 'rFRAKT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7V5AaqHTwiySegaAmNPLekQfTAoK4WvEVgfi2R8V44tB/rFRAKT.png',
    tags: ['old-registry', 'solana-fm'],
  },
  ched: {
    address: '12mcpYL84oMi8Hiinyjuv2Zq3F47tLLxjw1THLcAdKT2',
    chainId: 101,
    decimals: 6,
    name: 'giggleched',
    symbol: 'ched',
    logoURI:
      'https://bafybeidbzidjhf5wo2l2dljjgbcozxwafwdgsfjzzmt2zwcketodzzqdfa.ipfs.cf-ipfs.com/',
    tags: ['community'],
    extensions: { coingeckoId: 'giggleched' },
  },
  MOON: {
    address: '2kMpEJCZL8vEDZe7YPLMCS9Y3WKSAMedXBn7xHPvsWvi',
    chainId: 101,
    decimals: 5,
    name: 'SolarMoon',
    symbol: 'MOON',
    logoURI:
      'https://gateway.ipfscdn.io/ipfs/bafkreifwdwgcv6fnh5icz3wkefokatsemsojck4hftsnuf4xcfjcvagsva',
    tags: ['community', 'token-2022'],
  },
  ASV: {
    address: 'AxaTJdRuuc3626FtPWdQCMcWPH6yzgxXKWbFCZN3TMgy',
    chainId: 101,
    decimals: 9,
    name: 'Asvoria',
    symbol: 'ASV',
    logoURI:
      'https://bafybeiap2jgce7xki7m6ei2ctkkdwczxgllo3gnueia63c6aipbka3opmu.ipfs.nftstorage.link/asvoria.png',
    tags: ['community', 'token-2022'],
  },
  'PRE-': {
    address: 'EZGvDFqrMSAxTt3Ud2bcguASaPKwGExiDzHVLqT3STZm',
    chainId: 101,
    decimals: 6,
    name: 'PRE-TOKEN',
    symbol: 'PRE-',
    logoURI:
      'https://bafybeiceqp3ezfenvetlirrtqzpyeagbafzuabsr3klzfaev4bpzqbccke.ipfs.nftstorage.link/PRE-logo.png',
    tags: ['community'],
  },
  DJT: {
    address: 'HRw8mqK8N3ASKFKJGMJpy4FodwR3GKvCFKPDQNqUNuEP',
    chainId: 101,
    decimals: 9,
    name: 'TrumpCoin',
    symbol: 'DJT',
    logoURI: 'https://i.postimg.cc/htS9z8BK/image.png',
    tags: ['community'],
    extensions: { coingeckoId: 'trumpcoin' },
  },
  TWT: {
    address: 'HZNpqL7RT9gxf9eWoWsWzC5DfjzQ41XTQgEA7p3VzaaD',
    chainId: 101,
    decimals: 8,
    name: 'Trust Wallet (Portal)',
    symbol: 'TWT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HZNpqL7RT9gxf9eWoWsWzC5DfjzQ41XTQgEA7p3VzaaD/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  EURC: {
    address: 'HzwqbKZw8HxMN6bF2yFZNrht3c2iXXzpKcFu7uBEDKtr',
    chainId: 101,
    decimals: 6,
    name: 'EURC',
    symbol: 'EURC',
    logoURI: 'https://www.circle.com/hubfs/Brand/EURC/EURC-icon_128x128.png',
    tags: ['community'],
    extensions: { coingeckoId: 'euro-coin' },
  },
  SOLNTN: {
    address: 'aXNx9xd9EUAd1xci3aUX1FjyNTAxaYrX29GsL7YtBJq',
    chainId: 101,
    decimals: 6,
    name: 'SOLANATIN',
    symbol: 'SOLNTN',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmS4CnjQ6BFNjKgXKoKDL1LRBPmXqfLTPkPA8XDT6oeMGW',
    tags: ['community'],
  },
  DUSK: {
    address: 'DMbb79fgxR3fKKVKWcbetMCuxNNtJnfjY3qqAE4G4wJf',
    chainId: 101,
    decimals: 3,
    name: 'Dusk',
    symbol: 'DUSK',
    logoURI:
      'https://firebasestorage.googleapis.com/v0/b/mom-prod-625e5.appspot.com/o/TokensNoBG%2FDusk_Coin_INV.png?alt=media&token=66709e38-8d35-40b0-8c0d-9499372f421f',
    tags: ['old-registry', 'solana-fm'],
  },
  PFIRE: {
    address: 'PFireKhT5WG7axMSLBmMRpvYH7cgHx9CRWHU8F8HNbr',
    chainId: 101,
    decimals: 6,
    name: 'Pepe On Fire',
    symbol: 'PFIRE',
    logoURI:
      'https://gateway.pinata.cloud/ipfs/QmUhpKiX532EjvCx7p2TLYVQ6ggwTWH1wkHtBnmPiZEzdo',
    tags: ['community', 'token-2022'],
    extensions: { coingeckoId: 'pepe-on-fire' },
  },
  IVN: {
    address: 'iVNcrNE9BRZBC9Aqf753iZiZfbszeAVUoikgT9yvr2a',
    chainId: 101,
    decimals: 6,
    name: 'Investin Protocol',
    symbol: 'IVN',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/iVNcrNE9BRZBC9Aqf753iZiZfbszeAVUoikgT9yvr2a/logo.png',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'investin' },
  },
  MEAN: {
    address: 'MEANeD3XDdUmNMsRGjASkSWdC8prLYsoRJ61pPeHctD',
    chainId: 101,
    decimals: 6,
    name: 'MEAN',
    symbol: 'MEAN',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MEANeD3XDdUmNMsRGjASkSWdC8prLYsoRJ61pPeHctD/logo.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'meanfi' },
  },
  GLEEK: {
    address: '4ACuWnJZjE1Q51589mBmmyfD82RZ4LNFVeuPdSRFPc3L',
    chainId: 101,
    decimals: 9,
    name: 'GLEEK',
    symbol: 'GLEEK',
    logoURI:
      'https://bafkreidxnygbex6dsrdz6xlqhtdzgub3vqrhx36sfzglfmstebktmn24um.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'gleek' },
  },
  TNSR: {
    address: 'TNSRxcUxoT9xBG3de7PiJyTDYu7kskLqcpddxnEJAS6',
    chainId: 101,
    decimals: 9,
    name: 'Tensor',
    symbol: 'TNSR',
    logoURI: 'https://arweave.net/beGAyeIzjV_UkyjFtxbkZyi_YqfOBWayiQ0B6wqWygY',
    tags: ['community'],
    extensions: { coingeckoId: 'tensor' },
  },
  SECROT: {
    address: '7VQzGuk2xbWteJ2A5yzGkuDmCQLQGAsvD9MjmW8VutTG',
    chainId: 101,
    decimals: 6,
    name: 'Secrot Servoce',
    symbol: 'SECROT',
    logoURI:
      'https://bafybeigwk4iji6ggqylastjcb5ssvar7l44tdwsckqhgv32jmghyauxplu.ipfs.cf-ipfs.com',
    tags: ['community'],
  },
  JIZZLORD: {
    address: '69SEPKGCzFzJLoSawb3xPfcqnZdiVm21Xu28AWZB5pzk',
    chainId: 101,
    decimals: 6,
    name: 'JizzLord',
    symbol: 'JIZZLORD',
    logoURI:
      'https://raw.githubusercontent.com/Terminator0611/Jizz/main/jizzlord.png',
    tags: ['community'],
  },
  SEAT: {
    address: '29ad8JW1YcVT3mxcvxJpe3EzWqXCGD7KaSRWrc3TEMWG',
    chainId: 101,
    decimals: 6,
    name: 'Cris Hensan',
    symbol: 'SEAT',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmYNgQq18FFX9ybECYMF7v4JqHRmDwojrJTv58nrFRsrha',
    tags: ['community'],
    extensions: { coingeckoId: 'cris-hensan' },
  },
  RAD: {
    address: 'B6aJ3TGfme3SMnLSouHXqWXjVFqYyqj7czzhzr8WJFAi',
    chainId: 101,
    decimals: 4,
    name: 'RAD',
    symbol: 'RAD',
    logoURI:
      'https://raw.githubusercontent.com/NFTBrickLayer/zillaz-assets/main/RAD-logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'rad' },
  },
  SDO: {
    address: '7SZUnH7H9KptyJkUhJ5L4Kee5fFAbqVgCHvt7B6wg4Xc',
    chainId: 101,
    decimals: 5,
    name: 'TheSolanDAO',
    symbol: 'SDO',
    logoURI: 'https://thesolandao.com/logohero.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'thesolandao' },
  },
  DLP8: {
    address: '9AGm8DWXiB4PwvH1V2r31ChsTE2f2TDySvDbfJmfaMBq',
    chainId: 101,
    decimals: 8,
    name: 'DLP8 Coin',
    symbol: 'DLP8',
    logoURI: 'https://arweave.net/T2RURwcLfB2xSjrfksI7R2SL3fKyZoqlTPiLCE3fsBo',
    tags: ['community'],
  },
  MALL: {
    address: '5EbpXhW7t8ypBF3Q1X7odFaHjuh7XJfCohXR3VYAW32i',
    chainId: 101,
    decimals: 3,
    name: 'MetaMall',
    symbol: 'MALL',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5EbpXhW7t8ypBF3Q1X7odFaHjuh7XJfCohXR3VYAW32i/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'metamall' },
  },
  AVAX: {
    address: 'KgV1GvrHQmRBY8sHQQeUKwTm2r2h8t4C8qt12Cw1HVE',
    chainId: 101,
    decimals: 8,
    name: 'AVAX (Portal)',
    symbol: 'AVAX',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/AVAX_wh.png',
    tags: ['wormhole', 'old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'avalanche-wormhole' },
  },
  CHAN: {
    address: 'ChanGGuDHboPswpTmKDfsTVGQL96VHhmvpwrE4UjWssd',
    chainId: 101,
    decimals: 9,
    name: 'memechan',
    symbol: 'CHAN',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f617277656176652e6e65742f55323763623969577435694d55673152743364726b56315a38487076777261474a4d57615f6b4331563155',
    tags: ['community'],
    extensions: { coingeckoId: 'memechan' },
  },
  BOGGY: {
    address: 'GMEhF4sFXd9PRR9KJo7hyPjeHdcdg5yxTNP22KKNyWvZ',
    chainId: 101,
    decimals: 9,
    name: 'Boggy Coin',
    symbol: 'BOGGY',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f626f676779636f696e2e636f6d2f77702d636f6e74656e742f75706c6f6164732f323032342f30352f6f6666696369616c5f73796d626f6c2e706e67',
    tags: ['community'],
    extensions: { coingeckoId: 'boggy-coin' },
  },
  $BIAO: {
    address: '3W52uCb8NW8ruMF9mmJX3oKiYAjdPai4633srsZFQCS6',
    chainId: 101,
    decimals: 6,
    name: 'Biao Coin',
    symbol: '$BIAO',
    logoURI: 'https://i.imgur.com/BNautKe.png',
    tags: ['community'],
    extensions: { coingeckoId: 'biao-coin' },
  },
  YOLO: {
    address: '4jE4VuqFWZfmSXjTDD5KUoN2qkVu96nPTPj4mhs5PA1W',
    chainId: 101,
    decimals: 5,
    name: 'YoloNolo Coin',
    symbol: 'YOLO',
    logoURI:
      'https://nftstorage.link/ipfs/bafybeiepttutbes4mzojtmlvx3iqgwvs673czt5g7k7uoficx4vmjunmie/YOLO LOGO.png',
    tags: ['community'],
  },
  WIFS: {
    address: '6vUQsePjhpH67Db6p7Koj1wQsQP1UtovBkWXSrC1DkaA',
    chainId: 101,
    decimals: 9,
    name: 'dogwifscarf',
    symbol: 'WIFS',
    logoURI:
      'https://bafybeigzgek3mu5mobqo7kwk77g5ieliaqmsbdeav6qm6jj62mhdt6lw3q.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'dogwifscarf' },
  },
  WOOP: {
    address: 'A3HyGZqe451CBesNqieNPfJ4A9Mu332ui8ni6dobVSLB',
    chainId: 101,
    decimals: 5,
    name: 'WOOP',
    symbol: 'WOOP',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A3HyGZqe451CBesNqieNPfJ4A9Mu332ui8ni6dobVSLB/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'woop' },
  },
  YUGE: {
    address: 'Fpc2tnmme78kjsttyuSjfUfgB14vk15a3P13P9zZYvov',
    chainId: 101,
    decimals: 6,
    name: 'YUGE COIN',
    symbol: 'YUGE',
    logoURI:
      'https://bafkreiawhwcqtxdim7guin2pyepbbgsivkphgq4w24gbnvcwpp7z2aj2cq.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  KART: {
    address: 'GDzfemoYR5GkbK4YupYpyq3E8Du9fSfKXxKDpkdrqGjs',
    chainId: 101,
    decimals: 6,
    name: 'Klausen Art',
    symbol: 'KART',
    logoURI: 'https://arweave.net/X9ohmd49MmOEYi4dfMyOv_M24NnnyzQXZEoALTuQ0ao',
    tags: ['community'],
  },
  MELL: {
    address: 'MELLd8PyFoeNW3D5VaUe7L96eZeihtrzgLWrbKz5DR2',
    chainId: 101,
    decimals: 6,
    name: 'Mellivora',
    symbol: 'MELL',
    logoURI: 'https://arweave.net/YIrpNAQuLbOMvI8hSRCzJakzm5qiT_wv1Av5mpcYxrY',
    tags: ['community'],
    extensions: { coingeckoId: 'mellivora' },
  },
  $BLEK: {
    address: 'EYaJJKb2VDZuYKEWf5TjngFRc43cMgttPuRtcJwQt35z',
    chainId: 101,
    decimals: 5,
    name: 'Blekrok',
    symbol: '$BLEK',
    logoURI:
      'https://bafkreictirsdhaoboqtpji3wdqk6usuxgd42jd56tua5vkz72jvydzhcpu.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  WUF: {
    address: '73xsLcBnLnc9bh81cqVKqj8uEyiarXng5ZwJuTbnVebG',
    chainId: 101,
    decimals: 4,
    name: 'Wuffi',
    symbol: 'WUF',
    logoURI:
      'https://raw.githubusercontent.com/wuffi-inu/wuffi-inu.github.io/release-1.1.0/assets/wuffi-on-chain-image.png',
    tags: ['community'],
    extensions: { coingeckoId: 'wuffi' },
  },
  $Clown: {
    address: 'V7ntWk4D9Frwaec2CUjMRMMsJG28Cd31HxDKNdP1qjM',
    chainId: 101,
    decimals: 9,
    name: 'ClownOnSolana',
    symbol: '$Clown',
    logoURI: 'https://arweave.net/q1EkX68UfbUnvjpA87GaRjGxVaP6f0lYMBeQXmFzN6w',
    tags: ['community'],
  },
  KPOP: {
    address: 'DcUoGUeNTLhhzyrcz49LE7z3MEFwca2N9uSw1xbVi1gm',
    chainId: 101,
    decimals: 9,
    name: 'K-Pop',
    symbol: 'KPOP',
    logoURI: 'https://arweave.net/rzUo3sj5mtF5Q1ceUp0R7X_BOdULgdbL9OhzaFoP11U',
    tags: ['community'],
    extensions: { coingeckoId: 'k-pop-on-solana' },
  },
  UPDOG: {
    address: 'HJ39rRZ6ys22KdB3USxDgNsL7RKiQmsC3yL8AS3Suuku',
    chainId: 101,
    decimals: 9,
    name: "What's Updog?",
    symbol: 'UPDOG',
    logoURI: 'https://arweave.net/utcdLmTdNHmyD02oMfQn9Yc5L4UbwrtCtka3gpQQas4',
    tags: ['community'],
    extensions: { coingeckoId: 'what-s-updog' },
  },
  UberJeets: {
    address: 'G2ShfTkHaPgY1YUqZzB611coeFpbhyGirzgGmyW5fEhV',
    chainId: 101,
    decimals: 6,
    name: 'Uber Jeets',
    symbol: 'UberJeets',
    logoURI:
      'https://ipfs.io/ipfs/QmXoowrVjJGbJwu8PcNrRrkgaMvmRSWhtJzXgctookqrjf',
    tags: ['community'],
  },
  HONEY: {
    address: '4vMsoUT2BWatFweudnQM1xedRLfJgJ7hswhcpz4xgBTy',
    chainId: 101,
    decimals: 9,
    name: 'HONEY',
    symbol: 'HONEY',
    logoURI:
      'https://hivemapper-marketing-public.s3.us-west-2.amazonaws.com/Hivemapper_HONEY_token.png',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'hivemapper' },
  },
  SOL100: {
    address: 'GkDg1ZfoFkroLAwLqtJNXhxCDg8gmKxHAGxSUZagYFfE',
    chainId: 101,
    decimals: 9,
    name: 'SOL100',
    symbol: 'SOL100',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GkDg1ZfoFkroLAwLqtJNXhxCDg8gmKxHAGxSUZagYFfE/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  USDCav: {
    address: 'FHfba3ov5P3RjaiLVgh8FTv4oirxQDoVXuoUUDvHuXax',
    chainId: 101,
    decimals: 6,
    name: 'USD Coin (Portal from Avalanche)',
    symbol: 'USDCav',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/USDCav_wh.png',
    tags: ['wormhole'],
  },
  vSOL: {
    address: 'vSoLxydx6akxyMD9XEcPvGYNGq6Nn66oqVb3UkGkei7',
    chainId: 101,
    decimals: 9,
    name: 'The Vault',
    symbol: 'vSOL',
    logoURI:
      'https://cloudflare-ipfs.com/ipfs/bafkreig55mf3lazzbgndiqyqvdmchdsykvvebww7cqlws6ywgog5xfdzta',
    tags: ['community'],
    extensions: { coingeckoId: 'the-vault-staked-sol' },
  },
  EVOL: {
    address: 'EVoLsbmQXT6R3b11WjPpGEXyCjw1zmmir271XqDbKRsg',
    chainId: 101,
    decimals: 9,
    name: 'Evolve',
    symbol: 'EVOL',
    logoURI:
      'https://bafkreidpaebbynfkp2zpljslaugedn2fkvlujoanerpcifgppxdxhm4kxm.ipfs.nftstorage.link',
    tags: ['community', 'token-2022'],
    extensions: { coingeckoId: 'evolve' },
  },
  GODZ: {
    address: 'BB33fYoeBVA2uv119be9tKvmXeuwtcx1W25N9KFNd2ca',
    chainId: 101,
    decimals: 9,
    name: 'Godz Token',
    symbol: 'GODZ',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BB33fYoeBVA2uv119be9tKvmXeuwtcx1W25N9KFNd2ca/logo.jpg',
    tags: ['old-registry', 'solana-fm'],
  },
  NEAR: {
    address: 'BYPsjxa3YuZESQz1dKuBw1QSFCSpecsm8nCQhY5xbU1Z',
    chainId: 101,
    decimals: 9,
    name: 'NEAR (Allbridge from Near)',
    symbol: 'NEAR',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BYPsjxa3YuZESQz1dKuBw1QSFCSpecsm8nCQhY5xbU1Z/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  dSOL: {
    address: 'Dso1bDeDjCQxTrWHqUUi63oBvV7Mdm6WaobLbQ7gnPQ',
    chainId: 101,
    decimals: 9,
    name: 'Drift Staked SOL',
    symbol: 'dSOL',
    logoURI: 'https://drift-public.s3.eu-central-1.amazonaws.com/dSOL.svg',
    tags: ['community'],
    extensions: { coingeckoId: 'drift-staked-sol' },
  },
  Doogle: {
    address: 'F6TsRcjtLBzkdtZYqjTPVq9WtnwHMMc1WcQguEgCpump',
    chainId: 101,
    decimals: 6,
    name: 'Doogle',
    symbol: 'Doogle',
    logoURI:
      'https://ipfs.io/ipfs/QmYSCYCAhujpS1u9E4yi8qCjpCQc1iFkstqnYLxw36jqz9',
    tags: ['community'],
    extensions: { coingeckoId: 'doogle' },
  },
  SWH: {
    address: 'ASsnSwFhGVREnuN6YmZQKietjprv731BbETDWQsreAwj',
    chainId: 101,
    decimals: 9,
    name: 'simbawifhat',
    symbol: 'SWH',
    logoURI:
      'https://ipfs.io/ipfs/QmTUg6JjcSzRjeZyDgguqVi484dcPk7r6DVbqwxWA4dufa',
    tags: ['community'],
  },
  'sUSDC-9': {
    address: 'JEFFSQ3s8T3wKsvp4tnRAsUBW7Cqgnf8ukBZC4C8XBm1',
    chainId: 101,
    decimals: 9,
    name: 'Saber Wrapped USD Coin (9 decimals)',
    symbol: 'sUSDC-9',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JEFFSQ3s8T3wKsvp4tnRAsUBW7Cqgnf8ukBZC4C8XBm1/icon.png',
    tags: ['old-registry'],
  },
  DIO: {
    address: 'BiDB55p4G3n1fGhwKFpxsokBMqgctL4qnZpDH1bVQxMD',
    chainId: 101,
    decimals: 9,
    name: 'Decimated',
    symbol: 'DIO',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BiDB55p4G3n1fGhwKFpxsokBMqgctL4qnZpDH1bVQxMD/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'decimated' },
  },
  WTRBR: {
    address: 'Aymdf5Fp2URJNcsHpEF2NqEqFHiqvvhrDvYQtyZFw7zn',
    chainId: 101,
    decimals: 4,
    name: 'Water Bears',
    symbol: 'WTRBR',
    logoURI: 'https://arweave.net/GZNQl83S1kK5kvak1yvMrI8muuPVm80fdliPcgwsWfw',
    tags: ['community'],
  },
  HMM: {
    address: 'BWhsvkyrUJqVvrAKjGYLpnTuUCG4SPEh6xVKcjnYCi27',
    chainId: 101,
    decimals: 9,
    name: 'HMM',
    symbol: 'HMM',
    logoURI: 'https://arweave.net/y7pZvMNt042EUDDGJmzKh4UUXYx1lpWg1JRtGE37ap8',
    tags: ['community'],
    extensions: { coingeckoId: 'hmmonsol' },
  },
  NARD: {
    address: 'F89doZeeUe9ajcU6gNR1F5RLaQWcJMdPDNSuLuvJUst9',
    chainId: 101,
    decimals: 6,
    name: 'Nard',
    symbol: 'NARD',
    logoURI:
      'https://cf-ipfs.com/ipfs/bafybeie62um5xrgkk6ct7eljhxkuuejccao7br4joj2ncsv55j2oro6z7m',
    tags: ['community'],
  },
  MDF: {
    address: 'ALQ9KMWjFmxVbew3vMkJj3ypbAKuorSgGst6svCHEe2z',
    chainId: 101,
    decimals: 6,
    name: 'MatrixETF DAO Finance',
    symbol: 'MDF',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALQ9KMWjFmxVbew3vMkJj3ypbAKuorSgGst6svCHEe2z/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'matrixetf' },
  },
  JENSEN: {
    address: 'HfQuaLjMguh7vyZqqrWuVqpsjQd7tAPrzBWKqA3pDTH3',
    chainId: 101,
    decimals: 9,
    name: 'JENSEN HUANG',
    symbol: 'JENSEN',
    logoURI: 'https://i.ibb.co/k5TC2w0/JENSEEEEN.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'jensen-huang-meme' },
  },
  CYS: {
    address: 'BRLsMczKuaR5w9vSubF4j8HwEGGprVAyyVgS4EX7DKEg',
    chainId: 101,
    decimals: 6,
    name: 'Cykura',
    symbol: 'CYS',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BRLsMczKuaR5w9vSubF4j8HwEGGprVAyyVgS4EX7DKEg/logo.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'cyclos' },
  },
  DABLNS: {
    address: 'dab15vg2k8zGJPy4xM2DH2G2BY3khrqduXapzYAV3y8',
    chainId: 101,
    decimals: 8,
    name: 'DABLOONS',
    symbol: 'DABLNS',
    logoURI: 'https://arweave.net/AYbNmLsPhYrlgrVtCERbp7UysLGL-IXQQrTL826ai6Y',
    tags: ['community'],
  },
  MELON: {
    address: '7DGJnYfJrYiP5CKBx6wpbu8F5Ya1swdFoesuCrAKCzZc',
    chainId: 101,
    decimals: 6,
    name: 'Melon Dog',
    symbol: 'MELON',
    logoURI: 'https://arweave.net/5VQtSLt9kwn-FlP4ulK9DjuowQZnLm2zarHqda2MhPI',
    tags: ['community'],
    extensions: { coingeckoId: 'melon-dog' },
  },
  BCAT: {
    address: '7bQsj9DciGXs6cTkhB3D1WbcEjuMpmD7amQRWjEVBpu',
    chainId: 101,
    decimals: 6,
    name: 'Bodega Cat',
    symbol: 'BCAT',
    logoURI:
      'https://bafybeiecqv3kypfokkfggfoeiocpfktvsitknxzwktsymvcofvb7jadppy.ipfs.nftstorage.link',
    tags: ['community'],
  },
  DILDO: {
    address: 'CDW5fC3Fp69Km6Kg4xTf5SiFqP3B24wt2SVK9GwG6LUs',
    chainId: 101,
    decimals: 9,
    name: 'Dildo BagHands',
    symbol: 'DILDO',
    logoURI:
      'https://ipfs.io/ipfs/QmdKAViQSX5tyzJBAVJfukRWpjwrPM2UjSGeixAbryY9YD',
    tags: ['community'],
  },
  SCY: {
    address: 'SCYfrGCw8aDiqdgcpdGjV6jp4UVVQLuphxTDLNWu36f',
    chainId: 101,
    decimals: 9,
    name: 'Synchrony',
    symbol: 'SCY',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SCYfrGCw8aDiqdgcpdGjV6jp4UVVQLuphxTDLNWu36f/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'synchrony' },
  },
  BRETT: {
    address: 'DxtssVdyYe4wWE5f5zEgx2NqtDFbVL3ABGY62WCycHWg',
    chainId: 101,
    decimals: 9,
    name: 'Brett',
    symbol: 'BRETT',
    logoURI:
      'https://node1.irys.xyz/Lc1vGYEY45eezP3nJcHDbUFOpn0jEQeTvoJ8akpRkXQ',
    tags: ['community'],
    extensions: { coingeckoId: 'brett' },
  },
  PAW: {
    address: '3WV4fTWGvtWNvQb8oVU4t99By8KztDLtExqHnkPfHAA9',
    chainId: 101,
    decimals: 9,
    name: 'CopyCats token',
    symbol: 'PAW',
    logoURI:
      'https://imagedelivery.net/aAmca-Po70V1nH4n9cT-uA/98c9b68b-1494-43d1-0c2c-31e5d0129a00/public',
    tags: ['old-registry', 'solana-fm'],
  },
  USEDCAR: {
    address: '9gwTegFJJErDpWJKjPfLr2g2zrE3nL1v5zpwbtsk3c6P',
    chainId: 101,
    decimals: 9,
    name: 'A Gently Used 2001 Honda Civic',
    symbol: 'USEDCAR',
    logoURI:
      'https://gateway.irys.xyz/7TTPG48afTZE8Ioeaa4XlZ7F4Q5g4lLYkC7N1Yr1pFo',
    tags: ['community'],
    extensions: { coingeckoId: 'a-gently-used-2001-honda' },
  },
  soLINK: {
    address: 'CWE8jPTUYhdCTZYWPTe1o5DFqfdjzWKc9WKz6rSjQUdG',
    chainId: 101,
    decimals: 6,
    name: 'Wrapped Chainlink (Sollet)',
    symbol: 'soLINK',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CWE8jPTUYhdCTZYWPTe1o5DFqfdjzWKc9WKz6rSjQUdG/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  DED: {
    address: '7raHqUrZXAqtxFJ2wcmtpH7SQYLeN9447vD4KhZM7tcP',
    chainId: 101,
    decimals: 2,
    name: 'DED',
    symbol: 'DED',
    logoURI:
      'https://cdn.bridgesplit.com/?fit=crop&height=400&width=400&image=https://www.arweave.net/nE_JLSoMnDq5bOuQLO7-UhTvlOmC6264v90IVB7ntjQ?ext=gif',
    tags: ['community'],
  },
  KEKW: {
    address: 'CEYNkwuEXU1KD3MN47NaMvHznPPimR15Sjfv6Y2r1SVw',
    chainId: 101,
    decimals: 6,
    name: 'KEKW',
    symbol: 'KEKW',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmTSxnMHGHdFpjZVUshFSvmmcpCYom9XEkJsS63mYMiDyw',
    tags: ['community'],
  },
  SAMO: {
    address: '7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU',
    chainId: 101,
    decimals: 9,
    name: 'Samoyed Coin',
    symbol: 'SAMO',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'samoyedcoin' },
  },
  EMMY: {
    address: '8Qrc2pf9p24NyJVG1FagnqJXwKw6h5L5McxnMfJoUxev',
    chainId: 101,
    decimals: 9,
    name: 'Emmy',
    symbol: 'EMMY',
    logoURI:
      'https://gateway.irys.xyz/ssZtKHuRF1VRdRBZ5Oo5PdrPVvQ_c94EnCzR0CdtVPw',
    tags: ['community'],
    extensions: { coingeckoId: 'emmy' },
  },
  SLB: {
    address: '2uRFEWRBQLEKpLmF8mohFZGDcFQmrkQEEZmHQvMUBvY7',
    chainId: 101,
    decimals: 9,
    name: 'Solberg',
    symbol: 'SLB',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2uRFEWRBQLEKpLmF8mohFZGDcFQmrkQEEZmHQvMUBvY7/logo.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'solberg' },
  },
  KNK: {
    address: 'kNkT1RDnexWqYP3EYGyWv5ZtazB8CfgGAfJtv9AQ3kz',
    chainId: 101,
    decimals: 9,
    name: 'Kineko',
    symbol: 'KNK',
    logoURI:
      'https://www.arweave.net/u7prAs3T9UHwykErAXDfX306yAxFU08PpgvN819K_so?ext=png',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'kineko-knk' },
  },
  EPEP: {
    address: 'RUpbmGF6p42AAeN1QvhFReZejQry1cLkE1PUYFVVpnL',
    chainId: 101,
    decimals: 8,
    name: 'Epep',
    symbol: 'EPEP',
    logoURI:
      'https://ipfs.io/ipfs/QmQHhrbf5g3TkpYH5UYBjoXYY83EzA7cq9SZHR4sGmToN5',
    tags: ['community'],
  },
  JUNGLE: {
    address: 'Aogv6j1wWiBAZcqRNN1Y89eozda2ke6rkc4CYy7c4iCi',
    chainId: 101,
    decimals: 9,
    name: 'Jungle',
    symbol: 'JUNGLE',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Aogv6j1wWiBAZcqRNN1Y89eozda2ke6rkc4CYy7c4iCi/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'jungle' },
  },
  SBAE: {
    address: 'BWWWurbodjGbovFetc3FC6oSbqkdoE62E1XqZ7X4pump',
    chainId: 101,
    decimals: 6,
    name: 'Salt Bae For The People',
    symbol: 'SBAE',
    logoURI:
      'https://framerusercontent.com/images/CQzb6jENwKQEt1JkKTgSFN9pig.png',
    tags: ['community'],
    extensions: { coingeckoId: 'salt-bae-for-the-people' },
  },
  LFNTY: {
    address: 'LFNTYraetVioAPnGJht4yNg2aUZFXR776cMeN9VMjXp',
    chainId: 101,
    decimals: 6,
    name: 'Lifinity',
    symbol: 'LFNTY',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LFNTYraetVioAPnGJht4yNg2aUZFXR776cMeN9VMjXp/logo.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'lifinity' },
  },
  MVP: {
    address: '55qMv1HtV8fqRjnFwDb9yDi9tBCeV8xwfgrPKgiJk5DN',
    chainId: 101,
    decimals: 8,
    name: 'MAGA VP (Wormhole)',
    symbol: 'MVP',
    logoURI: 'https://i.imgur.com/cFpN7lL.png',
    tags: ['community'],
    extensions: { coingeckoId: 'maga-vp' },
  },
  BULB: {
    address: 'A6JVLMAjR1aeCfz3X2z4vVQ9GafYWVT75tC5V5qefja2',
    chainId: 101,
    decimals: 9,
    name: 'BULB Token',
    symbol: 'BULB',
    logoURI:
      'https://www.arweave.net/3UIriLHyPZSzz637FQjn5kNm72Sc-2DG5vK45Vkmx3o?ext=png',
    tags: ['community', 'solana-fm'],
  },
  PEPECAT: {
    address: 'FfhArvgv8WB7eZ6qwENMouJRzcVpQVDoDtTuHrCk4Cxi',
    chainId: 101,
    decimals: 6,
    name: 'Pepe The Cat',
    symbol: 'PEPECAT',
    logoURI:
      'https://bafkreihaltjxv2fn2niuu5odd6ib74d6qidy744ntvplb74aosr522pjg4.ipfs.nftstorage.link',
    tags: ['community'],
  },
  SPWN: {
    address: '5U9QqCPhqXAJcEv9uyzFJd5zhN93vuPk1aNNkXnUfPnt',
    chainId: 101,
    decimals: 9,
    name: 'Bitspawn Token',
    symbol: 'SPWN',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5U9QqCPhqXAJcEv9uyzFJd5zhN93vuPk1aNNkXnUfPnt/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'bitspawn' },
  },
  Orcat: {
    address: '3iG5NRc36xaVnWKdFKw9HwDjG2Bf58YitCJmGUm7hrFJ',
    chainId: 101,
    decimals: 6,
    name: 'Orcat',
    symbol: 'Orcat',
    logoURI:
      'https://bafybeiez523kblwpduyyhrtr2ne4xpmzk3fj2hiuzoalsnhu2qin23llvu.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  DEO: {
    address: 'DeoP2swMNa9d4SGcQkR82j4RYYeNhDjcTCwyzEhKwfAf',
    chainId: 101,
    decimals: 9,
    name: 'Deo',
    symbol: 'DEO',
    logoURI: 'https://arweave.net/RuNPo7tAR05f_7g_Yz36NMyVKHAFrOXjIEKPtOZx2Qc',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'player-2' },
  },
  LC: {
    address: '5HJ3fCrCXUEEraLJUBLvhbNwSYS2RNzjuuS33FLH7UjP',
    chainId: 101,
    decimals: 9,
    name: 'Lion Cat',
    symbol: 'LC',
    logoURI:
      'https://bafkreiawncphofu5xtihstckvu34knehgsf4vple45dlfdupnza6xc3txq.ipfs.nftstorage.link',
    tags: ['community'],
  },
  LUIS: {
    address: '5wU4tUcAbds7d5cmnGK2otHa9gbayYsD2mhz1reR6c91',
    chainId: 101,
    decimals: 6,
    name: 'Tongue Cat',
    symbol: 'LUIS',
    logoURI:
      'https://bafkreihtcqx42secvikbvwtqzcrc2fldtapwl6w4qwdfu4yxh43n6l243u.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'tongue-cat' },
  },
  FLOCKA: {
    address: '9n8b1EXLCA8Z22mf7pjLKVNzuQgGbyPfLrmFQvEcHeSU',
    chainId: 101,
    decimals: 6,
    name: 'Waka Flocka',
    symbol: 'FLOCKA',
    logoURI:
      'https://bafkreif5xckv3cqz5t3cml5u2qjwjcx4aprzeaztt5kjxj445t25uyt33e.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'waka-flocka' },
  },
  MMOSH: {
    address: 'FwfrwnNVLGyS8ucVjWvyoRdFDpTY8w6ACMAxJ4rqGUSS',
    chainId: 101,
    decimals: 9,
    name: 'MMOSH: The Stoked Token',
    symbol: 'MMOSH',
    logoURI:
      'https://shdw-drive.genesysgo.net/7nPP797RprCMJaSXsyoTiFvMZVQ6y1dUgobvczdWGd35/MMoshCoin.png',
    tags: ['community'],
  },
  SPIKE: {
    address: 'sPiKEYAqoaGYYBAnPxro8NLSYLu93sr56n352jJRLN5',
    chainId: 101,
    decimals: 9,
    name: 'Spike',
    symbol: 'SPIKE',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f697066732e696f2f697066732f516d515362674c445432387359424e5953594d797a717a3755576557545a454b3274336448356a687975426752653f66696c656e616d653d5370696b652532305046502d322e706e67',
    tags: ['community'],
    extensions: { coingeckoId: 'spike-2' },
  },
  NAZA: {
    address: 'FjtwiPH9gyffNX7mdE2ZS4s8hZRfZB2VRzyaKMNpaakS',
    chainId: 101,
    decimals: 9,
    name: 'NAZA',
    symbol: 'NAZA',
    logoURI:
      'https://ipfs.io/ipfs/QmRtiNkm4mrdLm6Fm1BqMX685XVxm7tSRH54dJ8FqfHFaY',
    tags: ['community'],
  },
  aeWETH: {
    address: 'AaAEw2VCw1XzgvKB8Rj2DyK2ZVau9fbt2bE8hZFWsMyE',
    chainId: 101,
    decimals: 9,
    name: 'Wrapped ETH (Allbridge from Ethereum)',
    symbol: 'aeWETH',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeGn77dhg1KXRRFeSwwMiykZnZPw5JXW6naf2aQgZDQf/logo.png',
    tags: ['old-registry'],
  },
  LIQ: {
    address: '4wjPQJ6PrkC4dHhYghwJzGBVP78DkBzA2U3kHoFNBuhj',
    chainId: 101,
    decimals: 6,
    name: 'LIQ Protocol',
    symbol: 'LIQ',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4wjPQJ6PrkC4dHhYghwJzGBVP78DkBzA2U3kHoFNBuhj/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'liq-protocol' },
  },
  WSB: {
    address: 'AkVt31h8vgji5wF4nVbq1QmBV5wBoe8JdSoDTkDhQwEw',
    chainId: 101,
    decimals: 6,
    name: 'Wall Street Bets',
    symbol: 'WSB',
    logoURI:
      'https://bafkreidh7trekyj225tiafhfj6okhfqon2tihfn6d2hkic4rip7boqxmwq.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  SANTA: {
    address: 'EctmRn2jMAdTDvQdG7mxadyiTvhGZiGYNrt9PWe6zioG',
    chainId: 101,
    decimals: 9,
    name: 'Santaclaus',
    symbol: 'SANTA',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EctmRn2jMAdTDvQdG7mxadyiTvhGZiGYNrt9PWe6zioG/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  BOT: {
    address: 'AkhdZGVbJXPuQZ53u2LrimCjkRP6ZyxG1SoM85T98eE1',
    chainId: 101,
    decimals: 8,
    name: 'Starbots Token',
    symbol: 'BOT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AkhdZGVbJXPuQZ53u2LrimCjkRP6ZyxG1SoM85T98eE1/logo.png',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'starbots' },
  },
  HCOIN: {
    address: '4B619RbcXbXrKTzNVgDSRiUn9wfxWgA1w1oFLveGacNy',
    chainId: 101,
    decimals: 3,
    name: 'Hydrogencoin',
    symbol: 'HCOIN',
    logoURI:
      'https://raw.githubusercontent.com/dataalg/Hydrogen/main/4B619RbcXbXrKTzNVgDSRiUn9wfxWgA1w1oFLveGacNy/logo.png',
    tags: ['old-registry'],
  },
  BONES: {
    address: 'bonegFPgrpZ4bfVn3kQK1aMbGYddWtfMAywNt5LsuVE',
    chainId: 101,
    decimals: 2,
    name: 'BONES Token',
    symbol: 'BONES',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/bonegFPgrpZ4bfVn3kQK1aMbGYddWtfMAywNt5LsuVE/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'soul-dog-city-bones' },
  },
  TTT: {
    address: 'FNFKRV3V8DtA3gVJN6UshMiLGYA8izxFwkNWmJbFjmRj',
    chainId: 101,
    decimals: 6,
    name: 'TabTrader Token',
    symbol: 'TTT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FNFKRV3V8DtA3gVJN6UshMiLGYA8izxFwkNWmJbFjmRj/logo.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'tabtrader' },
  },
  wstETH: {
    address: 'ZScHuTtqZukUrtZS43teTKGs2VqkKL8k4QCouR2n6Uo',
    chainId: 101,
    decimals: 8,
    name: 'Lido Wrapped Staked ETH',
    symbol: 'wstETH',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ZScHuTtqZukUrtZS43teTKGs2VqkKL8k4QCouR2n6Uo/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  RACEFI: {
    address: 'AAmGoPDFLG6bE82BgZWjVi8k95tj9Tf3vUN7WvtUm2BU',
    chainId: 101,
    decimals: 6,
    name: 'RaceFi Token',
    symbol: 'RACEFI',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AAmGoPDFLG6bE82BgZWjVi8k95tj9Tf3vUN7WvtUm2BU/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'racefi' },
  },
  ASTRALIS: {
    address: 'ASTRALvKjGK2xk2pamjMBU5dav5cEQa6zpKCP6FZ7BAJ',
    chainId: 101,
    decimals: 6,
    name: 'ASTRALIS',
    symbol: 'ASTRALIS',
    logoURI:
      'https://shdw-drive.genesysgo.net/AcG1tjtJ3vR1XzSCcVArheGLrwmMsphqExa6VodLymGF/astralis_1500x1500_8bit.png',
    tags: ['community'],
  },
  META: {
    address: 'METADDFL6wWMWEoKTFJwcThTbUmtarRJZjRpzUvkxhr',
    chainId: 101,
    decimals: 9,
    name: 'META',
    symbol: 'META',
    logoURI:
      'https://6hgaiayuroxlfrn3djpb7mx4ocwielgo2ovpj4ka4v47jsxj2p2a.arweave.net/8cwEAxSLrrLFuxpeH7L8cKyCLM7TqvTxQOV59Mrp0_Q?ext=png',
    tags: ['community'],
    extensions: { coingeckoId: 'meta-2' },
  },
  PHNX: {
    address: 'JAzesW3tU2VLDx99pwgkDMca2DTNsEhSWG8sj21B1niz',
    chainId: 101,
    decimals: 6,
    name: 'Phoenix',
    symbol: 'PHNX',
    logoURI:
      'https://bafybeicmwtzskjhcsnoymhsv5fmlruwoxgounmcweiei2qnxh6ayesupiq.ipfs.nftstorage.link',
    tags: ['community', 'token-2022'],
  },
  Culture: {
    address: 'BuAL6Qt1CJbfa6wnHnYNqj572nQpKGR7C5xe4jn3icBp',
    chainId: 101,
    decimals: 9,
    name: 'For The Culture',
    symbol: 'Culture',
    logoURI: 'https://i.ibb.co/x6gxSQN/IMG-20240404-012003-903.png',
    tags: ['community'],
  },
  ILU: {
    address: 'Gm6szibJfB1ZzUxNYf85nXwFchugqTqNyE5fDwWfBc7K',
    chainId: 101,
    decimals: 9,
    name: 'ILoveU Token',
    symbol: 'ILU',
    logoURI:
      'https://raw.githubusercontent.com/rjdrar/crypto/main/symbol-1.png',
    tags: ['old-registry'],
  },
  NINJA: {
    address: '2xP43MawHfU7pwPUmvkc6AUWg4GX8xPQLTGMkSZfCEJT',
    chainId: 101,
    decimals: 9,
    name: 'Shinobi',
    symbol: 'NINJA',
    logoURI:
      'https://bafkreigqchichpw3ow5vrax2sne2fp2uudgjb3vcyqifgttc2echn3h6yy.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'shinobi-2' },
  },
  PISS: {
    address: '7qULVSb7XdoKBDDa7WnuUvoTx5ye4Vrj94iPcwibyQ1F',
    chainId: 101,
    decimals: 6,
    name: 'Frog Piss',
    symbol: 'PISS',
    logoURI:
      'https://shdw-drive.genesysgo.net/BLFjCRyZkaF9GGwiorXPRwYJVpxD7VrrKNH7XccWUCWF/frogpiss.png',
    tags: ['community'],
  },
  DARC: {
    address: 'CpFE715P5DnDoJj9FbCRcuyHHeTXNdRnvzNkHvq1o23U',
    chainId: 101,
    decimals: 8,
    name: 'DARC Token',
    symbol: 'DARC',
    logoURI:
      'https://raw.githubusercontent.com/Konstellation/DARC_token/main/256darctoken.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'darcmatter-coin' },
  },
  $BAYSE: {
    address: '9DgMYGtyeNzGchoSJWLZsueyTYa3v9bSpkzzfRhYJKDo',
    chainId: 101,
    decimals: 9,
    name: 'coynbayse',
    symbol: '$BAYSE',
    logoURI:
      'https://ipfs.io/ipfs/QmUKd7gTttnte7yMKg3QJD1qJRKhgYdqaR5CsiB3UbAKEZ',
    tags: ['community'],
  },
  xALGO: {
    address: 'xALGoH1zUfRmpCriy94qbfoMXHtK6NDnMKzT4Xdvgms',
    chainId: 101,
    decimals: 6,
    name: 'Wrapped ALGO',
    symbol: 'xALGO',
    logoURI: 'https://arweave.net/zZizaipiM5GvJ5upulPUzg9VW9bui0VaMCY6k1QyHgs',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'wrapped-algo' },
  },
  stakeSOL: {
    address: 'st8QujHLPsX3d6HG9uQg9kJ91jFxUgruwsb1hyYXSNd',
    chainId: 101,
    decimals: 9,
    name: 'stakeSOL',
    symbol: 'stakeSOL',
    logoURI: 'https://arweave.net/C2P2vlj4GZF1ZfAj5GV7B7cvpvdiz2tDEwh6hOfml_o',
    tags: ['community'],
    extensions: { coingeckoId: 'stake-city-staked-sol' },
  },
  DEADCO: {
    address: 'r8EXVDnCDeiw1xxbUSU7MNbLfbG1tmWTvigjvWNCiqh',
    chainId: 101,
    decimals: 9,
    name: 'DeadCoin',
    symbol: 'DEADCO',
    logoURI: 'https://arweave.net/4JJ_OkspoUbBeArWjMUbD5NrfQdC2PcxDIED_PUT93Y',
    tags: ['community'],
  },
  tert: {
    address: '4bBxhRezDJDu3uuh1KM3bWetYiAZSauTeUGixn9rmiX9',
    chainId: 101,
    decimals: 6,
    name: 'tert',
    symbol: 'tert',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f6261666b726569616f6b7967797668626e65753468337a697237686c67756168336d6e61756d656b6170707974713267776a6b71796d75686234752e697066732e6e667473746f726167652e6c696e6b',
    tags: ['community'],
    extensions: { coingeckoId: 'tert' },
  },
  XBM: {
    address: 'XBMuuVZKHd6u8GyX6JakhjgpCA6h7FG28bXaWX2s51P',
    chainId: 101,
    decimals: 9,
    name: 'Beast Meme',
    symbol: 'XBM',
    logoURI:
      'https://arweave.net/WpHohYQqfwWlp14kMnOjwlcpEhoEibuUsW-MvtDz29A?ext=png',
    tags: ['community'],
  },
  SATORI: {
    address: 'HkSiK3rrAf4JHXN7Cf1j7GsLofX9jo8962xabT1qiff1',
    chainId: 101,
    decimals: 2,
    name: 'Satori',
    symbol: 'SATORI',
    logoURI:
      'https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreieavkjl5rchszvydi7asd3e7jzms5cw6ggixjfto6euuxd3ido7ui.ipfs.dweb.link/',
    tags: ['community'],
  },
  SHIB: {
    address: 'CiKu4eHsVrc1eueVQeHn7qhXTcVu95gSQmBpX4utjL9z',
    chainId: 101,
    decimals: 8,
    name: 'Shiba Inu (Portal)',
    symbol: 'SHIB',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/SHIB_wh.png',
    tags: ['wormhole', 'old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'shiba-inu-wormhole' },
  },
  SOBER: {
    address: '2ZE6hSL36e44wP168YMnxrbi1CSCFuD2BJm7NoNHfsmN',
    chainId: 101,
    decimals: 9,
    name: 'Solabrador',
    symbol: 'SOBER',
    logoURI:
      'https://node2.irys.xyz/5xOYKlM26hYXX5wkZoQhc1yw0Bd0Xzoh_SU52TakOZQ',
    tags: ['community'],
  },
  FLOOF: {
    address: '3jzdrXXKxwkBk82u2eCWASZLCKoZs1LQTg87HBEAmBJw',
    chainId: 101,
    decimals: 1,
    name: 'FLOOF',
    symbol: 'FLOOF',
    logoURI:
      'https://raw.githubusercontent.com/GreenFailure/Floof/main/OkyT9kpz_400x400.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'floof' },
  },
  UNKN: {
    address: 'unknXbA1bDg39nuBqVgMNZ5qSZa8pw5HditgkPe5eFA',
    chainId: 101,
    decimals: 9,
    name: 'UNKN',
    symbol: 'UNKN',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/unknXbA1bDg39nuBqVgMNZ5qSZa8pw5HditgkPe5eFA/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  FAM: {
    address: '7njsg9BA1xvXX9DNpe5fERHK4zb7MbCHKZ6zsx5k3adr',
    chainId: 101,
    decimals: 9,
    name: 'Family',
    symbol: 'FAM',
    logoURI:
      'https://dd.dexscreener.com/ds-data/tokens/solana/7njsg9BA1xvXX9DNpe5fERHK4zb7MbCHKZ6zsx5k3adr.png?size=lg&key=188184',
    tags: ['community'],
    extensions: { coingeckoId: 'family-2' },
  },
  MAI: {
    address: '9mWRABuz2x6koTPCWiCPM49WUbcrNqGTHBV9T9k7y1o7',
    chainId: 101,
    decimals: 9,
    name: 'MAI Stablecoin',
    symbol: 'MAI',
    logoURI:
      'https://raw.githubusercontent.com/0xlaozi/qidao/main/images/mimatic-red.png',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'mai-solana' },
  },
  WHY: {
    address: 'WHYoaBumcmxCqw38y2mjs4cVkCBgwiDizbMVvcejmGT',
    chainId: 101,
    decimals: 9,
    name: 'WENWIFHAT',
    symbol: 'WHY',
    logoURI:
      'https://zusqmjtp6ujez6gm5rxjzouetdesm2yx2rotyroljao25ri4ntda.arweave.net/zSUGJm_1Ekz4zOxunLqEmMkmaxfUXTxFy0gdrsUcbMY',
    tags: ['community'],
    extensions: { coingeckoId: 'wenwifhat' },
  },
  SVT: {
    address: 'svtMpL5eQzdmB3uqK9NXaQkq8prGZoKQFNVJghdWCkV',
    chainId: 101,
    decimals: 6,
    name: 'Solvent',
    symbol: 'SVT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/svtMpL5eQzdmB3uqK9NXaQkq8prGZoKQFNVJghdWCkV/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  SOLZILLA: {
    address: '31iQsahfa4CMiirU7REygBzuAWg4R4ah7Y4aDu9ZfXJP',
    chainId: 101,
    decimals: 4,
    name: 'Solzilla',
    symbol: 'SOLZILLA',
    logoURI:
      'https://raw.githubusercontent.com/Solzilla/Solzilla/main/solzilla512x512.png',
    tags: ['community'],
    extensions: { coingeckoId: 'solzilla' },
  },
  L: {
    address: '2emYMetySwE7Xh6qrG1AwAQ3TDvrLkUSpSZ5AjcWCc7B',
    chainId: 101,
    decimals: 6,
    name: 'Lorm the Worm',
    symbol: 'L',
    logoURI:
      'https://bafybeihx6vk7furiw5ls3zpw2hoyg6eocrj6ztjce6z6fm3nsnqwfbcwye.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  BASC: {
    address: 'DM3Y4R7n1HGhP9AkNT6Ex4w1qQTpgq1TyujrMDX11FMT',
    chainId: 101,
    decimals: 9,
    name: 'BASC',
    symbol: 'BASC',
    logoURI: 'https://arweave.net/7qhG_tPq_PY6xc9yRamTq-o3tceb3z9iioH9383-rBk',
    tags: ['community'],
  },
  KITTY: {
    address: '6XWfkyg5mzGtKNftSDgYjyoPyUsLRf2rafj95XSFSFrr',
    chainId: 101,
    decimals: 9,
    name: 'Kitty Coin',
    symbol: 'KITTY',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6XWfkyg5mzGtKNftSDgYjyoPyUsLRf2rafj95XSFSFrr/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'kitty-coin-solana' },
  },
  FOXES: {
    address: '3RCVCywxSs8pDRU1hw8fo7xTUCpNuX86w8cKTM6fgWBY',
    chainId: 101,
    decimals: 2,
    name: 'Famous Fox Federation',
    symbol: 'FOXES',
    logoURI:
      'https://cloudflare-ipfs.com/ipfs/Qmetu9eyf7jxE9cNV9MYMWborQBGHqurt1MvD2LjwDcumy',
    tags: ['community'],
    extensions: { coingeckoId: 'famous-fox-federation-floor-index' },
  },
  CHICKS: {
    address: 'cxxShYRVcepDudXhe7U62QHvw8uBJoKFifmzggGKVC2',
    chainId: 101,
    decimals: 9,
    name: 'SolChicks',
    symbol: 'CHICKS',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/cxxShYRVcepDudXhe7U62QHvw8uBJoKFifmzggGKVC2/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'solchicks-token' },
  },
  tradebot: {
    address: 'GaRph5BcLZZ4sRh6EXKS6V7dicVanhcwa5iWEE6AbEYR',
    chainId: 101,
    decimals: 6,
    name: 'TradeBot',
    symbol: 'tradebot',
    logoURI:
      'https://bafybeibnvnsqtp5l47rj3czt4wsg5uzozbvk5pokpxclgb4b7djl2xb2jq.ipfs.cf-ipfs.com/',
    tags: ['community'],
  },
  GIANT: {
    address: 'A6YRaK4toMe2qZY7KLNCugXBrsjCG4fAjAR8uZUBeUek',
    chainId: 101,
    decimals: 9,
    name: 'AndreDefiant',
    symbol: 'GIANT',
    logoURI: 'https://arweave.net/NyVAfC6oeDsD4USdwHQuEv9Q-i5jSK2SHksF6FwN-us',
    tags: ['community'],
  },
  wFRAX_v1: {
    address: '8L8pDf3jutdpdr4m3np68CL9ZroLActrqwxi6s9Ah5xU',
    chainId: 101,
    decimals: 9,
    name: 'Frax (Wormhole v1)',
    symbol: 'wFRAX_v1',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8L8pDf3jutdpdr4m3np68CL9ZroLActrqwxi6s9Ah5xU/logo.png',
    tags: ['old-registry'],
  },
  MANA: {
    address: '7dgHoN8wBZCc5wbnQ2C47TDnBMAxG4Q5L3KjP67z8kNi',
    chainId: 101,
    decimals: 8,
    name: 'Decentraland (Portal)',
    symbol: 'MANA',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/MANA_wh.png',
    tags: ['wormhole', 'old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'decentraland-wormhole' },
  },
  JOEVER: {
    address: '5ycBARVYYau1CW8s1r6Ty439rbVvGm7x5DRkxGiGKmZ',
    chainId: 101,
    decimals: 9,
    name: "It's Joever",
    symbol: 'JOEVER',
    logoURI:
      'https://36rnr2hbkzq64nczb2xn46sm2ccdpgarvo3e45wuvftrcobezlfa.arweave.net/36LY6OFWYe40WQ6u3npM0IQ3mBGrtk521KlnETgkyso',
    tags: ['community'],
  },
  ISL: {
    address: '75XracgnqjPeuexHKWQU3bBcXMZG6XLDF867tKB1T9e6',
    chainId: 101,
    decimals: 9,
    name: 'The Islanders',
    symbol: 'ISL',
    logoURI: 'https://cdn.jsdelivr.net/gh/koudydev/crypto/main/islIcon.png',
    tags: ['old-registry'],
  },
  GMCAT: {
    address: '83HDsxuNFnhanLgkTdij3dT7tP5FH3bb1TV1rbTT7atz',
    chainId: 101,
    decimals: 6,
    name: 'GM Cat',
    symbol: 'GMCAT',
    logoURI:
      'https://bafkreihiz4wpdqhvybs6wofhf3y5grnta4v3ex2hi234obgsuszgjvtdzq.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  FRIES: {
    address: 'FriCEbw1V99GwrJRXPnSQ6su2TabHabNxiZ3VNsVFPPN',
    chainId: 101,
    decimals: 9,
    name: 'Soltato FRIES',
    symbol: 'FRIES',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FriCEbw1V99GwrJRXPnSQ6su2TabHabNxiZ3VNsVFPPN/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  SMOL: {
    address: 'A9jLULrDSYZkBtKfvG44w8NWzvCN6rGLpoA3hY7TQ85k',
    chainId: 101,
    decimals: 6,
    name: 'smol cat',
    symbol: 'SMOL',
    logoURI:
      'https://bafybeid2q7dpd2bon6qqbhfl6ergnv77lodvzsun6hmigjpd6a3h2shz54.ipfs.cf-ipfs.com/',
    tags: ['community'],
  },
  NANA: {
    address: 'HxRELUQfvvjToVbacjr9YECdfQMUqGgPYB68jVDYxkbr',
    chainId: 101,
    decimals: 9,
    name: 'NANA Token',
    symbol: 'NANA',
    logoURI:
      'https://shdw-drive.genesysgo.net/EV1ARo89dwRzR1kv7JMr7V97qrcXjffkcwEuNHMJfJmz/Banan.png',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'nana-token' },
  },
  MONKEY: {
    address: '921MoB1U7VprQfWw5D37a38LCBgB3nareT7rNffk66BG',
    chainId: 101,
    decimals: 4,
    name: 'Monkey',
    symbol: 'MONKEY',
    logoURI: 'https://i.ibb.co/ckNxCz8/photo-2024-02-07-17-12-39.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'monkey-2' },
  },
  ANSOM: {
    address: 'BfHkvKMEYjwPXnL36uiM8RnAoMFy8aqNyTJXYU3ZnZtz',
    chainId: 101,
    decimals: 6,
    name: 'ansom',
    symbol: 'ANSOM',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmXWnsBLVqSwfKn7zr89HJDb9HSAqHCQQE4eoTSd69qmYe',
    tags: ['community'],
    extensions: { coingeckoId: 'ansom' },
  },
  TRUNK: {
    address: '9mV4WUukVsva5wYcYW4veo34CNDiF44sh3Ji65JNdvh5',
    chainId: 101,
    decimals: 8,
    name: 'Elephant Money Stable (Wormhole)',
    symbol: 'TRUNK',
    logoURI: 'https://elephant.money/img/logo/trunk-logo.png',
    tags: ['community'],
    extensions: { coingeckoId: 'elephant-money-trunk' },
  },
  wBUSD_v1: {
    address: 'AJ1W9A9N9dEMdVyoDiam2rV44gnBm2csrPDP7xqcapgX',
    chainId: 101,
    decimals: 9,
    name: 'Binance USD (Wormhole v1)',
    symbol: 'wBUSD_v1',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AJ1W9A9N9dEMdVyoDiam2rV44gnBm2csrPDP7xqcapgX/logo.png',
    tags: ['old-registry'],
  },
  NSO: {
    address: 'HgMfSGndLq6vgLxCw4J33nJrwV2zTh81iEJNVwK9kcHD',
    chainId: 101,
    decimals: 9,
    name: "neverSURRENDERone's",
    symbol: 'NSO',
    logoURI: 'https://i.imghippo.com/files/HuWkT1710906839.jpg',
    tags: ['community'],
  },
  PROTUGAL: {
    address: 'BT2apS5umybEthwy6xP5PfgNDw3ykMyxirY5XxZ7H654',
    chainId: 101,
    decimals: 9,
    name: 'PROTUGAL',
    symbol: 'PROTUGAL',
    logoURI:
      'https://bafybeidkqx32txz6d64z6zjuicj3ooteeazj3hbjqopu5j5uuvr6fiqxza.ipfs.nftstorage.link',
    tags: ['community'],
  },
  STYLE: {
    address: '3FHpkMTQ3QyAJoLoXVdBpH4TfHiehnL2kXmv9UXBpYuF',
    chainId: 101,
    decimals: 9,
    name: 'STYLE',
    symbol: 'STYLE',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3FHpkMTQ3QyAJoLoXVdBpH4TfHiehnL2kXmv9UXBpYuF/style.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'style' },
  },
  TSUKI: {
    address: '463SK47VkB7uE7XenTHKiVcMtxRsfNE2X4Q9wByaURVA',
    chainId: 101,
    decimals: 9,
    name: 'Tsuki',
    symbol: 'TSUKI',
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://ipfs.io/ipfs/QmV4hWeYKtfHXRpqqo2vHsr8tns5yTLpMyjGCKGjW3Xjp4',
    tags: ['community'],
    extensions: { coingeckoId: 'tsuki' },
  },
  GLXY: {
    address: 'CJ5U6wPmjxFUyTJpUTS7Rt1UqhTmSVRMvmJ8WD4nndXW',
    chainId: 101,
    decimals: 9,
    name: 'Astrals GLXY',
    symbol: 'GLXY',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CJ5U6wPmjxFUyTJpUTS7Rt1UqhTmSVRMvmJ8WD4nndXW/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'astrals-glxy' },
  },
  $RETIRE: {
    address: 'HXkbUADfocGyz2WrzJpjEfry8qyNDm5Kwiiq3Mz3tTi1',
    chainId: 101,
    decimals: 6,
    name: 'Retire on Sol',
    symbol: '$RETIRE',
    logoURI: 'https://i.imgur.com/k1zkuJa.png',
    tags: ['community'],
    extensions: { coingeckoId: 'retire-on-sol' },
  },
  $micha: {
    address: '43MvZht7GGYEuCg3bgiauh3N7zVe73yyGRSC1aUBXLHT',
    chainId: 101,
    decimals: 9,
    name: 'micha',
    symbol: '$micha',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f676174657761792e697279732e78797a2f4a376a66675a4965784f4c4e4a4c4c5a354b49697a757a55496c5343744b6d5657496e726f3458365a566f',
    tags: ['community'],
    extensions: { coingeckoId: 'micha' },
  },
  Board: {
    address: '4xBEoJFNxRY7ZyUPEFmWwHrGzYN5uqzsAH94DTvBv3b1',
    chainId: 101,
    decimals: 6,
    name: 'Solboard',
    symbol: 'Board',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmPtD9t7XMVVAaYoa5qGgjEF6GqkfoBCXtZ7J1x5dYkLMp',
    tags: ['community'],
  },
  BENJI: {
    address: 'CPjDhhBpwEsLygNuczKcNwBPgMeni1xLomD48x51MyYU',
    chainId: 101,
    decimals: 9,
    name: "Taylor Swift's Cat",
    symbol: 'BENJI',
    logoURI:
      'https://bafkreidr7yjxj75ollxohlgmrvix6dpwgy4y5r3wp2bzz6mrgi3tnuhkuy.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'taylor-swift-s-cat' },
  },
  eSOL: {
    address: 'Hg35Vd8K3BS2pLB3xwC2WqQV8pmpCm3oNRGYP1PEpmCM',
    chainId: 101,
    decimals: 9,
    name: 'Eversol staked SOL',
    symbol: 'eSOL',
    logoURI:
      'https://raw.githubusercontent.com/everstake/solana-program-library/master/stake-pool/static-content/images/esol_token.jpg',
    tags: ['old-registry'],
  },
  NELSOL: {
    address: 'EBPpUYEGsmVGG291MXoXmwucqw6nR9dEUEfK5LJdeuve',
    chainId: 101,
    decimals: 6,
    name: 'DogWalter',
    symbol: 'NELSOL',
    logoURI:
      'https://bafybeifalxlv3sydk3w6ud2qlfs3uzpqjsonhlrp23os6qif3appio7qnq.ipfs.cf-ipfs.com',
    tags: ['community'],
    extensions: { coingeckoId: 'dog-walter' },
  },
  MIMANY: {
    address: '3Rcc6tMyS7ZEa29dxV4g3J5StorS9J1dn98gd42pZTk1',
    chainId: 101,
    decimals: 6,
    name: 'MIMANY',
    symbol: 'MIMANY',
    logoURI:
      'https://bafybeicmv6htlk6vqunx4jifj52txzebkmvxiq3ph66igoqwbqnzkcbntq.ipfs.cf-ipfs.com/',
    tags: ['community'],
    extensions: { coingeckoId: 'mimany' },
  },
  UCIT: {
    address: 'HH8bchogQD71iuLghP4cuvSU7vsGJoMJDBxvWTFu7MpA',
    chainId: 101,
    decimals: 2,
    name: 'UCIT',
    symbol: 'UCIT',
    logoURI:
      'https://bafkreie6kc5hrewxecaco5v36l5gqcir7b6mbemnlr5tzq2apr77hnpvbq.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'ucit' },
  },
  SOOSH: {
    address: '6wktcKujaFRKV9Sz455nHUNz34dEWht1gqKzR5KQ9Ljb',
    chainId: 101,
    decimals: 2,
    name: 'Soosh',
    symbol: 'SOOSH',
    logoURI:
      'https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafybeig5il3cq3c7wojc323d7kiex3pyoyxcxtufh4m7pvixarvcjiiqga.ipfs.dweb.link/',
    tags: ['community'],
  },
  $WNZ: {
    address: 'WNZzxM1WqWFH8DpDZSqr6EoHKWXeMx9NLLd2R5RzGPA',
    chainId: 101,
    decimals: 4,
    name: 'Winerz',
    symbol: '$WNZ',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/WNZzxM1WqWFH8DpDZSqr6EoHKWXeMx9NLLd2R5RzGPA/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'winerz' },
  },
  renBTC: {
    address: 'CDJWUqTcYTVAKXAVXoQZFes5JUFc7owSeq7eMQcDSbo5',
    chainId: 101,
    decimals: 8,
    name: 'renBTC',
    symbol: 'renBTC',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CDJWUqTcYTVAKXAVXoQZFes5JUFc7owSeq7eMQcDSbo5/logo.png',
    tags: ['old-registry'],
  },
  BRO: {
    address: 'Bro4MuM7ZSWgGGhioxdMne8TwiZjKLJYK9eNQQbGEN9X',
    chainId: 101,
    decimals: 6,
    name: 'Be Like Bro',
    symbol: 'BRO',
    logoURI:
      'https://4gzm33pkf4jzx4eaxkh7jyuzl4gqwspggziyggvrcxjj5oyu52da.arweave.net/4bLN7eovE5vwgLqP9OKZXw0LSeY2UYMasRXSnrsU7oY',
    tags: ['community'],
  },
  $MARVIN: {
    address: 'ELuv4btje7nZNLeMSw7foptfSiRzEXpNxYuet8cM27SN',
    chainId: 101,
    decimals: 6,
    name: 'MARVIN',
    symbol: '$MARVIN',
    logoURI:
      'https://bafkreid3syczq66zhpvdmrvktpw2aksmgk2maol2bt3i2zghwjhisvqnfe.ipfs.nftstorage.link',
    tags: ['community'],
  },
  SBABYDOGE: {
    address: 'BABYsocP6cB95xvBDXnjXKX96VBNC37dmNWUtaV9Jk6v',
    chainId: 101,
    decimals: 2,
    name: 'SOL BABAY DOGE COIN',
    symbol: 'SBABYDOGE',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BABYsocP6cB95xvBDXnjXKX96VBNC37dmNWUtaV9Jk6v/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'sol-baby-doge' },
  },
  USDCpo: {
    address: 'E2VmbootbVCBkMNNxKQgCLMS1X3NoGMaYAsufaAsf7M',
    chainId: 101,
    decimals: 6,
    name: 'USD Coin (PoS) (Portal from Polygon)',
    symbol: 'USDCpo',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/USDCpo_wh.png',
    tags: ['wormhole', 'old-registry'],
    extensions: { coingeckoId: 'usd-coin-pos-wormhole' },
  },
  HAPPY: {
    address: 'ETBneBQ97qDUygsEBDnpiUF6e832GHV8FzsJCvbUgN1B',
    chainId: 101,
    decimals: 9,
    name: 'HappyAyeSOL',
    symbol: 'HAPPY',
    logoURI:
      'https://gateway.irys.xyz/1O94ecCGcXdL4h5Fqw-ZpClGBsdV_VhdKia5yBIxf5g',
    tags: ['community'],
  },
  TOKE: {
    address: 'AmgUMQeqW8H74trc8UkKjzZWtxBdpS496wh4GLy2mCpo',
    chainId: 101,
    decimals: 3,
    name: 'Mycelium McToken',
    symbol: 'TOKE',
    logoURI: 'https://arweave.net/kVddfi0QG_NfS_cRQQ0vehEt7n25wVk3O4ilHaeidqY',
    tags: ['community'],
  },
  YORK: {
    address: '5K1JtWpdSksVKaL6R2DuLpCDAjzxK6sq2CpXaXDWHVLg',
    chainId: 101,
    decimals: 9,
    name: 'Yorkipoo',
    symbol: 'YORK',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5K1JtWpdSksVKaL6R2DuLpCDAjzxK6sq2CpXaXDWHVLg/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  RLB: {
    address: 'RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a',
    chainId: 101,
    decimals: 2,
    name: 'Rollbit Coin',
    symbol: 'RLB',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  AART: {
    address: 'F3nefJBcejYbtdREjui1T9DPh5dBgpkKq7u2GAAMXs5B',
    chainId: 101,
    decimals: 6,
    name: 'ALL ART',
    symbol: 'AART',
    logoURI:
      'https://raw.githubusercontent.com/allartprotocol/token-list/main/assets/mainnet/F3nefJBcejYbtdREjui1T9DPh5dBgpkKq7u2GAAMXs5B/logo.jpg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'all-art' },
  },
  SOLPAY: {
    address: 'zwqe1Nd4eiWyCcqdo4FgCq7LYZHdSeGKKudv6RwiAEn',
    chainId: 101,
    decimals: 9,
    name: 'SOLPAY',
    symbol: 'SOLPAY',
    logoURI:
      'https://raw.githubusercontent.com/nguilbaud/token-list/main/assets/mainnet/zwqe1Nd4eiWyCcqdo4FgCq7LYZHdSeGKKudv6RwiAEn/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'solpay-finance' },
  },
  jucySOL: {
    address: 'jucy5XJ76pHVvtPZb5TKRcGQExkwit2P5s4vY8UzmpC',
    chainId: 101,
    decimals: 9,
    name: 'Juicy SOL',
    symbol: 'jucySOL',
    logoURI:
      'https://shdw-drive.genesysgo.net/5ohvXVJ7f3HxnwYGnXKdECWC3KFcU95dHu7S7x69Y1sA/logo_rev.svg',
    tags: ['community'],
    extensions: { coingeckoId: 'juicy-staked-sol' },
  },
  REMILIO: {
    address: 'remiG7sGaHWgrY7o6SXJW5CYi5A7kmKutyJz6x6hUsp',
    chainId: 101,
    decimals: 9,
    name: 'remilio',
    symbol: 'REMILIO',
    logoURI: 'https://arweave.net/NsC-eIEVyKfXDJ7JPtZzTJ4BMrkQNkmLDseP2IgeDZo',
    tags: ['community'],
    extensions: { coingeckoId: 'remilio' },
  },
  BRASIL: {
    address: 'HhAcs9JjcveM45FG2Zi3JcWAomra3QezZ22mVvr7zPSE',
    chainId: 101,
    decimals: 6,
    name: 'BRASIL COIN',
    symbol: 'BRASIL',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmSBvnTKer8LmVMDJSvCQ8o94NmreZJYUmzGHJ6adntwmZ',
    tags: ['community'],
  },
  SOLI: {
    address: '8JnNWJ46yfdq8sKgT1Lk4G7VWkAA8Rhh7LhqgJ6WY41G',
    chainId: 101,
    decimals: 6,
    name: 'Solana Ecosystem Index',
    symbol: 'SOLI',
    logoURI:
      'https://cdn.lima.amun.com/solana/8jnnwj46yfdq8skgt1lk4g7vwkaa8rhh7lhqgj6wy41g.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'solana-ecosystem-index' },
  },
  SLITE: {
    address: '7eJCLyW5KkvzdzkVXs1ukA1WfFjCcocXjVit64tYcown',
    chainId: 101,
    decimals: 9,
    name: 'SolanaLite',
    symbol: 'SLITE',
    logoURI:
      'https://raw.githubusercontent.com/SolanaLite/SolanaLite-Brand-Kit/main/SolanaLite%20(SLITE)%20Logo%20Solana%20Blockchain.svg',
    tags: ['old-registry', 'solana-fm'],
  },
  EZB: {
    address: '9Zf6CPFt8mJ5TBWJur3x5HrKdr7yfNg9QhEx2jkNNyFc',
    chainId: 101,
    decimals: 9,
    name: 'Eat Ze Bugz',
    symbol: 'EZB',
    logoURI:
      'https://bafkreihultovrkkziug4pxk6xgq46llvf3j22wahvtbq47jglejpi7angm.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  ROPE: {
    address: '8PMHT4swUMtBzgHnh5U564N5sjPSiUz2cjEQzFnnP1Fo',
    chainId: 101,
    decimals: 9,
    name: 'Rope Token',
    symbol: 'ROPE',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8PMHT4swUMtBzgHnh5U564N5sjPSiUz2cjEQzFnnP1Fo/logo.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'rope-token' },
  },
  CHI: {
    address: 'ARg9wfeLN4qZTxgYTYeuGtGFMmYdk5zFhBuSnTfXXUvb',
    chainId: 101,
    decimals: 9,
    name: 'Project Paradise - CHI Token',
    symbol: 'CHI',
    logoURI:
      'https://raw.githubusercontent.com/benthebape/projekt-paradise-chi-token/main/Moneda_1.png',
    tags: ['old-registry', 'solana-fm'],
  },
  TMOON: {
    address: '7Cx1fMabGWgm86xvv73WzSAcwtLppKf39Ywe8e6Qq4T6',
    chainId: 101,
    decimals: 5,
    name: 'TurboMoon',
    symbol: 'TMOON',
    logoURI:
      'https://bafkreiejjzcetjeauph77cgxrrx73btwjvd6v2sbwwygnleg2mzir6k2zi.ipfs.nftstorage.link',
    tags: ['community', 'token-2022'],
    extensions: { coingeckoId: 'turbomoon' },
  },
  aaUSDC: {
    address: '8Yv9Jz4z7BUHP68dz8E8m3tMe6NKgpMUKn8KVqrPA6Fr',
    chainId: 101,
    decimals: 9,
    name: 'Wrapped USDC (Allbridge from Avalanche)',
    symbol: 'aaUSDC',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png',
    tags: ['old-registry'],
  },
  soFTT: {
    address: 'AGFEad2et2ZJif9jaGpdMixQqvW5i81aBdvKe7PHNfz3',
    chainId: 101,
    decimals: 6,
    name: 'Wrapped FTT (Sollet)',
    symbol: 'soFTT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AGFEad2et2ZJif9jaGpdMixQqvW5i81aBdvKe7PHNfz3/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'ftx-token' },
  },
  VNXAU: {
    address: '9TPL8droGJ7jThsq4momaoz6uhTcvX2SeMqipoPmNa8R',
    chainId: 101,
    decimals: 9,
    name: 'VNX Gold',
    symbol: 'VNXAU',
    logoURI:
      'https://4183046207-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2FsAJThQfNCJePE5h9iplX%2Fuploads%2FPtbQj02lkcRrWtIxo9jl%2FVNXAU%20256x256.png?alt=media&token=55ff0216-91fa-48e1-9426-0327ac43c4b8',
    tags: ['community'],
    extensions: { coingeckoId: 'vnx-gold' },
  },
  gil: {
    address: 'CyUgNnKPQLqFcheyGV8wmypnJqojA7NzsdJjTS4nUT2j',
    chainId: 101,
    decimals: 6,
    name: 'kith gil',
    symbol: 'gil',
    logoURI:
      'https://img.fotofolio.xyz/?url=https%3A%2F%2Fcf-ipfs.com%2Fipfs%2FQmYjnAgqAY4MTWdaBJTUHorQB7LABcsnEmu49AhKTzZBkp',
    tags: ['community'],
    extensions: { coingeckoId: 'kith-gil' },
  },
  STASH: {
    address: 'EWMfSJgDCE7CXDAYz3hbCaA7NsFHTnddySXx3shco2Hs',
    chainId: 101,
    decimals: 6,
    name: 'STASH INU',
    symbol: 'STASH',
    logoURI:
      'https://bafybeifodskw7mbbsygrad4oebwy5yh5qscrur5w4ltvximvxrrbrroiw4.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'stash-inu' },
  },
  DINO: {
    address: '6Y7LbYB3tfGBG6CSkyssoxdtHb77AEMTRVXe8JUJRwZ7',
    chainId: 101,
    decimals: 6,
    name: 'DINO',
    symbol: 'DINO',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Y7LbYB3tfGBG6CSkyssoxdtHb77AEMTRVXe8JUJRwZ7/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'dino' },
  },
  $KSH: {
    address: '6j14WyX1Ag2pLWvn99euK4xp2VcZD62VeJv2iwCrYmT8',
    chainId: 101,
    decimals: 9,
    name: 'Keeshond Coin',
    symbol: '$KSH',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6j14WyX1Ag2pLWvn99euK4xp2VcZD62VeJv2iwCrYmT8/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  INU: {
    address: '5jFnsfx36DyGk8uVGrbXnVUMTsBkPXGpx6e69BiGFzko',
    chainId: 101,
    decimals: 9,
    name: 'Solana INU',
    symbol: 'INU',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5jFnsfx36DyGk8uVGrbXnVUMTsBkPXGpx6e69BiGFzko/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'solana-inu' },
  },
  PRGC: {
    address: '66edZnAPEJSxnAK4SckuupssXpbu5doV57FUcghaqPsY',
    chainId: 101,
    decimals: 9,
    name: 'ProtoReality Games Token',
    symbol: 'PRGC',
    logoURI: 'https://www.protorealitygames.com/images/PRGC_Token.png',
    tags: ['old-registry', 'solana-fm'],
  },
  $INA: {
    address: '2yd2Suus3YY4Sa7LHhn1PSHkjXj3XKrars4cCog2tGU8',
    chainId: 101,
    decimals: 6,
    name: 'pepeinatux',
    symbol: '$INA',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d6351324d7a4e7a686b714b5266554d50327042396d5a75317653396e714b32386d7654513848415063515150',
    tags: ['community'],
    extensions: { coingeckoId: 'pepeinatux' },
  },
  COOK: {
    address: 'G8Vy25NzjRmuQtnN35xF7j3X2Z1TrV39XijZu8Mg4w8n',
    chainId: 101,
    decimals: 6,
    name: 'Let Him Cook',
    symbol: 'COOK',
    logoURI:
      'https://ipfs.io/ipfs/bafkreicbrcaa5n3zwxgvykcpdbhyzpwvcihnfhsnrhkf2rwtw3pzd4d4sm',
    tags: ['community'],
    extensions: { coingeckoId: 'let-him-cook' },
  },
  REWD: {
    address: '2eu1K3wvfPC7gVj1CK8ohv4ggusdN6qxyxpjHyTCkjZT',
    chainId: 101,
    decimals: 6,
    name: 'Reward Protocol',
    symbol: 'REWD',
    logoURI:
      'https://bafkreic2ndn27fumdff4q3qht3a6dq44peoys7bf437n2rlcnci25tzkyi.ipfs.nftstorage.link',
    tags: ['community', 'token-2022'],
    extensions: { coingeckoId: 'reward-protocol' },
  },
  BORG: {
    address: '3dQTr7ror2QPKQ3GbBCokJUmjErGg8kTJzdnYjNfvi3Z',
    chainId: 101,
    decimals: 9,
    name: 'SwissBorg Token',
    symbol: 'BORG',
    logoURI:
      'https://bafybeiaxwonn35jbb7xexmlmguibskmbg5vlv7wzabht3prfespwde6e6u.ipfs.dweb.link',
    tags: ['community'],
    extensions: { coingeckoId: 'swissborg' },
  },
  EPCT: {
    address: 'CvB1ztJvpYQPvdPBePtRzjL4aQidjydtUz61NWgcgQtP',
    chainId: 101,
    decimals: 6,
    name: 'Epics Token',
    symbol: 'EPCT',
    logoURI:
      'https://bafybeibal7k2hz6frznyjbl4qcnzcwlsuzxmquatrsaly6ttmuppgmdvwe.ipfs.dweb.link/EpicsCoin.png',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'epics-token' },
  },
  superSOL: {
    address: 'suPer8CPwxoJPQ7zksGMwFvjBQhjAHwUMmPV4FVatBw',
    chainId: 101,
    decimals: 9,
    name: 'Superfast Staked SOL',
    symbol: 'superSOL',
    logoURI: 'https://superfast.org/supersollogo.png',
    tags: ['community'],
    extensions: { coingeckoId: 'superfast-staked-sol' },
  },
  FSM: {
    address: 'DMqv9nGbEVAkQoz13ncKh5XJrFg2BQ5YTwd1XrGEKkaz',
    chainId: 101,
    decimals: 6,
    name: 'Flying Spaghetti Monster',
    symbol: 'FSM',
    logoURI:
      'https://bafybeibxoen5asgzdf7kz6jlac6656s3a2xpfc2m3t77m73pfwy6keh6gu.ipfs.nftstorage.link',
    tags: ['community'],
  },
  ENRX: {
    address: '5s4BYUXLuvs9ZcVDTxkTpKhThWFSpaU8GG55q2iySe2N',
    chainId: 101,
    decimals: 2,
    name: 'Enrex',
    symbol: 'ENRX',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5s4BYUXLuvs9ZcVDTxkTpKhThWFSpaU8GG55q2iySe2N/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'enrex' },
  },
  SIN: {
    address: 'sin1uRe1cMCWR7VPLdZrGrvKs8UvKMsGzhvpJLg4Ld9',
    chainId: 101,
    decimals: 6,
    name: 'sinDAO',
    symbol: 'SIN',
    logoURI:
      'https://nftstorage.link/ipfs/bafkreibne6m5rh42mgvud2womqc2zordzqvki5w4c2yasa76klz7dzwuve',
    tags: ['community'],
    extensions: { coingeckoId: 'sin' },
  },
  OPOS: {
    address: '3byG6HEKhG5gUZ5PZU9zKkFaEHYPveGEtvs68LYR8R5N',
    chainId: 101,
    decimals: 4,
    name: 'OPOS Coin',
    symbol: 'OPOS',
    logoURI: 'https://arweave.net/fivrynPZdJv5ajd4d1TXW1yHArDA-RvkYYfDoh-_4EU',
    tags: ['community'],
  },
  BOXXY: {
    address: 'EzYyFvUE2AepSxbtdDkkq5rWpN7bXhYzQphZ2gF4Y24R',
    chainId: 101,
    decimals: 6,
    name: 'Boxxy Coin',
    symbol: 'BOXXY',
    logoURI:
      'https://bafkreigs3ktjx5x5w42c2ad2mwirh54iyfq2a7bapfae3f6jgbh4qpqxmq.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  MUTANT: {
    address: 'mmonechkLNdUxDbvP9Yntwo459QfUmqDdzV95DNvnr6',
    chainId: 101,
    decimals: 9,
    name: 'MUTANT',
    symbol: 'MUTANT',
    logoURI:
      'https://shdw-drive.genesysgo.net/893AmBr2P9NVydpWc2TAkR3prwBtWMZH2A8RniDmduhn/mmonToken.jpg',
    tags: ['community', 'token-2022'],
  },
  BarrTremp: {
    address: '2CMxmFb2nsNdw351TfZoawpN1DnDuVrH4Wn6fxH2EJT6',
    chainId: 101,
    decimals: 6,
    name: 'Barron Tremp',
    symbol: 'BarrTremp',
    logoURI:
      'https://bafkreiaxhbgj5l2cfziu66n5fipedkylcmw2di6ijtvhnw5q4fdp3d3eme.ipfs.nftstorage.link',
    tags: ['community'],
  },
  $WATER: {
    address: 'B6h248NJkAcBAkaCnji889a26tCiGXGN8cxhEJ4dX391',
    chainId: 101,
    decimals: 6,
    name: 'WATER',
    symbol: '$WATER',
    logoURI: 'https://arweave.net/4Tq7tzIwblJcLPm7kAuC7-fy0hcy7RDVVPZt3TarfaU',
    tags: ['community'],
    extensions: { coingeckoId: 'water-coin' },
  },
  TALK: {
    address: '3Ce4PdWfdGjp2F5gn2iyxz7CDMG7TpwunbKHkF67itqf',
    chainId: 101,
    decimals: 6,
    name: 'Soltalk AI',
    symbol: 'TALK',
    logoURI:
      'https://bafkreigz75uicd36sgztzkpox636h543dnv56mopioflrxeh353idqrlu4.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'soltalk-ai' },
  },
  $VIDEOGAME: {
    address: 'Cd35Zsee8atgY14q47mgN4N7xa2VpJG57ygtAXL7pump',
    chainId: 101,
    decimals: 6,
    name: 'video-game.cc',
    symbol: '$VIDEOGAME',
    logoURI:
      'https://ipfs.io/ipfs/QmUefrhiyEYfU1vbP7kcetSxJUKAXDdntMjWUUh7DGf6vA',
    tags: ['community'],
  },
  SKULL: {
    address: '3X36yhq35MJnt2JjwodeFDfv2MFPb99RC53yUyNrpump',
    chainId: 101,
    decimals: 6,
    name: 'PEPE SKULL',
    symbol: 'SKULL',
    logoURI: 'https://imgur.com/a/lqdkusC',
    tags: ['community'],
    extensions: { coingeckoId: 'pepe-skull' },
  },
  COMFY: {
    address: 'FbJpd8yhrGGkWVL1Ujf7qFvTw4uD4675k8CYk82LEKvZ',
    chainId: 101,
    decimals: 8,
    name: 'SOCOMFY',
    symbol: 'COMFY',
    logoURI:
      'https://bafybeicg67e5ireijwcndiakcfeiyebhpdk6hcnqislttv5dbsax4kkyse.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'socomfy' },
  },
  MUGI: {
    address: 'Dc7mzCE1aG8rNJUgD3zDiRPcgJUYKSybxBA1oXWqjLz4',
    chainId: 101,
    decimals: 6,
    name: 'MUGI',
    symbol: 'MUGI',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d5876524d4b7452486362544a7a77504c4e6d55326b4c654b3475414e567445565a4854756978675246766b4b',
    tags: ['community'],
  },
  NONCE: {
    address: '3wDQAHLuaaotFajeJXpJpNMio3LBhSTscHa8Xq5UwLo2',
    chainId: 101,
    decimals: 6,
    name: 'nonce pesoli',
    symbol: 'NONCE',
    logoURI:
      'https://bafybeia4j7ydmcgorosacsow6n4ae5xxzin6liod4jxj2ppe2e5ogzcaaq.ipfs.nftstorage.link',
    tags: ['community'],
  },
  STACKS: {
    address: '8npbXTu4oTNw3xrMGY1aTm4CtB7cMPVuRUCh3Wqj3fSW',
    chainId: 101,
    decimals: 9,
    name: 'STACKS',
    symbol: 'STACKS',
    logoURI:
      'https://assets.pinit.io/FADeYLKV1tWgX3pP9boaiF9Qt2vbGfXm3QuEoqCmRPH3/0edcd082-6fa5-4889-987d-0137889771c0/slap_city.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'slap-city' },
  },
  WXM: {
    address: 'wxmJYe17a2oGJZJ1wDe6ZyRKUKmrLj2pJsavEdTVhPP',
    chainId: 101,
    decimals: 9,
    name: 'WeatherXM',
    symbol: 'WXM',
    logoURI:
      'https://ipfs.io/ipfs/QmZkghK7bGgT5s9jibodXCM8tFMCJqLKtUnzCw4Fu2nqcw',
    tags: ['community'],
    extensions: { coingeckoId: 'weatherxm-network' },
  },
  bozoHYBRID: {
    address: 'EJPtJEDogxzDbvM8qvAsqYbLmPj5n1vQeqoAzj9Yfv3q',
    chainId: 101,
    decimals: 9,
    name: 'Bozo Hybrid',
    symbol: 'bozoHYBRID',
    logoURI:
      'https://bafkreiamobqahwlwio5syavvfkknvfecgt7osbsh2s4xizihgpsajethyy.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'bozo-hybrid' },
  },
  ELU: {
    address: '4tJZhSdGePuMEfZQ3h5LaHjTPsw1iWTRFTojnZcwsAU6',
    chainId: 101,
    decimals: 9,
    name: 'Elumia Crowns',
    symbol: 'ELU',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4tJZhSdGePuMEfZQ3h5LaHjTPsw1iWTRFTojnZcwsAU6/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'elumia' },
  },
  EGIRL: {
    address: 'ARt4N4WY4PEdYUuBG7qENwuYSSiQUqP1RXFiahhwfzH9',
    chainId: 101,
    decimals: 9,
    name: 'EGIRL',
    symbol: 'EGIRL',
    logoURI:
      'https://bafkreid6lv4sec5o74hm2n43cjgoqt3g4ar3qx7fc3zw3niouxzbkudz24.ipfs.nftstorage.link',
    tags: ['community'],
  },
  SHOE: {
    address: '8XVXzmsMMw7ufa8RC21fHcDP6TGti5y3ZidQinnYurqr',
    chainId: 101,
    decimals: 6,
    name: 'Laughing Shoe',
    symbol: 'SHOE',
    logoURI:
      'https://dd.dexscreener.com/ds-data/tokens/solana/8XVXzmsMMw7ufa8RC21fHcDP6TGti5y3ZidQinnYurqr.png',
    tags: ['community'],
    extensions: { coingeckoId: 'shoe' },
  },
  PORTNOY: {
    address: '8wzYfqeqkjBwYBHMacBVen8tSuJqXiDtsCgmjnUJDSKM',
    chainId: 101,
    decimals: 6,
    name: 'PORTNOY',
    symbol: 'PORTNOY',
    logoURI: 'https://i.ibb.co/K0M01Ws/image.png',
    tags: ['community'],
  },
  WSBS: {
    address: '5RDHUiwLChkKfusxeu9kEDUtRfxsDWV8etoopnZMwVM7',
    chainId: 101,
    decimals: 6,
    name: 'Wall Street Bets Solana',
    symbol: 'WSBS',
    logoURI:
      'https://bafybeibvap6pkovsxqf2jpkbh4faaz7cifefmvcozs3v4ev475zv5m2mjq.ipfs.nftstorage.link',
    tags: ['community'],
  },
  HELIA: {
    address: 'FZnSMd1hPu5MVgttmtfDZUPN5gcuexvrh3CB67UuQshb',
    chainId: 101,
    decimals: 6,
    name: 'StareCat',
    symbol: 'HELIA',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmWJGdJLF9GwYCsJfP81SvnCbZaso8aqokA3ZEQRigieyq',
    tags: ['community'],
    extensions: { coingeckoId: 'starecat' },
  },
  DEDS: {
    address: 'BvEj2MNMPsUrD4vSk7NHs4TtRcCcJd75Wx5HvVbY4rbK',
    chainId: 101,
    decimals: 9,
    name: 'Decimus Dynamics Token',
    symbol: 'DEDS',
    logoURI:
      'https://github.com/DecimusDynamics/assets/blob/main/decimus-icon-1x1-black.png',
    tags: ['old-registry', 'solana-fm'],
  },
  SYP: {
    address: 'FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ',
    chainId: 101,
    decimals: 9,
    name: 'Sypool',
    symbol: 'SYP',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/b0e47e39f84cffb655f406eb569c57a88b5211cc/assets/mainnet/FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'sypool' },
  },
  JIZZUS: {
    address: '7vQdgLtR2Qo6MBotkKVmCKfw83fGZkFQLR3Cj6X3ZRF7',
    chainId: 101,
    decimals: 7,
    name: 'JIZZUS CHRIST',
    symbol: 'JIZZUS',
    logoURI:
      'https://bafkreiatmmrkg2jjnvp5hncjbyowfzoom7lb46fwvqafopnjazirj3qoqq.ipfs.nftstorage.link',
    tags: ['community'],
  },
  GEMS: {
    address: '2YJH1Y5NbdwJGEUAMY6hoTycKWrRCP6kLKs62xiSKWHM',
    chainId: 101,
    decimals: 9,
    name: 'SolGems',
    symbol: 'GEMS',
    logoURI:
      'https://public.djib.io/QmdLDat9CvntvFPG98CcXJJ3tE1mQZkf5DEfPNhK8F3guq',
    tags: ['old-registry', 'solana-fm'],
  },
  BAMBIT: {
    address: 'xN9Qd63mUYg7npanmdksmcqp3NQjTcGFQPTyq2F1TQC',
    chainId: 101,
    decimals: 9,
    name: 'BAMBIT',
    symbol: 'BAMBIT',
    logoURI:
      'https://node1.irys.xyz/18vDmWZ-3R81Eys-kECJM8tgAIuJIbJ-o6mIkcfSWOg',
    tags: ['community'],
    extensions: { coingeckoId: 'bambit' },
  },
  pipi: {
    address: 'BZKuDqzD4rb2puUV2cbSNd2kZzJ5JzKiQKwBzCk8hrLu',
    chainId: 101,
    decimals: 9,
    name: 'pipi the cat',
    symbol: 'pipi',
    logoURI: 'https://i.ibb.co/mChpPQK/photo-2024-01-14-01-32-58.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'pipi-the-cat' },
  },
  GCR: {
    address: '791hZNiCJy1qGSGzAvqUU8X6gejiBJ2mBV8JjYoVnzBR',
    chainId: 101,
    decimals: 6,
    name: 'GCRClassic',
    symbol: 'GCR',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmPck3YwoS8JHvxG9py11zbEQaBzgcRG4gSYzPpM9b4yjE',
    tags: ['community'],
    extensions: { coingeckoId: 'gcrclassic' },
  },
  Bekele: {
    address: '4pPMX85G5t6M8hoKvdsbRDGbMkXxRg1vefcQ7MVjjMKM',
    chainId: 101,
    decimals: 6,
    name: 'Nayeb Bekele',
    symbol: 'Bekele',
    logoURI:
      'https://bafkreiaphyug77bsxdnx55jmzppii3ttgaenxykz6kedlgowoun7ha53ku.ipfs.nftstorage.link',
    tags: ['community'],
  },
  USDTbs: {
    address: '8qJSyQprMC57TWKaYEmetUR3UUiTP2M3hXdcvFhkZdmv',
    chainId: 101,
    decimals: 8,
    name: 'Tether USD (Portal from BSC)',
    symbol: 'USDTbs',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/USDTbs_wh.png',
    tags: ['wormhole', 'old-registry'],
  },
  DRIPSPLIT: {
    address: 'uL2qhMckUAroJPt2MLHwEeppJNYE3wBAGFMCs3anwXn',
    chainId: 101,
    decimals: 2,
    name: 'Drippies',
    symbol: 'DRIPSPLIT',
    logoURI:
      'https://img-cdn.magiceden.dev/rs:fit:640:640:0:0/plain/https://arweave.net/WeX4k81n1xfmCQjaqr16a_iK_R43b8F5mBYiob5YiUQ/4248.png',
    tags: ['community', 'solana-fm'],
  },
  macho: {
    address: '8zFovnzXzK9JDiftGaw7wiRxARrRtvm9Lz12vJ8CZ5ZA',
    chainId: 101,
    decimals: 5,
    name: 'macho',
    symbol: 'macho',
    logoURI:
      'https://gateway.irys.xyz/V6AglMVN9EMa8IB2aerQlV5vAj9h0unJMXTm9tCb9iE',
    tags: ['community'],
  },
  NYXC: {
    address: 'HrLx8MLKegpbmbmWePZiuvf3AbJNx1CJyjBwUHwicEgW',
    chainId: 101,
    decimals: 7,
    name: 'Nyxia Coin',
    symbol: 'NYXC',
    logoURI: 'http://nyxia.ai/NYXC_coin-200.png',
    tags: ['community'],
    extensions: { coingeckoId: 'nyxia-ai' },
  },
  RPEPE: {
    address: '8jnGePqBBq1KQfPvqA7zAZySiFTyy8tv2zA8u6nHhsdo',
    chainId: 101,
    decimals: 6,
    name: 'RoaringPepe',
    symbol: 'RPEPE',
    logoURI:
      'https://ipfs.io/ipfs/QmbC7tJ3EieLuAm4GeuUWsqhyCdidASrarJ6hZZTQDzWdd',
    tags: ['community'],
  },
  SCUM: {
    address: '5cvj5rEEocG5Wvh3oJuY6MoYj7gVZd8aoXSLZjDY6W4W',
    chainId: 101,
    decimals: 9,
    name: 'Dead Bear Inc',
    symbol: 'SCUM',
    logoURI:
      'https://ipfs.io/ipfs/QmasP8ZTEGAc4gqkhKfzq9Qu2tUaN8JpzQE2EcNaE9avkM',
    tags: ['community'],
  },
  KATCHU: {
    address: 'CKiW2P4zngHa6fiPhMJGGhgDZm638dWotxkzgi8PsDxf',
    chainId: 101,
    decimals: 6,
    name: 'Katchu Coin',
    symbol: 'KATCHU',
    logoURI:
      'https://node1.irys.xyz/5Wfa6AyqS0xT0i5n4UmHTdacOSv55pZIlamhixfuFeE',
    tags: ['community'],
    extensions: { coingeckoId: 'katchusol' },
  },
  USDK: {
    address: '43m2ewFV5nDepieFjT9EmAQnc1HRtAF247RBpLGFem5F',
    chainId: 101,
    decimals: 8,
    name: 'USDK (Portal)',
    symbol: 'USDK',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/USDK_wh.png',
    tags: ['wormhole', 'old-registry'],
  },
  DONK: {
    address: 'xABfKiG2KCHi6keTeLycW1iK7B52wJmchSWXu3YrsDp',
    chainId: 101,
    decimals: 5,
    name: 'DONK',
    symbol: 'DONK',
    logoURI: 'https://arweave.net/yu68b5mpxYjDqL990DEL9b_ri9E8TX-o27E1PS9W6II',
    tags: ['community'],
    extensions: { coingeckoId: 'donk' },
  },
  GRAY: {
    address: '6XfB1VVAxjtfKnF1kotGxLUq4p87xTDp3cCyC6Q2VHNG',
    chainId: 101,
    decimals: 9,
    name: 'Gray',
    symbol: 'GRAY',
    logoURI: 'https://arweave.net/sNQ2D1R6C07UbP4mqZqR7P_hmrwje0y3T5_Vyy5lLGM',
    tags: ['community'],
  },
  KHAI: {
    address: '3TWgDvYBL2YPET2LxnWAwsMeoA8aL4DutNuwat2pKCjC',
    chainId: 101,
    decimals: 8,
    name: 'Kitten Haimer',
    symbol: 'KHAI',
    logoURI:
      'https://bafkreihkv72pvkaaboxl5rc7g7r5jihqjycdy4cbrap25ilfvvlgefhca4.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'kitten-haimer' },
  },
  DOBI: {
    address: 'GzQzkt2B4Jr6whWVBF7XqkzWvoUy1jEd5z9tczzGg1rH',
    chainId: 101,
    decimals: 6,
    name: 'DOBI',
    symbol: 'DOBI',
    logoURI:
      'https://gateway.irys.xyz/099elCExuWO7iBY2_E-4mZVNLL21Hmj_nxHTGTdUxx0',
    tags: ['community'],
    extensions: { coingeckoId: 'dobi' },
  },
  CHEEPEPE: {
    address: 'FwBixtdcmxawRFzBNeUmzhQzaFuvv6czs5wCQuLgWWsg',
    chainId: 101,
    decimals: 6,
    name: 'cheepepe',
    symbol: 'CHEEPEPE',
    logoURI:
      'https://bafybeichlgwyhbuo4c7v5cxlhqwwd7hy2xoirpsfukli4fc3ym5qlb4g4q.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'cheepepe' },
  },
  BRETTGOLD: {
    address: 'F6ExBzKdLRcJkCAknQgfbhRXX78EhqoNxPnegJWPpump',
    chainId: 101,
    decimals: 6,
    name: 'BRETT GOLD',
    symbol: 'BRETTGOLD',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d63703939554a43557978737a42783741366157524850386974326237767834436e69457166516561735a5765',
    tags: ['community'],
    extensions: { coingeckoId: 'brett-gold' },
  },
  MIM: {
    address: 'G33s1LiUADEBLzN5jL6ocSXqrT2wsUq9W6nZ8o4k1b4L',
    chainId: 101,
    decimals: 9,
    name: 'Magic Internet Money',
    symbol: 'MIM',
    logoURI:
      'https://bafkreict6ykbea4qa5ulzkkagkihqqvo33frgaqo4kzofgsuyxxjozcuii.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'magic-internet-money-meme' },
  },
  FCAT: {
    address: 'CcFfGMU9Jodk6s5fSZM1vYGF3UNng7fZvTmPgzvU1ScF',
    chainId: 101,
    decimals: 6,
    name: 'Floki Cat',
    symbol: 'FCAT',
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://cf-ipfs.com/ipfs/QmZZwoQtSYAJWpwsc2sT1rA6zxxaeP3NJBu1vEr13KMt3W',
    tags: ['community'],
    extensions: { coingeckoId: 'floki-cat' },
  },
  SMOLCAT: {
    address: 'AJd5bi9wK4GupVf9XeftfrzQraQVthErLxEtdHv5qEHU',
    chainId: 101,
    decimals: 9,
    name: 'Smol Cat',
    symbol: 'SMOLCAT',
    logoURI:
      'https://bafkreigvfvqnfq7wvned2hbt6esd55iefkck2o7oookiyklgvmd2fmduzi.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  SBABE: {
    address: 'D9mFkgnZHnQGRtZKvnJ44yvoLtJmfBZRahXiUKcAzRE4',
    chainId: 101,
    decimals: 6,
    name: 'SNOOPYBABE',
    symbol: 'SBABE',
    logoURI:
      'https://bafkreifqg2pcpxdqgsdkh77dxarzskvww64w34dxxcdorpizcoynfrcxa4.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'snoopybabe' },
  },
  DPAY: {
    address: 'Gnca3UkjR4a1FFNZuGfEELmbaHkL6GteSC2swpdWRmf7',
    chainId: 101,
    decimals: 8,
    name: 'Devour',
    symbol: 'DPAY',
    logoURI:
      'https://tokens.debridge.finance/0x39b22d4e4dd2668575e36ed9ce554a1ed4a436f4cce8777c450ec0fc5187cb5e.png',
    tags: ['community'],
    extensions: { coingeckoId: 'devour-2' },
  },
  INBRED: {
    address: 'EjzzyCSiLqjFDprpZj8e1zjXmcTG5HPGFRSEoWcJWHh9',
    chainId: 101,
    decimals: 9,
    name: 'INBRED CAT',
    symbol: 'INBRED',
    logoURI: 'https://arweave.net/iVf74QkkM6G7JfKjwkl37xbFEq0FCU4o5PLGH3--i2o',
    tags: ['community'],
    extensions: { coingeckoId: 'inbred-cat' },
  },
  SAITAMA: {
    address: 'CVRwdbkwSube1ifeNLFDdZSTmVEnLcyDuKXTdaZX1ua5',
    chainId: 101,
    decimals: 9,
    name: 'One Punch Man',
    symbol: 'SAITAMA',
    logoURI:
      'https://static.wixstatic.com/media/eee02f_fc2caa69836949eca0e963201a863f77~mv2.png',
    tags: ['community'],
  },
  BSLSK: {
    address: 'EMAb1cexvwrMeViyYiK8941V7SGWiXsyhMREBcswggPw',
    chainId: 101,
    decimals: 2,
    name: 'Basilisk',
    symbol: 'BSLSK',
    logoURI: 'https://i.postimg.cc/d0j6rRWH/Dark-BASLSK-666x666.png',
    tags: ['community'],
  },
  OPPIE: {
    address: 'GwyxednWbrhgT2K6iPUsbtadErA7TBGqsJjyzAody2mv',
    chainId: 101,
    decimals: 6,
    name: 'TOLYS CAT',
    symbol: 'OPPIE',
    logoURI: 'https://imgur.com/a/g8ncEiF',
    tags: ['community'],
    extensions: { coingeckoId: 'tolys-cat' },
  },
  COST: {
    address: 'Av6qVigkb7USQyPXJkUvAEm4f599WTRvd75PUWBA9eNm',
    chainId: 101,
    decimals: 9,
    name: 'Costco Hot Dog',
    symbol: 'COST',
    logoURI:
      'https://ipfs.io/ipfs/QmSqddq1svm7VpaLCcSQzCea58J8xRo8vhHuVJgdRPUw1J',
    tags: ['community'],
    extensions: { coingeckoId: 'costco-hot-dog' },
  },
  aSOL: {
    address: 'ASoLXbfe7cd6igh5yiEsU8M7FW64QRxPKkxk7sjAfond',
    chainId: 101,
    decimals: 9,
    name: 'aSOL Aggregate Solana Stake Pool',
    symbol: 'aSOL',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ASoLXbfe7cd6igh5yiEsU8M7FW64QRxPKkxk7sjAfond/logo.svg',
    tags: ['old-registry'],
  },
  shork: {
    address: 'BJB5tHWAHboMAwti5AHbCNX17F3jL8ehkh4zuTUx99Zn',
    chainId: 101,
    decimals: 6,
    name: 'shork',
    symbol: 'shork',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmZ4PJT5qZ1MyMwaYz1dbDD3kpBE4bkskwmampKAQWuNRe',
    tags: ['community'],
  },
  CROWS: {
    address: '6gSmbxSfgJrqeGBYwuKGuSbShhbDLjcCLWqzivvPeG3i',
    chainId: 101,
    decimals: 2,
    name: 'Royal Crows',
    symbol: 'CROWS',
    logoURI:
      'https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://creator-hub-prod.s3.us-east-2.amazonaws.com/royal_crows_pfp_1654260294179.gif',
    tags: ['community'],
  },
  OGT: {
    address: 'DHbCBJVWGTHpiV4WNZ9AajvNkEWKRdu2mmJXYjQj847t',
    chainId: 101,
    decimals: 9,
    name: 'Olympic Games Token',
    symbol: 'OGT',
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://ipfs.io/ipfs/QmTHniyPJLer4TkgNffjMZRTMUvai2Kvfwj9JYJAJnnJa2',
    tags: ['community'],
  },
  tTRIAD: {
    address: 't3DohmswhKk94PPbPYwA6ZKACyY3y5kbcqeQerAJjmV',
    chainId: 101,
    decimals: 6,
    name: 'Triad',
    symbol: 'tTRIAD',
    logoURI:
      'https://6ur2hw5rrmu4yljraaqrkrikec6alcexzlrhoghw2peghvp624ka.arweave.net/9SOj27GLKcwtMQAhFUUKILwFiJfK4ncY9tPIY9X-1xQ?ext=png',
    tags: ['community', 'token-2022'],
  },
  pepe: {
    address: '3tS6fbLh2P8tzxXuqCiHZpZhsxJpmrR3Xb9psmypnp69',
    chainId: 101,
    decimals: 9,
    name: 'pepecoin on SOL',
    symbol: 'pepe',
    logoURI: 'https://i.imgur.com/XzaFkn5.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'pepecoin-on-sol' },
  },
  BWB: {
    address: '6FVyLVhQsShWVUsCq2FJRr1MrECGShc3QxBwWtgiVFwK',
    chainId: 101,
    decimals: 8,
    name: 'Bitget Wallet Token',
    symbol: 'BWB',
    logoURI: 'https://cdn.bitkeep.vip/bwb-token.png',
    tags: ['community'],
    extensions: { coingeckoId: 'bitget-wallet-token' },
  },
  NESTA: {
    address: 'Czt7Fc4dz6BpLh2vKiSYyotNK2uPPDhvbWrrLeD9QxhV',
    chainId: 101,
    decimals: 9,
    name: 'Nest Arcade',
    symbol: 'NESTA',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Czt7Fc4dz6BpLh2vKiSYyotNK2uPPDhvbWrrLeD9QxhV/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'nest-arcade' },
  },
  GRLC: {
    address: '88YqDBWxYhhwPbExF966EdaCYBKP51xVm1oGBcbWzcf2',
    chainId: 101,
    decimals: 9,
    name: 'Garlic',
    symbol: 'GRLC',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/88YqDBWxYhhwPbExF966EdaCYBKP51xVm1oGBcbWzcf2/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  Barron: {
    address: 'HmAgiwjjP9CXqK5wQNsHKtjAt2CH3Kv8Q7xH5kGL2nqZ',
    chainId: 101,
    decimals: 6,
    name: 'Time Traveler',
    symbol: 'Barron',
    logoURI:
      'https://bafybeibew7y6kwsvmuictnamsabp6yjchcaflxhopimtzwkfh3dofrgwgi.ipfs.cf-ipfs.com',
    tags: ['community'],
  },
  EGG: {
    address: '4ynyx6BzY2XGFgjjun9Cruj1bSRo8FLsAqNnPsW6jDsu',
    chainId: 101,
    decimals: 9,
    name: 'EGG',
    symbol: 'EGG',
    logoURI:
      'https://gateway.irys.xyz/ar31vqcXlnD4hN5WZ5ha2xnKmYtMv9YXEtLY4BEpJxs',
    tags: ['community'],
    extensions: { coingeckoId: 'egg' },
  },
  UNI: {
    address: '8FU95xFJhUUkyyCLU13HSzDLs7oC4QZdXQHL6SCeab36',
    chainId: 101,
    decimals: 8,
    name: 'Uniswap (Portal)',
    symbol: 'UNI',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/UNI_wh.png',
    tags: ['wormhole', 'old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'uniswap-wormhole' },
  },
  SNS: {
    address: 'SNSNkV9zfG5ZKWQs6x4hxvBRV6s8SqMfSGCtECDvdMd',
    chainId: 101,
    decimals: 9,
    name: 'SynesisOne',
    symbol: 'SNS',
    logoURI:
      'https://raw.githubusercontent.com/Synesis-One/spl-token/main/icon.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'synesis-one' },
  },
  CLOUD: {
    address: 'CLoUDKc4Ane7HeQcPpE3YHnznRxhMimJ4MyaUqyHFzAu',
    chainId: 101,
    decimals: 9,
    name: 'Cloud',
    symbol: 'CLOUD',
    logoURI: 'https://arweave.net/N7vCgQdgQ-fab28zEB4m8QRLMwI91_KcXI-Gtr151gg',
    tags: ['community'],
    extensions: { coingeckoId: 'sanctum-2' },
  },
  GRAPE: {
    address: '8upjSpvjcdpuzhfR1zriwg5NXkwDruejqNE9WNbPRtyA',
    chainId: 101,
    decimals: 6,
    name: 'Grape',
    symbol: 'GRAPE',
    logoURI:
      'https://lh3.googleusercontent.com/y7Wsemw9UVBc9dtjtRfVilnS1cgpDt356PPAjne5NvMXIwWz9_x7WKMPH99teyv8vXDmpZinsJdgiFQ16_OAda1dNcsUxlpw9DyMkUk=s0',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'grape-2' },
  },
  xETH: {
    address: '8bqjz8DeSuim1sEAsQatjJN4zseyxSPdhHQcuuhL8PCK',
    chainId: 101,
    decimals: 9,
    name: 'Synthetic ETH',
    symbol: 'xETH',
    logoURI: 'https://www.synthetify.io/icons/xeth.svg',
    tags: ['old-registry'],
  },
  SBONK: {
    address: 'H1G6sZ1WDoMmMCFqBKAbg9gkQPCo1sKQtaJWz9dHmqZr',
    chainId: 101,
    decimals: 9,
    name: 'SHIBONK',
    symbol: 'SBONK',
    logoURI:
      'https://raw.githubusercontent.com/Sperlo64/SHIBONK/main/bonklogo2.png',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'shibonk-311f81df-a4ea-4f31-9e61-df0af8211bd7' },
  },
  HuntBoden: {
    address: '22513u2QwiY6xaJn7nVFWGKy3aBdw6WfZsRPW2RRtCKj',
    chainId: 101,
    decimals: 6,
    name: 'Hunter Boden',
    symbol: 'HuntBoden',
    logoURI:
      'https://cf-ipfs.com/ipfs/Qmdh9Jz5SEPKeRSmTu7Ey3BnFEqAPtGZ9nM6qKceJhk1Jv',
    tags: ['community'],
    extensions: { coingeckoId: 'hunter-boden' },
  },
  CICADA: {
    address: 'HdnUzyXVD64afcdti3asmtWnsSP9TDrRs16UAqoJp9xS',
    chainId: 101,
    decimals: 6,
    name: 'Locust Pocus',
    symbol: 'CICADA',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmQtU6LGYU6q11nsKzedpyHurZU9JPEXpA1odZA811PKEG',
    tags: ['community'],
    extensions: { coingeckoId: 'locust-pocus' },
  },
  COS: {
    address: '3obNTknsf4hq5pNh3GDPEhyCB9WTKJJzM2PcqfKgLmBu',
    chainId: 101,
    decimals: 9,
    name: 'Cats Of Sol',
    symbol: 'COS',
    logoURI:
      'https://photos.pinksale.finance/file/pinksale-logo-upload/1711039213391-48bc7a91af8847137b880faf98ee0b9c.png',
    tags: ['community'],
    extensions: { coingeckoId: 'cats-of-sol' },
  },
  sRLY: {
    address: 'RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq',
    chainId: 101,
    decimals: 9,
    name: 'Rally (Legacy - deprecated)',
    symbol: 'sRLY',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RLYv2ubRMDLcGG2UyvPmnPmkfuQTsMbg4Jtygc7dmnq/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  SENDER: {
    address: '9yotbA6u4g2ZZoUajkyS8FntaFUwVRFyLn2Q4PSrfNik',
    chainId: 101,
    decimals: 9,
    name: 'Burnie Senders',
    symbol: 'SENDER',
    logoURI:
      'https://bafkreiemgihzqfd7bxbxjzwps4e5wf3swh46up2ls5c5fy3cmnzte44dey.ipfs.nftstorage.link',
    tags: ['community'],
  },
  CTKOL: {
    address: 'Fyr3bF5PatZLcq6odQ7izFyJom1uoS96WftVWdWsJYwq',
    chainId: 101,
    decimals: 6,
    name: 'catKOL',
    symbol: 'CTKOL',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmQeBKc2Vj8unypk6KKWrxX4mN7vMXVag1ZndT67N9Yt8Z',
    tags: ['community'],
  },
  RIBBET: {
    address: '32gaR4rn9JyzoDVwMzZ5j3NgcHc5RQhMSJby55FFKnq3',
    chainId: 101,
    decimals: 9,
    name: 'RIBBET',
    symbol: 'RIBBET',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/32gaR4rn9JyzoDVwMzZ5j3NgcHc5RQhMSJby55FFKnq3/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  SCRAT: {
    address: '3GEznP41VaAGv9yRdBiuYdA8zkffNc8b7DzNJgFbH4Kh',
    chainId: 101,
    decimals: 6,
    name: 'Scrat',
    symbol: 'SCRAT',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f6261666b726569636e7834777176766a6665657a78773273336b70666f6e6a3764677371366876366275727a7567626673656e65746b77326a76692e697066732e6e667473746f726167652e6c696e6b',
    tags: ['community'],
    extensions: { coingeckoId: 'scrat' },
  },
  $GARY: {
    address: '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk',
    chainId: 101,
    decimals: 9,
    name: 'GARY',
    symbol: '$GARY',
    logoURI:
      'https://raw.githubusercontent.com/puresec-ng/gary/main/GARY_coin.jpg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'gary' },
  },
  FUEL: {
    address: 'fueL3hBZjLLLJHiFH9cqZoozTG3XQZ53diwFPwbzNim',
    chainId: 101,
    decimals: 0,
    name: 'Fuel',
    symbol: 'FUEL',
    logoURI: 'https://storage.googleapis.com/nft-assets/items/FUEL.png',
    tags: ['community'],
  },
  BON: {
    address: '6bPFbxgpsFZg6WDruj7vrodq4GY7K7TmqUnAm1byu3PW',
    chainId: 101,
    decimals: 6,
    name: 'bon shatiro',
    symbol: 'BON',
    logoURI:
      'https://bafkreidzjjgjpoghdhd4nn35rgsonvwz532kmv3d3oqcmovfxdoj7wucri.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  clSUI: {
    address: 'JzwfZvJGdsqbrKZQUvzJpWhbHcZUix7CYcCaoiNpjxg',
    chainId: 101,
    decimals: 8,
    name: 'Cloned Sui',
    symbol: 'clSUI',
    logoURI: 'https://markets.clone.so/images/assets/on-sui.svg',
    tags: ['community'],
    extensions: { coingeckoId: 'cloned-sui' },
  },
  PINGU: {
    address: 'Ffjrfw9phxYYpQc9fyYq5uRV3K6943Wzo3t9a1L4vfoW',
    chainId: 101,
    decimals: 4,
    name: 'Pingu',
    symbol: 'PINGU',
    logoURI:
      'https://bafkreifpyinqj5cl7ioyqfj4gmozxryhxljvk75e5kytvdsbr2wtc4w77a.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'pingu-on-sol' },
  },
  ATH: {
    address: 'F9BqFoWRML4Red6YPiL3xJBP7u9g8f61AKJMEYDB3wBR',
    chainId: 101,
    decimals: 9,
    name: 'Athens',
    symbol: 'ATH',
    logoURI: 'https://arweave.net/GNYZdT1icjRPLbRoivdtSU9_GqBM7LF6khrpV9qCBtM',
    tags: ['community'],
  },
  pumpkinSOL: {
    address: 'pumpkinsEq8xENVZE6QgTS93EN4r9iKvNxNALS1ooyp',
    chainId: 101,
    decimals: 9,
    name: "Pumpkin's Staked SOL",
    symbol: 'pumpkinSOL',
    logoURI:
      'https://raw.githubusercontent.com/bartenbach/pumpkins_pool/main/assets/img/pumpkintoken.png',
    tags: ['community'],
    extensions: { coingeckoId: 'pumpkin-staked-sol' },
  },
  CIGGS: {
    address: '7p6RjGNZ7HLHpfTo6nh21XYw4CZgxXLQPzKXG72pNd2y',
    chainId: 101,
    decimals: 9,
    name: 'CHUNGHWA',
    symbol: 'CIGGS',
    logoURI:
      'https://bafkreieagcborzxb4mkn37uvxjq7wzrxuuix76crpyym7by2bkkrik4jn4.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  JORDAN: {
    address: 'HKmEsdtmRm9WXuEHgJbU3oHyij4EwB3YtEuUrm5qEJFZ',
    chainId: 101,
    decimals: 9,
    name: 'Jordan Belfort',
    symbol: 'JORDAN',
    logoURI:
      'https://bafybeih64j4xgfzm7vgu5ivy6fm6fi2gshh7mug4tzsfray73ykt7lynsm.ipfs.cf-ipfs.com',
    tags: ['community'],
  },
  MOOCAT: {
    address: 'FuLH3f9TPxiLNojPBZxfaT5Rb5VFfia8hMzLo9e9c6CG',
    chainId: 101,
    decimals: 9,
    name: 'MooCat',
    symbol: 'MOOCAT',
    logoURI:
      'https://ipfs.io/ipfs/QmbEttNfGhPjtQ79P1xd31Mgu3TyDUVENpnMgYx1QsAw77',
    tags: ['community'],
    extensions: { coingeckoId: 'moocat' },
  },
  DAPE: {
    address: '6AarZpv8KwmPBxBEZdRmd3g1q2tUBaSgTNQ5e621qcZQ',
    chainId: 101,
    decimals: 2,
    name: 'Degenerate Ape Academy',
    symbol: 'DAPE',
    logoURI:
      'https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreie44hlb5xve225n6p2siebaevxiwha37aefyvs2ul2kx2akalnree.ipfs.dweb.link/',
    tags: ['community', 'solana-fm'],
  },
  CZOL: {
    address: 'AUwugnCh1tFc5scRHLNqnHjfcRbHRsq7yrKFUe7Ysmgs',
    chainId: 101,
    decimals: 6,
    name: 'CZOL',
    symbol: 'CZOL',
    logoURI:
      'https://bafkreiemwpzys44xlp5ug34bvjl3b4iyhoc3aznaykas67vwajx7zl3csi.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'czolana' },
  },
  HABIBI: {
    address: '864YJRb3JAVARC4FNuDtPKFxdEsYRbB39Nwxkzudxy46',
    chainId: 101,
    decimals: 6,
    name: 'HABIBI',
    symbol: 'HABIBI',
    logoURI:
      'https://bafybeidxe4hbr3vn7cy7m3tcvxvrmzzc4cl6dcbmkqdb4yg7wgxli4iwyi.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'habibi-sol' },
  },
  UM: {
    address: 'DMCUFm2ZAnSU7UgsdVq23gRogMU3MEBjPgQF1gK53rEn',
    chainId: 101,
    decimals: 6,
    name: 'UncleMine',
    symbol: 'UM',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DMCUFm2ZAnSU7UgsdVq23gRogMU3MEBjPgQF1gK53rEn/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  FLTH: {
    address: 'FLTHudk5B5zag7JmGXqrYrFfey6otevLQA6jm1UHHLEE',
    chainId: 101,
    decimals: 9,
    name: 'FLTH',
    symbol: 'FLTH',
    logoURI:
      'https://www.arweave.net/6cZ-d9BnAsCqxkpzazYmKjLpUN2kE-jYnU3WDV4_lo0?ext=png',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'liberty-square-filth' },
  },
  POT: {
    address: '9iz45n44TQUPyoRymdZXEunqvZUksZyhzS6zQ7sLMadj',
    chainId: 101,
    decimals: 9,
    name: 'Positron',
    symbol: 'POT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9iz45n44TQUPyoRymdZXEunqvZUksZyhzS6zQ7sLMadj/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  MATH: {
    address: 'CaGa7pddFXS65Gznqwp42kBhkJQdceoFVT7AQYo8Jr8Q',
    chainId: 101,
    decimals: 8,
    name: 'MATH Token (Portal)',
    symbol: 'MATH',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/MATH_wh.png',
    tags: ['wormhole'],
    extensions: { coingeckoId: 'math' },
  },
  CAPY: {
    address: 'CAPYD6Lrm7bTZ6C7t7JvSxvpEcfKQ9YNB7kUjh6p6XBN',
    chainId: 101,
    decimals: 9,
    name: 'Capybara',
    symbol: 'CAPY',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CAPYD6Lrm7bTZ6C7t7JvSxvpEcfKQ9YNB7kUjh6p6XBN/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'capybara' },
  },
  aaDAI: {
    address: 'EgQ3yNtVhdHz7g1ZhjfGbxhFKMPPaFkz8QHXM5RBZBgi',
    chainId: 101,
    decimals: 9,
    name: 'Wrapped DAI (Allbridge from Avalanche)',
    symbol: 'aaDAI',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1/logo.png',
    tags: ['old-registry'],
  },
  $blep: {
    address: 'Bkd4k5nLA6Aq7PHLJUFnWGSyWriyT2fcKbADScaBGwJy',
    chainId: 101,
    decimals: 6,
    name: 'blep :p',
    symbol: '$blep',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmWs45aHW1DQuAYVT51qSRuKVf7naTSJoAqXZbuuCrKR7u',
    tags: ['community'],
  },
  PHAUNTEM: {
    address: '5veVHPNDsmSxDW4Abpb368wLHpo32LP3fqhfJHnkSSwo',
    chainId: 101,
    decimals: 9,
    name: 'Phauntem',
    symbol: 'PHAUNTEM',
    logoURI:
      'https://rose-imperial-bug-423.mypinata.cloud/ipfs/Qmby3HKMXxR6Gx4qQJuy8BVyvw93jnZhQu3NV6wQk7x4QR',
    tags: ['community'],
    extensions: { coingeckoId: 'phauntem' },
  },
  SOLC: {
    address: 'Bx1fDtvTN6NvE4kjdPHQXtmGSg582bZx9fGy4DQNMmAT',
    chainId: 101,
    decimals: 9,
    name: 'Solcubator',
    symbol: 'SOLC',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bx1fDtvTN6NvE4kjdPHQXtmGSg582bZx9fGy4DQNMmAT/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  $NAP: {
    address: '4G86CMxGsMdLETrYnavMFKPhQzKTvDBYGMRAdVtr72nu',
    chainId: 101,
    decimals: 6,
    name: 'SNAP',
    symbol: '$NAP',
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://bafkreibmmrxk72fmcp3pxcrusm5hot44mwawyfhny4tp2jwc2sgjtjfa2y.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'snap-kero' },
  },
  GMECAT: {
    address: 'DcgDYp2AbHzCSsdhxTds887uk98wtTTA9fd2DLzNi6UZ',
    chainId: 101,
    decimals: 9,
    name: 'GME CAT',
    symbol: 'GMECAT',
    logoURI:
      'https://node1.irys.xyz/cs-2kjnEtfahbF3pXI0raiwr6k1ZA94-SA_H_UdsTbU',
    tags: ['community'],
  },
  GOME: {
    address: '8ULCkCTUa3XXrNXaDVzPcja2tdJtRdxRr8T4eZjVKqk',
    chainId: 101,
    decimals: 6,
    name: 'GAME OF MEMES',
    symbol: 'GOME',
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://cf-ipfs.com/ipfs/QmRf3hwySTcY257EbqmLNZMqyBwY6dPhQodAuHXjEutDYK',
    tags: ['community'],
    extensions: { coingeckoId: 'game-of-memes' },
  },
  XTV: {
    address: 'GTTS8jkhmfWBdYyKNSgbDQeYqVkpALkP98YJRGbRbvUA',
    chainId: 101,
    decimals: 9,
    name: 'XTV',
    symbol: 'XTV',
    logoURI:
      'https://dd.dexscreener.com/ds-data/tokens/solana/GTTS8jkhmfWBdYyKNSgbDQeYqVkpALkP98YJRGbRbvUA.png?size=lg&key=591e51',
    tags: ['community'],
  },
  'swhETH-9': {
    address: 'KNVfdSJyq1pRQk9AKKv1g5uyGuk6wpm4WG16Bjuwdma',
    chainId: 101,
    decimals: 9,
    name: 'Saber Wrapped Ether (Portal) (9 decimals)',
    symbol: 'swhETH-9',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KNVfdSJyq1pRQk9AKKv1g5uyGuk6wpm4WG16Bjuwdma/icon.png',
    tags: ['old-registry'],
  },
  ' TOBI': {
    address: '6h4ZKWqb9dDW8jaB4d76LNfP8PoRgiSvQLBHnkuih1Ty',
    chainId: 101,
    decimals: 6,
    name: 'MOTO DOG',
    symbol: ' TOBI',
    logoURI:
      'https://bafkreicd4673w7b4ziv27nxm2vdb7nviqjpxzdthbsgukbdpk3ggztb2sy.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'moto-dog' },
  },
  SMOG: {
    address: 'FS66v5XYtJAFo14LiPz5HT93EUMAHmYipCfQhLpU4ss8',
    chainId: 101,
    decimals: 6,
    name: 'Smog',
    symbol: 'SMOG',
    logoURI:
      'https://cloudflare-ipfs.com/ipfs/QmXXiyBNz4PWiP73JicWvQBPvsKJG2uwsvF5obyNvUvL9h',
    tags: ['community'],
    extensions: { coingeckoId: 'smog' },
  },
  CWH: {
    address: '2biAFVhHGr13P2wBKeRjnD1HUsBGFBvbHtMyooVZii7E',
    chainId: 101,
    decimals: 6,
    name: 'Cat Wif Hoodie',
    symbol: 'CWH',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmRfHnjPPTpW168G8a5sQCtpwMT7YaxUEQa2eyVkZcP6rD',
    tags: ['community'],
  },
  CAN: {
    address: 'FF4dN8Qy8NNF88HRgMA3TkbRVZ8PTXWXZCZJb59X3Sbg',
    chainId: 101,
    decimals: 9,
    name: 'canwifhat',
    symbol: 'CAN',
    logoURI:
      'https://bafkreicux6styygfnt6imzlhjhpq76glpkosoo7m6lt5xcqrsa5ucjtome.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'canwifhat' },
  },
  RARE: {
    address: 'HmLspvjpQtQEnArUyJoBSFGS38gNJwBuxAeqSV9SZ66K',
    chainId: 101,
    decimals: 9,
    name: 'RARE',
    symbol: 'RARE',
    logoURI:
      'https://raw.githubusercontent.com/SuperRareBears/srb-assets/main/tokens/rare/logo.png',
    tags: ['community'],
    extensions: { coingeckoId: 'superrarebears-rare' },
  },
  SANDY: {
    address: '6hBry7w55A3NXDAXxjKqrx3oXfAatNkAJUSGt66ZRsZk',
    chainId: 101,
    decimals: 6,
    name: 'Sandy',
    symbol: 'SANDY',
    logoURI:
      'https://bafkreieur42wdbsrrlglcz6kp55kruavaxisd25o42fnydemcvp5qulf2i.ipfs.nftstorage.link',
    tags: ['community', 'token-2022'],
  },
  KEYDOG: {
    address: '3fGoNjPohYRVPk9iJuwEW5WuURU5gmAdSRgNgeaanexQ',
    chainId: 101,
    decimals: 6,
    name: 'keydog',
    symbol: 'KEYDOG',
    logoURI:
      'https://bafybeicduibywphlr6mrlo45gvpmlpnfcbc322alo4bgibwu46l6j7mkay.ipfs.cf-ipfs.com/',
    tags: ['community'],
    extensions: { coingeckoId: 'keydog' },
  },
  rkSOL: {
    address: 'EPCz5LK372vmvCkZH3HgSuGNKACJJwwxsofW6fypCPZL',
    chainId: 101,
    decimals: 9,
    name: 'StaRKe SOL',
    symbol: 'rkSOL',
    logoURI: 'https://arweave.net/INyKzzPVD4BUqqMgQbd_QxmhmPJq-NrLHujNTouwD4g',
    tags: ['community'],
  },
  WIK: {
    address: 'wikkR42C9DC4KukAQXcZESnYcRcrQXPEYULk1qpcgeK',
    chainId: 101,
    decimals: 9,
    name: 'Jon Wik',
    symbol: 'WIK',
    logoURI:
      'https://ipfs.io/ipfs/bafybeiflljnmin7wb34bb2z63cl74f5h5klzfvcde4sxbwd7p4ukff7rii',
    tags: ['community'],
  },
  KI: {
    address: 'kiGenopAScF8VF31Zbtx2Hg8qA5ArGqvnVtXb83sotc',
    chainId: 101,
    decimals: 9,
    name: 'Genopets Ki',
    symbol: 'KI',
    logoURI:
      'https://arweave.net/WfPR8w5dEoerG_bI3S2o2_nuSfWY3p8M4YbQ6ijd1cQ?ext=png',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'genopet-ki' },
  },
  $Gretch: {
    address: '7ay4X2zcHQRSSZd8NtzNwxqzLtwxoCCRnH2daWn3pump',
    chainId: 101,
    decimals: 6,
    name: 'Gretchen Witmer',
    symbol: '$Gretch',
    logoURI:
      'https://ipfs.io/ipfs/QmcvTcHzHKyUVuk4qXdLtsEceEHjDfkfHDjPRGFEMYEWiA',
    tags: ['community'],
  },
  LMDA: {
    address: 'LMDAmLNduiDmSiMxgae1gW7ubArfEGdAfTpKohqE5gn',
    chainId: 101,
    decimals: 6,
    name: 'lambda.markets',
    symbol: 'LMDA',
    logoURI: 'https://arweave.net/6y2eY-KEaBu4KbZLJwLCTQQUXhWVPKxjgdyW69DwI0o',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'lambda-markets' },
  },
  BAREBEARS: {
    address: 'AeNg6DaCAjNpK7CvkSC6c9j5g8YFSp78aTQxejaNRNcz',
    chainId: 101,
    decimals: 6,
    name: 'BAREBEARS',
    symbol: 'BAREBEARS',
    logoURI:
      'https://bafybeigojiffozmk6hzhbyrwvdiyrq3anyp2otos7p4us7nowkanlhgirq.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  MAGA: {
    address: 'AwduJQUJoSCZm5BUdhF99iZkUTUGHU34TPft8w5kNbw1',
    chainId: 101,
    decimals: 9,
    name: 'TRUMP’S HAT',
    symbol: 'MAGA',
    logoURI:
      'https://node1.irys.xyz/4sdErRxXo3KxARIkOffen9JWzEyjjA4GfbXxbTVr-Ks',
    tags: ['community'],
  },
  FRKT: {
    address: 'ErGB9xa24Szxbk1M28u2Tx8rKPqzL6BroNkkzk5rG4zj',
    chainId: 101,
    decimals: 8,
    name: 'FRAKT Token',
    symbol: 'FRKT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ErGB9xa24Szxbk1M28u2Tx8rKPqzL6BroNkkzk5rG4zj/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'frakt-token' },
  },
  GMCOCK: {
    address: '8CSTYs264XFrGym2WLy6QGgq6isxU8G4Und9q1gVbbbB',
    chainId: 101,
    decimals: 8,
    name: 'Felt Gaming Labs',
    symbol: 'GMCOCK',
    logoURI:
      'https://cdn.discordapp.com/attachments/1026783859309809674/1188099299565908029/IMG_8491.png',
    tags: ['community'],
  },
  SOLANA: {
    address: 'fESbUKjuMY6jzDH9VP8cy4p3pu2q5W2rK2XghVfNseP',
    chainId: 101,
    decimals: 6,
    name: 'CatFrogDogShark',
    symbol: 'SOLANA',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmVB3D2Cm19FKUL6sKwoGLxYRggwGV56p66gehhAepWewC',
    tags: ['community'],
    extensions: { coingeckoId: 'catfrogdogshark' },
  },
  peanie: {
    address: 'dekNoN3D8mXa4JHLwTbVXz8aPAyJUkk443UjcSpJKi4',
    chainId: 101,
    decimals: 6,
    name: 'peanie',
    symbol: 'peanie',
    logoURI:
      'https://bafybeigdlwglyry5q3y547rqg4jwagzbvx66byg7hotw4xz2bgjnzpb2oy.ipfs.cf-ipfs.com/',
    tags: ['community'],
    extensions: { coingeckoId: 'peanie' },
  },
  MOGGO: {
    address: 'FbtRb4zF2u52FSnjLB79yRg73hbMVgSw9rC897shqzb4',
    chainId: 101,
    decimals: 9,
    name: 'MOGGO',
    symbol: 'MOGGO',
    logoURI: 'https://moggo.club/wp-content/uploads/2024/04/moggoLogo200.png',
    tags: ['community'],
    extensions: { coingeckoId: 'moggo' },
  },
  ALL: {
    address: 'BaoawH9p2J8yUK9r5YXQs3hQwmUJgscACjmTkh8rMwYL',
    chainId: 101,
    decimals: 6,
    name: 'AllDomains',
    symbol: 'ALL',
    logoURI: 'https://arweave.net/FY7yQGrLCAvKAup_SYEsHDoTRZXsttuYyQjvHTnOrYk',
    tags: ['community'],
    extensions: { coingeckoId: 'alldomains' },
  },
  FRNT: {
    address: '3vHSsV6mgvpa1JVuuDZVB72vYbeUNzW4mBxiBftwzHEA',
    chainId: 101,
    decimals: 9,
    name: 'Final Frontier',
    symbol: 'FRNT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3vHSsV6mgvpa1JVuuDZVB72vYbeUNzW4mBxiBftwzHEA/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  LEGEND: {
    address: 'LGNDeXXXaDDeRerwwHfUtPBNz5s6vrn1NMSt9hdaCwx',
    chainId: 101,
    decimals: 9,
    name: 'Legends of SOL',
    symbol: 'LEGEND',
    logoURI: 'https://arweave.net/sCGvXDlbFn8p0s0cfqtznbjn9K_cyYY2aa9HGC1XWR8',
    tags: ['community'],
    extensions: { coingeckoId: 'legends-of-sol' },
  },
  LDO: {
    address: 'HZRCwxP2Vq9PCpPXooayhJ2bxTpo5xfpQrwB1svh332p',
    chainId: 101,
    decimals: 8,
    name: 'Lido DAO (Portal)',
    symbol: 'LDO',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/LDO_wh.png',
    tags: ['wormhole', 'old-registry'],
    extensions: { coingeckoId: 'lido-dao-wormhole' },
  },
  FUJI: {
    address: 'fujiCeCeP9AFDVCv27P5JRcKLoH7wfs2C9xmDECs24m',
    chainId: 101,
    decimals: 3,
    name: 'FUJI',
    symbol: 'FUJI',
    logoURI:
      'https://raw.githubusercontent.com/MarkSackerberg/token-list/main/assets/mainnet/fujiCeCeP9AFDVCv27P5JRcKLoH7wfs2C9xmDECs24m/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  jenner: {
    address: '4GJ3TCt5mTgQT5BRKb14AkjddpFQqKVfphxzS3t4foZ9',
    chainId: 101,
    decimals: 6,
    name: 'Caitlyn Jenner',
    symbol: 'jenner',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmUNHd4Tnm5VdAJUWntGy5fKZ9nP9AmrqHovRz3bwsAHcP',
    tags: ['community'],
  },
  LDZ: {
    address: 'E5ZVeBMazQAYq4UEiSNRLxfMeRds9SKL31yPan7j5GJK',
    chainId: 101,
    decimals: 9,
    name: 'Voodoo',
    symbol: 'LDZ',
    logoURI:
      'https://shdw-drive.genesysgo.net/5QGtUB5S2RKa2QyXYcEaXnoDEDN1VswZ5YZkgZ8Zf81S/FB062705-73CE-42C7-9C6F-4C7679DAF01A.jpeg',
    tags: ['community'],
    extensions: { coingeckoId: 'voodoo' },
  },
  UST: {
    address: '9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i',
    chainId: 101,
    decimals: 6,
    name: 'UST (Portal)',
    symbol: 'UST',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/UST_wh.png',
    tags: ['wormhole', 'old-registry'],
    extensions: { coingeckoId: 'terrausd-wormhole' },
  },
  fuseSOL: {
    address: 'fuseYvhNJbSzdDByyTCrLcogsoNwAviB1WeewhbqgFc',
    chainId: 101,
    decimals: 9,
    name: 'Fuse Staked SOL',
    symbol: 'fuseSOL',
    logoURI: 'https://fusesol.nyc3.digitaloceanspaces.com/fuseSOL.png',
    tags: ['community'],
  },
  EUROe: {
    address: '2VhjJ9WxaGC3EZFwJG9BDUs9KxKCAjQY4vgd1qxgYWVg',
    chainId: 101,
    decimals: 6,
    name: 'EUROe Stablecoin',
    symbol: 'EUROe',
    logoURI: 'https://dev.euroe.com/persistent/token-icon/png/256x256.png',
    tags: ['community'],
    extensions: { coingeckoId: 'euroe-stablecoin' },
  },
  GPT: {
    address: 'Eyhi3qZCW1hNgsLtv1geaeDgZJmRFCpEtbjABWfGcGx5',
    chainId: 101,
    decimals: 6,
    name: 'SolGPT',
    symbol: 'GPT',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmbzeT99i7YNMAXbjKbHq6zEuiPgEj7GwDvX5ZAz9cDYCo',
    tags: ['community'],
  },
  MER: {
    address: 'MERt85fc5boKw3BW1eYdxonEuJNvXbiMbs6hvheau5K',
    chainId: 101,
    decimals: 6,
    name: 'Mercurial(Deprecated)',
    symbol: 'MER',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MERt85fc5boKw3BW1eYdxonEuJNvXbiMbs6hvheau5K/logo.png',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'mercurial' },
  },
  CROX: {
    address: 'DTEqTxxGFn3SZ4C8tNP35X8iegCCgCBrX974WFSuYVZh',
    chainId: 101,
    decimals: 9,
    name: 'CROX',
    symbol: 'CROX',
    logoURI: 'https://croxcoin.com/logo/crox-logo-200x200.png',
    tags: ['community'],
    extensions: { coingeckoId: 'crox' },
  },
  'sUST-8': {
    address: 'UST8SCn7jrqsq51odVLqcmvnC658HkqrKrPL3w2hHQ7',
    chainId: 101,
    decimals: 8,
    name: 'Saber Wrapped UST (Portal) (8 decimals)',
    symbol: 'sUST-8',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UST8SCn7jrqsq51odVLqcmvnC658HkqrKrPL3w2hHQ7/icon.png',
    tags: ['old-registry'],
  },
  GROK: {
    address: 'BQ3F72yt9FVRgYrqCVCG3YohyBesDZ9bTuhGdmQ7GNEF',
    chainId: 101,
    decimals: 9,
    name: 'Grok',
    symbol: 'GROK',
    logoURI: 'https://imageupload.io/ib/buy0hGfs0Eq69TN_1699677473.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'grok-6' },
  },
  POS: {
    address: 'B8vV6An7xFF3bARB1cmU7TMfKNjjes2WvY7jWqiRc6K6',
    chainId: 101,
    decimals: 6,
    name: 'PengyOS',
    symbol: 'POS',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmTPWpadD376oTXzoEWMYEcWEFkLocK48hpbmUvxTpoWrn',
    tags: ['community'],
    extensions: { coingeckoId: 'pengyos' },
  },
  SSE: {
    address: 'CAa3j9oD6nDn5AeRmwZ6fcR78TAVXv9kumoihWvSbXsB',
    chainId: 101,
    decimals: 9,
    name: 'SelfieSteve',
    symbol: 'SSE',
    logoURI:
      'https://ipfs.io/ipfs/QmQaZBrdSeLAZiz8nTS2uQRpbGphWPb25ChapqpgrQfhWv',
    tags: ['community'],
    extensions: { coingeckoId: 'selfiesteve' },
  },
  ALIEN: {
    address: '4mJ6N65rD9w6sFPQ17UDWot2H64UtzR31biVLaKpZT6J',
    chainId: 101,
    decimals: 0,
    name: 'AlienUniverse Token',
    symbol: 'ALIEN',
    logoURI:
      'https://raw.githubusercontent.com/alien-universe/assets/main/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  BOKU: {
    address: 'BkW2v5uv6skTW5c5GYjBctkbY9nuyyHs3gry1dCo5Hra',
    chainId: 101,
    decimals: 9,
    name: 'BōKU Labz',
    symbol: 'BOKU',
    logoURI:
      'https://bafybeid35kcydo75arckv6nwbpxofohxp6jbx3sq6qy4fbmv3idmiqayeq.ipfs.nftstorage.link',
    tags: ['old-registry'],
  },
  CEICAT: {
    address: '8PMJczfs9W8TDKiNBD85AuqxE8tWACCDeUwxpUeadL3j',
    chainId: 101,
    decimals: 9,
    name: 'CEILING CAT',
    symbol: 'CEICAT',
    logoURI: 'https://i.imgur.com/ROCbyO3.png',
    tags: ['community'],
    extensions: { coingeckoId: 'ceiling-cat' },
  },
  GER: {
    address: '52DfsNknorxogkjqecCTT3Vk2pUwZ3eMnsYKVm4z3yWy',
    chainId: 101,
    decimals: 9,
    name: 'Germany ',
    symbol: 'GER',
    logoURI:
      'https://ipfs.io/ipfs/QmXiq9xyzZcnnYvNHumLjBGkLU4fQDeUUR7htrsJtEfpuQ',
    tags: ['community'],
    extensions: { coingeckoId: 'germany-coin' },
  },
  APE: {
    address: 'DF5yCVTfhVwvS1VRfHETNzEeh1n6DjAqEBs3kj9frdAr',
    chainId: 101,
    decimals: 9,
    name: 'Ape.lol',
    symbol: 'APE',
    logoURI:
      'https://gateway.irys.xyz/gp2rRn6Dsf9Riv4EkEWMiM82gokBIgqO__xaWN9UTVA',
    tags: ['community'],
    extensions: { coingeckoId: 'ape-lol' },
  },
  GME: {
    address: '8wXtPeU6557ETkp9WHFY1n1EcU6NxDvbAggHGsMYiHsB',
    chainId: 101,
    decimals: 9,
    name: 'GameStop',
    symbol: 'GME',
    logoURI:
      'https://nftstorage.link/ipfs/bafybeiddrzeyne4n2hi7qkhyhqorc4jbs4565pset7fc3ifwvhvxfdrt7q/gamestop-logo.jpeg',
    tags: ['community'],
    extensions: { coingeckoId: 'gme' },
  },
  pathSOL: {
    address: 'pathdXw4He1Xk3eX84pDdDZnGKEme3GivBamGCVPZ5a',
    chainId: 101,
    decimals: 9,
    name: 'Pathfinders SOL',
    symbol: 'pathSOL',
    logoURI:
      'https://raw.githubusercontent.com/igneous-labs/lst-offchain-metadata/master/pathSOL/pathSOL.png',
    tags: ['community'],
    extensions: { coingeckoId: 'pathfinders-staked-sol' },
  },
  BASIS: {
    address: 'Basis9oJw9j8cw53oMV7iqsgo6ihi9ALw4QR31rcjUJa',
    chainId: 101,
    decimals: 6,
    name: 'basis',
    symbol: 'BASIS',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Basis9oJw9j8cw53oMV7iqsgo6ihi9ALw4QR31rcjUJa/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'basis-markets' },
  },
  spurdo: {
    address: '8HJ81sHVsmJMzm6XBfTgywWQXwQTmVdmXaQHm7htGPH2',
    chainId: 101,
    decimals: 9,
    name: 'Spurdo Spärde',
    symbol: 'spurdo',
    logoURI:
      'https://bafkreiedrxet7nlt4bcqwkf6eeouhp3r2ioswagyn7dpb4eqao4lwweede.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'spurdo-sparde' },
  },
  BMT: {
    address: 'FanJWA4yEVUJj1r83tR7XybxmDGF6bNH8M81ag9aeUbF',
    chainId: 101,
    decimals: 9,
    name: 'BMT',
    symbol: 'BMT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FanJWA4yEVUJj1r83tR7XybxmDGF6bNH8M81ag9aeUbF/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  DUAL: {
    address: 'DUALa4FC2yREwZ59PHeu1un4wis36vHRv5hWVBmzykCJ',
    chainId: 101,
    decimals: 6,
    name: 'Dual DAO Token',
    symbol: 'DUAL',
    logoURI: 'https://www.dual.finance/images/token-logos/dual.png',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'dual-finance' },
  },
  CAW: {
    address: '9niFQK8MsParjSxhYQ3Ys2a6zHmsEuKSB1M7wwdvZ7bj',
    chainId: 101,
    decimals: 0,
    name: 'crow with knife (Wormhole)',
    symbol: 'CAW',
    logoURI: 'https://www.crowwithknife.com/200px.png',
    tags: ['community'],
    extensions: { coingeckoId: 'crow-with-knife' },
  },
  KEYCAT: {
    address: '9pPE1q9EW1bMQWbHmffrzUCfRr7S82UoxNUFfA6mAZC6',
    chainId: 101,
    decimals: 9,
    name: 'Keyboard Cat',
    symbol: 'KEYCAT',
    logoURI:
      'https://qd2ztbap7y35ga24m3m4y2zb234ellh5lx6uaw5jj4fgcdgmw7xa.arweave.net/gPWZhA_-N9MDXGbZzGsh1vhFrP1d_UBbqU8KYQzMt-4',
    tags: ['community'],
    extensions: { coingeckoId: 'keyboard-cat' },
  },
  WALLY: {
    address: 'Fo6tfAkXj74X6j8hati8SxtwZHHcdGeqXVUPLP9Abvqu',
    chainId: 101,
    decimals: 6,
    name: 'Wally The Whale',
    symbol: 'WALLY',
    logoURI:
      'https://nftstorage.link/ipfs/bafkreiaeqwqeluwwjyrusaofikmi5vivhl65lynym5oruemf7i52wrooyq',
    tags: ['community'],
    extensions: { coingeckoId: 'wally-the-whale' },
  },
  PAI: {
    address: '2TybzwN2X1g7Y6GRbB4cRx4KPK7bqdK9tYCozBXxiTfr',
    chainId: 101,
    decimals: 6,
    name: 'Procreation AI',
    symbol: 'PAI',
    logoURI:
      'https://bafkreifcytddas5fcgeaatsxu4yx5wwu4ths7726qa7rw4c2njbyuvmhum.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  ARB: {
    address: '9tzZzEHsKnwFL1A3DyFJwj36KnZj3gZ7g4srWp9YTEoh',
    chainId: 101,
    decimals: 6,
    name: 'ARB Protocol',
    symbol: 'ARB',
    logoURI:
      'https://raw.githubusercontent.com/ARBProtocol/arbprotocol/main/IMG_3600.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'arb-protocol' },
  },
  SPACEGOLD: {
    address: '9mXZ54YnJJRmUN2MaMEtWCfFyoncP4ZhKz7U9DZ4JY2X',
    chainId: 101,
    decimals: 4,
    name: 'SPACEGOLD',
    symbol: 'SPACEGOLD',
    logoURI:
      'https://raw.githubusercontent.com/cosmic-condos/SPACEGOLD/main/logo-icon.png',
    tags: ['old-registry', 'solana-fm'],
  },
  DUST: {
    address: 'DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ',
    chainId: 101,
    decimals: 9,
    name: 'DUST Protocol',
    symbol: 'DUST',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ/logo.jpg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'dust-protocol' },
  },
  CFA: {
    address: 'F1n2Tn7Eb9jTbSQiqy2Z7G4VTbkreHGQqcRKKmwZv726',
    chainId: 101,
    decimals: 9,
    name: 'Tradfi Bro',
    symbol: 'CFA',
    logoURI:
      'https://turquoise-elderly-caribou-768.mypinata.cloud/ipfs/QmcnF55f9w2eT1LQQPgCRanXeAmz7DUb7QZCkJDewC21oS',
    tags: ['community'],
    extensions: { coingeckoId: 'tradfi-bro' },
  },
  BURRRD: {
    address: 'F8qtcT3qnwQ24CHksuRrSELtm5k9ob8j64xAzj3JjsMs',
    chainId: 101,
    decimals: 4,
    name: 'BURRRD',
    symbol: 'BURRRD',
    logoURI: 'https://arweave.net/5qQw9rLaDDQeFJ6-384p8yRmnl_8zK47JBwl3QYmD60',
    tags: ['community'],
    extensions: { coingeckoId: 'burrrd' },
  },
  batcat: {
    address: 'EtBc6gkCvsB9c6f5wSbwG8wPjRqXMB5euptK6bqG1R4X',
    chainId: 101,
    decimals: 6,
    name: 'batcat',
    symbol: 'batcat',
    logoURI:
      'https://bafybeicsjyohcl7dtqag6towz7xyyc2h2fjn7mkhjpbyo7mhinfwkidwjm.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'batcat' },
  },
  XSB: {
    address: '4UuGQgkD3rSeoXatXRWwRfRd21G87d5LiCfkVzNNv1Tt',
    chainId: 101,
    decimals: 9,
    name: 'Solareum',
    symbol: 'XSB',
    logoURI: 'https://solareum.app/icons/XSB-G.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'solareum-wallet' },
  },
  speed: {
    address: '5Wd2ALxQfnpgQKCyH4WL9giBiiuuLuJs84CJxfQccvmN',
    chainId: 101,
    decimals: 6,
    name: 'real fast',
    symbol: 'speed',
    logoURI:
      'https://cf-ipfs.com/ipfs/Qmefzw1YgkLqYGJbrPirJLtbzjoNJ5JfBKN1tMX3okpHfE',
    tags: ['community'],
    extensions: { coingeckoId: 'real-fast' },
  },
  ICEDOUT: {
    address: '26bedy893CHqi5bcuUFhMgD6uTLw9V9iLWKAQjjDjpEA',
    chainId: 101,
    decimals: 9,
    name: 'Iced Out Bulls',
    symbol: 'ICEDOUT',
    logoURI: 'https://icedoutbulls.github.io/icedoutbulls/io_logo.png',
    tags: ['community'],
  },
  daoSOL: {
    address: 'GEJpt3Wjmr628FqXxTgxMce1pLntcPV4uFi8ksxMyPQh',
    chainId: 101,
    decimals: 9,
    name: 'daoSOL Token',
    symbol: 'daoSOL',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GEJpt3Wjmr628FqXxTgxMce1pLntcPV4uFi8ksxMyPQh/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'daosol' },
  },
  GOFURS: {
    address: '4xnxNjLkeVoJEAUFjj5xTvkdTLGYHtrdyyXThGDFhwmr',
    chainId: 101,
    decimals: 6,
    name: 'GOFURS Del Sol',
    symbol: 'GOFURS',
    logoURI: 'https://arweave.net/X9m1CVUfzInuKry4cjZrarZE_omTtbwPXrSeaHvkvWE',
    tags: ['community'],
  },
  edgeSOL: {
    address: 'edge86g9cVz87xcpKpy3J77vbp4wYd9idEV562CCntt',
    chainId: 101,
    decimals: 9,
    name: 'Edgevana Staked SOL',
    symbol: 'edgeSOL',
    logoURI: 'https://arweave.net/JqUwm41OHf2jFLDognx7BKD6N9LiUDDRMTI8k2B7fHE',
    tags: ['community'],
    extensions: { coingeckoId: 'edgevana-staked-sol' },
  },
  BIRDDOG: {
    address: '3XTp12PmKMHxB6YkejaGPUjMGBLKRGgzHWgJuVTsBCoP',
    chainId: 101,
    decimals: 9,
    name: 'Bird Dog',
    symbol: 'BIRDDOG',
    logoURI:
      'https://dd.dexscreener.com/ds-data/tokens/solana/3XTp12PmKMHxB6YkejaGPUjMGBLKRGgzHWgJuVTsBCoP.png?size=md',
    tags: ['community'],
    extensions: { coingeckoId: 'bird-dog-on-sol' },
  },
  strongSOL: {
    address: 'strng7mqqc1MBJJV6vMzYbEqnwVGvKKGKedeCvtktWA',
    chainId: 101,
    decimals: 9,
    name: 'Stronghold LST',
    symbol: 'strongSOL',
    logoURI:
      'https://shdw-drive.genesysgo.net/CqCTE2k6ext17JLE7EFk6pXAznS7m1rLJaN9nHxUAwpc/stronghold.png',
    tags: ['community'],
    extensions: { coingeckoId: 'stronghold-staked-sol' },
  },
  SLO: {
    address: 'E43qU77tnWDwN11o7TtaGMNpxCAqz8RZEZ7PcTCUXSim',
    chainId: 101,
    decimals: 9,
    name: 'SmoLanO',
    symbol: 'SLO',
    logoURI:
      'https://img.fotofolio.xyz/?url=https%3A%2F%2Farweave.net%2F2SMzEgv4IqPaiTkKe09CLodbOWbiC2rmIKRzgkFsFNs',
    tags: ['community'],
    extensions: { coingeckoId: 'smolano' },
  },
  QUEST: {
    address: '6ybxMQpMgQhtsTLhvHZqk8uqao7kvoexY6e8JmCTqAB1',
    chainId: 101,
    decimals: 4,
    name: 'QUEST',
    symbol: 'QUEST',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6ybxMQpMgQhtsTLhvHZqk8uqao7kvoexY6e8JmCTqAB1/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  SAUCE: {
    address: '427xvZVKbFj7ZyfFoYS9iFpNuNsrijm6T9VP8znfko9j',
    chainId: 101,
    decimals: 9,
    name: 'SAUCE',
    symbol: 'SAUCE',
    logoURI:
      'https://golxb67pwrvlfxe44zvsvl25lhkb6hhk3xwhwqasfgpwcovn62ba.arweave.net/M5dw---0arLcnOZrKq9dWdQfHOrd7HtAEimfYTqt9oI',
    tags: ['community'],
    extensions: { coingeckoId: 'sauce' },
  },
  LOWQ: {
    address: '7UAzn8R4mBfG1foqyL1169Lzd6cSWXYSLQFXYHzsiNQG',
    chainId: 101,
    decimals: 9,
    name: 'LowQ',
    symbol: 'LOWQ',
    logoURI:
      'https://bafkreianicicf7nez6viem3m3jferpzuegfjjbfvrxmafv572versrnvqy.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'lowq' },
  },
  SHDW: {
    address: 'SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y',
    chainId: 101,
    decimals: 9,
    name: 'Shadow Token',
    symbol: 'SHDW',
    logoURI:
      'https://shdw-drive.genesysgo.net/FDcC9gn12fFkSU2KuQYH4TUjihrZxiTodFRWNF4ns9Kt/250x250_with_padding.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'genesysgo-shadow' },
  },
  aaUSDT: {
    address: 'FwEHs3kJEdMa2qZHv7SgzCiFXUQPEycEXksfBkwmS8gj',
    chainId: 101,
    decimals: 9,
    name: 'Wrapped USDT (Allbridge from Avalanche)',
    symbol: 'aaUSDT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg',
    tags: ['old-registry'],
  },
  FUDSI: {
    address: '91Fv8PztVLsf4S49RANPGW92veJ2oL6BjwsP9i666Wxu',
    chainId: 101,
    decimals: 6,
    name: 'Fuddles Shiba Inu',
    symbol: 'FUDSI',
    logoURI: 'https://arweave.net/iv6zV6x03hbFXfnS1IJo0nSbr0HFNrjMzy_qbL3sjW8',
    tags: ['community'],
  },
  BERN: {
    address: 'CKfatsPMUf8SkiURsDXs7eK6GWb4Jsd6UDbs7twMCWxo',
    chainId: 101,
    decimals: 5,
    name: 'BonkEarn',
    symbol: 'BERN',
    logoURI: 'https://i.imgur.com/nd9AVZ4.jpeg',
    tags: ['community', 'token-2022'],
    extensions: { coingeckoId: 'bonkearn' },
  },
  BANA: {
    address: 'BhPXDQio8xtNC6k5Bg5fnUVL9kGN8uvRDNwW8MZBu8DL',
    chainId: 101,
    decimals: 4,
    name: 'Shibana',
    symbol: 'BANA',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BhPXDQio8xtNC6k5Bg5fnUVL9kGN8uvRDNwW8MZBu8DL/Shibana.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'shibana' },
  },
  rZOOM: {
    address: 'Vjq9T5xmqRzLXQRyvigzyZzpHCGCsbYAJ7afLVuF8j9',
    chainId: 101,
    decimals: 9,
    name: 'Random Zaysan Raptors',
    symbol: 'rZOOM',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Vjq9T5xmqRzLXQRyvigzyZzpHCGCsbYAJ7afLVuF8j9/rZOOM.png',
    tags: ['old-registry'],
  },
  METAS: {
    address: '3Ztt53vwGhQGoEp3n1RjSu4CFnGRfqzwo6L8KN8gmXfd',
    chainId: 101,
    decimals: 9,
    name: 'METASEER',
    symbol: 'METAS',
    logoURI: 'https://metaseer.io/img/home-one/logo256.png',
    tags: ['old-registry', 'solana-fm'],
  },
  $DTJR: {
    address: '7G7SMGV9nSG316ykk6iobjMZWa8GZb15Wd25kgaZGTaZ',
    chainId: 101,
    decimals: 6,
    name: 'Donald Trump Jr.',
    symbol: '$DTJR',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d5a38556b52397a4c74566a3738437763667a4b7771377a57453463554463366448526d3574354b4c3462417a',
    tags: ['community'],
  },
  WEYU: {
    address: 'EHaEBhYHWA7HSphorXXosysJem6qF4agccoqDqQKCUge',
    chainId: 101,
    decimals: 8,
    name: 'WEYU',
    symbol: 'WEYU',
    logoURI: 'https://weyu.io/img/WEYU.png',
    tags: ['old-registry', 'solana-fm'],
  },
  LMF: {
    address: 'LMFzmYL6y1FX8HsEmZ6yNKNzercBmtmpg2ZoLwuUboU',
    chainId: 101,
    decimals: 9,
    name: 'Lamas Finance',
    symbol: 'LMF',
    logoURI: 'https://www.lamas.co/resource/lmf_token.png',
    tags: ['community'],
    extensions: { coingeckoId: 'lamas-finance' },
  },
  EURO2024: {
    address: 'D5oCx51J1hgwGAaaWgn4aKqU8TvbaTDx3NtNUdkKrGgq',
    chainId: 101,
    decimals: 9,
    name: 'EURO2024',
    symbol: 'EURO2024',
    logoURI:
      'https://ipfs.io/ipfs/QmPpoqMS7a1789sdaHuN7VjAMZSSXLCC8ANwkFgste4wKb',
    tags: ['community'],
  },
  WIT: {
    address: 'Adq3wnAvtaXBNfy63xGV1YNkDiPKadDT469xF9uZPrqE',
    chainId: 101,
    decimals: 6,
    name: 'What in Tarnation?',
    symbol: 'WIT',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmeqEFthErkc4E5r758Uc3X7hLEyB9S83iWc5ZjcW1C9M3',
    tags: ['community'],
    extensions: { coingeckoId: 'what-in-tarnation' },
  },
  CHIPPY: {
    address: 'Bz7Nx1F3Mti1BVS7ZAVDLSKGEaejufxvX2DPdjpf8PqT',
    chainId: 101,
    decimals: 6,
    name: 'FISH N CHIPS',
    symbol: 'CHIPPY',
    logoURI:
      'https://pump.mypinata.cloud/ipfs/QmdiHcE6Fr6H8qsyPtxcodUTvdsfJVS5iG29ysGzNsbKLe',
    tags: ['community'],
    extensions: { coingeckoId: 'fish-n-chips' },
  },
  WOOF: {
    address: '9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE',
    chainId: 101,
    decimals: 6,
    name: 'WOOF',
    symbol: 'WOOF',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'woof-token' },
  },
  WHALES: {
    address: 'GTH3wG3NErjwcf7VGCoXEXkgXSHvYhx5gtATeeM5JAS1',
    chainId: 101,
    decimals: 6,
    name: 'Whales Market',
    symbol: 'WHALES',
    logoURI:
      'https://nftstorage.link/ipfs/bafkreic6gboxk4nlhhzzuoqakj55s23y37qzwb7yzykczfxbwj7kafy75i',
    tags: ['community'],
    extensions: { coingeckoId: 'whales-market' },
  },
  Norm: {
    address: '9M26M7CxkJdaewdiH8v5kSiiXKbWBBirTE1QmXHmDvVg',
    chainId: 101,
    decimals: 6,
    name: 'Norman',
    symbol: 'Norm',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d506850696f745951615332326953713943774141424b5737596a31754e464c7a794267747231464258424e53',
    tags: ['community'],
    extensions: { coingeckoId: 'norman' },
  },
  KING: {
    address: '9noXzpXnkyEcKF3AeXqUHTdR59V5uvrRBUZ9bwfQwxeq',
    chainId: 101,
    decimals: 9,
    name: 'KING',
    symbol: 'KING',
    logoURI: 'https://i.imgur.com/IQ4rEmD.png',
    tags: ['community'],
    extensions: { coingeckoId: 'king-2' },
  },
  PEEP: {
    address: 'n54ZwXEcLnc3o7zK48nhrLV4KTU5wWD4iq7Gvdt5tik',
    chainId: 101,
    decimals: 6,
    name: 'Peepo',
    symbol: 'PEEP',
    logoURI:
      'https://zk3y35n3ess4i2a4ya5a6hcllkelztul6a5vm2hk7wfjlq7fgypa.arweave.net/yreN9bskpcRoHMA6DxxLWoi8zovwO1Zo6v2KlcPlNh4',
    tags: ['community'],
    extensions: { coingeckoId: 'peepo-sol' },
  },
  $SSHIB: {
    address: '6VHL2vMKgrF1YQFSv29Rs1pj9VCRK29bD11NtDqerqHA',
    chainId: 101,
    decimals: 5,
    name: 'SSHIB',
    symbol: '$SSHIB',
    logoURI: 'https://solshib.space/resources/images/icons/logo.png',
    tags: ['community'],
    extensions: { coingeckoId: 'solana-shib' },
  },
  $BEAR: {
    address: '24a1k6wSDQUSwoRXJyvkr25kF5do8f6Ddff6A2fTc1pM',
    chainId: 101,
    decimals: 9,
    name: 'BEAR',
    symbol: '$BEAR',
    logoURI: 'https://arweave.net/7Qzb-BKL734z8w77fPNJ8lu3f6TtA87nbiw2A-qmCK0',
    tags: ['community'],
  },
  IOT: {
    address: 'iotEVVZLEywoTn1QdwNPddxPWszn3zFhEot3MfL9fns',
    chainId: 101,
    decimals: 6,
    name: 'Helium IOT',
    symbol: 'IOT',
    logoURI:
      'https://shdw-drive.genesysgo.net/CsDkETHRRR1EcueeN346MJoqzymkkr7RFjMqGpZMzAib/iot.png',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'helium-iot' },
  },
  calUSD: {
    address: 'CALusHembJf3tQ69BxFbLRUSpGRwKzEnLKWUPhQo5dFk',
    chainId: 101,
    decimals: 9,
    name: 'calUSD Stablecoin',
    symbol: 'calUSD',
    logoURI:
      'https://user-images.githubusercontent.com/8619106/167318344-e9ea9147-8969-4818-877c-5c75de10aecc.png',
    tags: ['old-registry'],
  },
  apUSDC: {
    address: 'eqKJTf1Do4MDPyKisMYqVaUFpkEFAs3riGF3ceDH2Ca',
    chainId: 101,
    decimals: 6,
    name: 'Wrapped USDC (Allbridge from Polygon)',
    symbol: 'apUSDC',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXXkv6z8ykpG1yuvUDPgh732wzVHB69RnB9YgSYh3itW/logo.png',
    tags: ['old-registry'],
  },
  $JPMT: {
    address: '7ErxzRN1hpyMZC8gps7ANZFTGgeDG7cFmVZcMfE6oGrd',
    chainId: 101,
    decimals: 6,
    name: 'Jake Paul Mike Tyson',
    symbol: '$JPMT',
    logoURI:
      'https://bafkreic4mm6qtl5ke3qfpniwch77ozovecaz656ytrdouls4bcizusuqoe.ipfs.nftstorage.link',
    tags: ['community'],
  },
  Mbapepe: {
    address: '5cJUt4HzoVpRfwAqeNEDfbFJLVNxSpV9rzgPyyY3XLJx',
    chainId: 101,
    decimals: 9,
    name: 'Mbapepe',
    symbol: 'Mbapepe',
    logoURI: 'https://i.ibb.co/hsk8QCp/IMG-20240408-191027-942-3-1.png',
    tags: ['community'],
  },
  GMFC: {
    address: '42Y3CgJQLnHjdScYMu8VS4TbeZMUNVdBMKYbf7hz7aum',
    chainId: 101,
    decimals: 6,
    name: 'Gamify Club',
    symbol: 'GMFC',
    logoURI:
      'https://pbs.twimg.com/profile_images/1444964749897175046/wdz26CzM_400x400.jpg',
    tags: ['old-registry', 'solana-fm'],
  },
  Jorlps: {
    address: 'EA4SyW5UDnntXwUyyogN9UgpLU4q71HWgjhJBFUHTqLY',
    chainId: 101,
    decimals: 9,
    name: 'Jorlps',
    symbol: 'Jorlps',
    logoURI: 'https://i.imgur.com/6T2GZ98.jpeg',
    tags: ['community'],
  },
  GOO: {
    address: 'GRFKaABC518SqXMvBpAVYUZtVT3Nj4mYk7E7xU4gA5Rg',
    chainId: 101,
    decimals: 9,
    name: 'Silly Goose',
    symbol: 'GOO',
    logoURI:
      'https://bafkreiaorhiyiwevs2h6qnm4vdku64r6yjc7avbzwmdszgxcikcynjaknu.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'silly-goose' },
  },
  CHEEKS: {
    address: '6e6rViDzavLRv56nvZye5UofrKDg36mf6dTQrMCoPTW9',
    chainId: 101,
    decimals: 6,
    name: 'CHEEKS',
    symbol: 'CHEEKS',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmS8jETGNgZcH1BSYLY9Kjx2t89cxX3yhhjQqoBWcDg8Hc',
    tags: ['community'],
    extensions: { coingeckoId: 'cheeks' },
  },
  MOCHICAT: {
    address: 'EVDQN4P1YTTD1Y3eLBDBoALWnR4aMBC9y2xUYCrbGbuy',
    chainId: 101,
    decimals: 9,
    name: 'MOCHICAT',
    symbol: 'MOCHICAT',
    logoURI:
      'https://bafkreics2dftf4c72rk5kwc6eupuxvjv6b3tt2gpsg4ovmbyby7ctirsom.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'mochicat' },
  },
  KURO: {
    address: '2Kc38rfQ49DFaKHQaWbijkE7fcymUMLY5guUiUsDmFfn',
    chainId: 101,
    decimals: 6,
    name: 'Kurobi',
    symbol: 'KURO',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Kc38rfQ49DFaKHQaWbijkE7fcymUMLY5guUiUsDmFfn/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'kurobi' },
  },
  SHYCAT: {
    address: '6WErZ2aMZYLUyzbP9n5gm4fwHJbv9Ln8yimSQRwZpump',
    chainId: 101,
    decimals: 6,
    name: 'ShyCat',
    symbol: 'SHYCAT',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmTmqXggFuwZzES93EigEN189dfSX4yTHa5QKFxwjzN283',
    tags: ['community'],
  },
  $SOLO: {
    address: 'J8cKU4pD2NTSovvV5XghWHQiJy5TTEzgSyozorxz6ax8',
    chainId: 101,
    decimals: 9,
    name: 'Solordi',
    symbol: '$SOLO',
    logoURI:
      'https://bafkreigqmkl7y7vqt55imq7tejyft2ppnjzrrfqywjvjp3dgkdblkc6fjq.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'solordi' },
  },
  LARIX: {
    address: 'Lrxqnh6ZHKbGy3dcrCED43nsoLkM1LTzU2jRfWe8qUC',
    chainId: 101,
    decimals: 6,
    name: 'Larix',
    symbol: 'LARIX',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Lrxqnh6ZHKbGy3dcrCED43nsoLkM1LTzU2jRfWe8qUC/logo.jpg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'larix' },
  },
  Blob: {
    address: '85vb4pd3gyVMxQ211ZBXXWQJwJ4xZPBC5AQXvnmb1HTi',
    chainId: 101,
    decimals: 8,
    name: 'DumbGayRetard',
    symbol: 'Blob',
    logoURI:
      'https://bafkreihnoonnyxbvgl3hmwncc5jf7ibduzcbo6wgts572peipa5vlhigmi.ipfs.nftstorage.link',
    tags: ['community'],
  },
  GOLDY: {
    address: 'GoLDYyyiVeXnVf9qgoK712N5esm1cCbHEK9aNJFx47Sx',
    chainId: 101,
    decimals: 9,
    name: 'DeFi Land Gold',
    symbol: 'GOLDY',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GoLDYyyiVeXnVf9qgoK712N5esm1cCbHEK9aNJFx47Sx/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'defi-land-gold' },
  },
  DUBAI: {
    address: '93NhryHqdN5eVz2n4Qoof7ELwnTcgHBw44tmGcLzxKXz',
    chainId: 101,
    decimals: 9,
    name: 'Habibi Come To Dubai',
    symbol: 'DUBAI',
    logoURI:
      'https://cdn.discordapp.com/attachments/912336086913679371/1203082217631260753/606344e0a14fa1.65973326-original.png?ex=65cfcca0&is=65bd57a0&hm=ce80eadae9234b0f02b0edbcb2a1c5f132afa0c14281783ff39085d95807bc63&',
    tags: ['community'],
  },
  PHY: {
    address: 'EswgBj2hZKdgovX2ihWSUDnuBg9VNbGmSGoH5yjNsPRa',
    chainId: 101,
    decimals: 9,
    name: 'Physis',
    symbol: 'PHY',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EswgBj2hZKdgovX2ihWSUDnuBg9VNbGmSGoH5yjNsPRa/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'physis' },
  },
  OMNI: {
    address: '7mmXL6Et4SbpDs2iXoZQ3oPEeXeAiyETxh1QjDNi5qnV',
    chainId: 101,
    decimals: 8,
    name: 'OmniCat (Wormhole)',
    symbol: 'OMNI',
    logoURI:
      'https://raw.githubusercontent.com/mrmeowman/meow-assets/main/omni-logo.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'omnicat' },
  },
  rot: {
    address: 'APoM2sXUzdRHTkUjXSsdUheX1wPPdP4HFLotmtRNMU8P',
    chainId: 101,
    decimals: 6,
    name: 'brainrot',
    symbol: 'rot',
    logoURI:
      'https://nftstorage.link/ipfs/Qmea5bQBRfTU2ZAhQrYL59kpobLjCiYqk8xAXXDDYWWA7t',
    tags: ['community'],
    extensions: { coingeckoId: 'brainrot' },
  },
  POOT: {
    address: 'CErSpNnEHUNsNw3AZJhyvekwhMpr9H3W2S71uA3pzJus',
    chainId: 101,
    decimals: 9,
    name: 'Poot Coin',
    symbol: 'POOT',
    logoURI:
      'https://bafkreielqewi4dr5p572orjw5osgszuq4efrjybxjhi3se45xs3lqr6r3y.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  CANDLE: {
    address: '6iFUKWGDksVvmnSYJUGYnsu168xstni8xJkTF7QrpPAu',
    chainId: 101,
    decimals: 6,
    name: 'Candle Cat',
    symbol: 'CANDLE',
    logoURI:
      'https://nftstorage.link/ipfs/bafkreicihgr364leax2u77ltfcrsl7hnrkj2dew2innyekaepvigsta3rm',
    tags: ['community'],
    extensions: { coingeckoId: 'candle-cat' },
  },
  SONIC: {
    address: '7EW2NTuQFYKVxF3WTA1L1v62pxB7RFYmVC7veGxNDFis',
    chainId: 101,
    decimals: 6,
    name: 'Sonic Sniper',
    symbol: 'SONIC',
    logoURI:
      'https://gateway.irys.xyz/4YjV9mHRSR89u80wMQm2-7cjvMsEJx47UyZE_JdZy-k',
    tags: ['community'],
    extensions: { coingeckoId: 'sonic-sniper-bot' },
  },
  BabyBonk: {
    address: 'Dx1Lq5FjangW5ifRMEogAiakm24LyB5AoHmQifepvNjV',
    chainId: 101,
    decimals: 9,
    name: 'Baby Bonk',
    symbol: 'BabyBonk',
    logoURI: 'https://babybonk.pro/img/bonk-1.png',
    tags: ['community'],
    extensions: { coingeckoId: 'babybonk-2' },
  },
  HAWK: {
    address: 'BKipkearSqAUdNKa1WDstvcMjoPsSKBuNyvKDQDDu9WE',
    chainId: 101,
    decimals: 6,
    name: 'Hawksight',
    symbol: 'HAWK',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BKipkearSqAUdNKa1WDstvcMjoPsSKBuNyvKDQDDu9WE/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'hawksight' },
  },
  GBOY: {
    address: 'GKZbA2gDzw3MoxbfRsnrJTNi5uBDrnrz9bq1pNnx6kv',
    chainId: 101,
    decimals: 9,
    name: 'GameBoy',
    symbol: 'GBOY',
    logoURI:
      'https://images.squarespace-cdn.com/content/v1/65bd61a6fc592e7b51a773a7/72d9c35c-2b02-498b-b406-4cf5f6d06067/d2b08cecea46e8dac3603b9c57c4659c.png',
    tags: ['community'],
    extensions: { coingeckoId: 'gameboy' },
  },
  PERP: {
    address: 'EBQ6gWBQNxA2zB4twR5GWP6CkeAhqZZZeDgeP7BTtdM3',
    chainId: 101,
    decimals: 2,
    name: 'PerpeTraders',
    symbol: 'PERP',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EBQ6gWBQNxA2zB4twR5GWP6CkeAhqZZZeDgeP7BTtdM3/logo.jpg',
    tags: ['old-registry'],
  },
  $CWIF: {
    address: '7atgF8KQo4wJrD5ATGX7t1V2zVvykPJbFfNeVf1icFv1',
    chainId: 101,
    decimals: 2,
    name: 'catwifhat',
    symbol: '$CWIF',
    logoURI: 'https://i.postimg.cc/d1QD417z/200x200logo-copy.jpg',
    tags: ['community', 'token-2022'],
    extensions: { coingeckoId: 'catwifhat-2' },
  },
  CRODIE: {
    address: 'GvcNXdSehfNSNyhDVDj27kc459LzFqWozt9CSJywMy6r',
    chainId: 101,
    decimals: 9,
    name: 'Crodie',
    symbol: 'CRODIE',
    logoURI:
      'https://gateway.irys.xyz/s1WSRMI9z06trgN6PLpggkUvfP69vrLPJvE--6eBJJM',
    tags: ['community'],
    extensions: { coingeckoId: 'crodie' },
  },
  FEEL: {
    address: 'FEELeKVxHtsHHvu1ARySjETxnJMKbLC6wHCaMD68ipiR',
    chainId: 101,
    decimals: 9,
    name: 'FEEL',
    symbol: 'FEEL',
    logoURI: 'https://api.feel.market/static/feel.png',
    tags: ['community'],
  },
  soBTC: {
    address: '9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E',
    chainId: 101,
    decimals: 6,
    name: 'Wrapped Bitcoin (Sollet)',
    symbol: 'soBTC',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'wrapped-bitcoin-sollet' },
  },
  PURPE: {
    address: 'HBoNJ5v8g71s2boRivrHnfSB5MVPLDHHyVjruPfhGkvL',
    chainId: 101,
    decimals: 1,
    name: 'Purple Pepe',
    symbol: 'PURPE',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f617277656176652e6e65742f574575676b676c4243354c654138544175764f763878325f3777534744554e324938315f6e597032422d41',
    tags: ['community'],
    extensions: { coingeckoId: 'purple-pepe' },
  },
  PRIME: {
    address: 'PRiME7gDoiG1vGr95a3CRMv9xHY7UGjd4JKvfSkmQu2',
    chainId: 101,
    decimals: 9,
    name: 'SolanaPrime',
    symbol: 'PRIME',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PRiME7gDoiG1vGr95a3CRMv9xHY7UGjd4JKvfSkmQu2/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'solanaprime' },
  },
  BLOCK: {
    address: 'NFTUkR4u7wKxy9QLaX2TGvd9oZSWoMo4jqSJqdMb7Nk',
    chainId: 101,
    decimals: 6,
    name: 'Blockasset',
    symbol: 'BLOCK',
    logoURI:
      'https://arweave.net/gKXMDOCUjrOA_0H7EugvTbOOgfRRJ7N3hfZ1Y38PC5s?ext=png',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'blockasset' },
  },
  SLIM: {
    address: 'xxxxa1sKNGwFtw2kFn8XauW9xq8hBZ5kVtcSesTT9fW',
    chainId: 101,
    decimals: 6,
    name: 'Solanium',
    symbol: 'SLIM',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xxxxa1sKNGwFtw2kFn8XauW9xq8hBZ5kVtcSesTT9fW/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'solanium' },
  },
  TAP: {
    address: 'CejQBkdRPN8Jyod2jVUYTfHwU9hPHvM3rD9prvQiffDU',
    chainId: 101,
    decimals: 9,
    name: 'TAP Coin',
    symbol: 'TAP',
    logoURI: 'https://arweave.net/gHPUUFpbtWac5AnYtV10nGXa3VBgu0PgBiA7gDHJkJE',
    tags: ['community'],
  },
  SENK: {
    address: 'FeBg9Utf5wFa2PsT6KnJ6uitvWtfc87R38wmRnxrNMiW',
    chainId: 101,
    decimals: 9,
    name: 'SENK',
    symbol: 'SENK',
    logoURI:
      'https://solscan.io/_next/image?url=https%3A%2F%2Fimg.fotofolio.xyz%2F%3Furl%3Dhttps%253A%252F%252Fmedia.discordapp.net%252Fattachments%252F1226213954997714995%252F1226215942766202891%252F4jx0JOan_400x400.png%253Fex%253D6623f596%2526is%253D66118096%2526hm%253D92bd402fc4fd9c2d35f143e95a15a3a256e2944660bfbbb1ce7cb84640941c31%2526%253D%2526format%253Dwebp%2526quality%253Dlossless%2526width%253D352%2526height%253D352&w=64&q=75',
    tags: ['community'],
    extensions: { coingeckoId: 'senk' },
  },
  ROLL: {
    address: '76aYNHbDfHemxSS7vmh6eJGfjodK8m7srCxiYCrKxzY1',
    chainId: 101,
    decimals: 6,
    name: 'HRHC Token',
    symbol: 'ROLL',
    logoURI: 'https://hrhc.io/images/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  $BEN: {
    address: '2Ry7AGS1w488LdSxhz2XvSrU1EVUzUGJo27DBP57couh',
    chainId: 101,
    decimals: 7,
    name: 'BEN',
    symbol: '$BEN',
    logoURI: 'https://arweave.net/vvRsvtg1dtVxoUL6XNeRp-cOIYp8BRbaDigmuKvLazk',
    tags: ['community'],
  },
  BTL: {
    address: 'aYZPYgohjK6LYM8o1v6pnr3ZinhuRzSHd6TRDVDUBkK',
    chainId: 101,
    decimals: 8,
    name: 'BitLegacy Token',
    symbol: 'BTL',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/aYZPYgohjK6LYM8o1v6pnr3ZinhuRzSHd6TRDVDUBkK/logo.png',
    tags: ['old-registry'],
  },
  SolBlock: {
    address: '3gxEC9N9SGgoaRiey3AkbynbHcHsdmgrKDQf31zsiJvm',
    chainId: 101,
    decimals: 9,
    name: 'SolBlock AI',
    symbol: 'SolBlock',
    logoURI:
      'https://ipfs.coinsult.app/ipfs/QmVvBjyrKN3FXu6ARs4RgW2TJVi5Ms3ecQ8cTkJUPMiTzC',
    tags: ['community'],
    extensions: { coingeckoId: 'solblock-ai' },
  },
  ALEPH: {
    address: '3UCMiSnkcnkPE1pgQ5ggPCBv6dXgVUy16TmMUe1WpG9x',
    chainId: 101,
    decimals: 8,
    name: 'Aleph.im (Portal)',
    symbol: 'ALEPH',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/ALEPH_wh.png',
    tags: ['wormhole', 'old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'aleph-im-wormhole' },
  },
  JIMMY: {
    address: '94XduSfSnyas7jAEFSJSXiCi1xQ4mENWcii1aCvjVuqu',
    chainId: 101,
    decimals: 9,
    name: 'JIMMY',
    symbol: 'JIMMY',
    logoURI: 'https://arweave.net/5NmSALkMrjr5p33OrjuQlA5a3LRPLv0Ta07SnEWt1fk',
    tags: ['community'],
    extensions: { coingeckoId: 'jimmy-on-solana' },
  },
  NUTS: {
    address: 'DEEZgP19ZPovNeWRJZw7KuNbkLH6xjNjZ4HsUJnmZv7J',
    chainId: 101,
    decimals: 8,
    name: 'Deez Nuts',
    symbol: 'NUTS',
    logoURI:
      'https://bafkreiecrtk3savhnotkruboj6ng245jrptp4mxfcolb5fquw2ulzk323u.ipfs.cf-ipfs.com',
    tags: ['community'],
    extensions: { coingeckoId: 'deez-nuts-sol' },
  },
  $STICKY: {
    address: '8tgtGXhYRGKtaF4CPeVzSQpM3cUXBZ8P4ZW4hEhyLWZZ',
    chainId: 101,
    decimals: 6,
    name: 'stickmanpride',
    symbol: '$STICKY',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d5643735574704e3171616f784a77656269466a6a364534535555446362427962756174327431516f50375342',
    tags: ['community'],
    extensions: { coingeckoId: 'stickman' },
  },
  SOLA: {
    address: 'FYfQ9uaRaYvRiaEGUmct45F9WKam3BYXArTrotnTNFXF',
    chainId: 101,
    decimals: 9,
    name: 'Sola Token',
    symbol: 'SOLA',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYfQ9uaRaYvRiaEGUmct45F9WKam3BYXArTrotnTNFXF/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'sola-token' },
  },
  LST: {
    address: 'LSTxxxnJzKDFSLr4dUkPcmCf5VyryEqzPLz5j4bpxFp',
    chainId: 101,
    decimals: 9,
    name: 'Liquid Staking Token',
    symbol: 'LST',
    logoURI: 'https://storage.googleapis.com/static-marginfi/lst.png',
    tags: ['community'],
    extensions: { coingeckoId: 'liquid-staking-token' },
  },
  BUBBA: {
    address: 'BMt3pq4g8ggWWBnd6DJ1jhVyTkHfWjAfJwWW6sRCbQJv',
    chainId: 101,
    decimals: 9,
    name: 'BUBBA',
    symbol: 'BUBBA',
    logoURI:
      'https://node1.irys.xyz/V2FzTx2Kk0j9PkX0PRfWn9zjDStHWWd0KE_6zgqGMMI',
    tags: ['community'],
    extensions: { coingeckoId: 'bubba' },
  },
  clARB: {
    address: '9cMWa1wuWcio3vgEpiFg7PqKbcoafuUw5sLYFkXJ2J8M',
    chainId: 101,
    decimals: 8,
    name: 'Cloned Arbitrum',
    symbol: 'clARB',
    logoURI: 'https://markets.clone.so/images/assets/on-arb.svg',
    tags: ['community'],
    extensions: { coingeckoId: 'clone-protocol-clarb' },
  },
  $MOONDELA: {
    address: 'BSXvX9dMvedcXUvwUQ5pzYJb9G8bBXKtddQns4oAxgEo',
    chainId: 101,
    decimals: 6,
    name: 'Nelson Moondela',
    symbol: '$MOONDELA',
    logoURI:
      'https://bafybeiebyum5ql7lhtgocj37hkesya762gt7vcno7shnykv2kvinbv6bu4.ipfs.cf-ipfs.com',
    tags: ['community'],
  },
  ZEBU: {
    address: '7unYePWUHcpB28cnS65TpqT2qqmZaftRz9QABkdR8yN7',
    chainId: 101,
    decimals: 6,
    name: 'ZEBU',
    symbol: 'ZEBU',
    logoURI:
      'https://bafybeifi5wr2k5giyoikp7hnyx5zsdlt37ez3qzav6y5nssrjzaxuxnedi.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'zebu' },
  },
  BUSDbs: {
    address: '5RpUwQ8wtdPCZHhu6MERp2RGrpobsbZ6MH5dDHkUjs2',
    chainId: 101,
    decimals: 8,
    name: 'Binance USD (Portal from BSC)',
    symbol: 'BUSDbs',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/BUSDbs_wh.png',
    tags: ['wormhole', 'old-registry'],
  },
  NEKI: {
    address: 'ALKiRVrfLgzeAV2mCT7cJHKg3ZoPvsCRSV7VCRWnE8zQ',
    chainId: 101,
    decimals: 9,
    name: 'Maneki-neko',
    symbol: 'NEKI',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALKiRVrfLgzeAV2mCT7cJHKg3ZoPvsCRSV7VCRWnE8zQ/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'maneki-neko' },
  },
  RUSTY: {
    address: 'Ayy1QvG5vR6nJ9fdijWWTrvNmjVfEhGGoQrX9nhZ6Dg3',
    chainId: 101,
    decimals: 9,
    name: 'First Youtube dog  - Community',
    symbol: 'RUSTY',
    logoURI:
      'https://bafkreicizeunawx46hqnp4sprlukw5uk7bededjuiit3o6ojk3lkfnh5ty.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  CROCHET: {
    address: 'Aqjju8gCv3Uc2XsmF5x92ZarDo3hCnP7EgUeDkv1i7jK',
    chainId: 101,
    decimals: 9,
    name: 'Crochet World',
    symbol: 'CROCHET',
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://bafkreiegq57dmupyttnl6m3atkxd6zrpdy2gchal27gkkqttyqi5i3upmy.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'crochet-world' },
  },
  Cope: {
    address: 'o1Mw5Y3n68o8TakZFuGKLZMGjm72qv4JeoZvGiCLEvK',
    chainId: 101,
    decimals: 2,
    name: 'Cope',
    symbol: 'Cope',
    logoURI:
      'https://shdw-drive.genesysgo.net/HNTiV9QS4UDrcxpucyKRGTKQTqKGLif61GwM6tpd5R5V/image.png',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'cope-token' },
  },
  wifSOL: {
    address: 'Fi5GayacZzUrfaCRCJtBz2vSYkGF56xjgCceZx5SbXwq',
    chainId: 101,
    decimals: 9,
    name: 'wifSOL',
    symbol: 'wifSOL',
    logoURI: 'https://arweave.net/C9ISQflnq1MPpAFbN9D3MG4yhCPST1aGz97gv9zCC70',
    tags: ['community'],
  },
  UXP: {
    address: 'UXPhBoR3qG4UCiGNJfV7MqhHyFqKN68g45GoYvAeL2M',
    chainId: 101,
    decimals: 9,
    name: 'UXP Governance Token',
    symbol: 'UXP',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UXPhBoR3qG4UCiGNJfV7MqhHyFqKN68g45GoYvAeL2M/uxp-icon-black.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'uxd-protocol-token' },
  },
  ANSEM: {
    address: '3Lec18q7nPM62LQwqXG2ddiBTDrFCiNw1NEA1ehBZPgB',
    chainId: 101,
    decimals: 1,
    name: 'SoylanaManletCaptainZ',
    symbol: 'ANSEM',
    logoURI: 'https://arweave.net/tJXiOAU5ZXS7PZbWGKrg5nNRCh5OUlr07iIY4FRWK0o',
    tags: ['community'],
  },
  MAGAIBA: {
    address: 'A6rSPi9JmJgVkW6BatsA6MjFYLseizPM2Fnt92coFjf4',
    chainId: 101,
    decimals: 8,
    name: 'MAGAIBA',
    symbol: 'MAGAIBA',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/29941.png',
    tags: ['community'],
    extensions: { coingeckoId: 'magaiba' },
  },
  doky: {
    address: '5Rs53fY3q4t4mLk9zBQ45cVNgF1RH7NqRNXiP6Pa5rYH',
    chainId: 101,
    decimals: 6,
    name: 'Donkey King',
    symbol: 'doky',
    logoURI:
      'https://bafkreid55t54yhvsbufdawhomaom323xiwqngfefe3lxumqi27xemme3bi.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'donkey-king' },
  },
  MBS: {
    address: 'Fm9rHUTF5v3hwMLbStjZXqNBBoZyGriQaFM6sTFz3K8A',
    chainId: 101,
    decimals: 6,
    name: 'MonkeyBucks',
    symbol: 'MBS',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fm9rHUTF5v3hwMLbStjZXqNBBoZyGriQaFM6sTFz3K8A/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'monkeyball' },
  },
  FLUXB: {
    address: 'FLUXBmPhT3Fd1EDVFdg46YREqHBeNypn1h4EbnTzWERX',
    chainId: 101,
    decimals: 5,
    name: 'FluxBot',
    symbol: 'FLUXB',
    logoURI:
      'https://bafybeiakyhwwesdvxpikjn4kyqddira3hjpjfcn26ycrrezt65bfa7r3gm.ipfs.nftstorage.link',
    tags: ['community', 'token-2022'],
    extensions: { coingeckoId: 'fluxbot' },
  },
  ETX: {
    address: '9DZ58i5vAfk3JaFVYezYzhrVht7j8McZsUbuTcDiSbrP',
    chainId: 101,
    decimals: 5,
    name: 'Ethrix',
    symbol: 'ETX',
    logoURI: 'https://arweave.net/iOND5JLSsq_0dka0SnzTsDCl83hu0_-sOQ2fDbQDuTQ',
    tags: ['community'],
    extensions: { coingeckoId: 'ethrix' },
  },
  VICE: {
    address: 'AWeL8nCXxJm3GZkEVRqxeeT5KWT2KwDa1shgQFeBogkC',
    chainId: 101,
    decimals: 9,
    name: 'VICE',
    symbol: 'VICE',
    logoURI:
      'https://bafybeieprtdo3xgvk2ezdlqd47lhcnwdqneoig7jiiiuodoy36jmu6jhte.ipfs.dweb.link',
    tags: ['community', 'token-2022'],
  },
  fUSDC: {
    address: 'Ez2zVjw85tZan1ycnJ5PywNNxR6Gm4jbXQtZKyQNu3Lv',
    chainId: 101,
    decimals: 6,
    name: 'Fluid USDC',
    symbol: 'fUSDC',
    logoURI: 'https://static.fluidity.money/images/tokens/fUSDC.png',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'fluid-usdc' },
  },
  MRC: {
    address: '5HsZR8eG7QpQcN8Mnp8oFdENRkJMP9ZkcKhPSCKTJSWh',
    chainId: 101,
    decimals: 9,
    name: 'Moon Roll Coin',
    symbol: 'MRC',
    logoURI: 'https://moonroll-bucket.s3.amazonaws.com/token-image/Frame.png',
    tags: ['community'],
    extensions: { coingeckoId: 'moon-roll-coin' },
  },
  pBTC: {
    address: 'DYDWu4hE4MN3aH897xQ3sRTs5EAjJDmQsKLNhbpUiKun',
    chainId: 101,
    decimals: 8,
    name: 'pBTC (Parrot BTC)',
    symbol: 'pBTC',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DYDWu4hE4MN3aH897xQ3sRTs5EAjJDmQsKLNhbpUiKun/logo.svg',
    tags: ['old-registry'],
  },
  FELON: {
    address: 'f1niVdDCEAxcKg5a1pdsvwN18AQHBJQQcgATWob3M8r',
    chainId: 101,
    decimals: 9,
    name: 'Convicted Felon',
    symbol: 'FELON',
    logoURI:
      'https://github.com/convicted-felon-resources/solana-token/blob/46116dbc809a8010ce6421b92247ccfc41897712/logo.png?raw=true',
    tags: ['community'],
  },
  SOUL: {
    address: 'F6weWmuc1vwdL4u38Ro9jKXHEMjP9BoNdWMF5o5TvtJf',
    chainId: 101,
    decimals: 9,
    name: 'SOUL',
    symbol: 'SOUL',
    logoURI: 'https://arweave.net/ipj9IdEAQAXt0LS6lOt7jQY7W4iazsVFBkN60U7M4iQ',
    tags: ['community'],
  },
  TULIP: {
    address: 'TuLipcqtGVXP9XR62wM8WWCm6a9vhLs7T1uoWBk6FDs',
    chainId: 101,
    decimals: 6,
    name: 'Tulip',
    symbol: 'TULIP',
    logoURI:
      'https://raw.githubusercontent.com/sol-farm/token-logos/main/tulip.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'solfarm' },
  },
  $CRYPT: {
    address: 'CRYPTi2V87Tu6aLc9gSwXM1wSLc6rjZh3TGC4GDRCecq',
    chainId: 101,
    decimals: 9,
    name: 'Crypt',
    symbol: '$CRYPT',
    logoURI:
      'https://aql3kfuqpqpkdchvrzuwyx6pdnjw6p6pualx7kie5erqalavewgq.arweave.net/BBe1FpB8HqGI9Y5pbF_PG1NvP8-gF3-pBOkjACwVJY0?ext=png',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'secret-skellies-society' },
  },
  NCAT: {
    address: '82Rc22mnyHrmBGwj15rhYhFzVrU3bgFkjNtV3iHjpump',
    chainId: 101,
    decimals: 6,
    name: 'Neuracat',
    symbol: 'NCAT',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d653532705a4b6742467a6b736465705643444c6668784742336963717653357838356f78504a615832527745',
    tags: ['community'],
    extensions: { coingeckoId: 'neuracat-2' },
  },
  CHEEMS: {
    address: '3FoUAsGDbvTD6YZ4wVKJgTB76onJUKz7GPEBNiR5b8wc',
    chainId: 101,
    decimals: 4,
    name: 'Cheems',
    symbol: 'CHEEMS',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3FoUAsGDbvTD6YZ4wVKJgTB76onJUKz7GPEBNiR5b8wc/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'cheems' },
  },
  ANTC: {
    address: '4WRST6Q2yyDP1p7Bqszg8PAAoj8j69cj7QY4QLn5Aq3o',
    chainId: 101,
    decimals: 6,
    name: 'ANT Coin',
    symbol: 'ANTC',
    logoURI: 'https://arweave.net/1rW4kHXhx8eW-YljQXGi1EhGdE6jCKZztlIWBrs-Fh4',
    tags: ['community'],
    extensions: { coingeckoId: 'kingdom-of-ants-ant-coins' },
  },
  ELON: {
    address: '7ZCm8WBN9aLa3o47SoYctU6iLdj7wkGG5SV2hE5CgtD5',
    chainId: 101,
    decimals: 4,
    name: 'Dogelon (Portal)',
    symbol: 'ELON',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/ELON_wh.png',
    tags: ['wormhole', 'community'],
    extensions: { coingeckoId: 'dogelon-mars' },
  },
  PYTH: {
    address: 'HZ1JovNiVvGrGNiiYvEozEVgZ58xaU3RKwX8eACQBCt3',
    chainId: 101,
    decimals: 6,
    name: 'Pyth Network',
    symbol: 'PYTH',
    logoURI: 'https://pyth.network/token.svg',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'pyth-network' },
  },
  $GGEM: {
    address: 'GGEMxCsqM74URiXdY46VcaSW73a4yfHfJKrJrUmDVpEF',
    chainId: 101,
    decimals: 9,
    name: 'Guild Saga Gems',
    symbol: '$GGEM',
    logoURI:
      'https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://gs-token.s3.us-west-2.amazonaws.com/guild-saga-gems-logo.png',
    tags: ['community', 'solana-fm'],
  },
  PC: {
    address: 'F5WPg7xdZczNg5pynWjPK8TZLT52WmAiT1ZuKRbaLnEM',
    chainId: 101,
    decimals: 6,
    name: 'Pig Cat',
    symbol: 'PC',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmSeSJ6huZQLHrNP2TFjU48WtjfP87vFg5FVabP4q9Yf4h',
    tags: ['community'],
    extensions: { coingeckoId: 'pigcatsol' },
  },
  DBC: {
    address: '98ouGzbMV2DnHfn3Uc7oahwvDP4Cf7Ls77JsBiARdjvn',
    chainId: 101,
    decimals: 6,
    name: 'DBank Coin',
    symbol: 'DBC',
    logoURI: 'https://arweave.net/g4a4JTGvXRxWYFIkuJtyEIDdIp2b487R_2YdTSUreXM',
    tags: ['old-registry', 'solana-fm'],
  },
  DOCS: {
    address: 'gr1qPTo3tpMAxt59BftQo2uSfRHRuUZJaWLhR8ADtwz',
    chainId: 101,
    decimals: 9,
    name: 'SolDocs',
    symbol: 'DOCS',
    logoURI:
      'https://bafybeifomvesje3xslajba4hlxulr7koacjsv42vwmtqaawdx4nzypw2ju.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'soldocs' },
  },
  FTM: {
    address: 'EsPKhGTMf3bGoy4Qm7pCv3UCcWqAmbC1UGHBTDxRjjD4',
    chainId: 101,
    decimals: 9,
    name: 'FTM (Allbridge from Fantom)',
    symbol: 'FTM',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EsPKhGTMf3bGoy4Qm7pCv3UCcWqAmbC1UGHBTDxRjjD4/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  $DRUNK: {
    address: 'EaRMzBwWRwvSgus8rfdZGfdzX3ZKRKoCL7U36G7xfvcb',
    chainId: 101,
    decimals: 9,
    name: 'DRUNK',
    symbol: '$DRUNK',
    logoURI:
      'https://node2.irys.xyz/e-xB6tVMZT17UYNGBwT-QfE2omthP5WSzEcGdJB9j-c',
    tags: ['community'],
    extensions: { coingeckoId: 'drunk' },
  },
  MOGDOG: {
    address: 'Bzu1nWVKRFEn7FRumTNrTC4qqxtBaMCMBNY1z7ejpump',
    chainId: 101,
    decimals: 6,
    name: 'MOGDOG',
    symbol: 'MOGDOG',
    logoURI:
      'https://ipfs.io/ipfs/QmXrU3CPw7yhzE5uztXgQ5D89MzhVDCxFYf6NTCEEEcrbc',
    tags: ['community'],
    extensions: { coingeckoId: 'mogdog' },
  },
  SOULO: {
    address: 'Gz3u6eJaKEviYpPC5AwUziz891kNX76PNdsmJrnaNNY4',
    chainId: 101,
    decimals: 9,
    name: 'SouloCoin',
    symbol: 'SOULO',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gz3u6eJaKEviYpPC5AwUziz891kNX76PNdsmJrnaNNY4/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'soulocoin' },
  },
  EYE: {
    address: '74Eyos32V2B6ineYgAcRMZsiDpz65z7sXHq7D5MSMYgF',
    chainId: 101,
    decimals: 9,
    name: 'SolEye - OFFCHAIN TRACKER',
    symbol: 'EYE',
    logoURI:
      'https://gateway.irys.xyz/UVHLqXdvPG0u4uiYcynEIzPznelypD_3lSY_-6C5eT4',
    tags: ['community'],
    extensions: { coingeckoId: 'soleye-offchain-tracker' },
  },
  APT: {
    address: 'APTtJyaRX5yGTsJU522N4VYWg3vCvSb65eam5GrPT5Rt',
    chainId: 101,
    decimals: 6,
    name: 'Apricot',
    symbol: 'APT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/APTtJyaRX5yGTsJU522N4VYWg3vCvSb65eam5GrPT5Rt/logo.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'apricot' },
  },
  ENG: {
    address: 'GtLBsmS6Z4FC9gmnCFS2ZzCKmb9vYL3kELnjVmxHurnh',
    chainId: 101,
    decimals: 2,
    name: 'ENG Token',
    symbol: 'ENG',
    logoURI:
      'https://www.arweave.net/N_pDeYTYERWexWo2E3-mzZhqMtqdcqA_ZktPva4ImnY?ext=png',
    tags: ['community'],
  },
  FINDER: {
    address: 'Dn7mshRUg4LEq1RfMLz27ViL2P16hn8p12bGBVZsiyak',
    chainId: 101,
    decimals: 9,
    name: 'Gem Finder',
    symbol: 'FINDER',
    logoURI:
      'https://bafkreie35aa2seimlokllhc4y6ctjlimxa5j3qzw2z73nwzmudxeitflum.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'gem-finder' },
  },
  PUN: {
    address: 'FaSJ3PKDjaHatJMgpC92cmhjcJxnc8sbTkpaPuTF3hW1',
    chainId: 101,
    decimals: 6,
    name: 'Punkko',
    symbol: 'PUN',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmTE6AzJQKRLCE6iTPsYtkw17CV6TypVKQDVRVsdrb1riv',
    tags: ['community'],
    extensions: { coingeckoId: 'punkko' },
  },
  HBB: {
    address: 'HBB111SCo9jkCejsZfz8Ec8nH7T6THF8KEKSnvwT6XK6',
    chainId: 101,
    decimals: 6,
    name: 'Hubble Protocol Token',
    symbol: 'HBB',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HBB111SCo9jkCejsZfz8Ec8nH7T6THF8KEKSnvwT6XK6/logo.svg',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'hubble' },
  },
  APU: {
    address: 'ECutGg12PNhqhkvnH1s1FcuXgCDzKDNhSf5aLtANioR7',
    chainId: 101,
    decimals: 6,
    name: 'Apu Apustaja',
    symbol: 'APU',
    logoURI:
      'https://soldeployer.mypinata.cloud/ipfs/QmVb8aYRjzvr7hRkKLD3L3mTfDo41dECpPMpa1pzcqhD2L',
    tags: ['community'],
  },
  milly: {
    address: '6yNcxrS1Fd6Ut3c3bTuJKQLG9xzpbMZCBqTahvpeAXjk',
    chainId: 101,
    decimals: 9,
    name: 'milly',
    symbol: 'milly',
    logoURI:
      'https://node1.irys.xyz/C8UX1RVmR042foWNTo3EjH1h007zSSFF0ODiURG7wJI',
    tags: ['community'],
  },
  NEKO: {
    address: '6wdbFQAxDVwUdJrZEnnzgPzsZ1NruvLhf9qCvmSD5DLX',
    chainId: 101,
    decimals: 6,
    name: 'NEKO',
    symbol: 'NEKO',
    logoURI:
      'https://ipfs.io/ipfs/QmWPQHhRAgsztGEME1TCauuGeyeo7ZhAHY1ShJTx5no9NF',
    tags: ['community'],
  },
  ROCKY: {
    address: 'DhTNFBp4NjaxWqf6LwG57GrJQZfXrso1qK9FcMZt9sv7',
    chainId: 101,
    decimals: 9,
    name: 'Rocky',
    symbol: 'ROCKY',
    logoURI: 'https://imageupload.io/ib/B1wtisSaYlCUmwy_1700434086.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'rocky' },
  },
  THOG: {
    address: '5CqfXex1knfRiozwDtgFFNaiGR9TsmSUcWDNUTUGZQru',
    chainId: 101,
    decimals: 6,
    name: 'Theory Of Gravity',
    symbol: 'THOG',
    logoURI:
      'https://www.arweave.net/EYICWnbpkym9JUouBKmvUxKP4WR-KvQtwYG42kUtZ8s/collection.gif',
    tags: ['community'],
    extensions: { coingeckoId: 'the-theory-of-gravity' },
  },
  FCON: {
    address: 'HovGjrBGTfna4dvg6exkMxXuexB3tUfEZKcut8AWowXj',
    chainId: 101,
    decimals: 4,
    name: 'Space Falcon',
    symbol: 'FCON',
    logoURI:
      'https://raw.githubusercontent.com/spacefalcon-io/crypto/main/logo.svg',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'spacefalcon' },
  },
  Pepe: {
    address: 'B5WTLaRwaUQpKk7ir1wniNB6m5o8GgMrimhKMYan2R6B',
    chainId: 101,
    decimals: 6,
    name: 'Pepe',
    symbol: 'Pepe',
    logoURI:
      'https://ipfs.io/ipfs/QmZMTnCNYincJTTtNvxptHGEnB36F336C544Re5Zjo2QLj',
    tags: ['community'],
    extensions: { coingeckoId: 'pepe-sol' },
  },
  ABULL: {
    address: 'J3hxVJtn3kGtr1tHkJSWXvMr2D4M8iBzxzB75zywxVzF',
    chainId: 101,
    decimals: 9,
    name: 'AumentoBULL',
    symbol: 'ABULL',
    logoURI:
      'https://api.degencdn.com/v1/nfts/J3hxVJtn3kGtr1tHkJSWXvMr2D4M8iBzxzB75zywxVzF/image.jpg',
    tags: ['community', 'token-2022'],
  },
  IRS: {
    address: 'A3tCD8Q1yjdy3gRDJ4LFzpECnafW3sHNa3dJKHesG9Xf',
    chainId: 101,
    decimals: 9,
    name: 'Internal Retard Service',
    symbol: 'IRS',
    logoURI:
      'https://ipfs.io/ipfs/bafkreie7ntaifs5iq2txyjmll4v4i7myivt6gpnu6fht5pd3ggc7sfv35m',
    tags: ['community'],
  },
  $RKT: {
    address: 'RKT69NZHN5uovcan3q5hRbZzfJuXiGEuPmGANoBJLLz',
    chainId: 101,
    decimals: 5,
    name: 'Racket',
    symbol: '$RKT',
    logoURI: 'https://arweave.net/Vr36ys1neJyzyfhkxv1Cxt3QJF7LhuDd_iGmvSYjLkg',
    tags: ['community'],
    extensions: { coingeckoId: 'racket' },
  },
  SCP: {
    address: '5sMyPtYRcrEVt27DW3xhGVVha3zCXLv4caVt88PXjBgV',
    chainId: 101,
    decimals: 9,
    name: 'SCP',
    symbol: 'SCP',
    logoURI: 'https://scpri.me/files/scp/logo.png',
    tags: ['community'],
    extensions: { coingeckoId: 'siaprime-coin' },
  },
  Pika: {
    address: '42o42KH1dzEDjqijWpWHcNtpmW42Hxzg7YbMs1h6A5r1',
    chainId: 101,
    decimals: 6,
    name: 'Pika',
    symbol: 'Pika',
    logoURI:
      'https://pyrgidxylosngvfqmn3ombwppzcxy5umsubmnfwcf3om76cfmoda.arweave.net/fiJkDvhbpNNUsGN25gbPfkV8doyVAsaWwi7cz_hFY4Y',
    tags: ['community'],
  },
  FM: {
    address: 'Eh1fXbAipe4k7CYR9UMb2bbWmBcpU3HcyX3LWuRVFBLz',
    chainId: 101,
    decimals: 4,
    name: 'Flowmatic',
    symbol: 'FM',
    logoURI:
      'https://nftstorage.link/ipfs/bafybeighw6z7wdbk73tezruawliwt622xic6r43wmzgpk22rsnmqvjvkrm/Brando_logo_of_F_on_a_black_background_in_the_style_of_light_te_42b8cbea-cc40-4c9f-a098-62c381d624df.png',
    tags: ['community'],
    extensions: { coingeckoId: 'flowmatic' },
  },
  fUSD: {
    address: 'B7mXkkZgn7abwz1A3HnKkb18Y6y18WcbeSkh1DuLMkee',
    chainId: 101,
    decimals: 8,
    name: 'Synthetic USD (Fabric)',
    symbol: 'fUSD',
    logoURI:
      'https://imagedelivery.net/9NaZ0y3QBjls8_Ib2N2gQw/ec3521b6-b382-4efb-1d16-7b60c75b9100/public',
    tags: ['old-registry'],
  },
  'D/ACC': {
    address: 'FabjHjc1druUQoHVtudpNiCpnf73rtLzMkRM1b5NSbb6',
    chainId: 101,
    decimals: 9,
    name: 'D/ACC',
    symbol: 'D/ACC',
    logoURI:
      'https://img.fotofolio.xyz/?url=https%3A%2F%2Fnftstorage.link%2Fipfs%2Fbafkreiaad6yxjxad6qpkb7taodfnbfv74hmgmnorqatab27r54jpgroqci%2F',
    tags: ['community'],
  },
  BANX: {
    address: 'BANXbTpN8U2cU41FjPxe2Ti37PiT5cCxLUKDQZuJeMMR',
    chainId: 101,
    decimals: 9,
    name: 'Banx',
    symbol: 'BANX',
    logoURI: 'https://arweave.net/5QRitQGPVjPwpjt43Qe_WISmys4dWwLMqQqQDT0oshg',
    tags: ['community'],
    extensions: { coingeckoId: 'banx' },
  },
  WBS: {
    address: 'GJgHsc1HU4ibmzW6oWQr8L2RRT95ATc1BoNuLkp94AwU',
    chainId: 101,
    decimals: 9,
    name: 'White Boy Summer',
    symbol: 'WBS',
    logoURI:
      'https://bafkreidj6mokshjwyykli4zxn74ah4o6udof5hyrqehopcn2z6vjdq6puu.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'white-boy-summer' },
  },
  MYKE: {
    address: 'DV2TCTbENV9xJ14kHC89qd1w2KZB6efvZKHNRpoo5cuD',
    chainId: 101,
    decimals: 6,
    name: 'Myke Thysen',
    symbol: 'MYKE',
    logoURI:
      'https://bafkreih3gjkz3cyu42n47y5vs6kpmr4gahrhleuim2oa6rpoeho4yxpb7y.ipfs.nftstorage.link',
    tags: ['community'],
  },
  YOM: {
    address: 'yomFPUqz1wJwYSfD5tZJUtS3bNb8xs8mx9XzBv8RL39',
    chainId: 101,
    decimals: 9,
    name: 'YOM',
    symbol: 'YOM',
    logoURI:
      'https://yom.mypinata.cloud/ipfs/QmQ9qE5XWMEyzQGWJFcZjwjigHBzSWDaaf2xFhCFqdvUcx',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'your-open-metaverse' },
  },
  LIKE: {
    address: '3bRTivrVsitbmCTGtqwp7hxXPsybkjn4XLNtPsHqa3zR',
    chainId: 101,
    decimals: 9,
    name: 'Only1 (LIKE)',
    symbol: 'LIKE',
    logoURI:
      'https://zbdf6pvmksjpci7njnig2cal2d4smxivlmhuyzdik2otbf5focdq.arweave.net/yEZfPqxUkvEj7UtQbQgL0PkmXRVbD0xkaFadMJelcIc',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'only1' },
  },
  SHILL: {
    address: '6cVgJUqo4nmvQpbgrDZwyfd6RwWw5bfnCamS3M9N1fd',
    chainId: 101,
    decimals: 6,
    name: 'Project SEED Token',
    symbol: 'SHILL',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6cVgJUqo4nmvQpbgrDZwyfd6RwWw5bfnCamS3M9N1fd/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'shill-token' },
  },
  BRZ: {
    address: 'FtgGSFADXBtroxq8VCausXRr2of47QBf5AS1NtZCu4GD',
    chainId: 101,
    decimals: 4,
    name: 'BRZ',
    symbol: 'BRZ',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FtgGSFADXBtroxq8VCausXRr2of47QBf5AS1NtZCu4GD/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'brz' },
  },
  CRYY: {
    address: '56tNQ29XBrbovm5K5SThuQatjCy92w2wKUaUeQ8WCD9g',
    chainId: 101,
    decimals: 9,
    name: 'CRY Coin',
    symbol: 'CRYY',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/56tNQ29XBrbovm5K5SThuQatjCy92w2wKUaUeQ8WCD9g/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  $NEON: {
    address: 'DuSyBCGuhPvyGu6cSvbZonvQvh8JLyGvXJn1TmkJh6Zn',
    chainId: 101,
    decimals: 0,
    name: 'NeonGame Credits',
    symbol: '$NEON',
    logoURI: 'https://arweave.net/VPS4BrOgXT4rg-mwGfCLqXL8vtbII0ianMBsw_L2CeE',
    tags: ['old-registry', 'solana-fm'],
  },
  chibi: {
    address: 'HfXLMuSCHvsuYHRLekyW88oiz1LJvRerKh4QH1y5f8xk',
    chainId: 101,
    decimals: 6,
    name: 'chibi',
    symbol: 'chibi',
    logoURI:
      'https://ipfs.com/ipfs/QmSqWhojj8iPSAQSfK5Bb6cj9N84YgznVBoiJ3wr13opHR',
    tags: ['community'],
    extensions: { coingeckoId: 'chibi' },
  },
  Moutai: {
    address: '45EgCwcPXYagBC7KqBin4nCFgEZWN7f3Y6nACwxqMCWX',
    chainId: 101,
    decimals: 6,
    name: 'Moutai',
    symbol: 'Moutai',
    logoURI:
      'https://bafybeihn5s2ykzqwpmb6d4dbowecovc7vo2wjx7zjltur7sl4ykfhbmoaq.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  APEX: {
    address: '51tMb3zBKDiQhNwGqpgwbavaGH54mk8fXFzxTc1xnasg',
    chainId: 101,
    decimals: 9,
    name: 'APEX',
    symbol: 'APEX',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/51tMb3zBKDiQhNwGqpgwbavaGH54mk8fXFzxTc1xnasg/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'apexit-finance' },
  },
  PHOX: {
    address: '9Pug19UEjW5wiFG5bLWYpjtz6s9K8ZGXmRvZwyfX7SNy',
    chainId: 101,
    decimals: 6,
    name: 'PHOX',
    symbol: 'PHOX',
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://gateway.irys.xyz/MgqcN7mpQS3MV4Tc2fomrLKkKu_tpXtgjQplUNo5xr4',
    tags: ['community'],
  },
  MC: {
    address: 'FYUkUybywqUUyrUwiAezbvhTp2DUgx1eg8tQNiKkXqJ9',
    chainId: 101,
    decimals: 9,
    name: 'Magic Crystal Coin',
    symbol: 'MC',
    logoURI: 'https://arweave.net/Nwami1xs2ciMI7mUcawKMkUTKf_UUrLjYvIqUSL1ShI',
    tags: ['community', 'solana-fm'],
  },
  SHARKI: {
    address: '6U48jtR53ZK3E1MozLrpwJDTrtj74uuFhMGNzGY18YPu',
    chainId: 101,
    decimals: 9,
    name: 'sharki',
    symbol: 'SHARKI',
    logoURI:
      'https://gateway.irys.xyz/V6O5BkXCXxmKNxs5NVjhvwH_NjvTAD8Jz0-2MKGM6jQ',
    tags: ['community'],
    extensions: { coingeckoId: 'sharki' },
  },
  CSM: {
    address: 'EzfnjRUKtc5vweE1GCLdHV4MkDQ3ebSpQXLobSKgQ9RB',
    chainId: 101,
    decimals: 6,
    name: 'Cricket Star Manager',
    symbol: 'CSM',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EzfnjRUKtc5vweE1GCLdHV4MkDQ3ebSpQXLobSKgQ9RB/logo.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'cricket-star-manager' },
  },
  $BOZO: {
    address: 'BoZoQQRAmYkr5iJhqo7DChAs7DPDwEZ5cv1vkYC9yzJG',
    chainId: 101,
    decimals: 5,
    name: 'BOZO',
    symbol: '$BOZO',
    logoURI: 'https://arweave.net/QN7RFzy_OqPO_cAbBtBUzcUwObkDuvkRLS16oxJI-s0',
    tags: ['community'],
    extensions: { coingeckoId: 'bozo-collective' },
  },
  WAVE: {
    address: 'GS6E87SLTioRDG3uSVRwQmuKKMxDmWU7fktCTJ5xkEM8',
    chainId: 101,
    decimals: 9,
    name: 'Lost At Sea WAVE',
    symbol: 'WAVE',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GS6E87SLTioRDG3uSVRwQmuKKMxDmWU7fktCTJ5xkEM8/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  LIGMA: {
    address: 'LigMAx75tZtD9NyxF3VwYQuEsf4uWPxoHgXkeiA2TPu',
    chainId: 101,
    decimals: 8,
    name: 'LIGMA',
    symbol: 'LIGMA',
    logoURI:
      'https://bafkreiekwx6w5j4sydsk5mjrjv63mabripomxxkg24tueblk4q7567yr4i.ipfs.cf-ipfs.com',
    tags: ['community'],
  },
  Green: {
    address: '4iEkSPpXxjsD6fRY9UmCGX73NJch5yakPzYt6McLpump',
    chainId: 101,
    decimals: 6,
    name: 'Phthalocyanine Green',
    symbol: 'Green',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmZHqirGHSkWbEMm5VGDuW5CTKQXnzPvkExgEMm8qcrxbJ',
    tags: ['community'],
  },
  PUGAI: {
    address: 'PugAiDuaQ5hzicBHAW9qrQQ8qi4B6sh3n7PknKhyjeX',
    chainId: 101,
    decimals: 5,
    name: 'PUGAI',
    symbol: 'PUGAI',
    logoURI:
      'https://shdw-drive.genesysgo.net/CS412vMTyfY1J4u1Ve7UjAdhGu7V9jCJibkVussMnaKr/pug1%20(2).png',
    tags: ['community'],
    extensions: { coingeckoId: 'pug-ai' },
  },
  TEIT: {
    address: 'GzWP5TSam9VBdPx2bA4US2E3sX5pP65JMZWCyYFY9ZbF',
    chainId: 101,
    decimals: 6,
    name: 'Andru Teit',
    symbol: 'TEIT',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d64426d447650725831464267766b756a6a615052724371333748754a50646659705a4d713655634270476970',
    tags: ['community'],
  },
  STEP: {
    address: 'StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT',
    chainId: 101,
    decimals: 9,
    name: 'Step',
    symbol: 'STEP',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT/logo.png',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'step-finance' },
  },
  T1NY: {
    address: 'C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6',
    chainId: 101,
    decimals: 9,
    name: 'Tiny Bonez',
    symbol: 'T1NY',
    logoURI:
      'https://raw.githubusercontent.com/danvernon/tiny-dogz-logo/main/coin.png',
    tags: ['old-registry', 'solana-fm'],
  },
  BITARD: {
    address: '2ZZaE2gNQSPZLEFiNzBewgQhj3wGpx4oDzuAawh4DHBB',
    chainId: 101,
    decimals: 9,
    name: 'BITARD',
    symbol: 'BITARD',
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://ipfs.io/ipfs/QmQvturiF2A4YXf4PjuYX9wBUqQyxsT4X79G1yu2y31Uoa',
    tags: ['community'],
    extensions: { coingeckoId: 'bitard' },
  },
  BABY: {
    address: '5hmf8Jt9puwoqiFQTb3vr22732ZTKYRLRw9Vo7tN3rcz',
    chainId: 101,
    decimals: 6,
    name: 'Baby',
    symbol: 'BABY',
    logoURI:
      'https://photos.pinksale.finance/file/pinksale-logo-upload/1709065389877-8334609f627697b59fa95b3f76dc5f39.png',
    tags: ['community'],
    extensions: { coingeckoId: 'baby' },
  },
  USH: {
    address: '9iLH8T7zoWhY7sBmj1WK9ENbWdS1nL8n9wAxaeRitTa6',
    chainId: 101,
    decimals: 9,
    name: 'Hedge USD',
    symbol: 'USH',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9iLH8T7zoWhY7sBmj1WK9ENbWdS1nL8n9wAxaeRitTa6/logo.png',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'hedge-usd' },
  },
  MOBILE: {
    address: 'mb1eu7TzEc71KxDpsmsKoucSSuuoGLv1drys1oP2jh6',
    chainId: 101,
    decimals: 6,
    name: 'Helium Mobile',
    symbol: 'MOBILE',
    logoURI:
      'https://shdw-drive.genesysgo.net/CsDkETHRRR1EcueeN346MJoqzymkkr7RFjMqGpZMzAib/mobile.png',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'helium-mobile' },
  },
  $TURBO: {
    address: '42KmnheUHt3UaQFoAKnipLgBJ1Zm427heJdQcHGPtnjc',
    chainId: 101,
    decimals: 7,
    name: 'TURBO on Solana',
    symbol: '$TURBO',
    logoURI: 'https://arweave.net/FVrzsx6r7mwCakD64Axt59qAB_g-nvYfA0wvWydw5u0',
    tags: ['community'],
  },
  PRINT: {
    address: 'HKYX2jvwkdjbkbSdirAiQHqTCPQa3jD2DVRkAFHgFXXT',
    chainId: 101,
    decimals: 6,
    name: 'Print Protocol',
    symbol: 'PRINT',
    logoURI:
      'https://bafkreiaepyesgvxkwkgy242tig5bdre65f4zcm2ohcqkv5yafcz3o3ijfy.ipfs.nftstorage.link',
    tags: ['community', 'token-2022'],
    extensions: { coingeckoId: 'print-protocol' },
  },
  PU238: {
    address: '7p6zGHdmWHvCH4Lsik2MoMBXqPGhFbSPSceSBXd8KNEC',
    chainId: 101,
    decimals: 6,
    name: 'Plutonium 238',
    symbol: 'PU238',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7p6zGHdmWHvCH4Lsik2MoMBXqPGhFbSPSceSBXd8KNEC/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  'sUSDC-8': {
    address: '88881Hu2jGMfCs9tMu5Rr7Ah7WBNBuXqde4nR5ZmKYYy',
    chainId: 101,
    decimals: 8,
    name: 'Saber Wrapped USD Coin (8 decimals)',
    symbol: 'sUSDC-8',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/88881Hu2jGMfCs9tMu5Rr7Ah7WBNBuXqde4nR5ZmKYYy/icon.png',
    tags: ['old-registry'],
  },
  POVT: {
    address: 'PhiLR4JDZB9z92rYT5xBXKCxmq4pGB1LYjtybii7aiS',
    chainId: 101,
    decimals: 5,
    name: 'Phil Overdrive | Youtube',
    symbol: 'POVT',
    logoURI:
      'https://raw.githubusercontent.com/PhilOverdrive/Token/main/Logo.png',
    tags: ['community'],
  },
  ACS: {
    address: '5MAYDfq5yxtudAhtfyuMBuHZjgAbaS9tbEyEQYAhDS5y',
    chainId: 101,
    decimals: 6,
    name: 'Access Protocol',
    symbol: 'ACS',
    logoURI: 'https://ap-staging.fra1.digitaloceanspaces.com/1663691449945',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'access-protocol' },
  },
  $PUPPA: {
    address: '8shTEDxbwvM2C9ELXNHsh8pmkoWovzJ3xgazQFWFapMx',
    chainId: 101,
    decimals: 6,
    name: 'PUPPA',
    symbol: '$PUPPA',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmNcspmaF4jHVz9dL4iM5b3eNRmFmNFDHYEqFPowyqSBYs',
    tags: ['community'],
    extensions: { coingeckoId: 'puppacoin' },
  },
  MILK: {
    address: 'CCKDRAd4Xwjoovtf2s1duu3d4TPTmFRyh1hfrb3ZUGR2',
    chainId: 101,
    decimals: 9,
    name: 'Tiddy Juice Coin',
    symbol: 'MILK',
    logoURI: 'https://arweave.net/S71DNASA5TYlrK9giRy7QgqFNw1m4mxqY6OdtgY1E7k',
    tags: ['old-registry', 'solana-fm'],
  },
  ATS: {
    address: 'HJbNXx2YMRxgfUJ6K4qeWtjatMK5KYQT1QnsCdDWywNv',
    chainId: 101,
    decimals: 9,
    name: 'Atlas Dex',
    symbol: 'ATS',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HJbNXx2YMRxgfUJ6K4qeWtjatMK5KYQT1QnsCdDWywNv/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'atlas-dex' },
  },
  Murgan: {
    address: '63kAw6KkS7fSqJ4RADJqdYFcB8QVEZz2BjA9m81sEwpN',
    chainId: 101,
    decimals: 6,
    name: 'Piers Murgan',
    symbol: 'Murgan',
    logoURI:
      'https://bafybeiaj2vodsquphqqjrwv2wdk3qkd7tzn4blegshtdzlnkve3dlsbe7e.ipfs.cf-ipfs.com',
    tags: ['community'],
  },
  PSY: {
    address: 'PsyFiqqjiv41G7o5SMRzDJCu4psptThNR2GtfeGHfSq',
    chainId: 101,
    decimals: 6,
    name: 'PsyOptions',
    symbol: 'PSY',
    logoURI:
      'https://user-images.githubusercontent.com/32071703/149460918-3694084f-2a37-4c95-93d3-b5aaf078d444.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'psyoptions' },
  },
  $FORCE: {
    address: '9MjAmgHXbu5drkNa9XpzfozgsM5Dcq6bSnKZzdNrwscC',
    chainId: 101,
    decimals: 9,
    name: 'Force',
    symbol: '$FORCE',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9MjAmgHXbu5drkNa9XpzfozgsM5Dcq6bSnKZzdNrwscC/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  JET: {
    address: 'JET6zMJWkCN9tpRT2v2jfAmm5VnQFDpUBCyaKojmGtz',
    chainId: 101,
    decimals: 9,
    name: 'Jet Protocol',
    symbol: 'JET',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JET6zMJWkCN9tpRT2v2jfAmm5VnQFDpUBCyaKojmGtz/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'jet' },
  },
  NNI: {
    address: 'buMnhMd5xSyXBssTQo15jouu8VhuEZJCfbtBUZgRcuW',
    chainId: 101,
    decimals: 6,
    name: 'NeoNomad',
    symbol: 'NNI',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/buMnhMd5xSyXBssTQo15jouu8VhuEZJCfbtBUZgRcuW/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  LOAF: {
    address: '3de2yRhtD4VbJBb8EQAQffYMPLU4EnSHT1eveBwiL3tn',
    chainId: 101,
    decimals: 6,
    name: 'LOAF CAT',
    symbol: 'LOAF',
    logoURI: 'https://loaf.cat/img/LOAFLOGO.png',
    tags: ['community'],
    extensions: { coingeckoId: 'loaf-cat' },
  },
  SCS: {
    address: 'SCSuPPNUSypLBsV4darsrYNg4ANPgaGhKhsA3GmMyjz',
    chainId: 101,
    decimals: 5,
    name: 'Solcasino Token',
    symbol: 'SCS',
    logoURI: 'https://files.solcasino.io/logo.png',
    tags: ['community'],
    extensions: { coingeckoId: 'solcasino-token' },
  },
  BOBBY: {
    address: '48yNDqabAvGNfnkhadsV1MAvtp44fFDdHBRBdFhvpump',
    chainId: 101,
    decimals: 6,
    name: 'Bobby',
    symbol: 'BOBBY',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d576764684561653579505170764d5848725a756e6a6a4b743266364d33714b7a4b7270657834327659564737',
    tags: ['community'],
    extensions: { coingeckoId: 'bobby' },
  },
  MEMES: {
    address: '8W4qpyLx74vwBRewa3rVEPPVMnJ8VWMkCTWCTSYPQTDu',
    chainId: 101,
    decimals: 6,
    name: 'MemeCoinDAOai',
    symbol: 'MEMES',
    logoURI:
      'https://arweave.net/hb9M20acG4rA6SFess40tLnhJ6RZkd0EmluNwzh11Wc?ext=png',
    tags: ['community'],
    extensions: { coingeckoId: 'memecoindao' },
  },
  bonkSOL: {
    address: 'BonK1YhkXEGLZzwtcvRTip3gAL9nCeQD7ppZBLXhtTs',
    chainId: 101,
    decimals: 9,
    name: 'Bonk SOL',
    symbol: 'bonkSOL',
    logoURI: 'https://arweave.net/ms-FdIyJ8TxEJOb2SAYhfyrLop7TDrCEjD-I-oIl5u4',
    tags: ['community'],
    extensions: { coingeckoId: 'bonk-staked-sol' },
  },
  WVE: {
    address: '33MJjPS6H7P2nwD648uiTiUu8v5fJ1zKyjnVqeWZ344F',
    chainId: 101,
    decimals: 9,
    name: 'everwave',
    symbol: 'WVE',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/33MJjPS6H7P2nwD648uiTiUu8v5fJ1zKyjnVqeWZ344F/logo.jpg',
    tags: ['old-registry', 'solana-fm'],
  },
  CRWNY: {
    address: 'CRWNYkqdgvhGGae9CKfNka58j6QQkaD5bLhKXvUYqnc1',
    chainId: 101,
    decimals: 6,
    name: 'Crowny token',
    symbol: 'CRWNY',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CRWNYkqdgvhGGae9CKfNka58j6QQkaD5bLhKXvUYqnc1/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'crowny-token' },
  },
  FBZ: {
    address: 'BKydRTNdaMJ8B4zPva3YhwUQcpvAsyZaGJnKA6F44fX7',
    chainId: 101,
    decimals: 9,
    name: 'FakeBiz',
    symbol: 'FBZ',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BKydRTNdaMJ8B4zPva3YhwUQcpvAsyZaGJnKA6F44fX7/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  ZOOMER: {
    address: 'nBZEcHSG771mRbi4y2sSgKjfDUH8jsM2Eo5fNcASLeU',
    chainId: 101,
    decimals: 8,
    name: 'ZOOMER (Wormhole)',
    symbol: 'ZOOMER',
    logoURI:
      'https://assets.coingecko.com/coins/images/30894/large/zoooooooooomer.jpg?1696529740',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'zoomer' },
  },
  SHARE: {
    address: 'E6Eg7Esj5tfSwkbDGdrzhrotqptv7ghJNarLZ9rbHDSG',
    chainId: 101,
    decimals: 6,
    name: 'SHARE',
    symbol: 'SHARE',
    logoURI:
      'ipfs://bafkreihmnbzlnzod2x6abcoxyyqbm5mjccwihkvdjo2jwbxkztihpnhksa/',
    tags: ['community'],
    extensions: { coingeckoId: 'share-on-crypto' },
  },
  DYOR: {
    address: 'DidjvEEFLk31yEjTkxf6CfNi6RcsUjPS6qHNTVzhApNU',
    chainId: 101,
    decimals: 6,
    name: 'Book of DYOR',
    symbol: 'DYOR',
    logoURI:
      'https://dd.dexscreener.com/ds-data/tokens/solana/DidjvEEFLk31yEjTkxf6CfNi6RcsUjPS6qHNTVzhApNU.png?size=lg&key=ea332c',
    tags: ['community'],
    extensions: { coingeckoId: 'book-of-dyor' },
  },
  SOLAPE: {
    address: 'GHvFFSZ9BctWsEc5nujR1MTmmJWY7tgQz2AXE6WVFtGN',
    chainId: 101,
    decimals: 9,
    name: 'SolAPE Token',
    symbol: 'SOLAPE',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GHvFFSZ9BctWsEc5nujR1MTmmJWY7tgQz2AXE6WVFtGN/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'solape-token' },
  },
  SI: {
    address: 'Fxgdfsy1Z5Mvh53o69s2Ev6TGxtAJ1RQ5RJ5moCpKmQZ',
    chainId: 101,
    decimals: 9,
    name: 'sealwifhat',
    symbol: 'SI',
    logoURI:
      'https://bafybeiay63g7wqdbndfp6yxuvonuigsp4qrgm22ultyndxurnuu7tkkbbi.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'sealwifhat' },
  },
  BORK: {
    address: '4jZXkSNgTQKCDb36ECZ6a2aNzcUniGcDeXgTdtM2HxAX',
    chainId: 101,
    decimals: 9,
    name: 'Bork',
    symbol: 'BORK',
    logoURI:
      'https://node1.irys.xyz/v7mjymWz2rjAHe1Sn3rUECDdjWjYx_MZ_zi5hhQsgOU',
    tags: ['community'],
    extensions: { coingeckoId: 'bork-2' },
  },
  JEFF: {
    address: 'FkCaFsprX7gySagFoQPHNbe9MRkjrvh21cokJo6C1e2T',
    chainId: 101,
    decimals: 6,
    name: 'JEFF',
    symbol: 'JEFF',
    logoURI: 'https://arweave.net/Pi-s2vzZT8pfYVvFbNjgC0kSrW45PXb1_VPb5HoHuyI',
    tags: ['community', 'token-2022'],
  },
  clockSOL: {
    address: 'GRJQtWwdJmp5LLpy8JWjPgn5FnLyqSJGNhn5ZnCTFUwM',
    chainId: 101,
    decimals: 9,
    name: 'Overclock SOL',
    symbol: 'clockSOL',
    logoURI:
      'https://raw.githubusercontent.com/Overclock-Validator/assets/main/img.png',
    tags: ['community'],
    extensions: { coingeckoId: 'overclock-staked-sol' },
  },
  LSP: {
    address: 'BAy5FmGzFwcVcZq1yXaDvF1mEAChF3MPtBLrUMBsnLN9',
    chainId: 101,
    decimals: 6,
    name: 'Lambo Space Princess',
    symbol: 'LSP',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmawB8QaHKMCe8myQbkM15FmsZ1YzCnLnPWPHEN6GAB8a6',
    tags: ['community'],
  },
  $gerta: {
    address: 'DLvWoNT1d5iSX2T1aUUBzRdGbSef2xW3MwJBKtewVW6h',
    chainId: 101,
    decimals: 6,
    name: 'gerta',
    symbol: '$gerta',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmWLHrrt6ah8WJWkn4rtmTKPeTkW1HScKaDde7sRCw3kFh',
    tags: ['community'],
    extensions: { coingeckoId: 'gerta' },
  },
  KIWI: {
    address: '66Qq2qS67K4L5xQ3xUTinCyxzdPeZQG1R1ipK8jrY7gc',
    chainId: 101,
    decimals: 5,
    name: 'KIWI Token',
    symbol: 'KIWI',
    logoURI:
      'https://bafkreibcamcjwo5z3itvybznrdtb3fgeiplfy36izu75jygxkt7jzoq4ju.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'kiwi-token-2' },
  },
  DOGO: {
    address: '5LSFpvLDkcdV2a3Kiyzmg5YmJsj2XDLySaXvnfP1cgLT',
    chainId: 101,
    decimals: 6,
    name: 'Dogemon',
    symbol: 'DOGO',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5LSFpvLDkcdV2a3Kiyzmg5YmJsj2XDLySaXvnfP1cgLT/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'dogemon-go' },
  },
  DONALD: {
    address: '4Yz5zByTwnVe46AXD6hsrccbq4TKLyih2xRqPyLBDT1P',
    chainId: 101,
    decimals: 6,
    name: 'DONALD',
    symbol: 'DONALD',
    logoURI:
      'https://gateway.irys.xyz/LQ9YCmIWoXtDUAxNQhPjpbGbTgLjkgnLFVQJfRn73o0',
    tags: ['community'],
  },
  MPLX: {
    address: 'METAewgxyPbgwsseH8T16a39CQ5VyVxZi9zXiDPY18m',
    chainId: 101,
    decimals: 6,
    name: 'Metaplex Token',
    symbol: 'MPLX',
    logoURI: 'https://arweave.net/VRKOcXIvCxqp35RZ9I0-bDGk5qNfT46OTho-2oP9iGc',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'metaplex' },
  },
  TPC: {
    address: '5WWRMYPchxgh3VmYGPqoq2kfzCtBLxXB9vFH2TeFeR9m',
    chainId: 101,
    decimals: 4,
    name: 'TOYPOODLE COIN',
    symbol: 'TPC',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5WWRMYPchxgh3VmYGPqoq2kfzCtBLxXB9vFH2TeFeR9m/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  SSU: {
    address: 'AGkFkKgXUEP7ZXazza5a25bSKbz5dDpgafPhqywuQnpf',
    chainId: 101,
    decimals: 9,
    name: 'SunnySideUp Token',
    symbol: 'SSU',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AGkFkKgXUEP7ZXazza5a25bSKbz5dDpgafPhqywuQnpf/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'sunnysideup' },
  },
  QUACK: {
    address: '6frkvZf72wiz3uqRWhBqLftNU4PS6XXYCoNrW9P4CFdK',
    chainId: 101,
    decimals: 6,
    name: 'QUACK',
    symbol: 'QUACK',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GP9zY2D8CgMreoUdYQjyn7Fo7XCq9ubVnX3u4ot1wpgt/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  POW: {
    address: 'wA1MArtTmL1yYtweBjyHZZdteHQRd5fgwsUKx7iiqKU',
    chainId: 101,
    decimals: 6,
    name: 'People of Walmart',
    symbol: 'POW',
    logoURI: 'https://arweave.net/YtN5TutCP4Y4ZDWMYs1F9qdM8fJaAMDfq37Zmxkss_E',
    tags: ['community'],
  },
  LOVE: {
    address: '4QQV4LQUUXAn1eN1XQGrfY65TfLe5STJcfsCQozqyb8T',
    chainId: 101,
    decimals: 9,
    name: 'Love Monster',
    symbol: 'LOVE',
    logoURI:
      'https://lime-accused-tarantula-433.mypinata.cloud/ipfs/QmZcKtLG7RzM1UGSBUU1GuhQT93s73MF145VCh7Wukz78S',
    tags: ['community'],
    extensions: { coingeckoId: 'love-monster' },
  },
  VCHF: {
    address: 'AhhdRu5YZdjVkKR3wbnUDaymVQL2ucjMQ63sZ3LFHsch',
    chainId: 101,
    decimals: 9,
    name: 'VNX Swiss Franc',
    symbol: 'VCHF',
    logoURI:
      'https://4183046207-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2FsAJThQfNCJePE5h9iplX%2Fuploads%2F2fiefVjQxDNLja1fPN7g%2FVCHF%20256x256.png?alt=media&token=b990889a-841a-49f8-9d54-cbe2bb83b7f7',
    tags: ['community'],
    extensions: { coingeckoId: 'vnx-swiss-franc' },
  },
  CARL: {
    address: 'CARL1SLwhaK4eaF633jgEgYgJ7UDJmTa9XGHfMgPud5e',
    chainId: 101,
    decimals: 6,
    name: 'Carl',
    symbol: 'CARL',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f6261666b726569643663656f676b767275646d367a6d3263663668333477717233776634376d34687533796a71357a767a7270646b6867727232792e697066732e6e667473746f726167652e6c696e6b2f',
    tags: ['community'],
  },
  Leia: {
    address: '9EPYaNqzJLSPSFH9aKSxn2EaJoxHS1oqt7pVBR3yivt',
    chainId: 101,
    decimals: 6,
    name: 'Leia',
    symbol: 'Leia',
    logoURI:
      'https://node1.irys.xyz/t6iSQGCEQhB1jBHPU9CSunaYsK-ocD-HxA4EBw1R4Ow',
    tags: ['community'],
    extensions: { coingeckoId: 'leia' },
  },
  GP: {
    address: '31k88G5Mq7ptbRDf3AM13HAq6wRQHXHikR8hik7wPygk',
    chainId: 101,
    decimals: 9,
    name: 'Graphite',
    symbol: 'GP',
    logoURI: 'https://arweave.net/ALLzymnuIihFPhoNUJpPxQzbGI8LodXKhNzXzbRMPbA',
    tags: ['community'],
    extensions: { coingeckoId: 'graphite-protocol' },
  },
  cgntSOL: {
    address: 'CgnTSoL3DgY9SFHxcLj6CgCgKKoTBr6tp4CPAEWy25DE',
    chainId: 101,
    decimals: 9,
    name: 'Cogent SOL',
    symbol: 'cgntSOL',
    logoURI: 'https://cogent-cogs.s3.us-west-2.amazonaws.com/cgntSOL.png',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'cogent-sol' },
  },
  PUMPMAN: {
    address: 'BU7mDyqDEYMwB7gPyAHf7MRXSmT9DNMZn7o3AZavYL1R',
    chainId: 101,
    decimals: 6,
    name: 'Pump Man',
    symbol: 'PUMPMAN',
    logoURI:
      'https://solscan.io/_next/image?url=https%3A%2F%2Fimg.fotofolio.xyz%2F%3Furl%3Dhttps%253A%252F%252Fcf-ipfs.com%252Fipfs%252FQmPu1ZkeGtdtQd9cLdercu4iD7eGycaJjZ5vEWKwC3sWWo&w=32&q=75',
    tags: ['community'],
  },
  KREECHURE: {
    address: '3XwZtyP5g2ku5cNzLXYcBYygvToXKdjw73GSRq73QYLQ',
    chainId: 101,
    decimals: 2,
    name: 'Kreechures',
    symbol: 'KREECHURE',
    logoURI:
      'https://cloudflare-ipfs.com/ipfs/QmQLfAMg5aDV2oLeeF5er4hTU5y8wQMB4CaEURxraG6SJd',
    tags: ['community'],
  },
  'sUSDT-9': {
    address: 'AEUT5uFm1D575FVCoQd5Yq891FJEqkncZUbBFoFcAhTV',
    chainId: 101,
    decimals: 9,
    name: 'Saber Wrapped USDT (9 decimals)',
    symbol: 'sUSDT-9',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AEUT5uFm1D575FVCoQd5Yq891FJEqkncZUbBFoFcAhTV/icon.png',
    tags: ['old-registry'],
  },
  TIME: {
    address: 'AG9yih1Wkunf17yucoNYUacw4LwQugr2vWBPpHA6xz6q',
    chainId: 101,
    decimals: 9,
    name: 'TIME Magazine of Solana',
    symbol: 'TIME',
    logoURI:
      'https://ipfs.io/ipfs/QmY5JBY5pVC1uWkqwRA2P7HXZV1KpBsbA8LdAywJAxkqjc',
    tags: ['community'],
  },
  doodoo: {
    address: 'JDwzFSxcUvLubUb9xAuuZNvh4bbcEJcuM9TezpmRHVWF',
    chainId: 101,
    decimals: 8,
    name: 'DooDoo (Wormhole)',
    symbol: 'doodoo',
    logoURI:
      'https://nftstorage.link/ipfs/bafybeidyutrgtbcw2oxfajdty7kyd3slwqbxval2itdfingeop4324cdjy',
    tags: ['community'],
    extensions: { coingeckoId: 'doodoo' },
  },
  SONAR: {
    address: 'sonarX4VtVkQemriJeLm6CKeW3GDMyiBnnAEMw1MRAE',
    chainId: 101,
    decimals: 9,
    name: 'Sonar Watch',
    symbol: 'SONAR',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sonarX4VtVkQemriJeLm6CKeW3GDMyiBnnAEMw1MRAE/logo.png',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'sonarwatch' },
  },
  EDSE: {
    address: '2Zvo6bnwJtyXhsPgbcfajTrEtB7NwrJhK8mMmtEXvHHc',
    chainId: 101,
    decimals: 9,
    name: 'Eddie Seal',
    symbol: 'EDSE',
    logoURI:
      'https://ipfs.io/ipfs/Qmb2rK2UwLonw85t473b7vhZsJ6ZqnTTbBjnhKmGCiUJya',
    tags: ['community'],
    extensions: { coingeckoId: 'eddie-seal' },
  },
  lilpump: {
    address: '9vrGUHwsC8LyLjQoh3zJb9S53x7A88u49La63qPB6F5t',
    chainId: 101,
    decimals: 6,
    name: 'Lil Pump',
    symbol: 'lilpump',
    logoURI:
      'https://bafkreien64lco2u3jwti6oeuhua7jf3acwo3fnbhau2m6fpz4hdt3ggkgi.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'lil-pump' },
  },
  green: {
    address: '7VUUicqKYFVmewg1U4w4e9K4FoEYdTcYyxYCPhRNf5Qy',
    chainId: 101,
    decimals: 9,
    name: 'solana green',
    symbol: 'green',
    logoURI: 'https://arweave.net/W68r4cNKbj0t_GRf1km_e9nzm4BLfspy0X-yX8mh4fQ',
    tags: ['community'],
  },
  POPDOG: {
    address: 'EATGZHJViJsk7nEKkrdJicwNbfpkJfAtmrEmrjXR8NBj',
    chainId: 101,
    decimals: 6,
    name: 'PopDog',
    symbol: 'POPDOG',
    logoURI: 'https://i.postimg.cc/SRb6fSps/SBZ5KM3L.png',
    tags: ['community'],
    extensions: { coingeckoId: 'popdog' },
  },
  AXSet: {
    address: 'HysWcbHiYY9888pHbaqhwLYZQeZrcQMXKQWRqS7zcPK5',
    chainId: 101,
    decimals: 8,
    name: 'Axie Infinity Shard (Portal from Ethereum)',
    symbol: 'AXSet',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/AXSet_wh.png',
    tags: ['wormhole', 'old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'axie-infinity-shard-wormhole' },
  },
  $KEIF: {
    address: '9QgXH6RjuLx5izvgRU1ovzackRsbzQoe415mxHUZJkkH',
    chainId: 101,
    decimals: 6,
    name: 'Keif The Kat',
    symbol: '$KEIF',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmetHJ1RirB7vth3yznTvUcfqfuzgyWXAFKEFSd4B9P7uu',
    tags: ['community'],
  },
  SUSHI: {
    address: 'ChVzxWRmrTeSgwd3Ui3UumcN8KX7VK3WaD4KGeSKpypj',
    chainId: 101,
    decimals: 8,
    name: 'SushiToken (Portal)',
    symbol: 'SUSHI',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/SUSHI_wh.png',
    tags: ['wormhole', 'old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'sushi' },
  },
  DFTZ: {
    address: '71WsPPm44SUTWDfcCdLKsDezw4ZBXDrPjHz2rea7Lo97',
    chainId: 101,
    decimals: 7,
    name: 'DeFiTimeZ',
    symbol: 'DFTZ',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f6261666b7265696233616836696969636137637070646f6f646334726b6961686d7a7569756e72667676626c6c6f623536637773333363657476792e697066732e6e667473746f726167652e6c696e6b',
    tags: ['community'],
  },
  DYDX: {
    address: '4Hx6Bj56eGyw8EJrrheM6LBQAvVYRikYCWsALeTrwyRU',
    chainId: 101,
    decimals: 8,
    name: 'dYdX (Portal)',
    symbol: 'DYDX',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/DYDX_wh.png',
    tags: ['wormhole', 'old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'dydx-wormhole' },
  },
  MDT: {
    address: '8Wqbst4qAN2FqBCCh5gdXq2WJ7vTNWEY4oNLrpUg7Tya',
    chainId: 101,
    decimals: 8,
    name: 'Measurable Data Token (Wormhole)',
    symbol: 'MDT',
    logoURI:
      'https://assets.coingecko.com/coins/images/2441/standard/mdt_logo.png',
    tags: ['community'],
  },
  JJ: {
    address: '8eAUrugF8ToBmkg4CpJjTY9AcPx1UBMdExw2Ju84MCG4',
    chainId: 101,
    decimals: 9,
    name: 'JJmoji',
    symbol: 'JJ',
    logoURI:
      'https://gateway.irys.xyz/fZ0qQb-6kgoFrHkppEy2lldo8miuSp76fuxZIw6J6_M',
    tags: ['community'],
    extensions: { coingeckoId: 'jjmoji-2' },
  },
  SWAG: {
    address: 'FaxYQ3LVXP51rDP2yWGLWVrFAAHeSdFF8SGZxwj2dvor',
    chainId: 101,
    decimals: 2,
    name: 'swag coin',
    symbol: 'SWAG',
    logoURI:
      'https://lhh47k2s4fcnzgbccwh537aja2wpu7adocuilfh2fq465lfuttaq.arweave.net/Wc_Pq1LhRNyYIhWP3fwJBqz6fANwqIWU-iw57qy0nME',
    tags: ['community'],
    extensions: { coingeckoId: 'swag-coin' },
  },
  SPX: {
    address: 'J3NKxxXZcnNiMjKw9hYb2K4LUxgwB6t1FtPtQVsv3KFr',
    chainId: 101,
    decimals: 8,
    name: 'SPX6900',
    symbol: 'SPX',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/SPX6900_wh.png',
    tags: ['wormhole'],
    extensions: { coingeckoId: 'spx6900' },
  },
  WAVES: {
    address: '4uRn7vxRPWYP4HuAa4UNXwEPLRL8oQ71YByMhr6yBnL4',
    chainId: 101,
    decimals: 2,
    name: 'Playground Waves',
    symbol: 'WAVES',
    logoURI: 'https://i.imgur.com/CFSGSyE.png',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'playground-waves-floor-index' },
  },
  CRAMER: {
    address: 'E3tHHRa9Y1TWAA1nPZo8FAyVFgBDnXrS8u8nmMShL3M3',
    chainId: 101,
    decimals: 8,
    name: 'Cramer Coin (Wormhole)',
    symbol: 'CRAMER',
    logoURI: 'https://i.imgur.com/6ERdMOo.png',
    tags: ['community'],
  },
  PUFF: {
    address: 'G9tt98aYSznRk7jWsfuz9FnTdokxS6Brohdo9hSmjTRB',
    chainId: 101,
    decimals: 9,
    name: 'PUFF',
    symbol: 'PUFF',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G9tt98aYSznRk7jWsfuz9FnTdokxS6Brohdo9hSmjTRB/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'puff' },
  },
  JURDEN: {
    address: 'HYWB4HkyfDSA1i4t4cQaK9cvLHG4nQBRzGX9tzmEmgDr',
    chainId: 101,
    decimals: 6,
    name: 'Jurden Peeturdson',
    symbol: 'JURDEN',
    logoURI:
      'https://bafybeihfqmvynbetxlwxxbc6rbypbkzohfgbkqqyohyds6fgjiyropsi6e.ipfs.cf-ipfs.com/',
    tags: ['community'],
  },
  NLTK: {
    address: '3uNAevHamuZKKQdtdLzmHNvqD8r14tXUUXx5PN48UbYC',
    chainId: 101,
    decimals: 9,
    name: 'Nasi Lemak',
    symbol: 'NLTK',
    logoURI: 'https://raw.githubusercontent.com/avaelon/nltk/main/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  HOPE: {
    address: '9Zhvjcce3kHbYQGojybM9GbtE76rvoMJM36egCcmPs2s',
    chainId: 101,
    decimals: 9,
    name: 'HOPE',
    symbol: 'HOPE',
    logoURI:
      'https://ipfs.io/ipfs/QmfJE2zGbSrFzopqis8B9AAfKRZHLYt6GDjcB368zBmvSB',
    tags: ['community'],
  },
  LAB: {
    address: '2Pp6ebUvEL9YRTauUTmGTwYZKRfyQXGM9jE4S8WPDtEy',
    chainId: 101,
    decimals: 6,
    name: 'The Professor',
    symbol: 'LAB',
    logoURI:
      'https://bafybeid7jj3mtoveumfngqonkx7n6usw437c66asi2ucce6sewtbjga2ni.ipfs.cf-ipfs.com/',
    tags: ['community'],
    extensions: { coingeckoId: 'the-professor' },
  },
  BOOK: {
    address: '4nFwuKievw5wcpcXtUDdfxWLyXsEdvgkpENzC9M9Y5me',
    chainId: 101,
    decimals: 6,
    name: 'Solbook',
    symbol: 'BOOK',
    logoURI: 'https://i.ibb.co/m4ZWcvG/tokenlogo-1.png',
    tags: ['community'],
    extensions: { coingeckoId: 'solbook' },
  },
  POOTI: {
    address: '2nDpiBboQgHcBuRyNwCQzc5fcpxBZKQ1t37pr5aNFczn',
    chainId: 101,
    decimals: 6,
    name: 'POOTI (RELAUNCH)',
    symbol: 'POOTI',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d54765776333741416361446568766b436f6337557745387268454d74544470424e6b4374726731716653767a',
    tags: ['community'],
    extensions: { coingeckoId: 'pooti-relaunch' },
  },
  APYS: {
    address: '5JnZ667P3VcjDinkJFysWh2K2KtViy63FZ3oL5YghEhW',
    chainId: 101,
    decimals: 9,
    name: 'APYSwap',
    symbol: 'APYS',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5JnZ667P3VcjDinkJFysWh2K2KtViy63FZ3oL5YghEhW/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'apyswap' },
  },
  YES: {
    address: '32h846XXTSWGUaaKHMC5b2e39n1nwJD6UtDBppX5p4E9',
    chainId: 101,
    decimals: 9,
    name: 'Yes Token',
    symbol: 'YES',
    logoURI: 'https://arweave.net/6iPXS6gy4DFtFVBA__znCRKAAiiDoG79kc0f3wzwSEA',
    tags: ['community'],
  },
  sMEAN: {
    address: 'sMEANebFMnd9uTYpyntGzBmTmzEukRFwCjEcnXT2E8z',
    chainId: 101,
    decimals: 6,
    name: 'Staked MEAN',
    symbol: 'sMEAN',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sMEANebFMnd9uTYpyntGzBmTmzEukRFwCjEcnXT2E8z/logo.svg',
    tags: ['old-registry', 'solana-fm'],
  },
  DARK: {
    address: 'FmQ7v2QUqXVVtAXkngBh3Mwx7s3mKT55nQ5Z673dURYS',
    chainId: 101,
    decimals: 9,
    name: 'Dark Protocol',
    symbol: 'DARK',
    logoURI:
      'https://www.arweave.net/3VPYgJz-wlRAm1H5_4zrsAckyz55qa5ILyk3Uq6l4Ms?ext=png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'dark-protocol' },
  },
  AEVUM: {
    address: 'EsirN3orp85uyvZyDrZnbe9cyo7N1114ynLFdwMPCQce',
    chainId: 101,
    decimals: 9,
    name: 'Aevum',
    symbol: 'AEVUM',
    logoURI: 'https://arweave.net/mqtxUgbHYOu4lEUzMZy5YFZtItQQZtjZWbFUIbcM6cU',
    tags: ['community'],
    extensions: { coingeckoId: 'aevum-ore' },
  },
  BIRD: {
    address: '7y2KpMzsxmjCN8ogA3PSfwZvmYUgMuWo7NWY4Ct5jRHp',
    chainId: 101,
    decimals: 6,
    name: 'SolBird',
    symbol: 'BIRD',
    logoURI: 'https://arweave.net/K_IaCXwL9YQTPJnZfvi302k2lhANDvmzkiy6e0Y6Zho',
    tags: ['community'],
  },
  DINOEGG: {
    address: '2TxM6S3ZozrBHZGHEPh9CtM74a9SVXbr7NQ7UxkRvQij',
    chainId: 101,
    decimals: 6,
    name: 'DINOEGG',
    symbol: 'DINOEGG',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2TxM6S3ZozrBHZGHEPh9CtM74a9SVXbr7NQ7UxkRvQij/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  MEDIA: {
    address: 'ETAtLmCmsoiEEKfNrHKJ2kYy3MoABhU6NQvpSfij5tDs',
    chainId: 101,
    decimals: 6,
    name: 'Media Network',
    symbol: 'MEDIA',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ETAtLmCmsoiEEKfNrHKJ2kYy3MoABhU6NQvpSfij5tDs/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'media-network' },
  },
  GYC: {
    address: 'GYCVdmDthkf3jSz5ns6fkzCmHub7FSZxjVCfbfGqkH7P',
    chainId: 101,
    decimals: 9,
    name: 'GameYoo Token',
    symbol: 'GYC',
    logoURI: 'https://raw.githubusercontent.com/gameyoo/materials/main/gyc.png',
    tags: ['old-registry', 'solana-fm'],
  },
  FANT: {
    address: 'FANTafPFBAt93BNJVpdu25pGPmca3RfwdsDsRrT3LX1r',
    chainId: 101,
    decimals: 6,
    name: 'Phantasia',
    symbol: 'FANT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FANTafPFBAt93BNJVpdu25pGPmca3RfwdsDsRrT3LX1r/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  USDTpo: {
    address: '5goWRao6a3yNC4d6UjMdQxonkCMvKBwdpubU3qhfcdf1',
    chainId: 101,
    decimals: 6,
    name: 'Tether USD (PoS) (Portal from Polygon)',
    symbol: 'USDTpo',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/USDTpo_wh.png',
    tags: ['wormhole', 'old-registry'],
    extensions: { coingeckoId: 'tether-usd-pos-wormhole' },
  },
  FEED: {
    address: '3LDAW7enNUZ4DjE1jCi1cDpXvXLrJ1rPiECPbcHpMgG2',
    chainId: 101,
    decimals: 2,
    name: 'FEED on ACF Game',
    symbol: 'FEED',
    logoURI: 'https://shop.alienchickenfarm.com/logos/feed-token.png',
    tags: ['old-registry', 'solana-fm'],
  },
  XCOPE: {
    address: '3K6rftdAaQYMPunrtNRHgnK2UAtjm2JwyT2oCiTDouYE',
    chainId: 101,
    decimals: 0,
    name: 'XCOPE',
    symbol: 'XCOPE',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3K6rftdAaQYMPunrtNRHgnK2UAtjm2JwyT2oCiTDouYE/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  MYRA: {
    address: 'H1aN3vcvB68eaFPbMkoAss3vnfi4AhP5C2dpnrZzdBc7',
    chainId: 101,
    decimals: 9,
    name: 'Myra',
    symbol: 'MYRA',
    logoURI:
      'https://bafkreieakw6eo745t2l46h2lii3n4sych5jl4v4ldumngslnjph53r6m5y.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'myra' },
  },
  KEKE: {
    address: 'GgKDdEJ9n2NCHHonE5qSxVgPKGQNsbeXEkr1SHDLapPv',
    chainId: 101,
    decimals: 7,
    name: 'Freaky KEKE',
    symbol: 'KEKE',
    logoURI:
      'https://bafybeihvl5whykpdkgi4anxjj3uxtzoevkbzdls4hsljgxtboy7yg23df4.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'freaky-keke' },
  },
  IMARO: {
    address: '3Gjckk5jXnJffBruUS2EEYhpiDEN6z5TPXLkFVHkSkkg',
    chainId: 101,
    decimals: 6,
    name: 'IMARO',
    symbol: 'IMARO',
    logoURI: 'https://arweave.net/DHesbj9OXu_a4xL9XYw27lxuG67IrG7Uv0WOuTwu9mo',
    tags: ['community'],
    extensions: { coingeckoId: 'imaro' },
  },
  Puri: {
    address: 'CzLWmvjtj5bp9YEWkzzBnuWovVtogeuchFu5GeFh68hB',
    chainId: 101,
    decimals: 9,
    name: 'Puri',
    symbol: 'Puri',
    logoURI:
      'https://gateway.irys.xyz/ILXdxJTexpeuGPKMjPtP4wBmvg4x3GlI3nc0n4V9RIU',
    tags: ['community'],
  },
  OLYMP24: {
    address: '4e1SQnaJn9JEAp7KYJwrQp3V4ED1dSkPygYbfFQM11uA',
    chainId: 101,
    decimals: 6,
    name: 'OLYMPIC 2024',
    symbol: 'OLYMP24',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d564746376468596957643135485439795355776a393565356b686f6d706d6261595139416362467244387757',
    tags: ['community'],
  },
  BOOP: {
    address: 'CCRJohp9bfQdhZCvCe7MzUQuXMJkKBi4XCjUq3A2YqN8',
    chainId: 101,
    decimals: 10,
    name: 'BOOP',
    symbol: 'BOOP',
    logoURI: 'https://arweave.net/2ArDrNlyF34gjZ7UdXmMVl_ET9igdcyDiAY-VFsw3ao',
    tags: ['community'],
  },
  Froggo: {
    address: 'A12XggFFk3b5GCd6ZYxuQ55cQbxarHL4h7Jxs3GQcdC3',
    chainId: 101,
    decimals: 9,
    name: 'Froggo',
    symbol: 'Froggo',
    logoURI: 'https://froggotoken.com/token_logo.png',
    tags: ['community'],
  },
  ZERO: {
    address: '93RC484oMK5T9H89rzT5qiAXKHGP9jscXfFfrihNbe57',
    chainId: 101,
    decimals: 9,
    name: 'ZERO',
    symbol: 'ZERO',
    logoURI:
      'https://gateway.irys.xyz/0qYdLixPAk4cYEpaf3ylqZ-JIbw8Vqg6R9xXZrH9SCc',
    tags: ['community'],
    extensions: { coingeckoId: 'analysoor' },
  },
  MEWING: {
    address: '9bxaVJmUwSc71j8Z2pvUL3UAr1s5fCnwUpvYhqV9jtmw',
    chainId: 101,
    decimals: 6,
    name: 'Mewing Coin',
    symbol: 'MEWING',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d533838504636457144314d714479734a4571416b69617a33616d333132554757536b5a596642543550645254',
    tags: ['community'],
    extensions: { coingeckoId: 'mewing-coin' },
  },
  MuShu: {
    address: 'CF97pGa5aNhXYkERsYLbAZ1wyk6cSFrfKhd1ansRD3So',
    chainId: 101,
    decimals: 10,
    name: 'Dragon',
    symbol: 'MuShu',
    logoURI:
      'https://bafybeiesfkh2pusubfi4xjttjerzvuyhfp4qwjj24atubpi6zs4l3zvcoy.ipfs.nftstorage.link',
    tags: ['community'],
  },
  Tyrese: {
    address: 'EKiwUg6K1aAyfF1uugHxEYPQksVTkCeHorkr8XwsoNAa',
    chainId: 101,
    decimals: 9,
    name: 'Tyrese filmed the moon landing',
    symbol: 'Tyrese',
    logoURI:
      'https://ipfs.io/ipfs/QmQcoYUuHBLUXgcdm2opq4dZXZk1DbPXMzBWLtWRBB6QcL',
    tags: ['community'],
  },
  FOOD: {
    address: 'foodQJAztMzX1DKpLaiounNe2BDMds5RNuPC6jsNrDG',
    chainId: 101,
    decimals: 0,
    name: 'Food',
    symbol: 'FOOD',
    logoURI: 'https://storage.googleapis.com/nft-assets/items/FOOD.png',
    tags: ['community', 'solana-fm'],
  },
  FRONK: {
    address: '5yxNbU8DgYJZNi3mPD9rs4XLh9ckXrhPjJ5VCujUWg5H',
    chainId: 101,
    decimals: 5,
    name: 'Fronk',
    symbol: 'FRONK',
    logoURI:
      'https://shdw-drive.genesysgo.net/8tfWzweVe7MAfi8qwiKFnzLq6wuLT7WAPMoQC7DH47Fq/fronk.gif',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'fronk' },
  },
  ZPET: {
    address: '4AnuSHLSYc4J6yjNekrYMjknL4zJHCvRLXfUxgpmCLeQ',
    chainId: 101,
    decimals: 3,
    name: 'Zeus Pet AI',
    symbol: 'ZPET',
    logoURI:
      'https://raw.githubusercontent.com/ZeusPet-AI/contracts/main/zeuspet_logo.png',
    tags: ['community'],
  },
  BARK: {
    address: 'APkM2bqzpwQaiv5BY2eA7vR7DEB1EX5MMf6JNcBCHYrC',
    chainId: 101,
    decimals: 6,
    name: 'BarkCoin',
    symbol: 'BARK',
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://cf-ipfs.com/ipfs/QmXuizMEJ9dZFViwydz3R2u9MH9k6uVupnWRgunhBGYrKv',
    tags: ['community'],
    extensions: { coingeckoId: 'barkcoin' },
  },
  VOTEDOGE: {
    address: '98ZmjtNLjTr1yih6D7fdFm8ViU1jEYDcBU1wjc2k9imV',
    chainId: 101,
    decimals: 9,
    name: 'Doge for President',
    symbol: 'VOTEDOGE',
    logoURI:
      'https://bafkreiho3miah3wtyoxa2t3qpksrpt4xnwjgfxxhldmzey5rh2fkplpovq.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'doge-for-president' },
  },
  SLCL: {
    address: 'SLCLww7nc1PD2gQPQdGayHviVVcpMthnqUz2iWKhNQV',
    chainId: 101,
    decimals: 9,
    name: 'Solcial token',
    symbol: 'SLCL',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLCLww7nc1PD2gQPQdGayHviVVcpMthnqUz2iWKhNQV/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'solcial' },
  },
  SEEDED: {
    address: 'seedEDBqu63tJ7PFqvcbwvThrYUkQeqT6NLf81kLibs',
    chainId: 101,
    decimals: 9,
    name: 'Seeded Network',
    symbol: 'SEEDED',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/seedEDBqu63tJ7PFqvcbwvThrYUkQeqT6NLf81kLibs/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'seeded-network' },
  },
  CREDITS: {
    address: '4ptcYkUypE7sDH82oXaeykaAJunuB4yeDJeiLJwS2nQc',
    chainId: 101,
    decimals: 8,
    name: 'SDrive Credit Token',
    symbol: 'CREDITS',
    logoURI: 'https://cdn.sdrive.pro/sdrive/credits_token.png',
    tags: ['community'],
  },
  SOLY: {
    address: 'CUwif1FiX5b3bwwb2n5Bm35AixvnR8LJjGUVmEwNZNgR',
    chainId: 101,
    decimals: 9,
    name: 'Solamander',
    symbol: 'SOLY',
    logoURI: 'https://img.solyonsol.io/soly.png',
    tags: ['community'],
    extensions: { coingeckoId: 'solamander' },
  },
  XTAG: {
    address: '5gs8nf4wojB5EXgDUWNLwXpknzgV2YWDhveAeBZpVLbp',
    chainId: 101,
    decimals: 6,
    name: 'xHashtag Token',
    symbol: 'XTAG',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5gs8nf4wojB5EXgDUWNLwXpknzgV2YWDhveAeBZpVLbp/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'xhashtag' },
  },
  BUSDet: {
    address: '33fsBLA8djQm82RpHmE3SuVrPGtZBWNYExsEUeKX1HXX',
    chainId: 101,
    decimals: 8,
    name: 'Binance USD (Portal from Ethereum)',
    symbol: 'BUSDet',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/BUSDet_wh.png',
    tags: ['wormhole', 'old-registry'],
  },
  ARTE: {
    address: '6Dujewcxn1qCd6rcj448SXQL9YYqTcqZCNQdCn3xJAKS',
    chainId: 101,
    decimals: 6,
    name: 'ARTE',
    symbol: 'ARTE',
    logoURI:
      'https://raw.githubusercontent.com/ArtPunks/ARTE-token/main/assets/token_512.png',
    tags: ['old-registry', 'solana-fm'],
  },
  TENKAI: {
    address: 'TKDrcm3n4mfXFfPKZoLp5soRSdFQSmyWLdomdKL3ktU',
    chainId: 101,
    decimals: 0,
    name: 'Tenkai Token',
    symbol: 'TENKAI',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TKDrcm3n4mfXFfPKZoLp5soRSdFQSmyWLdomdKL3ktU/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  clDOGE: {
    address: 'Dd6Pde7K4J7yB691gW8K177TWd1Huy6RkfTwrbkz8Fre',
    chainId: 101,
    decimals: 8,
    name: 'Cloned Doge',
    symbol: 'clDOGE',
    logoURI: 'https://markets.clone.so/images/assets/on-doge.svg',
    tags: ['community'],
    extensions: { coingeckoId: 'cloned-dogecoin' },
  },
  HIM: {
    address: '3sk6T5gzpvPcvGDqXC4erFvrB9yeJrRJniXLaH6Cpump',
    chainId: 101,
    decimals: 6,
    name: 'Himitsu',
    symbol: 'HIM',
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://bafkreiekcd6ewwjkx7h3gymv4la66m66vf2jtvrjvcnrlzkbyg5fpg2f7a.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'himitsu' },
  },
  HEIST: {
    address: 'FjBedzf7WrWmo4yd3Tc2U6indeEQXtdewZ514hYxH5X9',
    chainId: 101,
    decimals: 2,
    name: 'The Heist',
    symbol: 'HEIST',
    logoURI:
      'https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafybeigch4m7rbec2l255powwbjmacnyj5n5o54qcboiwfgs2nnw6thimq.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  WLKN: {
    address: 'EcQCUYv57C4V6RoPxkVUiDwtX1SP8y8FP5AEToYL8Az',
    chainId: 101,
    decimals: 9,
    name: 'Walken',
    symbol: 'WLKN',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EcQCUYv57C4V6RoPxkVUiDwtX1SP8y8FP5AEToYL8Az/logo.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'walken' },
  },
  SAROS: {
    address: 'SarosY6Vscao718M4A778z4CGtvcwcGef5M9MEH1LGL',
    chainId: 101,
    decimals: 6,
    name: 'Saros',
    symbol: 'SAROS',
    logoURI: 'https://rapid.coin98.com/Currency/saros.png',
    tags: ['community'],
    extensions: { coingeckoId: 'saros-finance' },
  },
  LORGY: {
    address: 'C3Vg7ps6dNPugYCpA52KETpdCytE6ki3oe8ccTsaY63i',
    chainId: 101,
    decimals: 9,
    name: 'Memeolorgy',
    symbol: 'LORGY',
    logoURI:
      'https://ipfs.io/ipfs/Qman1J5CQ2Z3cFshVvsBk6FxzVHavHQgVbUJF49xEFcsJ6',
    tags: ['community'],
  },
  OOGI: {
    address: 'H7Qc9APCWWGDVxGD5fJHmLTmdEgT9GFatAKFNg6sHh8A',
    chainId: 101,
    decimals: 9,
    name: 'OOGI',
    symbol: 'OOGI',
    logoURI: 'https://oogi.com/icon.png',
    tags: ['old-registry'],
  },
  TWTR: {
    address: 'FcScaNdN3TRPMwcgqHj1E5GuEh1rNesBGVN5WtZuad1z',
    chainId: 101,
    decimals: 9,
    name: 'Twitter',
    symbol: 'TWTR',
    logoURI:
      'https://ipfs.io/ipfs/QmcjVdSFfWsecrqusiviGb2HJri4PKJjLs8fa3hzycv3xL',
    tags: ['community'],
  },
  SHROOM: {
    address: 'xyzR4s6H724bUq6q7MTqWxUnhi8LM5fiKKUq38h8M1P',
    chainId: 101,
    decimals: 9,
    name: 'Shroom',
    symbol: 'SHROOM',
    logoURI:
      'https://bafkreigoe2lb5utcb4xcneknfgh4b37wptis2n43tuegjye4ydwpw273hm.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'shroom' },
  },
  maga: {
    address: 'TrumptpNNBEgVjDc8bnemRTNYZKhdsst9ujNAnTSHqp',
    chainId: 101,
    decimals: 6,
    name: 'maek amuraca graet agun',
    symbol: 'maga',
    logoURI:
      'https://quicknode.quicknode-ipfs.com/ipfs/QmRqsMJB8nwJtJBXBkidBYmQ9PGn3hfYZjXH3zKkN5G8BY',
    tags: ['community'],
    extensions: { coingeckoId: 'maek-amuraca-graet-agun' },
  },
  sSOL: {
    address: '8EDaoeBqpcVACwvkYXh1vAcU29HiBiNhqoF4pRsuUsZS',
    chainId: 101,
    decimals: 9,
    name: 'SunnySideUp staked SOL (sSOL)',
    symbol: 'sSOL',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8EDaoeBqpcVACwvkYXh1vAcU29HiBiNhqoF4pRsuUsZS/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  BIJU: {
    address: '4onzDs1X6ubktirorHB8iYbve3K4bBtkGpYehqzGm9So',
    chainId: 101,
    decimals: 9,
    name: 'BIJU',
    symbol: 'BIJU',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4onzDs1X6ubktirorHB8iYbve3K4bBtkGpYehqzGm9So/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  ELGATO: {
    address: 'F47vvwFYuLioQsqEVAjqdY6Yihc8wVRiUcfHGcBR9XUs',
    chainId: 101,
    decimals: 6,
    name: 'el gato',
    symbol: 'ELGATO',
    logoURI:
      'https://dd.dexscreener.com/ds-data/tokens/solana/F47vvwFYuLioQsqEVAjqdY6Yihc8wVRiUcfHGcBR9XUs.png',
    tags: ['community'],
    extensions: { coingeckoId: 'el-gato' },
  },
  HAMI: {
    address: '4sp2EUDrQf46rZun6sYAWzjrXwUpx2T3njuoKmV766RJ',
    chainId: 101,
    decimals: 9,
    name: 'HAMI',
    symbol: 'HAMI',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f697066732e696f2f697066732f516d526579545254426a4257524c746b6d6d4b416569444d5358694b7242796f574b5455454b3548315356583442',
    tags: ['community'],
    extensions: { coingeckoId: 'hami' },
  },
  SVIZ: {
    address: 'GV6n9Uow3XzMWSs8vwTCML8SvMA6ozbidaEfdPoSoraQ',
    chainId: 101,
    decimals: 9,
    name: 'Space Vizsla',
    symbol: 'SVIZ',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GV6n9Uow3XzMWSs8vwTCML8SvMA6ozbidaEfdPoSoraQ/VizslaMoonLogo100x100.png',
    tags: ['old-registry', 'solana-fm'],
  },
  soSUSHI: {
    address: 'AR1Mtgh7zAtxuxGd2XPovXPVjcSdY3i4rQYisNadjfKy',
    chainId: 101,
    decimals: 6,
    name: 'Wrapped SUSHI (Sollet)',
    symbol: 'soSUSHI',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AR1Mtgh7zAtxuxGd2XPovXPVjcSdY3i4rQYisNadjfKy/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  KRILL: {
    address: 'EP2aYBDD4WvdhnwWLUMyqU69g1ePtEjgYK6qyEAFCHTx',
    chainId: 101,
    decimals: 9,
    name: 'KRILL',
    symbol: 'KRILL',
    logoURI:
      'https://raw.githubusercontent.com/solanahodlwhales/whitelist/main/Krill_towen.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'krill' },
  },
  KENG: {
    address: 'BKykA4H1s4717FhBGFhDXkKF4UzvvsWB4uxqHBWX8FUU',
    chainId: 101,
    decimals: 3,
    name: 'Keng Boldwen',
    symbol: 'KENG',
    logoURI:
      'https://ipfs.io/ipfs/QmS8cKcvSUXX4V7oYoLdn6W3tULQ66YAXhXZh8s5KEyXEg',
    tags: ['community'],
  },
  ELE: {
    address: '8A9HYfj9WAMgjxARWVCJHAeq9i8vdN9cerBmqUamDj7U',
    chainId: 101,
    decimals: 9,
    name: 'Elementerra',
    symbol: 'ELE',
    logoURI: 'https://elementerra.s3.amazonaws.com/images/elementum.png',
    tags: ['community'],
    extensions: { coingeckoId: 'elementum' },
  },
  MEND: {
    address: 'Ch9NFVk5sqEPQHtw2gJVgnHfTm7FW1JspYwc7SxLi6q3',
    chainId: 101,
    decimals: 9,
    name: 'Mend',
    symbol: 'MEND',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ch9NFVk5sqEPQHtw2gJVgnHfTm7FW1JspYwc7SxLi6q3/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'mend' },
  },
  SAO: {
    address: '2HeykdKjzHKGm2LKHw8pDYwjKPiFEoXAz74dirhUgQvq',
    chainId: 101,
    decimals: 9,
    name: 'Sator',
    symbol: 'SAO',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2HeykdKjzHKGm2LKHw8pDYwjKPiFEoXAz74dirhUgQvq/Sator_Logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'sator' },
  },
  GRAF: {
    address: '9EL3CHVQS3nwUFhyVT7AGbttRsbJ5UE8Qjnw5ZAtkUhr',
    chainId: 101,
    decimals: 5,
    name: 'Smoking Giraffe',
    symbol: 'GRAF',
    logoURI:
      'https://ipfs.io/ipfs/QmedtE5ELVcMBe5jFBHojVSxftARQox6NaoEyD7cJXrj7L',
    tags: ['community'],
    extensions: { coingeckoId: 'smoking-giraffe' },
  },
  hubSOL: {
    address: 'HUBsveNpjo5pWqNkH57QzxjQASdTVXcSK7bVKTSZtcSX',
    chainId: 101,
    decimals: 9,
    name: 'SolanaHub staked SOL',
    symbol: 'hubSOL',
    logoURI: 'https://arweave.net/RI0OfNg4Ldn5RRdOp9lE60NqUmweGtJxF5N8JjU_Y0k',
    tags: ['community'],
    extensions: { coingeckoId: 'solanahub-staked-sol' },
  },
  yPRT: {
    address: 'yPRTUpLDftNej7p6QofNYgRArRXsm6Mvkzohj4bh4WM',
    chainId: 101,
    decimals: 6,
    name: 'yPRT (Parrot Yield Token)',
    symbol: 'yPRT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yPRTUpLDftNej7p6QofNYgRArRXsm6Mvkzohj4bh4WM/logo.svg',
    tags: ['old-registry', 'solana-fm'],
  },
  CHUMP: {
    address: 'HJ8WWpsheTMKwuoFkvEuhAzdqqUTgqdS7JVR37rxgnFS',
    chainId: 101,
    decimals: 9,
    name: 'Chump Change',
    symbol: 'CHUMP',
    logoURI: 'https://i.imgur.com/SoPTbDC.png',
    tags: ['community'],
    extensions: { coingeckoId: 'chump-change' },
  },
  PORT: {
    address: 'PoRTjZMPXb9T7dyU7tpLEZRQj7e6ssfAE62j2oQuc6y',
    chainId: 101,
    decimals: 6,
    name: 'Port Finance Token',
    symbol: 'PORT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PoRTjZMPXb9T7dyU7tpLEZRQj7e6ssfAE62j2oQuc6y/PORT.png',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'port-finance' },
  },
  FRN: {
    address: 'J5tzd1ww1V1qrgDUQHVCGqpmpbnEnjzGs9LAqJxwkNde',
    chainId: 101,
    decimals: 6,
    name: 'FREE ROSS NOW',
    symbol: 'FRN',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f6261666b7265696264347172646967656a68686635627576717970796c76716c653433706661756432796e6f7971326e36646a7a7064643532716d2e697066732e6e667473746f726167652e6c696e6b',
    tags: ['community', 'token-2022'],
  },
  abUSDC: {
    address: '8XSsNvaKU9FDhYWAv7Yc7qSNwuJSzVrXBNEk7AFiWF69',
    chainId: 101,
    decimals: 9,
    name: 'Wrapped USDC (Allbridge from BSC)',
    symbol: 'abUSDC',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXXkv6z8ykpG1yuvUDPgh732wzVHB69RnB9YgSYh3itW/logo.png',
    tags: ['old-registry'],
  },
  SCALES: {
    address: '8FA3TPf84h9gEZAxDhiLkMN4vvZcdEUZxmGadbo12auh',
    chainId: 101,
    decimals: 5,
    name: 'SCALES',
    symbol: 'SCALES',
    logoURI:
      'https://bafkreid7dzkdnfpjmg6lb3dfyrfa4xrvhurj5amgmdcgm254tq3gckrmra.ipfs.nftstorage.link/',
    tags: ['community', 'token-2022'],
    extensions: { coingeckoId: 'scales' },
  },
  pwrSOL: {
    address: 'pWrSoLAhue6jUxUkbWgmEy5rD9VJzkFmvfTDV5KgNuu',
    chainId: 101,
    decimals: 9,
    name: 'Power Staked SOL',
    symbol: 'pwrSOL',
    logoURI: 'https://arweave.net/vmJI1aPZNfTTIWH7ZLFxBP1VK7ptapg1hBukoDDNPME',
    tags: ['community'],
    extensions: { coingeckoId: 'power-staked-sol' },
  },
  'srenBTC-9': {
    address: 'FACTQhZBfRzC7A76antnpAoZtiwYmUfdAN8wz7e8rxC5',
    chainId: 101,
    decimals: 9,
    name: 'Saber Wrapped renBTC (9 decimals)',
    symbol: 'srenBTC-9',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FACTQhZBfRzC7A76antnpAoZtiwYmUfdAN8wz7e8rxC5/icon.png',
    tags: ['old-registry'],
  },
  WIZE: {
    address: '7rrJLRar2WjZwRoF3iJKHKnA7d7d9YJT1X9HAJnwUH3Z',
    chainId: 101,
    decimals: 6,
    name: 'Project Wisdom',
    symbol: 'WIZE',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7rrJLRar2WjZwRoF3iJKHKnA7d7d9YJT1X9HAJnwUH3Z/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  '21BTC': {
    address: '21BTCo9hWHjGYYUQQLqjLgDBxjcn8vDt4Zic7TB3UbNE',
    chainId: 101,
    decimals: 8,
    name: '21.co Wrapped Bitcoin',
    symbol: '21BTC',
    logoURI: 'https://cdn.onyx.21.co/images/tokens/21BTC.png',
    tags: ['community'],
    extensions: { coingeckoId: 'wrapped-btc-21-co' },
  },
  BOP: {
    address: 'BLwTnYKqf7u4qjgZrrsKeNs2EzWkMLqVCu6j8iHyrNA3',
    chainId: 101,
    decimals: 8,
    name: 'Boring Protocol',
    symbol: 'BOP',
    logoURI:
      'https://raw.githubusercontent.com/boringprotocol/brand-assets/main/boplogo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'boring-protocol' },
  },
  HEEHEE: {
    address: '9dLuVbJMd4ZpTpFgmaFHAGSsFwVjtcnzFWaLAA1expHg',
    chainId: 101,
    decimals: 6,
    name: 'HeeeHeee',
    symbol: 'HEEHEE',
    logoURI: '',
    tags: ['community'],
    extensions: { coingeckoId: 'heeeheee' },
  },
  FIDA: {
    address: 'EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp',
    chainId: 101,
    decimals: 6,
    name: 'Bonfida',
    symbol: 'FIDA',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp/logo.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'bonfida' },
  },
  KOKO: {
    address: 'FsA54yL49WKs7rWoGv9sUcbSGWCWV756jTD349e6H2yW',
    chainId: 101,
    decimals: 6,
    name: 'KOALA AI',
    symbol: 'KOKO',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f6261666b726569636c776579336f6634636565736b6173747573696b6a77373379626d7464647276736b79356a78617370757361786a68747975792e697066732e6e667473746f726167652e6c696e6b',
    tags: ['community'],
    extensions: { coingeckoId: 'koala-ai' },
  },
  HARAMBE: {
    address: 'CNL2opdqaQDY7yudm5V4MAaTwuuY32LTKqNP93jZM8X6',
    chainId: 101,
    decimals: 8,
    name: 'HarambeCoin (Wormhole)',
    symbol: 'HARAMBE',
    logoURI:
      'https://enu6hxxdf2rvlczerdbramwgwbowknvkamy4wlglyahtnqycw2ha.arweave.net/I2nj3uMuo1WLJIjDEDLGsF1lNqoDMcssy8APNsMCto4',
    tags: ['community'],
    extensions: { coingeckoId: 'harambecoin' },
  },
  CHADS: {
    address: 'DjzG1gPn2cotdV9cvkho4KxD3Poy2VjJeghCivv8U8Wj',
    chainId: 101,
    decimals: 2,
    name: 'Chads',
    symbol: 'CHADS',
    logoURI:
      'https://pbs.twimg.com/profile_images/1644310237896114183/c0KZkqJ3_400x400.jpg',
    tags: ['community'],
  },
  LSD: {
    address: 'DDti34vnkrCehR8fih6dTGpPuc3w8tL4XQ4QLQhc3xPa',
    chainId: 101,
    decimals: 9,
    name: 'Liquid Solana Derivative 42069',
    symbol: 'LSD',
    logoURI: 'https://arweave.net/b4cfT3yHTxVvuZc2jzmgzWUiEBiuAC9hkM7GhXZEklg',
    tags: ['community'],
    extensions: { coingeckoId: 'liquid-solana-derivative' },
  },
  'sBTC-9': {
    address: '9999j2A8sXUtHtDoQdk528oVzhaKBsXyRGZ67FKGoi7H',
    chainId: 101,
    decimals: 9,
    name: 'Saber Wrapped Bitcoin (Sollet) (9 decimals)',
    symbol: 'sBTC-9',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9999j2A8sXUtHtDoQdk528oVzhaKBsXyRGZ67FKGoi7H/icon.png',
    tags: ['old-registry'],
  },
  duk: {
    address: 'A2khRbhRJNrAEHj95htivC4cR4VbJwfssDH5FPPbP4m9',
    chainId: 101,
    decimals: 6,
    name: 'duk',
    symbol: 'duk',
    logoURI:
      'https://bafkreidfel55dm3bvwm4kx4qkutiiwmixsebetskzn7fqrxzkm7r3ilkwy.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'duk-on-sol' },
  },
  fPHX: {
    address: '6gx6Ph2ek73kF6EWDrG4GQ54pcLJB6CYpATuRyxKXumo',
    chainId: 101,
    decimals: 9,
    name: 'FillmorePHX',
    symbol: 'fPHX',
    logoURI:
      'https://gateway.irys.xyz/lUbE3hfwk7lOAeHT5ct_u2TfhBQMe7q9wFG4G9baElI',
    tags: ['community'],
  },
  Kini: {
    address: 'GV4pBbdAp1Apvf6PPFR4VvL6BtNg4E7VY91GmkHqpump',
    chainId: 101,
    decimals: 6,
    name: 'Kini Coin',
    symbol: 'Kini',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d6463574c656d5478387a4b6973526b785062366d625944344136505a376a68693262383564437243734e3854',
    tags: ['community'],
    extensions: { coingeckoId: 'kini' },
  },
  NOVA: {
    address: 'BDrL8huis6S5tpmozaAaT5zhE5A7ZBAB2jMMvpKEeF8A',
    chainId: 101,
    decimals: 9,
    name: 'NOVA FINANCE',
    symbol: 'NOVA',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDrL8huis6S5tpmozaAaT5zhE5A7ZBAB2jMMvpKEeF8A/logo.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'nova-finance' },
  },
  opple: {
    address: 'BG5Dp9gU5WbkHEaz6y95apb5NVPw3jC17M4ro27sgSXP',
    chainId: 101,
    decimals: 9,
    name: 'steep jubs',
    symbol: 'opple',
    logoURI:
      'https://bafkreig7kb2jifavh2buo4l35op6dapjuunozaiwves42qgla5pk54t7ki.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'steep-jubs' },
  },
  PEPE: {
    address: 'ox698TZsZAN2miNQj1FQb48A58FZ546c4sWqq9Y9vmU',
    chainId: 101,
    decimals: 4,
    name: 'Pepe',
    symbol: 'PEPE',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f697066732e696f2f697066732f516d6455426132464e4747543164794d6f7139457157574147696b6232316558655379626d7656786f35574a6943',
    tags: ['community'],
  },
  NUT: {
    address: '4pb6mymm9hYQN6og9uF24eyZ2qwXCWCwGvcR1DkCgeEr',
    chainId: 101,
    decimals: 6,
    name: 'NutFlex',
    symbol: 'NUT',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmPC2j7zQaeW1u8HDXWh1uWD26Q12GxVgc8nNATzk8ZLFJ',
    tags: ['community'],
    extensions: { coingeckoId: 'nutflex' },
  },
  GECKO: {
    address: '6CNHDCzD5RkvBWxxyokQQNQPjFWgoHF94D7BmC73X6ZK',
    chainId: 101,
    decimals: 6,
    name: 'Gecko',
    symbol: 'GECKO',
    logoURI:
      'https://gateway.irys.xyz/2MOw1dmYBMp7XL-JylfJBKFsDZid_1xyK4cRjGYkzUY',
    tags: ['community'],
    extensions: { coingeckoId: 'gecko-meme' },
  },
  SLRS: {
    address: 'SLRSSpSLUTP7okbCUBYStWCo1vUgyt775faPqz8HUMr',
    chainId: 101,
    decimals: 6,
    name: 'Solrise Finance',
    symbol: 'SLRS',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLRSSpSLUTP7okbCUBYStWCo1vUgyt775faPqz8HUMr/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'solrise-finance' },
  },
  BONKFA: {
    address: 'DoxsC4PpVHiUxCKYeKSkPXVVVSJYzidZZJxW4XCFF2t',
    chainId: 101,
    decimals: 6,
    name: 'Bonk of America',
    symbol: 'BONKFA',
    logoURI:
      'https://bafybeibh32qfakyjqnxl2p3zyndqmtubjcwkbiimk3qcb6v4wyzekmr5x4.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'bonk-of-america' },
  },
  NVDA: {
    address: '9dwPiStDBwJJqC3QzMnjpJP7xohZbMVmHELFx3uy3KRq',
    chainId: 101,
    decimals: 9,
    name: 'NVIDIA',
    symbol: 'NVDA',
    logoURI:
      'https://pbs.twimg.com/profile_images/1760839132958642176/nq18MRdb_400x400.jpg',
    tags: ['community'],
  },
  ELIX: {
    address: '5cbq1HriesW4zHpFEk9Gc8UT4ccmfHcBTDCa2XcBduTo',
    chainId: 101,
    decimals: 9,
    name: 'Elixir Games',
    symbol: 'ELIX',
    logoURI: 'https://arweave.net/tml1EyafVlti4pzSdA9bE11DSlowFDs3XuqA-JewtBU',
    tags: ['community'],
    extensions: { coingeckoId: 'elixir-token' },
  },
  MROW: {
    address: 'GTeZ6qkRgHS4MZq9nSPvgtFWSNLn1HFq8rx5bSVktGub',
    chainId: 101,
    decimals: 9,
    name: 'MROW',
    symbol: 'MROW',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f697066732e696f2f697066732f516d6656364276583832326f767a71736731715a4a47437a5370385a4e6e6a6f77573571553266525a73544c4751',
    tags: ['community'],
  },
  FJB: {
    address: 'PRZdvhsihALAYQAwCaQYCSVTbkxfT9Fme28p19Np9VD',
    chainId: 101,
    decimals: 6,
    name: 'FJB',
    symbol: 'FJB',
    logoURI:
      'https://bafkreiheaju77mgrvkhh65owh6k3hfvponljao4gzwz6maim4fk2gqacly.ipfs.nftstorage.link/',
    tags: ['community', 'token-2022'],
  },
  COME: {
    address: 'GmW12mAzyTj897Y3pgxDQzpnNid7q58E8T7V56rmaUdD',
    chainId: 101,
    decimals: 6,
    name: 'Community of Meme',
    symbol: 'COME',
    logoURI:
      'https://photos.pinksale.finance/file/pinksale-logo-upload/1710895928245-3b82d54c6607c34a0499ee540316e572.png',
    tags: ['community'],
    extensions: { coingeckoId: 'community-of-meme' },
  },
  HOWDY: {
    address: 'ppVT3Vqb323UBEW3QuTvdNCpQm1spEZM8Bt1tv9WALW',
    chainId: 101,
    decimals: 6,
    name: 'Howdy',
    symbol: 'HOWDY',
    logoURI:
      'https://bafkreienmgbxxe6zqirt6th7hglhnf6nfo6easvdppvzb3vigdokjmrkcy.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'howdysol' },
  },
  VCAT: {
    address: 'VP9UaBXLM4KYRvyjisu65rz8BU5xNAbewU7LVmyU2x4',
    chainId: 101,
    decimals: 9,
    name: 'Vibing Cat',
    symbol: 'VCAT',
    logoURI:
      'https://bafybeial7nesuvpbffl6mvptgf33abr762iojb634ig3p6o6cdo2mlwe6a.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'vibing-cat' },
  },
  UGM: {
    address: '2Ey5sa3XTtjaR6KGaVmX48MUtUAmrBT14JeWjQWJcc4h',
    chainId: 101,
    decimals: 6,
    name: 'USER GENERATED MONEY',
    symbol: 'UGM',
    logoURI:
      'https://ipfs.io/ipfs/QmNuaQUJHG7h4njrC7isz5mbxnVxccFMrmw3uuLrNNTo6F',
    tags: ['community'],
  },
  DEV: {
    address: 'H5euuuZXAuFak2NVTMu53fckdkHFWuJzXXb3TfKTrLWK',
    chainId: 101,
    decimals: 9,
    name: 'DevCoin',
    symbol: 'DEV',
    logoURI:
      'https://raw.githubusercontent.com/thedevdojo/devcoin/main/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  Mail: {
    address: 'C8cNX2D1y3jqKpMFkQhP1gGbfvTEdeckZXLBKSN5z5KF',
    chainId: 101,
    decimals: 6,
    name: 'SolMail',
    symbol: 'Mail',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmcAtxKADTdcZvZxZj3Gkc5YR4Ket1TDodrwzhHt49u3Bn',
    tags: ['community'],
    extensions: { coingeckoId: 'solmail' },
  },
  $daumen: {
    address: 'T1oYbAejEESrZLtSAjumAXhzFqZGNxQ4kVN9vPUoxMv',
    chainId: 101,
    decimals: 9,
    name: 'Daumenfrosch',
    symbol: '$daumen',
    logoURI:
      'https://bafkreia2rq6bzgsq2jw2of54xv6hflj6zvkiw3zba2unmlomo2czhquqqy.ipfs.nftstorage.link',
    tags: ['community'],
  },
  BULLY: {
    address: '7qnTXCm7ZVMLdqNWzhfSm3RLKk8MrX3dYavB4hqJv5F9',
    chainId: 101,
    decimals: 9,
    name: 'Bully',
    symbol: 'BULLY',
    logoURI: 'https://i.imgur.com/ChfVh16.jpeg',
    tags: ['community'],
    extensions: { coingeckoId: 'bullysoltoken' },
  },
  MIGO: {
    address: '8odnqwCqNVQ4UrYmYgkWPuedBr3yEMWY2ZBhtNW7jrCu',
    chainId: 101,
    decimals: 6,
    name: 'AmigosOnSol',
    symbol: 'MIGO',
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://bafybeiflobot335eygnhpjqlbo3u2vptijnqw6mwspl3p25habyz3k6zsq.ipfs.nftstorage.link',
    tags: ['community'],
  },
  PATS: {
    address: '35fN6LMYt6cKsemgbR28nFooiJtcnvaKPCeRXyuMKfoF',
    chainId: 101,
    decimals: 6,
    name: 'PATS',
    symbol: 'PATS',
    logoURI:
      'https://lavender-gentle-primate-223.mypinata.cloud/ipfs/QmPUtgdVaYqPRUvaygwN8dPd8eqyReZDMUdiqusXWHMwBy?pinataGatewayToken=M45Jh03NicrVqTZJJhQIwDtl7G6fGS90bjJiIQrmyaQXC_xXj4BgRqjjBNyGV7q2',
    tags: ['community'],
    extensions: { coingeckoId: 'pats' },
  },
  DRAW: {
    address: '48AEwauAHsJibyt3WqjQ6EoHnFBcnyHASfo7vB2eCXPS',
    chainId: 101,
    decimals: 0,
    name: 'DragonWar',
    symbol: 'DRAW',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/48AEwauAHsJibyt3WqjQ6EoHnFBcnyHASfo7vB2eCXPS/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'dragon-war' },
  },
  $WAFFLES: {
    address: '8doS8nzmgVZEaACxALkbK5fZtw4UuoRp4Yt8NEaXfDMb',
    chainId: 101,
    decimals: 6,
    name: 'WAFFLES',
    symbol: '$WAFFLES',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmNykfpiTcSM692rpcvfSs1KczefHbUthAmc6xKqy4r9db',
    tags: ['community'],
    extensions: { coingeckoId: 'waffles' },
  },
  LUNA: {
    address: 'F6v4wfAdJB8D8p77bMXZgYt8TDKsYxLYxH5AFhUkYx9W',
    chainId: 101,
    decimals: 6,
    name: 'LUNA (Portal)',
    symbol: 'LUNA',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/LUNA_wh.png',
    tags: ['wormhole', 'old-registry'],
    extensions: { coingeckoId: 'luna-wormhole' },
  },
  MOTION: {
    address: 'Uiupt55owCqBJdunYQxyYf48KRMU4F53xQAyf31dpzx',
    chainId: 101,
    decimals: 6,
    name: 'Motion',
    symbol: 'MOTION',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmUooALuMhRZrV2hSUWxHA76TrnVtPkET9EA86xo1bJbX5',
    tags: ['community'],
    extensions: { coingeckoId: 'motion-coin' },
  },
  SLX: {
    address: 'AASdD9rAefJ4PP7iM89MYUsQEyCQwvBofhceZUGDh5HZ',
    chainId: 101,
    decimals: 9,
    name: 'Solex Finance',
    symbol: 'SLX',
    logoURI:
      'https://raw.githubusercontent.com/solexfin/token-list/main/assets/mainnet/AASdD9rAefJ4PP7iM89MYUsQEyCQwvBofhceZUGDh5HZ/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'solex-finance' },
  },
  KAMA: {
    address: 'HnKkzR1YtFbUUxM6g3iVRS2RY68KHhGV7bNdfF1GCsJB',
    chainId: 101,
    decimals: 9,
    name: 'Kamala Horris',
    symbol: 'KAMA',
    logoURI:
      'https://ipfs.io/ipfs/QmdJNxUnG6CoavHwCeVHLgCyzLnunL2ChVKr6GqkZoRhbT',
    tags: ['community'],
    extensions: { coingeckoId: 'kamala-horris' },
  },
  RHO: {
    address: 'Trhor7npQLca4DFiUWR9vJCAw1je2zghSbwh37nW81i',
    chainId: 101,
    decimals: 6,
    name: 'RHO',
    symbol: 'RHO',
    logoURI:
      'https://nftstorage.link/ipfs/bafkreif5ri7om5uezpbiqyx7xo74mxwoth5lsc2l4h6yki3aqlcmhsxkwe',
    tags: ['community', 'token-2022'],
    extensions: { coingeckoId: 'rho' },
  },
  acUSDC: {
    address: 'DHpoYejUDqzByb6HAdaLWF7KZvwUv2vWYDY9cTENNZui',
    chainId: 101,
    decimals: 9,
    name: 'Wrapped USDC (Allbridge from Celo)',
    symbol: 'acUSDC',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png',
    tags: ['old-registry'],
  },
  NATIX: {
    address: 'FRySi8LPkuByB7VPSCCggxpewFUeeJiwEGRKKuhwpKcX',
    chainId: 101,
    decimals: 6,
    name: 'NATIX Network',
    symbol: 'NATIX',
    logoURI:
      'https://lp5te7kfxvf6tsuwzmt5iizam6h6xxws5qrmnnqvcspbweesxpiq.arweave.net/W_syfUW9S-nKlssn1CMgZ4_r3tLsIsa2FRSeGxCSu9E',
    tags: ['community'],
    extensions: { coingeckoId: 'natix-network' },
  },
  JJJJC: {
    address: '6FLsSkF4AqQeDuqEmGPyBZvVebc4WWWntnZP2QZyBzG8',
    chainId: 101,
    decimals: 9,
    name: 'JJC',
    symbol: 'JJJJC',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6FLsSkF4AqQeDuqEmGPyBZvVebc4WWWntnZP2QZyBzG8/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  GUMMY: {
    address: 'H7bTHGb5Cvo5fGe5jBDNDPUv8KykQnzyZA3qZ8sH7yxw',
    chainId: 101,
    decimals: 9,
    name: 'GUMMY',
    symbol: 'GUMMY',
    logoURI:
      'https://bafkreih3pz2wklsnoae5zuyfvascdm3phyffimvyxbsi34rjnqzkjldopu.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'gummy' },
  },
  CHILI: {
    address: 'GPyzPHuFFGvN4yWWixt6TYUtDG49gfMdFFi2iniTmCkh',
    chainId: 101,
    decimals: 2,
    name: 'CHILI',
    symbol: 'CHILI',
    logoURI: 'https://iili.io/H5O87JS.png',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'chili' },
  },
  NCTR: {
    address: 'AgnHzGspNu7F3nFM4izuPt5g7m1URjVaTaFNgvqSXcjC',
    chainId: 101,
    decimals: 9,
    name: 'Nectar',
    symbol: 'NCTR',
    logoURI:
      'https://gateway.irys.xyz/dzNRpOihzq7F4MxL2J_WZbhRF_TOP0_qGz9Ea-312gs',
    tags: ['community'],
    extensions: { coingeckoId: 'dust-city-nectar' },
  },
  BDROP: {
    address: 'DcJLACAUR25RujgxBVhZtcWPCTjzSw6YDM8E7oipiT3k',
    chainId: 101,
    decimals: 6,
    name: 'BlockDrop',
    symbol: 'BDROP',
    logoURI:
      'https://bafkreibhvlkqakgp5a7q2n2xjnsnyrncgeyxia74crf3ytqhh6wfpofuuq.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'blockdrop' },
  },
  USDCbs: {
    address: 'FCqfQSujuPxy6V42UvafBhsysWtEq1vhjfMN1PUbgaxA',
    chainId: 101,
    decimals: 8,
    name: 'USD Coin (Portal from BSC)',
    symbol: 'USDCbs',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/USDCbs_wh.png',
    tags: ['wormhole', 'old-registry'],
  },
  N2H4: {
    address: '4q5UBXJxE91BZKX548qhU8i5QBWvZdXzS3RZwfTgLQda',
    chainId: 101,
    decimals: 6,
    name: 'Hydrazine',
    symbol: 'N2H4',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4q5UBXJxE91BZKX548qhU8i5QBWvZdXzS3RZwfTgLQda/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  ZBC: {
    address: 'zebeczgi5fSEtbpfQKVZKCJ3WgYXxjkMUkNNx7fLKAF',
    chainId: 101,
    decimals: 9,
    name: 'ZEBEC',
    symbol: 'ZBC',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/zebeczgi5fSEtbpfQKVZKCJ3WgYXxjkMUkNNx7fLKAF/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'zebec-protocol' },
  },
  $TOAD: {
    address: 'FViMp5phQH2bX81S7Yyn1yXjj3BRddFBNcMCbTH8FCze',
    chainId: 101,
    decimals: 4,
    name: 'Toad',
    symbol: '$TOAD',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f676174657761792e697279732e78797a2f38456251475f59645759334373385f71696c5230507061775170344f45696f7a51366d69777a4d76755949',
    tags: ['community'],
    extensions: { coingeckoId: 'toad-sol' },
  },
  AI: {
    address: '99ouK5YUK3JPGCPX9joNtHsMU7NPpU7w91JN4kdQ97po',
    chainId: 101,
    decimals: 9,
    name: 'AI',
    symbol: 'AI',
    logoURI:
      'https://gateway.irys.xyz/HnDwG54RZvJhTD2PX-mmrD2jwC9MIdxKjW2hL3M8jQc',
    tags: ['community'],
    extensions: { coingeckoId: 'ai-community' },
  },
  xSOL: {
    address: 'B5GgNAZQDN8vPrQ15jPrXmJxVtManHLqHogj9B9i4zSs',
    chainId: 101,
    decimals: 9,
    name: 'ElagabalX Staked SOL',
    symbol: 'xSOL',
    logoURI:
      'https://shdw-drive.genesysgo.net/FfXMHMFwXLHkGZDVCgBzFFAP7eT7Jd23SzAXcUXsH8j4/xSOL.svg',
    tags: ['community'],
  },
  $SCOT: {
    address: 'EoJEyppNNpycP1ZoPnWq6cxwA7mSYpr9T1WXPFyjEzEy',
    chainId: 101,
    decimals: 6,
    name: 'Scottish',
    symbol: '$SCOT',
    logoURI:
      'https://bafybeicd2glggyndv23phiemxco5f3cxsvlgm2a66vrvlthvgmk4loqvra.ipfs.nftstorage.link/%D7%97%D7%AA%D7%95%D7%9C.png',
    tags: ['community'],
    extensions: { coingeckoId: 'scottish' },
  },
  BEACH: {
    address: 'Ho2FQgg65oM1zpYuEnC8fULpBnWtqRCTrXRP56AeyCci',
    chainId: 101,
    decimals: 9,
    name: 'Solana Beach',
    symbol: 'BEACH',
    logoURI: 'https://i.postimg.cc/YC2WSK7V/beachball.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'solana-beach' },
  },
  arab: {
    address: 'BFpchrNVhyTRzMNAg9QkiZfRN2vqRBwcYoTX8qgkbDvm',
    chainId: 101,
    decimals: 9,
    name: 'Arab Cat',
    symbol: 'arab',
    logoURI:
      'https://assets.coingecko.com/coins/images/34845/standard/arab_cat_cg.png',
    tags: ['community'],
    extensions: { coingeckoId: 'arab-cat' },
  },
  WISDM: {
    address: '53ctv3wwFXQbXruKWsbQcCe7sefowyu96pXK6FRLTjfv',
    chainId: 101,
    decimals: 4,
    name: 'Dog Of Wisdom',
    symbol: 'WISDM',
    logoURI:
      'https://gateway.irys.xyz/aUIj5AIANJ9w7TIWSCDIws1UBtewzaz_ZRhDF5GbljI',
    tags: ['community'],
    extensions: { coingeckoId: 'dog-of-wisdom' },
  },
  Cheng: {
    address: 'BEsnQvkfeaQ3G8qbeN5FmUnsQUqXzXW5i83Hv3syoTTc',
    chainId: 101,
    decimals: 6,
    name: 'Chengshi',
    symbol: 'Cheng',
    logoURI:
      'https://ipfs.io/ipfs/QmVQbVAYZddB1kEq9a5Tj1ZPQLvsaV2pqQ2nCRK3MgW65w',
    tags: ['community'],
    extensions: { coingeckoId: 'chengshi' },
  },
  boden: {
    address: '3psH1Mj1f7yUfaD5gh6Zj7epE8hhrMkMETgv5TshQA4o',
    chainId: 101,
    decimals: 9,
    name: 'jeo boden',
    symbol: 'boden',
    logoURI:
      'https://bafkreid2t4f3i36tq4aowwaaa5633ggslefthxfdudaimog6unwu36umha.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'jeo-boden' },
  },
  soALEPH: {
    address: 'CsZ5LZkDS7h9TDKjrbL7VAwQZ9nsRu8vJLhRYfmGaN8K',
    chainId: 101,
    decimals: 6,
    name: 'Wrapped ALEPH (Sollet)',
    symbol: 'soALEPH',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CsZ5LZkDS7h9TDKjrbL7VAwQZ9nsRu8vJLhRYfmGaN8K/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  MEREDITH: {
    address: '7y3mLy8vai6uMt4iVfgm1gN5GRpQ4aMqtMJgM6oLuoeM',
    chainId: 101,
    decimals: 6,
    name: "Taylor Swift's Cat MEREDITH",
    symbol: 'MEREDITH',
    logoURI:
      'https://bafybeihrvcci6jedeqvm4exmqti4mlndo5heew5x7l7aczfkh2walpwuk4.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  RAi: {
    address: 'RaiuuHKrphE2jENyANz37mcTquwmwBqdnAiR881aEBZ',
    chainId: 101,
    decimals: 9,
    name: 'RealmAi',
    symbol: 'RAi',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f6261667962656962346f70366177666878696d347572346a706c6e77726a63616d787165627a37327966713236626f736c3378706f373474366f612e697066732e7733732e6c696e6b2f5265616c6d41692d6c6f676f2e706e67',
    tags: ['community', 'token-2022'],
  },
  MOONAK: {
    address: 'A8iqgLj9bmqb2JM8umkHj7B7AGriD1EKLZHVRGA2VcNP',
    chainId: 101,
    decimals: 6,
    name: 'RISHI MOONAK',
    symbol: 'MOONAK',
    logoURI:
      'https://bafkreifbuaznsv5g4qu2i3aimlcn6eooeuslhhnptqt6wyjlqhq7helz7m.ipfs.nftstorage.link',
    tags: ['community'],
  },
  WAM: {
    address: '7hdeo5QciUF8S2vfsx6uRJkdNVADBU3DDcXW4zjDcMin',
    chainId: 101,
    decimals: 8,
    name: 'WAM (Wormhole)',
    symbol: 'WAM',
    logoURI:
      'https://bafkreib4rpt2mttfg75fvvsgg3g725chzzpkqz4ci5kccryiaqusl5suei.ipfs.nftstorage.link',
    tags: ['community'],
  },
  MODUL: {
    address: 'A98UDy7z8MfmWnTQt6cKjje7UfqV3pTLf4yEbuwL2HrH',
    chainId: 101,
    decimals: 9,
    name: 'Modul',
    symbol: 'MODUL',
    logoURI:
      'https://raw.githubusercontent.com/parametrise/ParametriseLogo/main/Modul_Logomark_White_Pinky_Background.png',
    tags: ['old-registry', 'solana-fm'],
  },
  MOAR: {
    address: '76gDMbyohg6yHy6XJ2Rn9kxC22Q6v6uYpPXxkiwR5ota',
    chainId: 101,
    decimals: 6,
    name: 'MOAR GAINZ',
    symbol: 'MOAR',
    logoURI:
      'https://bafkreidv3a37niwg56nytrh2dijfs3vqzebedww6dt5fp2kr46pst7kyui.ipfs.nftstorage.link',
    tags: ['community', 'token-2022'],
  },
  DWH: {
    address: 'DEVwHJ57QMPPArD2CyjboMbdWvjEMjXRigYpaUNDTD7o',
    chainId: 101,
    decimals: 6,
    name: 'DevWifHat',
    symbol: 'DWH',
    logoURI: 'https://arweave.net/8axP5eu3H87THPNK3oWV0dJ_-z1zoZGhp-V8LgJ3IBU',
    tags: ['community'],
  },
  BOTS: {
    address: 'HDEqEpFgTrBawzDgTG1eyH8Go9PX84LCEC8Qjt8T4jFN',
    chainId: 101,
    decimals: 2,
    name: 'Skullbot Biker Gang',
    symbol: 'BOTS',
    logoURI:
      'https://cloudflare-ipfs.com/ipfs/bafybeifbl3x5iluraez46db4rdqdgay2z34s2bmqaydeszkej36xylljki/151.png',
    tags: ['community', 'solana-fm'],
  },
  CONUR: {
    address: '7yqC6J7qxuRzWg1xHHBmcTLG6X1K7Y5DBYB49Ks8nGKf',
    chainId: 101,
    decimals: 6,
    name: 'the keng is beck',
    symbol: 'CONUR',
    logoURI:
      'https://bafkreia3bcw6az6avol5rrnkvb5gf6zm4yi252lket7p5ljowmgfb4vt4e.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  ABR: {
    address: 'a11bdAAuV8iB2fu7X6AxAvDTo1QZ8FXB3kk5eecdasp',
    chainId: 101,
    decimals: 9,
    name: 'Allbridge',
    symbol: 'ABR',
    logoURI:
      'https://raw.githubusercontent.com/allbridge-io/media/main/token.svg',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'allbridge' },
  },
  EDUST: {
    address: '21kbezaCxz9dG2NtXp7pW1hAmtKe6ykiJkeT1EiGXDyY',
    chainId: 101,
    decimals: 9,
    name: 'Exiled Dust Protocol',
    symbol: 'EDUST',
    logoURI: 'https://i.imgur.com/KPPBoDB.png',
    tags: ['community'],
  },
  $POINTS: {
    address: '9cX8hMxZ2vW7pxYEPf2G5UHrcmMx83iTgGcxwwRKdarq',
    chainId: 101,
    decimals: 9,
    name: '$POINTS',
    symbol: '$POINTS',
    logoURI:
      'https://bafkreia7w7auilyxmky3ajrozqsdgexi42kakax3iueinz4asyyfumilky.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'points-on-solana' },
  },
  HELLO: {
    address: '4h49hPGphLNJNDRyiBwzvKoasR3rw1WJCEv19PhUbSS4',
    chainId: 101,
    decimals: 9,
    name: 'Hello Token',
    symbol: 'HELLO',
    logoURI:
      'https://raw.githubusercontent.com/Hello1Official/assets/main/internal/assets/images/coin-logo-512x.png',
    tags: ['community'],
    extensions: { coingeckoId: 'hello-labs' },
  },
  PSG: {
    address: 'DM8YLBujra6arvKuZ23d4ukqLdzbiGuhyWM4dUZnTnFA',
    chainId: 101,
    decimals: 2,
    name: 'Piggy Sol Gang',
    symbol: 'PSG',
    logoURI:
      'https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://cdn.piggygang.com/imgs/4a9e3480c4230ee0efb2a408fab74d91.jpg',
    tags: ['community'],
  },
  DGNA: {
    address: '8iSagwHZNj4Hx4CMeoZwLLMVbWt4mUT6qk42TxiHkRtn',
    chainId: 101,
    decimals: 6,
    name: 'DegenAlley',
    symbol: 'DGNA',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8iSagwHZNj4Hx4CMeoZwLLMVbWt4mUT6qk42TxiHkRtn/DGNA.png',
    tags: ['old-registry'],
  },
  TopG: {
    address: '2kARUpcjqKJdSTgZQKrzABLKbjvi2hNADYwHq7z52gGq',
    chainId: 101,
    decimals: 9,
    name: 'Andwu Tet',
    symbol: 'TopG',
    logoURI:
      'https://bafkreifktm2esm67rvurcqhnofh54ipnnqti2mzy3b2yfg65b2577yhrxi.ipfs.nftstorage.link',
    tags: ['community'],
  },
  capri: {
    address: 'JD3S1oqnjG5trRTZXpLmKnS7LsKsppyFa51rPBBMWogv',
    chainId: 101,
    decimals: 6,
    name: 'Caprisun Monkey',
    symbol: 'capri',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d4e68666b674636436b7634706964367a6d7636586b59744346655854507a70413651774267616f4172685138',
    tags: ['community'],
    extensions: { coingeckoId: 'caprisun-monkey' },
  },
  xUSD: {
    address: '83LGLCm7QKpYZbX8q4W2kYWbtt8NJBwbVwEepzkVnJ9y',
    chainId: 101,
    decimals: 6,
    name: 'Synthetic USD',
    symbol: 'xUSD',
    logoURI: 'https://www.synthetify.io/icons/xusd.svg',
    tags: ['old-registry'],
  },
  BABYTRUMP: {
    address: '6NbnHQKD2dh4jswTLmMCP7LnSh4Nh6y2cNgdQg2ny9zW',
    chainId: 101,
    decimals: 8,
    name: 'BABYTRUMP (Wormhole)',
    symbol: 'BABYTRUMP',
    logoURI:
      'https://ipfs.io/ipfs/QmZNnB88oNHtdL8BxsPk56FcC1t5jAXAXBp52DziJJGUc9',
    tags: ['community'],
    extensions: { coingeckoId: 'babytrump' },
  },
  SOLMEME: {
    address: '5MRMqvLZyRQhrMn2a8vSL3Kv9vfjNhjRKRPHtTBz1VEB',
    chainId: 101,
    decimals: 7,
    name: 'Solana Meme',
    symbol: 'SOLMEME',
    logoURI:
      'https://raw.githubusercontent.com/solmemetoken/solememe/main/logo.png',
    tags: ['community'],
  },
  OBEMA: {
    address: 'CzkM8bzWFdXsjtZQnz2piTxJKPtJ5mfTL8S6sNZg7n7S',
    chainId: 101,
    decimals: 2,
    name: 'Mechelle Obema',
    symbol: 'OBEMA',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f676174657761792e697279732e78797a2f7a44675f654467464256466649546157733550653533496664414665336c502d2d644479344967492d4949',
    tags: ['community'],
    extensions: { coingeckoId: 'obema' },
  },
  ELIXIR: {
    address: 'ELXRYrf8wd4DcyXDU9QPnMdD8jn2twg7o7qEtf5z2GBW',
    chainId: 101,
    decimals: 9,
    name: 'ELIXIR',
    symbol: 'ELIXIR',
    logoURI: 'https://raw.githubusercontent.com/zaptors/image/main/elixir.png',
    tags: ['old-registry', 'solana-fm'],
  },
  NICK: {
    address: 'G3q2zUkuxDCXMnhdBPujjPHPw9UTMDbXqzcc2UHM3jiy',
    chainId: 101,
    decimals: 6,
    name: 'I CHOOSE RICH EVERYTIME!',
    symbol: 'NICK',
    logoURI:
      'https://bafkreigzternd6pcuni3d56amd5vrilsfp66jtg3nfdpafli6byz4nljhi.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'i-choose-rich-everytime' },
  },
  WUPHF: {
    address: '8UFSHiHmiqyecJtYUtDDuP8ZVyNfGovj7qgPtLsaP9gh',
    chainId: 101,
    decimals: 9,
    name: 'WUPHF',
    symbol: 'WUPHF',
    logoURI:
      'https://bafybeigqvnqbugkvrtmiqsx7dvqdkd7xp3mow565kspi6rah3ctkrpfksy.ipfs.nftstorage.link',
    tags: ['community'],
  },
  YIKES: {
    address: 'Ce3dRaePi2PrcsHb45i8qcaeCpHacvjXbbzo2DTPfX8z',
    chainId: 101,
    decimals: 9,
    name: 'Yikes Dog',
    symbol: 'YIKES',
    logoURI:
      'https://ipfs.io/ipfs/QmYGSVcj5Au1PsUKXC3bSZnAe1xk5psY2xvWYrz3QyrpzB',
    tags: ['community'],
    extensions: { coingeckoId: 'yikes-dog' },
  },
  WOOOO: {
    address: 'JCqsyVajfHBGjNWNmLT6Cfx1Vn9f3mRibtNSdUsVRWS5',
    chainId: 101,
    decimals: 6,
    name: 'Enrique Flair',
    symbol: 'WOOOO',
    logoURI:
      'https://ipfs.io/ipfs/QmPjCNqjV8kEqzXp3wWhzDeN32iU9corqgXedPrcAjmvrx',
    tags: ['community'],
  },
  PRANA: {
    address: 'PRAxfbouRoJ9yZqhyejEAH6RvjJ86Y82vfiZTBSM3xG',
    chainId: 101,
    decimals: 6,
    name: 'prANA',
    symbol: 'PRANA',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PRAxfbouRoJ9yZqhyejEAH6RvjJ86Y82vfiZTBSM3xG/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'nirvana-prana' },
  },
  $PELF: {
    address: 'BgJW7U1u2RY5XJk9uYb5AqFRzjMtqE7pw3kaf9iw9Ntz',
    chainId: 101,
    decimals: 6,
    name: 'PELFORT',
    symbol: '$PELF',
    logoURI:
      'https://raw.githubusercontent.com/yamitora0/pelfort/main/logo.png',
    tags: ['community'],
    extensions: { coingeckoId: 'pelfort' },
  },
  OXS: {
    address: '4TGxgCSJQx2GQk9oHZ8dC5m3JNXTYZHjXumKAW3vLnNx',
    chainId: 101,
    decimals: 9,
    name: 'Oxbull Sol',
    symbol: 'OXS',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4TGxgCSJQx2GQk9oHZ8dC5m3JNXTYZHjXumKAW3vLnNx/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  POLIS: {
    address: 'poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk',
    chainId: 101,
    decimals: 8,
    name: 'Star Atlas DAO',
    symbol: 'POLIS',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'star-atlas-dao' },
  },
  DVINCI: {
    address: '5jFJCvNgg8ytGWBygoquaUC6bMZyr7C5jmGHECBzrxR5',
    chainId: 101,
    decimals: 6,
    name: 'Davinci Jeremie',
    symbol: 'DVINCI',
    logoURI:
      'https://bafkreihbx7trnvsdgd5gi7gzldasjcimedtnmuqpnhgen2c6vkxw44u7ky.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'davinci-jeremie' },
  },
  CIRCLE: {
    address: 'EkHr62PC6Y1axrLS7cR8YC4BZeW19mtHxQLCLMrf9vnq',
    chainId: 101,
    decimals: 3,
    name: 'You Looked',
    symbol: 'CIRCLE',
    logoURI: 'https://i.imgur.com/fEFVS51.png',
    tags: ['community'],
    extensions: { coingeckoId: 'you-looked' },
  },
  LUX: {
    address: 'LUX88ZHPnte7tThA4F2nnXDCZWE3G61TqLBvFw7i8SM',
    chainId: 101,
    decimals: 9,
    name: 'LUX Token',
    symbol: 'LUX',
    logoURI:
      'https://node1.irys.xyz/5SOEbPheL3FXZpm2EGo7zJrz6QHM6h6-qotrLB5HsGo',
    tags: ['community'],
  },
  Hobbes: {
    address: '6n7Janary9fqzxKaJVrhL9TG2F61VbAtwUMu1YZscaQS',
    chainId: 101,
    decimals: 6,
    name: "Ansem's Cat",
    symbol: 'Hobbes',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmccykK3PtscEH8QpkR54CStmcfpazK4nrf8t167gqML99',
    tags: ['community'],
    extensions: { coingeckoId: 'ansem-s-cat' },
  },
  TORI: {
    address: 'D8F1FvrUhwg8WBjMqABopFYo13WwymGnVhsL3d7dRexP',
    chainId: 101,
    decimals: 5,
    name: 'Tori the Cat',
    symbol: 'TORI',
    logoURI:
      'https://bafybeia3p3zgz5j2fynyjle6p53bfacquh7psqaxixiln6qzwcfxxcn76m.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'tori-the-cat' },
  },
  NOTE: {
    address: 'CgbcLNUZP7jWmFeXtaKmABRFXDhxejkeUH94JLu3Lcvk',
    chainId: 101,
    decimals: 6,
    name: 'SolNote',
    symbol: 'NOTE',
    logoURI:
      'https://photos.pinksale.finance/file/pinksale-logo-upload/1712198199126-d88f47e7da4981f1370974ff2b602a2b.png',
    tags: ['community'],
    extensions: { coingeckoId: 'solnote' },
  },
  POTATO: {
    address: 'GEYrotdkRitGUK5UMv3aMttEhVAZLhRJMcG82zKYsaWB',
    chainId: 101,
    decimals: 3,
    name: 'POTATO',
    symbol: 'POTATO',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GEYrotdkRitGUK5UMv3aMttEhVAZLhRJMcG82zKYsaWB/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'potato' },
  },
  FORGE: {
    address: 'FoRGERiW7odcCBGU1bztZi16osPBHjxharvDathL5eds',
    chainId: 101,
    decimals: 9,
    name: 'FORGE',
    symbol: 'FORGE',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FoRGERiW7odcCBGU1bztZi16osPBHjxharvDathL5eds/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'blocksmith-labs-forge' },
  },
  HMTR: {
    address: '7JhmUcZrrfhyt5nTSu3AfsrUq2L9992a7AhwdSDxdoL2',
    chainId: 101,
    decimals: 0,
    name: 'HampterFi',
    symbol: 'HMTR',
    logoURI: 'https://arweave.net/A6llvg1Lo9SXQYC4RQXVrTfwJ-eZpLl4_eZhJVGQfOg',
    tags: ['community'],
  },
  TINY: {
    address: 'HKfs24UEDQpHS5hUyKYkHd9q7GY5UQ679q2bokeL2whu',
    chainId: 101,
    decimals: 6,
    name: 'TinyBits',
    symbol: 'TINY',
    logoURI: 'https://tinycolony.io/tinycolonytoken.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'tiny-colony' },
  },
  iceSOL: {
    address: 'iceSdwqztAQFuH6En49HWwMxwthKMnGzLFQcMN3Bqhj',
    chainId: 101,
    decimals: 9,
    name: 'iceSOL',
    symbol: 'iceSOL',
    logoURI:
      'https://xsbf3mqssacc6xatyv27ornaiylcu5rw7qpbkciazgh6xwbpabja.arweave.net/vIJdshKQBC9cE8V190WgRhYqdjb8HhUJAMmP69gvAFI',
    tags: ['community'],
  },
  PHTEVE: {
    address: 'DhRQWsiP53gVXnG8KQwq63Uo1FvouRpVNL8536tsVD5H',
    chainId: 101,
    decimals: 8,
    name: 'Phteven',
    symbol: 'PHTEVE',
    logoURI:
      'https://wakodvrjrzqvc3fkaymxuogqxwh2x3pnaqarada6hczh6l7tx4xq.arweave.net/sBTh1imOYVFsqgYZejjQvY-r7e0EARAMHjiyfy_zvy8',
    tags: ['community'],
    extensions: { coingeckoId: 'phteven' },
  },
  QUIL: {
    address: 'BzW7Gnm32KTn82a1yCiYFooBLzi37MvEXFF8soQVpump',
    chainId: 101,
    decimals: 6,
    name: 'Quilson',
    symbol: 'QUIL',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d59625562754c706a77465a4e4335487a70624b417066486762566d6a375a6755696132463556464c635a7375',
    tags: ['community'],
    extensions: { coingeckoId: 'quilson' },
  },
  '1SOL': {
    address: '4ThReWAbAVZjNVgs5Ui9Pk3cZ5TYaD9u6Y89fp6EFzoF',
    chainId: 101,
    decimals: 8,
    name: '1sol.io (Portal)',
    symbol: '1SOL',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/1SOL_wh.png',
    tags: ['wormhole', 'old-registry'],
  },
  ZIG: {
    address: 'ANqY8h3sRSwkC29FvGJenAh7VGRABVVx7Ls6Mq4BuGT',
    chainId: 101,
    decimals: 9,
    name: 'ZIG Coin',
    symbol: 'ZIG',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ANqY8h3sRSwkC29FvGJenAh7VGRABVVx7Ls6Mq4BuGT/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  BOOMER: {
    address: '7jmaTFBooHkaSrBJDftu3LcK85KPtqWTCaFZCDxQV7ZW',
    chainId: 101,
    decimals: 6,
    name: 'Boomers on Sol',
    symbol: 'BOOMER',
    logoURI:
      'https://bafybeicyv3sn4btptwdgptm3cx47xv4nrr7mpnq6kikxwfi5qrvbo4qiiy.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'boomers-on-sol' },
  },
  SUBO: {
    address: 'HtpMfWQELCfCCeG6nHQDPfxLPrr2BNkZ2YVa9hKqpump',
    chainId: 101,
    decimals: 6,
    name: 'SUBO',
    symbol: 'SUBO',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmdZqUYNg3S2LHQwaTHCSC4LsFiw5dwU7rf6JXLpKBsSUv',
    tags: ['community'],
  },
  VC: {
    address: 'AebrVZPfSH7KPAxPwnuqTZB9QNepdktk7HSSY4SNj7BM',
    chainId: 101,
    decimals: 9,
    name: 'Venture Coin',
    symbol: 'VC',
    logoURI: 'https://arweave.net/6HvW8RLbLhBbw09QDRpPd6HX-N3Sk9kF2vvdaZGHExU',
    tags: ['community'],
    extensions: { coingeckoId: 'venture-coin-2' },
  },
  SIUUU: {
    address: 'BQ74oddoJCJKz9W4QaDzLFh1JvLbmXRCvdaC65P7anex',
    chainId: 101,
    decimals: 6,
    name: 'crustieno renaldo',
    symbol: 'SIUUU',
    logoURI: 'https://i.ibb.co/wKQYQgX/11.png',
    tags: ['community'],
  },
  FAP: {
    address: 'D1YaKkQRaQQg6sPUtDiWyskppzfgMV51ovcagVU436TX',
    chainId: 101,
    decimals: 9,
    name: 'FAP',
    symbol: 'FAP',
    logoURI:
      'https://bafkreifgtuuai7s54fcejzzsyzyxlwpyinazbsxwnce5ikdmeqbar22glm.ipfs.nftstorage.link',
    tags: ['community'],
  },
  BOOTY: {
    address: 'bootyAfCh1eSQeKhFaDjN9Pu6zwPmAoQPoJWVuPasjJ',
    chainId: 101,
    decimals: 9,
    name: 'BOOTY',
    symbol: 'BOOTY',
    logoURI: 'https://arweave.net/N-9sTPFfUhoFzJ6prtY2hItNkVaHVAktRDiuQrHwWhQ',
    tags: ['community'],
    extensions: { coingeckoId: 'booty' },
  },
  DFL: {
    address: 'DFL1zNkaGPWm1BqAVqRjCZvHmwTFrEaJtbzJWgseoNJh',
    chainId: 101,
    decimals: 9,
    name: 'DeFi Land',
    symbol: 'DFL',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DFL1zNkaGPWm1BqAVqRjCZvHmwTFrEaJtbzJWgseoNJh/logo.png',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'defi-land' },
  },
  KORRA: {
    address: 't5cSTUSZzUAQXQKzQvhieFG4Hz4xC23z9du1Chp8gES',
    chainId: 101,
    decimals: 6,
    name: 'KORRA',
    symbol: 'KORRA',
    logoURI:
      'https://node2.irys.xyz/SE3kQL3Ur04sWUvYlCE_fS-Xdbc9IYaJFv1RyJFj7fQ',
    tags: ['community'],
    extensions: { coingeckoId: 'korra' },
  },
  aeUSDC: {
    address: 'DdFPRnccQqLD4zCHrBqdY95D6hvw6PLWp9DEXj1fLCL9',
    chainId: 101,
    decimals: 9,
    name: 'Wrapped USDC (Allbridge from Ethereum)',
    symbol: 'aeUSDC',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png',
    tags: ['old-registry'],
  },
  KIN: {
    address: 'kinXdEcpDQeHPEuQnqmUgtYykqKGVFq6CeVX5iAHJq6',
    chainId: 101,
    decimals: 5,
    name: 'KIN',
    symbol: 'KIN',
    logoURI: 'https://i.imgur.com/do6LTig.jpeg',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'kin' },
  },
  BAT: {
    address: 'EPeUFDgHRxs9xxEPVaL6kfGQvCon7jmAWKVUHuux1Tpz',
    chainId: 101,
    decimals: 8,
    name: 'Basic Attention Token (Portal)',
    symbol: 'BAT',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/BAT_wh.png',
    tags: ['wormhole', 'old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'basic-attention-token' },
  },
  WMP: {
    address: 'BygDd5LURoqztD3xETc99WCxLUbTi6WYSht9XiBgZ4HW',
    chainId: 101,
    decimals: 9,
    name: 'Whalemap',
    symbol: 'WMP',
    logoURI: 'https://raw.githubusercontent.com/ssi91/crypto/main/logo.svg',
    tags: ['old-registry', 'solana-fm'],
  },
  CMFI: {
    address: '5Wsd311hY8NXQhkt9cWHwTnqafk7BGEbLu8Py3DSnPAr',
    chainId: 101,
    decimals: 6,
    name: 'Compendium Finance',
    symbol: 'CMFI',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Wsd311hY8NXQhkt9cWHwTnqafk7BGEbLu8Py3DSnPAr/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'compendium-fi' },
  },
  DC: {
    address: 'FaUKsgcuqAd9sCaFz3if7ia1DasaxVoNPTXWFs2GScWN',
    chainId: 101,
    decimals: 6,
    name: 'DONALDCAT',
    symbol: 'DC',
    logoURI:
      'https://bafybeiar27nwe5eykqmbltz4ujrmdes6mkhsvkndbmfdgcffve3sicwm2y.ipfs.cf-ipfs.com/',
    tags: ['community'],
    extensions: { coingeckoId: 'donaldcat' },
  },
  SIZE: {
    address: 'HqLRjru6pD6GFGnQ7TwSSGQRuPhF8UZNey9T4yCsZzuq',
    chainId: 101,
    decimals: 6,
    name: 'SIZE',
    symbol: 'SIZE',
    logoURI:
      'https://bafybeidjk54m3p4mvfrvvgsyx6uszovxszwlvucupxfjbso3t3u2kfalja.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'size' },
  },
  TRUMPWIF: {
    address: 'DDGcYJkMMD1iiLRfPQLZePxLJCLDhiioQ83frmdAJd3h',
    chainId: 101,
    decimals: 6,
    name: 'TRUMPWIFHAT',
    symbol: 'TRUMPWIF',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmahgFmdBGQDNYzhaseYPA2ZB6suiAEPDRZUapBpVgB5Jk',
    tags: ['community'],
  },
  MNDE: {
    address: 'MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey',
    chainId: 101,
    decimals: 9,
    name: 'Marinade',
    symbol: 'MNDE',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey/logo.png',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'marinade' },
  },
  TAOLIE: {
    address: '2zE5rJ2ctXMz9hVbk1AvJa78X7mh3kuR728SNzGXTEeu',
    chainId: 101,
    decimals: 9,
    name: 'TAOlie Coin',
    symbol: 'TAOLIE',
    logoURI:
      'https://ipfs.io/ipfs/QmaRCxwwHUnGMETPbY4xinBJ8mYrWaiVkokYWhxy6rjsZc',
    tags: ['community'],
    extensions: { coingeckoId: 'taolie-coin' },
  },
  RUN: {
    address: '6F9XriABHfWhit6zmMUYAQBSy6XK5VF1cHXuW5LDpRtC',
    chainId: 101,
    decimals: 9,
    name: 'Run Token',
    symbol: 'RUN',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6F9XriABHfWhit6zmMUYAQBSy6XK5VF1cHXuW5LDpRtC/RunGear.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'run' },
  },
  TRANSEM: {
    address: 'E4r9cN6ZooDPwCrWiRQkdP15KD9G6wq5Hzkpk7ogTpfk',
    chainId: 101,
    decimals: 6,
    name: 'Transem',
    symbol: 'TRANSEM',
    logoURI:
      'https://ipfs.io/ipfs/QmZ9Q3ULJrD9T4epiCSi46d1ezjuNT4pMcjQnqpEWfY3cV',
    tags: ['community'],
  },
  NOCT: {
    address: '8HfNu2EfwFQz2srSNDvPsjaWV3G65ZWP3pDC67mcNv6J',
    chainId: 101,
    decimals: 9,
    name: 'Neural Octopus',
    symbol: 'NOCT',
    logoURI:
      'https://raw.githubusercontent.com/Neural-Octopus-Token/noct-metadata/main/logo.png',
    tags: ['community'],
  },
  NRC: {
    address: 'Gh1jKzmxf95cT5PQabNbfJskkQU8kQ5UugfpbHSnPq9z',
    chainId: 101,
    decimals: 3,
    name: 'Neon Rocket Coin',
    symbol: 'NRC',
    logoURI:
      'https://raw.githubusercontent.com/neontechanoah/neon_rocket_coin/main/asset/NRC_Logo_100px.png',
    tags: ['old-registry', 'solana-fm'],
  },
  MILEI: {
    address: '43N5UGr3mnfhJFzpBPNM7ZdnobghiYBtHTaQfZQv65mh',
    chainId: 101,
    decimals: 6,
    name: 'MILEI',
    symbol: 'MILEI',
    logoURI:
      'https://bafybeih5bduxtxzzear7ysgxoyqdnlkaj6qvbxdfcbp5azs7jlbfulfnty.ipfs.cf-ipfs.com',
    tags: ['community'],
    extensions: { coingeckoId: 'milei-solana' },
  },
  GM: {
    address: '3acxNNmfdKKZj9i35P4VDBFm74Ufdt8ojKWceVGynwC5',
    chainId: 101,
    decimals: 4,
    name: 'GM',
    symbol: 'GM',
    logoURI: 'https://arweave.net/jhNqKLDDC2ZtanzmFt-OZZJLFEYRpzus3rzhl7MGiOU',
    tags: ['community'],
    extensions: { coingeckoId: 'gm-machine' },
  },
  USN: {
    address: 'PUhuAtMHsKavMTwZsLaDeKy2jb7ciETHJP7rhbKLJGY',
    chainId: 101,
    decimals: 9,
    name: 'USN (Allbridge from Near)',
    symbol: 'USN',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PUhuAtMHsKavMTwZsLaDeKy2jb7ciETHJP7rhbKLJGY/logo.png',
    tags: ['old-registry'],
  },
  SLERF: {
    address: '7BgBvyjrZX1YKz4oh9mjb8ZScatkkwb8DzFx7LoiVkM3',
    chainId: 101,
    decimals: 9,
    name: 'SLERF',
    symbol: 'SLERF',
    logoURI:
      'https://bafkreih44n5jgqpwuvimsxzroyebjunnm47jttqusb4ivagw3vsidil43y.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'slerf' },
  },
  $RE: {
    address: 'REdaoGk6EcBVgXW7vHs9FnzWmkr3ba6eHRBBgEtLNWo',
    chainId: 101,
    decimals: 9,
    name: 'REdao',
    symbol: '$RE',
    logoURI: 'https://arweave.net/mQfc83s1RVPeQumJ8O6TVS8h9QH1ToSwilJ9USNU1_E',
    tags: ['community'],
  },
  SPOODY: {
    address: '8Nd3TZJfxt9yYKiPiPmYp6S5DhLftG3bwSqdW3KJwArb',
    chainId: 101,
    decimals: 6,
    name: 'Spodermen',
    symbol: 'SPOODY',
    logoURI:
      'https://raw.githubusercontent.com/deeeeeeeeeeeeeev/spodermen/main/spoody_logo.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'spodermen' },
  },
  YAW: {
    address: 'YAWtS7vWCSRPckx1agB6sKidVXiXiDUfehXdEUSRGKE',
    chainId: 101,
    decimals: 6,
    name: 'Yawww',
    symbol: 'YAW',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/YAWtS7vWCSRPckx1agB6sKidVXiXiDUfehXdEUSRGKE/yaw.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'yawww' },
  },
  ZAZA: {
    address: '3QJzpi68a3CUVPGVUjYLWziGKCAvbNXmC5VFNy1ypump',
    chainId: 101,
    decimals: 6,
    name: 'dog zoning out',
    symbol: 'ZAZA',
    logoURI:
      'https://pbs.twimg.com/profile_images/1803968193997742080/YtUdoCn5_400x400.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'zaza-sol' },
  },
  T: {
    address: '4Njvi3928U3figEF5tf8xvjLC5GqUN33oe4XTJNe7xXC',
    chainId: 101,
    decimals: 8,
    name: 'Threshold Network Token',
    symbol: 'T',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/T_wh.png',
    tags: ['wormhole'],
  },
  xBTC: {
    address: 'HWxpSV3QAGzLQzGAtvhSYAEr7sTQugQygnni1gnUGh1D',
    chainId: 101,
    decimals: 10,
    name: 'Synthetic BTC',
    symbol: 'xBTC',
    logoURI: 'https://www.synthetify.io/icons/xbtc.svg',
    tags: ['old-registry'],
  },
  CATY: {
    address: '8mY8bif63v5vAHYaHPhpob71K4uJsDKXVx7h9h1XmJ6N',
    chainId: 101,
    decimals: 6,
    name: 'Caty Wuud',
    symbol: 'CATY',
    logoURI:
      'https://bafkreigy7tvjbbq3j2ksiyjkihtcqduxho5uuwud6bbgltlyxbbsn6leiy.ipfs.nftstorage.link',
    tags: ['community'],
  },
  DXL: {
    address: 'GsNzxJfFn6zQdJGeYsupJWzUAm57Ba7335mfhWvFiE9Z',
    chainId: 101,
    decimals: 6,
    name: 'Dexlab',
    symbol: 'DXL',
    logoURI:
      'https://raw.githubusercontent.com/dexlab-project/assets/master/dexlab/dexlab_symbol_logo.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'dexlab' },
  },
  GEM: {
    address: 'E7BGDtpNXUTqPNbZxKHiLowgLddiAeuKcByD7tSnfYWD',
    chainId: 101,
    decimals: 5,
    name: 'GEM',
    symbol: 'GEM',
    logoURI:
      'https://bafkreifpm7rbwohp4yys2guyibfzqbctu3bist6zetmyq456om2rqvk544.ipfs.nftstorage.link/',
    tags: ['community', 'token-2022'],
  },
  XIAO: {
    address: 'EDavhezsuNnhdoAKPExWaMtnuhq6FVqoBYnyFEJLLBqC',
    chainId: 101,
    decimals: 6,
    name: 'xiaojie',
    symbol: 'XIAO',
    logoURI:
      'https://bafybeibqz4chze636s7ypnob4xogp7vobosqsbwmeu36w4x62iyqb6n6hm.ipfs.cf-ipfs.com/',
    tags: ['community'],
    extensions: { coingeckoId: 'xiaojie' },
  },
  VCC: {
    address: 'FZgL5motNWEDEa24xgfSdBDfXkB9Ru9KxfEsey9S58bb',
    chainId: 101,
    decimals: 6,
    name: 'VentureCapital',
    symbol: 'VCC',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FZgL5motNWEDEa24xgfSdBDfXkB9Ru9KxfEsey9S58bb/logo.svg',
    tags: ['old-registry', 'solana-fm'],
  },
  CAVE: {
    address: '4SZjjNABoqhbd4hnapbvoEPEqT8mnNkfbEoAwALf1V8t',
    chainId: 101,
    decimals: 6,
    name: 'Crypto Cavemen',
    symbol: 'CAVE',
    logoURI:
      'https://ftoblquxiunjey7bu4eevlz2u7kwg5s3yvou7adgge2jbihcu7qq.arweave.net/LNwVwpdFGpJj4acISq86p9VjdlvFXU-AZjE0kKDip-E/?ext=png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'cave' },
  },
  HAGGORD: {
    address: 'AYyYgh3i43s1QSpvG4vwhJ6s3gewfN7uteFwYrswgMGw',
    chainId: 101,
    decimals: 9,
    name: 'ps1 hagrid',
    symbol: 'HAGGORD',
    logoURI:
      'https://bafkreiak2a2gbdqqnz77xup3bi72lon5fe4ri4cpozxp5o4ekjnugm77wm.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'haggord' },
  },
  DPUNKZ: {
    address: 'McpgFn2CxFYFq6JLiBxeC6viNfebLsfsf9Sv5wcwKvL',
    chainId: 101,
    decimals: 2,
    name: 'Duck Punkz Universe',
    symbol: 'DPUNKZ',
    logoURI:
      'https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://creator-hub-prod.s3.us-east-2.amazonaws.com/duckpunkzuniverse_pfp_1650542071140.gif',
    tags: ['community', 'solana-fm'],
  },
  HUNT: {
    address: 'CTYiHf58UGShfHtpkTwx7vjPDA779dd6iVaeD281fEVx',
    chainId: 101,
    decimals: 9,
    name: 'Hunter Diamond',
    symbol: 'HUNT',
    logoURI: 'https://tokenhunters.app/assets/img/icons/chest-close.svg',
    tags: ['old-registry', 'solana-fm'],
  },
  SMRAI: {
    address: 'rvxo8t7TKeSmAgpdqK1CY9ddZi3NyowRCh1m2d7KrUc',
    chainId: 101,
    decimals: 9,
    name: 'SMRAI',
    symbol: 'SMRAI',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/rvxo8t7TKeSmAgpdqK1CY9ddZi3NyowRCh1m2d7KrUc/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  rLGND: {
    address: '9m8E1yLHaG1B2TFSNeWahsitQh5yQRnrbyw756HFAcEa',
    chainId: 101,
    decimals: 9,
    name: 'Random Blockasset Legends',
    symbol: 'rLGND',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9m8E1yLHaG1B2TFSNeWahsitQh5yQRnrbyw756HFAcEa/rLGND.png',
    tags: ['old-registry'],
  },
  SNIFF: {
    address: '4oaV94McVveRosSgdZTn3jkMpr1ge7SQZjwBZ7xG6oA7',
    chainId: 101,
    decimals: 6,
    name: 'SNIFF',
    symbol: 'SNIFF',
    logoURI:
      'https://ipfs.io/ipfs/QmWMo39iqF5HqQNfprcifguRR87CVo1iNXWSwERUCgRkPe',
    tags: ['community'],
    extensions: { coingeckoId: 'sniff' },
  },
  PIXI: {
    address: 'FtHCi9cxJSSizrzMzsPjAfTfJi32V1CGRDM5Skqn4QBF',
    chainId: 101,
    decimals: 6,
    name: 'Pixi',
    symbol: 'PIXI',
    logoURI:
      'https://bafybeifgup3glqbxon4cd2vt5k2cch3ahdfkjlnw2snkrpamgm5wdbspma.ipfs.dweb.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'pixi' },
  },
  DIP: {
    address: '3XxvmED354933DwSPJuzB7SE9uiWpD1ErydDuhmbFRMk',
    chainId: 101,
    decimals: 6,
    name: 'doginthpool',
    symbol: 'DIP',
    logoURI:
      'https://bafkreidjc5can5he5bx7qgvbdkrpmgajs3lcaqm6hvixvrceinc5tsaacq.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'doginthpool' },
  },
  TIMMI: {
    address: 'BxXmDhM8sTF3QG4foaVM2v1EUvg9DLSVUsDRTjR8tMyS',
    chainId: 101,
    decimals: 6,
    name: 'TIMMI',
    symbol: 'TIMMI',
    logoURI:
      'https://pbs.twimg.com/profile_images/1754820662550732800/SMcVuXll.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'timmi' },
  },
  CHEX: {
    address: '6dKCoWjpj5MFU5gWDEFdpUUeBasBLK3wLEwhUzQPAa1e',
    chainId: 101,
    decimals: 8,
    name: 'Chintai',
    symbol: 'CHEX',
    logoURI: 'https://chintai.io/images/icon.svg',
    tags: ['community'],
    extensions: { coingeckoId: 'chex-token' },
  },
  CHURRO: {
    address: 'A3t817aK6XkhqQA148bjXKWJMhBmP9t2d9caWvQNaHQR',
    chainId: 101,
    decimals: 6,
    name: 'CHURRO',
    symbol: 'CHURRO',
    logoURI:
      'https://bafybeicdl3dbn5cr4xwvov5jnuxusaeichgfyyxnpmmukdsv7xw44qfpxi.ipfs.nftstorage.link',
    tags: ['community'],
  },
  WUT: {
    address: 'DG5bH1BnfjB5YL7Vt3GjykkUKf6maDUW3jYvdNa9eEVa',
    chainId: 101,
    decimals: 6,
    name: 'WUT',
    symbol: 'WUT',
    logoURI: 'https://i.ibb.co/XtLXFLV/photo-2024-01-17-16-21-23.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'wut' },
  },
  soYFI: {
    address: '3JSf5tPeuscJGtaCp5giEiDhv51gQ4v3zWg8DGgyLfAB',
    chainId: 101,
    decimals: 6,
    name: 'Wrapped YFI (Sollet)',
    symbol: 'soYFI',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3JSf5tPeuscJGtaCp5giEiDhv51gQ4v3zWg8DGgyLfAB/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  POWME: {
    address: '6r25fEibeDLfh4K9pwJcVGmt4g8yxMqZ2rAA5jwdJzvt',
    chainId: 101,
    decimals: 6,
    name: 'Pixel Wall of Meme',
    symbol: 'POWME',
    logoURI:
      'https://ipfs.io/ipfs/Qmav528LKY17YvyrLrth1HMzbFp7CAS6EvgNkdmMTx2nLY',
    tags: ['community'],
  },
  FUTURE: {
    address: 'FUTURETnhzFApq2TiZiNbWLQDXMx4nWNpFtmvTf11pMy',
    chainId: 101,
    decimals: 9,
    name: 'Future',
    symbol: 'FUTURE',
    logoURI:
      'https://arweave.net/9HRtVucwSCY8fLiHBhiYoqDW9kgjoowdj9dIQfb1oY4?ext=png',
    tags: ['community'],
    extensions: { coingeckoId: 'futurespl' },
  },
  TICKET: {
    address: 'AymKzSDznoLT7Vhsb4wSRnCj1gjcG3zkgYFY8fxsHHer',
    chainId: 101,
    decimals: 8,
    name: 'The Ticket Finance',
    symbol: 'TICKET',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AymKzSDznoLT7Vhsb4wSRnCj1gjcG3zkgYFY8fxsHHer/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  BURR: {
    address: 'XwTZraiF1dVh69cZ2SpqyjDLmei2uVps5CYHD9vqK6d',
    chainId: 101,
    decimals: 2,
    name: 'Burrito Boyz',
    symbol: 'BURR',
    logoURI:
      'https://creator-hub-prod.s3.us-east-2.amazonaws.com/burrito_boyz_pfp_1653394754301.png',
    tags: ['community', 'solana-fm'],
  },
  USDTet: {
    address: 'Dn4noZ5jgGfkntzcQSUZ8czkreiZ1ForXYoV2H8Dm7S1',
    chainId: 101,
    decimals: 6,
    name: 'Tether USD (Portal from Ethereum)',
    symbol: 'USDTet',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/USDTet_wh.png',
    tags: ['wormhole', 'old-registry'],
    extensions: { coingeckoId: 'tether-usd-wormhole-from-ethereum' },
  },
  UNQ: {
    address: 'UNQtEecZ5Zb4gSSVHCAWUQEoNnSVEbWiKCi1v9kdUJJ',
    chainId: 101,
    decimals: 6,
    name: 'Unique Venture Clubs',
    symbol: 'UNQ',
    logoURI:
      'https://www.arweave.net/X58AnJYdgZRbTNyf5HzavUD7daqAtuPnz0JcUACU70Y?ext=jpg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'unq' },
  },
  '99CENTS': {
    address: '3VTXWW93L1WRfQ7QDPjvfBQgzFRVqDgHPnc8vwQmEpUi',
    chainId: 101,
    decimals: 6,
    name: 'Arizona Iced Tea',
    symbol: '99CENTS',
    logoURI:
      'https://ipfs.io/ipfs/Qmc5YUcDU8aYBMoXHSjXwLKb65Mw6KSUHPufftVCyzE5fM',
    tags: ['community'],
    extensions: { coingeckoId: 'arizona-iced-tea' },
  },
  FLUXT: {
    address: 'D1wUhnzTDscCDRdxDwR4h82XkesXgQR4Q2zLhSuYJA5m',
    chainId: 101,
    decimals: 6,
    name: 'Flux Terminal',
    symbol: 'FLUXT',
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://cf-ipfs.com/ipfs/QmeGpjQhH7CbUfJHFXii2u6gek4ATU4shonqSWMVFMjZ4h',
    tags: ['community'],
    extensions: { coingeckoId: 'flux-terminal' },
  },
  LIME: {
    address: 'H3QMCaMh5LxtS9oGDwaMaRXPSPSiXVqnY4YsfrQMRjqD',
    chainId: 101,
    decimals: 6,
    name: 'Lime Cat',
    symbol: 'LIME',
    logoURI:
      'https://bafybeihjj2t76pgdbyvrnxv47w27z4g5jssbupkf54eqcu4iic4sehtz2a.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'lime-cat' },
  },
  TOLY: {
    address: 'to1yVXiNRMVVgS8i54Yjj3xB51MTorFrCMz7N8cirbK',
    chainId: 101,
    decimals: 9,
    name: 'Anatoly Coin',
    symbol: 'TOLY',
    logoURI:
      'https://shdw-drive.genesysgo.net/3YZtwGNZZisAUxKokcivJEwq2ag93APVXYqeyfaqb9Rq/toly-img.png',
    tags: ['community'],
  },
  COINFRA: {
    address: 'FtVugRqBcn5gakjzfDyA3Spms63fYDAbhke1YAFgLgLB',
    chainId: 101,
    decimals: 2,
    name: 'Coinfra Samurai',
    symbol: 'COINFRA',
    logoURI:
      'https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://creator-hub-prod.s3.us-east-2.amazonaws.com/coinfra_samurai_pfp_1662121480181.png',
    tags: ['community'],
  },
  '1SP': {
    address: '2zzC22UBgJGCYPdFyo7GDwz7YHq5SozJc1nnBqLU8oZb',
    chainId: 101,
    decimals: 9,
    name: '1SPACE',
    symbol: '1SP',
    logoURI: 'https://1space.me/images/1SP_logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'onespace' },
  },
  SAIL: {
    address: '6kwTqmdQkJd8qRr9RjSnUX9XJ24RmJRSrU1rsragP97Y',
    chainId: 101,
    decimals: 6,
    name: 'SAIL',
    symbol: 'SAIL',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6kwTqmdQkJd8qRr9RjSnUX9XJ24RmJRSrU1rsragP97Y/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  KRUG: {
    address: 'ECy2miAgmx3UyYoGGHt15AHBkoA3twoZDQyNLwETzAUv',
    chainId: 101,
    decimals: 2,
    name: 'KRUGs',
    symbol: 'KRUG',
    logoURI:
      'https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://creator-hub-prod.s3.us-east-2.amazonaws.com/krug_pfp_1679408420983.png',
    tags: ['community'],
  },
  FUACK: {
    address: '4P6gQYnZ2AvwmAr37GCeUq1vyk1PQJmXnuA1QFRKGHkz',
    chainId: 101,
    decimals: 6,
    name: 'FUACK',
    symbol: 'FUACK',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d6479696167515750456f4a525936534139465156376353414d4c4e72704c78784c354242735a437533523666',
    tags: ['community'],
    extensions: { coingeckoId: 'fuack' },
  },
  STR: {
    address: '9zoqdwEBKWEi9G5Ze8BSkdmppxGgVv1Kw4LuigDiNr9m',
    chainId: 101,
    decimals: 9,
    name: 'Solster',
    symbol: 'STR',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9zoqdwEBKWEi9G5Ze8BSkdmppxGgVv1Kw4LuigDiNr9m/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'solster' },
  },
  SAYLOR: {
    address: '8bXZuG6NVuhdmGeMEjypYZGny48DgpZ68TvkvVTmFDdF',
    chainId: 101,
    decimals: 9,
    name: 'Saylor',
    symbol: 'SAYLOR',
    logoURI:
      'https://bafybeihsvj7r5hypkjrkayfq5sgh5fz3ef6hpa5wpnfeesmbc52ujjuvue.ipfs.nftstorage.link',
    tags: ['community'],
  },
  HONK: {
    address: '3ag1Mj9AKz9FAkCQ6gAEhpLSX8B2pUbPdkb9iBsDLZNB',
    chainId: 101,
    decimals: 9,
    name: 'Honk',
    symbol: 'HONK',
    logoURI:
      'https://bafkreia5nmyxbrxshpiebsakviidhkfuoevpcyzonwov7bmaovce5ekzym.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'honk' },
  },
  C98: {
    address: 'C98A4nkJXhpVZNAZdHUA95RpTF3T4whtQubL3YobiUX9',
    chainId: 101,
    decimals: 6,
    name: 'Coin98',
    symbol: 'C98',
    logoURI: 'https://coin98.s3.ap-southeast-1.amazonaws.com/Coin/c98-512.svg',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'coin98' },
  },
  RPC: {
    address: 'EAefyXw6E8sny1cX3LTH6RSvtzH6E5EFy1XsE2AiH1f3',
    chainId: 101,
    decimals: 6,
    name: 'Republic Credits',
    symbol: 'RPC',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EAefyXw6E8sny1cX3LTH6RSvtzH6E5EFy1XsE2AiH1f3/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'republic-credits' },
  },
  WYNN: {
    address: '4vqYQTjmKjxrWGtbL2tVkbAU1EVAz9JwcYtd2VE3PbVU',
    chainId: 101,
    decimals: 6,
    name: 'Anita Max Wynn',
    symbol: 'WYNN',
    logoURI:
      'https://bafybeiepbscydfgvbfqq4augkwub2tsvbsm3e6uau3he2mmmbj4ebuyyym.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'anita-max-wynn' },
  },
  kenidy: {
    address: 'Bg9CZr1CmVoCn2uNWwj9f5rLbmfYRYvcVikCRCwawUwR',
    chainId: 101,
    decimals: 9,
    name: 'ruburt f kenidy jr',
    symbol: 'kenidy',
    logoURI:
      'https://ipfs.io/ipfs/QmdvV2btGAz4EkwHV8oH78KC7KxqtHXrUbnw47B8M56yKT',
    tags: ['community'],
    extensions: { coingeckoId: 'ruburt-f-kenidy-jr' },
  },
  'sLUNA-9': {
    address: 'LUNGEjUXyP48nrC1GYY5o4eTAkwm4RdX8BxFUxWJBLB',
    chainId: 101,
    decimals: 9,
    name: 'Saber Wrapped LUNA (Portal) (9 decimals)',
    symbol: 'sLUNA-9',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LUNGEjUXyP48nrC1GYY5o4eTAkwm4RdX8BxFUxWJBLB/icon.png',
    tags: ['old-registry'],
  },
  SRM: {
    address: 'SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt',
    chainId: 101,
    decimals: 6,
    name: 'Serum',
    symbol: 'SRM',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt/logo.png',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'serum' },
  },
  PAJAMAS: {
    address: 'FvER7SsvY5GqAMawf7Qfb5MnUUmDdbPNPg4nCa4zHoLw',
    chainId: 101,
    decimals: 9,
    name: 'The First Youtube Cat',
    symbol: 'PAJAMAS',
    logoURI:
      'https://pajamas.cat/wp-content/uploads/2024/03/photo_6303196121761170014_x.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'pajamas-cat' },
  },
  IN: {
    address: 'inL8PMVd6iiW3RCBJnr5AsrRN6nqr4BTrcNuQWQSkvY',
    chainId: 101,
    decimals: 9,
    name: 'Sol Invictus',
    symbol: 'IN',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/inL8PMVd6iiW3RCBJnr5AsrRN6nqr4BTrcNuQWQSkvY/logo-owl.png',
    tags: ['old-registry'],
  },
  Ww3: {
    address: '7m2TUkpPZCScBhPJnGjWjbh75KkDNnwAdd7i74m8awad',
    chainId: 101,
    decimals: 6,
    name: 'World War 3',
    symbol: 'Ww3',
    logoURI:
      'https://bafybeibkq2ku7q3p6qsj24ac4s3dij4eu5jg47gjd4ra7ln5x77af66sty.ipfs.cf-ipfs.com/',
    tags: ['community'],
  },
  ANTT: {
    address: 'ANTT2Ve8c3JC6fAksFxUGtByzEHmJs49EupP7htA5W7j',
    chainId: 101,
    decimals: 9,
    name: 'Antara Token',
    symbol: 'ANTT',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f692e706f7374696d672e63632f6662675a516a73522f416e746172612d546f6b656e2e706e67',
    tags: ['community'],
    extensions: { coingeckoId: 'antara-raiders-royals' },
  },
  SEND: {
    address: 'SENDdRQtYMWaQrBroBrJ2Q53fgVuq95CV9UPGEvpCxa',
    chainId: 101,
    decimals: 6,
    name: 'Send',
    symbol: 'SEND',
    logoURI:
      'https://tgnrqry5tpxb5imfstlqu2mdqpm3zwmmaxrrakjxuinvpwhkanca.arweave.net/mZsYRx2b7h6hhZTXCmmDg9m82YwF4xApN6IbV9jqA0Q',
    tags: ['community'],
    extensions: { coingeckoId: 'send-2' },
  },
  JUM: {
    address: '43eeGP8k9AZKBSyd9iahhThNtE3vcZvBWMaHNd2akM5s',
    chainId: 101,
    decimals: 6,
    name: 'Jum Cromer',
    symbol: 'JUM',
    logoURI:
      'https://bafybeicd3jspljny22j65m3wqbx3tbkhw6gis55uygdswj4ootskckalxu.ipfs.cf-ipfs.com/',
    tags: ['community'],
  },
  CNN: {
    address: 'DoyVgrZbkAVMbTVGqa9mFNgjThsr337vxjFtEDWQibN7',
    chainId: 101,
    decimals: 6,
    name: 'Fak Nous',
    symbol: 'CNN',
    logoURI:
      'https://bafkreie7jffibizw4kjbb3k5akuqqxvgi2bx7ydqq5tvbng5xnqmkdnbyu.ipfs.nftstorage.link',
    tags: ['community'],
  },
  'ssoFTT-8': {
    address: 'FTT8cGNp3rfTC6c44uPTuEFLqmsVDhjd2BhH65v2uppr',
    chainId: 101,
    decimals: 8,
    name: 'Saber Wrapped Wrapped FTT (Sollet) (8 decimals)',
    symbol: 'ssoFTT-8',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTT8cGNp3rfTC6c44uPTuEFLqmsVDhjd2BhH65v2uppr/icon.png',
    tags: ['old-registry'],
  },
  SOLCEX: {
    address: 'AMjzRn1TBQwQfNAjHFeBb7uGbbqbJB7FzXAnGgdFPk6K',
    chainId: 101,
    decimals: 6,
    name: 'SolCex',
    symbol: 'SOLCEX',
    logoURI: 'https://i.imgur.com/YN1yUMM.png',
    tags: ['community'],
    extensions: { coingeckoId: 'solcex' },
  },
  NIRV: {
    address: 'NRVwhjBQiUPYtfDT5zRBVJajzFQHaBUNtC7SNVvqRFa',
    chainId: 101,
    decimals: 6,
    name: 'NIRV',
    symbol: 'NIRV',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NRVwhjBQiUPYtfDT5zRBVJajzFQHaBUNtC7SNVvqRFa/NIRV.png',
    tags: ['old-registry'],
  },
  DEVIN: {
    address: '7gbEP2TAy5wM3TmMp5utCrRvdJ3FFqYjgN5KDpXiWPmo',
    chainId: 101,
    decimals: 6,
    name: 'SCOTT WU',
    symbol: 'DEVIN',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d57616854575470554b54667378376342594d3351533175705171336a586556784d72314547666d437168746a',
    tags: ['community'],
    extensions: { coingeckoId: 'devin-on-solana' },
  },
  WATT: {
    address: 'GjdreVe7iUG4hyESSweGyFzgekWufhEwGJqAaa1hr7pf',
    chainId: 101,
    decimals: 9,
    name: 'WATT token',
    symbol: 'WATT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GjdreVe7iUG4hyESSweGyFzgekWufhEwGJqAaa1hr7pf/logo.png',
    tags: ['old-registry'],
  },
  CRM: {
    address: 'CRMaDAzKCWYbhUfsKYA8448vaA1qUzCETd7gNBDzQ1ks',
    chainId: 101,
    decimals: 9,
    name: 'Crema',
    symbol: 'CRM',
    logoURI:
      'https://www.arweave.net/E6II9rfGbg3zzLugMjtbPGf5sM-JKKky2iXGA5SoYjQ?ext=png',
    tags: ['community'],
  },
  GMERS: {
    address: '25yPcyqSpSv8T2JWyoKKRiU622Sr7LoMk9S3FmuBFt8S',
    chainId: 101,
    decimals: 2,
    name: 'GMers',
    symbol: 'GMERS',
    logoURI:
      'https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://creator-hub-prod.s3.us-east-2.amazonaws.com/gmers_pfp_1662860639809.png',
    tags: ['community'],
  },
  W: {
    address: '85VBFQZC9TZkfaptBWjvUw7YbZjy52A6mjtPGjstQAmQ',
    chainId: 101,
    decimals: 6,
    name: 'Wormhole Token',
    symbol: 'W',
    logoURI: 'https://wormhole.com/token.png',
    tags: ['community'],
    extensions: { coingeckoId: 'wormhole' },
  },
  SOLAMA: {
    address: 'AVLhahDcDQ4m4vHM4ug63oh7xc8Jtk49Dm5hoe9Sazqr',
    chainId: 101,
    decimals: 6,
    name: 'Solama',
    symbol: 'SOLAMA',
    logoURI:
      'https://gateway.irys.xyz/UoPHSd2rbj9krhwHfR4Vk94JnzgwbDs8SW92_Z90YUM',
    tags: ['community'],
    extensions: { coingeckoId: 'solama' },
  },
  PIXL: {
    address: '5L2YboFbHAUpBDDJjvDB5M6pu9CW2FRjyDB2asZyvjtE',
    chainId: 101,
    decimals: 8,
    name: 'Pixels.so Token',
    symbol: 'PIXL',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5L2YboFbHAUpBDDJjvDB5M6pu9CW2FRjyDB2asZyvjtE/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  SLC: {
    address: 'METAmTMXwdb8gYzyCPfXXFmZZw4rUsXX58PNsDg7zjL',
    chainId: 101,
    decimals: 6,
    name: 'Solice',
    symbol: 'SLC',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/METAmTMXwdb8gYzyCPfXXFmZZw4rUsXX58PNsDg7zjL/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'solice' },
  },
  sboy: {
    address: 'G3ukjeHBrDJ1zUFr6KandnW4bPcjmvK3qL2uATRb3F63',
    chainId: 101,
    decimals: 6,
    name: 'soulja coin',
    symbol: 'sboy',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmaLWmZgBvf2vhM2HCooXPNfJ6HdKgKGyB681pEncQHFzD',
    tags: ['community'],
    extensions: { coingeckoId: 'soulja-coin' },
  },
  OOINK: {
    address: 'KRTapyUMe5fW92KZkYoXToFtc6Cn7UG6seaKz646oGu',
    chainId: 101,
    decimals: 9,
    name: 'OOINK',
    symbol: 'OOINK',
    logoURI:
      'https://raw.githubusercontent.com/danvernon/tiny-dogz-logo/main/oink.png',
    tags: ['old-registry', 'solana-fm'],
  },
  Cuy: {
    address: '8MD6dV7B8Tgwo72WyPmfsXiXpt1G1y2u9ohhNrt3LVwX',
    chainId: 101,
    decimals: 6,
    name: 'Cuy',
    symbol: 'Cuy',
    logoURI:
      'https://ipfs.io/ipfs/QmU62kzW5662SoGrRGu3jH6skmtDmNem4sXiFvkhEdg8hb',
    tags: ['community'],
  },
  SEAL: {
    address: '3B3Zfs7eb46Re9GHWv6ccYRSBGy5EvQF2i2VXMD6tge6',
    chainId: 101,
    decimals: 6,
    name: 'Seal',
    symbol: 'SEAL',
    logoURI: 'https://i.ibb.co/3r7bnBW/vhlvnb.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'seal-sol' },
  },
  TROG: {
    address: '2cpCdyVCpxzwYWFhFqgMJqxnMgEMDGohHUQuf4ppWuAH',
    chainId: 101,
    decimals: 6,
    name: 'Thug Frog',
    symbol: 'TROG',
    logoURI:
      'https://bafkreifdk5zl36v7fe5hn2ccbdbt3ot4x25l6l25hjqmb6lzfbpi23yh4q.ipfs.nftstorage.link',
    tags: ['community'],
  },
  UXD: {
    address: '7kbnvuGBxxj8AG9qp8Scn56muWGaRaFqxg1FsRp3PaFT',
    chainId: 101,
    decimals: 6,
    name: 'UXD Stablecoin',
    symbol: 'UXD',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7kbnvuGBxxj8AG9qp8Scn56muWGaRaFqxg1FsRp3PaFT/uxd-icon-black.png',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'uxd-stablecoin' },
  },
  VOID: {
    address: 'DjPt6xxMoZx1DyyWUHGs4mwqWWX48Fwf6ZJgqv2F9qwc',
    chainId: 101,
    decimals: 9,
    name: 'VOID Token',
    symbol: 'VOID',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DjPt6xxMoZx1DyyWUHGs4mwqWWX48Fwf6ZJgqv2F9qwc/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'void-games' },
  },
  ' EVERMOON': {
    address: 'CgzdCjj5YNH51uFfQftFbuJKMrwgdWheVjwqjU84MV8y',
    chainId: 101,
    decimals: 6,
    name: 'Evermoon',
    symbol: ' EVERMOON',
    logoURI:
      'https://bafkreig3cc53og7bvr5yrvfunbws7nyjqezrw22jsrddti2sdajcpswziq.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'evermoon-sol' },
  },
  DORKL: {
    address: 'BgqjQdnnjRtcELAgkYpfKAxWRqSReWercDdRTH6uLoer',
    chainId: 101,
    decimals: 6,
    name: 'ᗪOᖇK ᒪOᖇᗪ',
    symbol: 'DORKL',
    logoURI:
      'https://bafkreicufnhs5evfdochjopzpjaeuxoh5s6uhyl54dlfeb2cm4bqjfi4dq.ipfs.nftstorage.link',
    tags: ['community'],
  },
  NFD: {
    address: 'CY2E69dSG9vBsMoaXDvYmMDSMEP4SZtRY1rqVQ9tkNDu',
    chainId: 101,
    decimals: 8,
    name: 'Feisty Doge NFT',
    symbol: 'NFD',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CY2E69dSG9vBsMoaXDvYmMDSMEP4SZtRY1rqVQ9tkNDu/logo.svg',
    tags: ['old-registry'],
  },
  MOGO: {
    address: '2WUecdL1GQA6pwQs7sMsCf2shEfZxWK2RaNHkthmLtmK',
    chainId: 101,
    decimals: 9,
    name: 'Monkey Gold',
    symbol: 'MOGO',
    logoURI: 'https://arweave.net/w_cYAcuwGOKxZWafjj-o14Ab_0PaaA_fZoQ8wqdy4rc',
    tags: ['community'],
  },
  boy: {
    address: 'GmEXg8FwpULzVb15r41CNB5qFnRcXCrEyZSmD3X7sv2e',
    chainId: 101,
    decimals: 6,
    name: 'Good Boy',
    symbol: 'boy',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmTPd9Z7wXdtVZRfigjvAYUqzG3F3mHcoTcokCp6JbvD9j',
    tags: ['community'],
    extensions: { coingeckoId: 'good-boy' },
  },
  CHILL: {
    address: 'BzpqoPc44oaHu9SB5hek1GRNjzc4UwFcL4oWusWozm3N',
    chainId: 101,
    decimals: 3,
    name: 'LumiChill',
    symbol: 'CHILL',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f626166796265696274666a68733372786f663336367678683277706469376379776c667a7967656171346c6e7271696961696464366c63747073652e697066732e6e667473746f726167652e6c696e6b',
    tags: ['community'],
    extensions: { coingeckoId: 'lumichill' },
  },
  GOSU: {
    address: '6D7nXHAhsRbwj8KFZR2agB6GEjMLg4BM7MAqZzRT8F1j',
    chainId: 101,
    decimals: 8,
    name: 'Gosu',
    symbol: 'GOSU',
    logoURI:
      'https://raw.githubusercontent.com/jGosu/gosu/main/GOSU%20COIN.png',
    tags: ['old-registry'],
  },
  SBC: {
    address: 'DBAzBUXaLj1qANCseUPZz4sp9F8d2sc78C4vKjhbTGMA',
    chainId: 101,
    decimals: 9,
    name: 'Stable Coin',
    symbol: 'SBC',
    logoURI: 'https://brale.xyz/assets/stablecoins/sbc-logo.svg',
    tags: ['community'],
  },
  PSOL: {
    address: 'Hmatmu1ktLbobSvim94mfpZmjL5iiyoM1zidtXJRAdLZ',
    chainId: 101,
    decimals: 7,
    name: 'Parasol',
    symbol: 'PSOL',
    logoURI:
      'https://raw.githubusercontent.com/parasol-finance/white-paper/main/logo.png',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'parasol-finance' },
  },
  GIV: {
    address: '3Xi3EhKjnKAk2KTChzybUSWcLW6eAgTHyotHH1U6sJE1',
    chainId: 101,
    decimals: 8,
    name: 'Giveth Token (Wormhole)',
    symbol: 'GIV',
    logoURI:
      'https://raw.githubusercontent.com/Giveth/giveth-design-assets/master/02-logos/GIV%20Token/GIVToken_600x600.png',
    tags: ['community'],
    extensions: { coingeckoId: 'giveth' },
  },
  CAT: {
    address: '7hWcHohzwtLddDUG81H2PkWq6KEkMtSDNkYXsso18Fy3',
    chainId: 101,
    decimals: 3,
    name: 'Catcoin',
    symbol: 'CAT',
    logoURI:
      'https://raw.githubusercontent.com/catcoincom/Audit/mastar/logo1.png',
    tags: ['community'],
    extensions: { coingeckoId: 'catcoin-cash' },
  },
  'swtUST-9': {
    address: 'UST98bfV6EASdTFQrRwCBczpehdMFwYCUdLT5tEbhpW',
    chainId: 101,
    decimals: 9,
    name: 'Saber Wrapped UST (Portal) (9 decimals)',
    symbol: 'swtUST-9',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UST98bfV6EASdTFQrRwCBczpehdMFwYCUdLT5tEbhpW/icon.png',
    tags: ['old-registry'],
  },
  FISHY: {
    address: 'Fishy64jCaa3ooqXw7BHtKvYD8BTkSyAPh6RNE3xZpcN',
    chainId: 101,
    decimals: 6,
    name: 'FISHY by sharky.fi',
    symbol: 'FISHY',
    logoURI:
      'https://shdw-drive.genesysgo.net/CpRahRvMZad2VNTJDN24m4Bk6PsWRBm14w684RatY1bd/The%20winning%20logo.png',
    tags: ['community'],
    extensions: { coingeckoId: 'fishy' },
  },
  BULLZ: {
    address: 'CdkXjsEokNWMabBn2HCrPcXNmSDE59QKtseLh1j9tEwd',
    chainId: 101,
    decimals: 6,
    name: 'BULLZ',
    symbol: 'BULLZ',
    logoURI:
      'https://img.fotofolio.xyz/?url=https%3A%2F%2Fbafkreida4wh6oubxpjxdhldhhdirjdjhiebkyvdilvdshofsxwizmw4gba.ipfs.nftstorage.link',
    tags: ['community'],
  },
  TREN: {
    address: 'HLnTNCG5RD7jYVduFc1pMCHiuApoWGn9LveqEFanQFZb',
    chainId: 101,
    decimals: 9,
    name: 'TREN',
    symbol: 'TREN',
    logoURI:
      'https://bafkreifiuo2ciqowl57a7hkzs2cjq5zikwczbhh3wr4h2ub3xyhsjtnb6u.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'tren' },
  },
  LPFi: {
    address: 'LPFiNAybMobY5oHfYVdy9jPozFBGKpPiEGoobK2xCe3',
    chainId: 101,
    decimals: 9,
    name: 'LP Finance DAO',
    symbol: 'LPFi',
    logoURI:
      'https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/LPFiToken.png',
    tags: ['community', 'solana-fm'],
  },
  bul: {
    address: '8RGy4BZUEkW9dc2UEZ3QHYYdas66X63vazdZJezGJw5e',
    chainId: 101,
    decimals: 7,
    name: 'bul',
    symbol: 'bul',
    logoURI:
      'https://cloudflare-ipfs.com/ipfs/bafkreiezviw3lwrcxzh6dctn47unj5tsidgqgjmlzqicr3xhcpx7lxbasy',
    tags: ['community'],
    extensions: { coingeckoId: 'bul' },
  },
  LFG: {
    address: 'LFG1ezantSY2LPX8jRz2qa31pPEhpwN9msFDzZw4T9Q',
    chainId: 101,
    decimals: 7,
    name: 'LessFnGas',
    symbol: 'LFG',
    logoURI:
      'https://mogos7pjgqvbf3z7kzixpv67uxngkrifdfoddttz2tu6ywphk33q.arweave.net/Y4zpfek0KhLvP1ZRd9ffpdplRQUZXDHOedTp7FnnVvc?ext=png',
    tags: ['community'],
    extensions: { coingeckoId: 'lessfngas' },
  },
  GARF: {
    address: 'Pz1b7iALFqzsgdL9ca6P3NZvTXwSF1koaQqnNohVFcT',
    chainId: 101,
    decimals: 6,
    name: 'Garf',
    symbol: 'GARF',
    logoURI:
      'https://pbs.twimg.com/profile_images/1770950513913139200/7WTpGIxH_400x400.jpg',
    tags: ['community'],
  },
  MOONBURN: {
    address: 'F14Cp89oAXMrNnaC4mKMNKHPWw2p2R4DRFAZEdJhUBkD',
    chainId: 101,
    decimals: 6,
    name: 'MOONBURN',
    symbol: 'MOONBURN',
    logoURI:
      'https://raw.githubusercontent.com/Joblo197/Crypto/main/Shroom%20Logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  MELENYE: {
    address: 'Cer9R2rqUEyemrgCWu5SsJu5d52sdZfC2gCYpwBhtEbB',
    chainId: 101,
    decimals: 6,
    name: 'melenye tremp',
    symbol: 'MELENYE',
    logoURI:
      'https://bafkreifn4atgecyr2xgzmkvl32aux2zar4boizpzkeoum3boi6ipoy6fhq.ipfs.nftstorage.link',
    tags: ['community'],
  },
  G: {
    address: 'FmqVMWXBESyu4g6FT1uz1GABKdJ4j6wbuuLFwPJtqpmu',
    chainId: 101,
    decimals: 9,
    name: "Andrew Tate's Dog",
    symbol: 'G',
    logoURI:
      'https://node1.irys.xyz/A0B7CXZE07qA3HgU83gd021CBPbVfaJjO8ooIDLHmC0',
    tags: ['community'],
  },
  DITH: {
    address: 'E1kvzJNxShvvWTrudokpzuc789vRiDXfXG3duCuY6ooE',
    chainId: 101,
    decimals: 9,
    name: 'Dither',
    symbol: 'DITH',
    logoURI:
      'https://zxejrbfcjk7fa67anpyuavtssrkgvbek4onzbza2v56flew72cta.arweave.net/zciYhKJKvlB74GvxQFZylFRqhIrjm5DkGq98VZLf0KY?ext=png',
    tags: ['community'],
    extensions: { coingeckoId: 'dither' },
  },
  JSOL: {
    address: '7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn',
    chainId: 101,
    decimals: 9,
    name: 'JPOOL Solana Token',
    symbol: 'JSOL',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn/logo.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'jpool' },
  },
  ROBERT: {
    address: 'AoN2z7w7ccQJQiWS7rjS45dcyYkVkBddXDcrzmj69tqf',
    chainId: 101,
    decimals: 3,
    name: 'Robert',
    symbol: 'ROBERT',
    logoURI:
      'https://raw.githubusercontent.com/scoops0/Robert-Logo/main/Robert%20Logo.webp',
    tags: ['community'],
  },
  SNY: {
    address: '4dmKkXNHdgYsXqBHCuMikNQWwVomZURhYvkkX5c4pQ7y',
    chainId: 101,
    decimals: 6,
    name: 'Synthetify',
    symbol: 'SNY',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4dmKkXNHdgYsXqBHCuMikNQWwVomZURhYvkkX5c4pQ7y/logo.png',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'synthetify-token' },
  },
  MUZKI: {
    address: 'C1kzNkFfgdtP8VF1pFYA4S32RLPqk5KPaurCaQJwxfWb',
    chainId: 101,
    decimals: 6,
    name: 'MUZKI',
    symbol: 'MUZKI',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmQX2fKJCmgJQkfsehQUyWdEeobRmup5RwVLnU9RJqCPwh',
    tags: ['community'],
    extensions: { coingeckoId: 'muzki' },
  },
  PPP: {
    address: '9HoRXnCcXdTWA1YhYJoPVpp9YByYS3Y8nVV42z1MTBoV',
    chainId: 101,
    decimals: 6,
    name: 'Piexeled Pepe',
    symbol: 'PPP',
    logoURI:
      'https://bafybeiavluzjellfa3w2e4lhn2wmypqpznhmogywt3mqy3llzveg722yau.ipfs.cf-ipfs.com/',
    tags: ['community'],
  },
  KITTI: {
    address: 'B5Fvzd2RL5ctrmFsvDafXiNGbBqbxapiryJo8JfoSEcA',
    chainId: 101,
    decimals: 5,
    name: 'KITTI TOKEN',
    symbol: 'KITTI',
    logoURI: 'https://arweave.net/I1RzcnSel2oghDkMBE-lZBV9b6AuyDJc0Ywo6QmDlSQ',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'kitti' },
  },
  ZAZU: {
    address: 'n7EksMkvk3WT5FjQ3HBMLm9XYU3EnoXUSJ7PoWpxsoG',
    chainId: 101,
    decimals: 6,
    name: 'ZAZU',
    symbol: 'ZAZU',
    logoURI:
      'https://bafybeielrggtbcg4rbeab7t4e735vovjksd4q52aaebeyclyzfwa2kbbr4.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'zazu' },
  },
  OGCINU: {
    address: '5doZSgpsKVJk9u58hsmDsq8N6oNtELvsycoFJ42P327p',
    chainId: 101,
    decimals: 6,
    name: 'The OG Cheems Inu',
    symbol: 'OGCINU',
    logoURI: 'https://i.im.ge/2024/03/08/8vC1Br.Profile02Artboard-62x.png',
    tags: ['community'],
    extensions: { coingeckoId: 'the-og-cheems-inu' },
  },
  VINU: {
    address: 'CgbJxXyaHeU8VsquBpySuFXA94b6LWXxioZ28wRr8fs9',
    chainId: 101,
    decimals: 6,
    name: 'Viral Inu',
    symbol: 'VINU',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CgbJxXyaHeU8VsquBpySuFXA94b6LWXxioZ28wRr8fs9/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'viral-inu' },
  },
  TOOL: {
    address: 'tooLsNYLiVqzg8o4m3L2Uetbn62mvMWRqkog6PQeYKL',
    chainId: 101,
    decimals: 0,
    name: 'Toolkit',
    symbol: 'TOOL',
    logoURI: 'https://storage.googleapis.com/nft-assets/items/TOOL.png',
    tags: ['community', 'solana-fm'],
  },
  BOC: {
    address: '47psVap1QaXYYEPhFn7pZuvR8m9GQaX8wDodZuY3fWL7',
    chainId: 101,
    decimals: 6,
    name: 'Book of cartoons',
    symbol: 'BOC',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f6261666b7265696236373368766f6e6d676f71656b6875707a616b66796668363573796575616868726267366d636f62657a656e75686b6a6578712e697066732e6e667473746f726167652e6c696e6b',
    tags: ['community'],
  },
  GUAC: {
    address: 'AZsHEMXd36Bj1EMNXhowJajpUXzrKcK57wW4ZGXVa7yR',
    chainId: 101,
    decimals: 5,
    name: 'Guacamole',
    symbol: 'GUAC',
    logoURI:
      'https://bafkreiajgw65o4ryczf7cadb43rzptvb62z4msoqr3c4625hdbdbntcydq.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'guacamole' },
  },
  DKM: {
    address: 'HtbhBYdcfXbbD2JiH6jtsTt2m2FXjn7h4k6iXfz98k5W',
    chainId: 101,
    decimals: 9,
    name: 'DeadKnight Token',
    symbol: 'DKM',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HtbhBYdcfXbbD2JiH6jtsTt2m2FXjn7h4k6iXfz98k5W/dkmlogo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  STSHIP: {
    address: 'GHtLF7drbYXGTHX73uSxqPKkJUzDqcBNe2M9fzjJzr3j',
    chainId: 101,
    decimals: 8,
    name: 'StarShip🚀',
    symbol: 'STSHIP',
    logoURI:
      'https://gateway.irys.xyz/JUL1T_9NFtLEDZGtY-4pKopYplV7bszLhp5pCl2fKzc',
    tags: ['community'],
    extensions: { coingeckoId: 'starship-4' },
  },
  BEAVER: {
    address: 'M9i5xQz8Z2Ua3VHuBkjBSkP5HYwdetu7N9RP5VUsW4z',
    chainId: 101,
    decimals: 6,
    name: 'Chinese Beaver',
    symbol: 'BEAVER',
    logoURI:
      'https://gateway.irys.xyz/vhQEI53x9msYz5gHSBofNA1oljDH9xKjGAqkQmge-1o',
    tags: ['community'],
  },
  $WEN: {
    address: '3J5QaP1zJN9yXE7jr5XJa3Lq2TyGHSHu2wssK7N1Aw4p',
    chainId: 101,
    decimals: 6,
    name: 'WEN',
    symbol: '$WEN',
    logoURI: 'https://arweave.net/sfrKTJisIlWsNJfw6GWnsitsbUbN6WIjoVJZWMe2Mdk',
    tags: ['community'],
    extensions: { coingeckoId: 'wen-2' },
  },
  BURGUM: {
    address: 'A7nt9kPpgGZFRjdgZ5EDUD53UpsodwdxRJYPHESwMXSC',
    chainId: 101,
    decimals: 9,
    name: 'DAWG BURGUM',
    symbol: 'BURGUM',
    logoURI:
      'https://img.fotofolio.xyz/?url=https%3A%2F%2Fsilver-far-lark-483.mypinata.cloud%2Fipfs%2FQmWQr4Gi8pQmjBBxEb2695c6cguJQxR4nFA3MsAXUmWfRd',
    tags: ['community'],
  },
  Harold: {
    address: 'B8AmDZRJeHuq8CPciey6jocq9p4ivc4ZurDXPwtqs2Qg',
    chainId: 101,
    decimals: 6,
    name: 'Hide the pain Harold',
    symbol: 'Harold',
    logoURI:
      'https://bafybeihtro4yen75sdqt5rc7s5fcjlfb2ts3xvsvibkm63eoe56ape32cu.ipfs.cf-ipfs.com/',
    tags: ['community'],
  },
  LEIA: {
    address: '7usVzynPTUJ9czdS96ezm9C6Z3hCsjb7j6TMKipURyyQ',
    chainId: 101,
    decimals: 6,
    name: 'Leia',
    symbol: 'LEIA',
    logoURI:
      'https://bafkreifjempcywq7od7svwed4ds4dieemifdy4tpguba2kv5zhmapi6ufm.ipfs.nftstorage.link',
    tags: ['community', 'token-2022'],
    extensions: { coingeckoId: 'leia-the-cat' },
  },
  CHIPI: {
    address: 'chiPiQTvkQ7oPtAD7YLQaEeHmPqXCa2wcRQdwFNneTe',
    chainId: 101,
    decimals: 9,
    name: 'chipi',
    symbol: 'CHIPI',
    logoURI:
      'https://dd.dexscreener.com/ds-data/tokens/solana/chiPiQTvkQ7oPtAD7YLQaEeHmPqXCa2wcRQdwFNneTe.png?size=lg&key=d4255f',
    tags: ['community'],
    extensions: { coingeckoId: 'chipi' },
  },
  RATIO: {
    address: 'ratioMVg27rSZbSvBopUvsdrGUzeALUfFma61mpxc8J',
    chainId: 101,
    decimals: 6,
    name: 'Ratio Governance Token',
    symbol: 'RATIO',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ratioMVg27rSZbSvBopUvsdrGUzeALUfFma61mpxc8J/logo.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'ratio-finance' },
  },
  VROOM: {
    address: 'KARTdF5K68Q2nGppizG3DeCzp7AhHy6YXf2uTQjBSQx',
    chainId: 101,
    decimals: 0,
    name: 'Mushroom Racers Token',
    symbol: 'VROOM',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KARTdF5K68Q2nGppizG3DeCzp7AhHy6YXf2uTQjBSQx/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  MATICpo: {
    address: 'Gz7VkD4MacbEB6yC5XD3HcumEiYx2EtDYYrfikGsvopG',
    chainId: 101,
    decimals: 8,
    name: 'MATIC (Portal from Polygon)',
    symbol: 'MATICpo',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/MATICpo_wh.png',
    tags: ['wormhole', 'solana-fm'],
    extensions: { coingeckoId: 'matic-wormhole' },
  },
  ARG: {
    address: '9XRpjZjhJPeWtUymiEWn3FW7uAnMeQca14ucTWWWyP2g',
    chainId: 101,
    decimals: 6,
    name: 'ArgentinaCoin',
    symbol: 'ARG',
    logoURI:
      'https://ipfs.io/ipfs/QmeZBCD8fvvp53LFQf3xHWVsvwwtNZGZn3VZFHC2m8EKFE',
    tags: ['community'],
    extensions: { coingeckoId: 'argentinacoin' },
  },
  $BULL: {
    address: '7ZYeCVdsXRFfh7TC5JRfBaZxQ6UhF5nNNdNtRzrdsDYF',
    chainId: 101,
    decimals: 9,
    name: 'Bull Run',
    symbol: '$BULL',
    logoURI:
      'https://bafkreigiqxec7djkmoapzfuht2pz2gig4v4ut744orfajocfomax572cue.ipfs.dweb.link',
    tags: ['community'],
    extensions: { coingeckoId: 'bull-run-solana' },
  },
  XGLI: {
    address: 'FsPncBfeDV3Uv9g6yyx1NnKidvUeCaAiT2NtBAPy17xg',
    chainId: 101,
    decimals: 6,
    name: 'Glitter Finance',
    symbol: 'XGLI',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FsPncBfeDV3Uv9g6yyx1NnKidvUeCaAiT2NtBAPy17xg/logo.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'glitter-finance' },
  },
  SLND: {
    address: 'SLNDpmoWTVADgEdndyvWzroNL7zSi1dF9PC3xHGtPwp',
    chainId: 101,
    decimals: 6,
    name: 'Solend',
    symbol: 'SLND',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLNDpmoWTVADgEdndyvWzroNL7zSi1dF9PC3xHGtPwp/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'solend' },
  },
  zippySOL: {
    address: 'Zippybh3S5xYYam2nvL6hVJKz1got6ShgV4DyD1XQYF',
    chainId: 101,
    decimals: 9,
    name: 'Zippy Staked SOL',
    symbol: 'zippySOL',
    logoURI: 'https://www.zippystake.org/mint.png',
    tags: ['community'],
    extensions: { coingeckoId: 'zippy-staked-sol' },
  },
  DRIFT: {
    address: 'DriFtupJYLTosbwoN8koMbEYSx54aFAVLddWsbksjwg7',
    chainId: 101,
    decimals: 6,
    name: 'Drift',
    symbol: 'DRIFT',
    logoURI: 'https://metadata.drift.foundation/drift.png',
    tags: ['community'],
    extensions: { coingeckoId: 'drift-protocol' },
  },
  FRAX: {
    address: 'FR87nWEUxVgerFGhZM8Y4AggKGLnaXswr1Pd8wZ4kZcp',
    chainId: 101,
    decimals: 8,
    name: 'Frax (Portal)',
    symbol: 'FRAX',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/FRAX_wh.png',
    tags: ['wormhole', 'old-registry'],
    extensions: { coingeckoId: 'frax' },
  },
  NIGI: {
    address: '3boRKAxWR6weV6kufr9ykdLcm9cL5q2p469tCqeCAnHy',
    chainId: 101,
    decimals: 6,
    name: 'Nigi',
    symbol: 'NIGI',
    logoURI:
      'https://bafybeigymdqageaw2wzmjtdquhsqjycysmzulh7yhtmzxvmgo6jpewuucu.ipfs.dweb.link',
    tags: ['community'],
    extensions: { coingeckoId: 'nigi' },
  },
  WHEY: {
    address: 'Ue4yjkPjA4QGis37eWbBsnqfzyK83BtY4AioDETp3Ab',
    chainId: 101,
    decimals: 6,
    name: 'WHEY',
    symbol: 'WHEY',
    logoURI:
      'https://cdn.discordapp.com/attachments/1052450866243850280/1076846325951365161/Coin_2.png',
    tags: ['community'],
    extensions: { coingeckoId: 'whey-token' },
  },
  SVNN: {
    address: 'DbM7mcJM9zitHanzKmFf7NH4SaEZZDCf5TPEgzwTmuh4',
    chainId: 101,
    decimals: 6,
    name: 'Savanna Haus',
    symbol: 'SVNN',
    logoURI: 'https://elephant.money/img/logo/savanna-haus-256.png',
    tags: ['community'],
  },
  USDTav: {
    address: 'Kz1csQA91WUGcQ2TB3o5kdGmWmMGp8eJcDEyHzNDVCX',
    chainId: 101,
    decimals: 6,
    name: 'Tether USD (Portal from Avalanche)',
    symbol: 'USDTav',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/USDTav_wh.png',
    tags: ['wormhole'],
  },
  GMSOL: {
    address: 'gmdu3snwW28DmmxCseChp9owWLUhamH9eS3hWfHG8Vg',
    chainId: 101,
    decimals: 9,
    name: 'We Say gm',
    symbol: 'GMSOL',
    logoURI: 'https://github.com/icnivadmai/gm-logo/blob/master/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  SIXY: {
    address: 'GKNr1Gwf7AMvEMEyMzBoEALVBvCpKJue9Lzn9HfrYYhg',
    chainId: 101,
    decimals: 9,
    name: '611Coin',
    symbol: 'SIXY',
    logoURI: 'https://raw.githubusercontent.com/dc215007/611/main/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  SHARK: {
    address: 'SHARKSYJjqaNyxVfrpnBN9pjgkhwDhatnMyicWPnr1s',
    chainId: 101,
    decimals: 6,
    name: 'Sharky',
    symbol: 'SHARK',
    logoURI:
      'https://shdw-drive.genesysgo.net/2F9tju1WHwocrTHj2iRoLhhVqbgQJusdUgkYNL84wKf4/$SHARK_logo_round.png',
    tags: ['community'],
    extensions: { coingeckoId: 'sharky-fi' },
  },
  USA: {
    address: '69kdRLyP5DTRkpHraaSZAQbWmAwzF9guKjZfzMXzcbAs',
    chainId: 101,
    decimals: 6,
    name: 'American Coin',
    symbol: 'USA',
    logoURI: 'https://arweave.net/xUs-YuP__T2cCUofTOJmYcHIzFHj5s8TdH-O-g9qn3w',
    tags: ['community'],
    extensions: { coingeckoId: 'american-coin' },
  },
  soba: {
    address: '25p2BoNp6qrJH5As6ek6H7Ei495oSkyZd3tGb97sqFmH',
    chainId: 101,
    decimals: 6,
    name: 'SolBastard',
    symbol: 'soba',
    logoURI:
      'https://img.fotofolio.xyz/?url=https%3A%2F%2Fcf-ipfs.com%2Fipfs%2FQmW1VaQ31oKGUeRnXiohvrZXzu1ETBWK3rM4haVYnfShXo',
    tags: ['community'],
    extensions: { coingeckoId: 'sol-bastard' },
  },
  ATR: {
    address: 'ATRLuHph8dxnPny4WSNW7fxkhbeivBrtWbY6BfB4xpLj',
    chainId: 101,
    decimals: 9,
    name: 'Artrade Token',
    symbol: 'ATR',
    logoURI: 'https://artrade.app/logo.png',
    tags: ['community'],
    extensions: { coingeckoId: 'artrade' },
  },
  FIW: {
    address: 'FeKmTunVrXDKEoDJbuTwZi8vfFFw3MHzpPB79JD8ARYU',
    chainId: 101,
    decimals: 6,
    name: '777FuckIlluminatiWorldwide',
    symbol: 'FIW',
    logoURI:
      'https://gateway.irys.xyz/L6E7LMN7Trvj-Iv4EzvkDqL205JVxGOHoY67LUr8Ytg',
    tags: ['community'],
    extensions: { coingeckoId: '777fuckilluminatiworldwid' },
  },
  WIFE: {
    address: '4y3oUrsJfSp431R3wJrWiaLxRPsnYtpkVJmoV2bYpBiy',
    chainId: 101,
    decimals: 6,
    name: 'wifejak',
    symbol: 'WIFE',
    logoURI:
      'https://wifejak.nyc3.cdn.digitaloceanspaces.com/wifejak-200x200.png',
    tags: ['community'],
    extensions: { coingeckoId: 'wifejak' },
  },
  harold: {
    address: 'EjmDTt8G3T725eFSV7oWmGD8J848guo3LZ1EB3RfwGSw',
    chainId: 101,
    decimals: 9,
    name: 'harold',
    symbol: 'harold',
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://bafkreihx4kdgg6floepe6xbb23n2ksjsqeuwtg2fajg2enorvxyu735wku.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'harold' },
  },
  SAC: {
    address: 'GZL4yjPohDShW4RofJ6dEWu2Fv7qEa5mBT7Dpje5hqe7',
    chainId: 101,
    decimals: 2,
    name: 'Stoned Ape Crew',
    symbol: 'SAC',
    logoURI:
      'https://dl.airtable.com/.attachmentThumbnails/483970a827af847e0b031c7d90d70baf/6cc644f1',
    tags: ['community', 'solana-fm'],
  },
  omaba: {
    address: 'AZ7ABJtSeZoFHhNzWhMVREMgGKZVCN8nEZwQfyZdALSZ',
    chainId: 101,
    decimals: 9,
    name: 'borack omaba',
    symbol: 'omaba',
    logoURI:
      'https://ipfs.io/ipfs/QmXh4hdF37yyPmsDys1LzLuvBbADAVqSHPEb75GHfchdy3',
    tags: ['community'],
  },
  GOFX: {
    address: 'GFX1ZjR2P15tmrSwow6FjyDYcEkoFb4p4gJCpLBjaxHD',
    chainId: 101,
    decimals: 9,
    name: 'GooseFX',
    symbol: 'GOFX',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GFX1ZjR2P15tmrSwow6FjyDYcEkoFb4p4gJCpLBjaxHD/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'goosefx' },
  },
  ICC: {
    address: 'EQGG5muKhviWmWJwy4Fi9TeeNpJUX7RpHAwkQMnTAyAj',
    chainId: 101,
    decimals: 9,
    name: 'Indian Call Center',
    symbol: 'ICC',
    logoURI:
      'https://bafkreicn4czqu7x72v6hqsi5uiu4a3oumhmlg7a6frmdh65kifhrc3naxm.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'indian-call-center' },
  },
  CREAMY: {
    address: 'CREAMpdDimXxj2zTCwP5wMEtba4NYaKCrTBEQTSKtqHe',
    chainId: 101,
    decimals: 9,
    name: 'Creamy',
    symbol: 'CREAMY',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CREAMpdDimXxj2zTCwP5wMEtba4NYaKCrTBEQTSKtqHe/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'creamy' },
  },
  SBF: {
    address: 'FkbWN4dcFQym2PgCELfThghQqLuA2e2jThMJyhZjfG4M',
    chainId: 101,
    decimals: 6,
    name: 'SBF',
    symbol: 'SBF',
    logoURI:
      'https://bafkreifhqihaiwyo4g2aogdu4qyfqftkxy3aq4xxbhoxdkbkufrobsnjwm.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'sam-bankmeme-fried' },
  },
  PRNT: {
    address: '4TUNzcgp2fPD48fcW4seRjyqyDZMrPj4ZubnXFEsKeYk',
    chainId: 101,
    decimals: 7,
    name: 'PRNT',
    symbol: 'PRNT',
    logoURI:
      'https://shdw-drive.genesysgo.net/EYH4kEfffWwRnF63G6Sx68sHdhkue4trzuSeUji9UJSe/prnt.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'prnt' },
  },
  MOOSK: {
    address: '3KroURETdWLyFReapbdh6nTWQgS7Y6rorLY8jb1pJXoM',
    chainId: 101,
    decimals: 6,
    name: 'elawn moosk',
    symbol: 'MOOSK',
    logoURI:
      'https://bafkreicqvacwy7uphmxa6lr7ssokinvjjqd6j3poidtg5jww5ckqu6jp7u.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'elawn-moosk' },
  },
  EGO: {
    address: '2zjmVX4KDWtNWQyJhbNXgpMmsAoXwftB7sGV4qvV18xa',
    chainId: 101,
    decimals: 9,
    name: 'Shear Ego Coin',
    symbol: 'EGO',
    logoURI: 'https://raw.githubusercontent.com/jcards26/sol/main/new-new.png',
    tags: ['old-registry'],
  },
  COOL: {
    address: '3wNmRxyZPzDWVAydsF3VuNKCNk89A8uSo7EzTnubDJcR',
    chainId: 101,
    decimals: 9,
    name: 'CoolCoin',
    symbol: 'COOL',
    logoURI:
      'https://bafkreigdop6clz6yo46or5cxdqoxund5jfz2iyrrr7pfixrxgpcfcaqlau.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  PRISM: {
    address: 'PRSMNsEPqhGVCH1TtWiJqPjJyh2cKrLostPZTNy1o5x',
    chainId: 101,
    decimals: 6,
    name: 'PRISM',
    symbol: 'PRISM',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PRSMNsEPqhGVCH1TtWiJqPjJyh2cKrLostPZTNy1o5x/logo.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'prism' },
  },
  lanternSOL: {
    address: 'LnTRntk2kTfWEY6cVB8K9649pgJbt6dJLS1Ns1GZCWg',
    chainId: 101,
    decimals: 9,
    name: 'Lantern Staked SOL',
    symbol: 'lanternSOL',
    logoURI: 'https://arweave.net/juI8rxciTff6pljaDiXMEq5klSOngQol_8lvyokGGwY',
    tags: ['community'],
    extensions: { coingeckoId: 'lantern-staked-sol' },
  },
  BOO: {
    address: 'boooCKXQn9YTK2aqN5pWftQeb9TH7cj7iUKuVCShWQx',
    chainId: 101,
    decimals: 9,
    name: 'Boo Network',
    symbol: 'BOO',
    logoURI: 'https://arweave.net/Reo2_w4k9PGdtYX3p8BllNZpSbbIJ-zype0qdbQgsoE',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'ghostkids' },
  },
  TIM: {
    address: 'DWjVPqEX4fPFQ47Xb7EegpGhgWzRviYfPoEWPnxz2CRd',
    chainId: 101,
    decimals: 9,
    name: 'TIM',
    symbol: 'TIM',
    logoURI:
      'https://ipfs.io/ipfs/QmTn8PTD3hvwokevqGZ18xEy8T4tMXZbkwhbxbWFRABbaM',
    tags: ['community'],
    extensions: { coingeckoId: 'floating-tim' },
  },
  WINE: {
    address: 'DoVRzHXXicZucnuskdMg7DePhBHFkhvvhta5nf1deFoQ',
    chainId: 101,
    decimals: 6,
    name: 'Wine',
    symbol: 'WINE',
    logoURI:
      'https://ipfs.io/ipfs/QmPBZhPerrYygkw8gwDgs7yhyTbi8diBQ26PwcgcTQmPuZ',
    tags: ['community'],
  },
  pSOL: {
    address: '9EaLkQrbjmbbuZG9Wdpo8qfNUEjHATJFSycEmw6f1rGX',
    chainId: 101,
    decimals: 9,
    name: 'pSOL (Parrot SOL)',
    symbol: 'pSOL',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9EaLkQrbjmbbuZG9Wdpo8qfNUEjHATJFSycEmw6f1rGX/logo.svg',
    tags: ['old-registry'],
  },
  SHARDS: {
    address: '8j3hXRK5rdoZ2vSpGLRmXtWmW6iYaRUw5xVk4Kzmc9Hp',
    chainId: 101,
    decimals: 9,
    name: 'SolChicks Shards',
    symbol: 'SHARDS',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8j3hXRK5rdoZ2vSpGLRmXtWmW6iYaRUw5xVk4Kzmc9Hp/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'solchicks-shards' },
  },
  SHUT: {
    address: '9e6nnqbsTjWx3ss6a3x7Q9ZvpupLNYLb8cTbVmm6UD2K',
    chainId: 101,
    decimals: 9,
    name: 'Shuttlecoin',
    symbol: 'SHUT',
    logoURI:
      'https://raw.githubusercontent.com/Mikkel76543/crypto9/main/logo.png',
    tags: ['old-registry'],
  },
  acat: {
    address: 'acatzTjUeHDT3SoufN6NMxGUmBFtoqFHnFwusdw8kYX',
    chainId: 101,
    decimals: 6,
    name: 'Apple Cat',
    symbol: 'acat',
    logoURI:
      'https://bafkreifcnqbrplil4wqfd54ey3tw25c3haucpzr6f6acltfee3pbprkgzq.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'apple-cat' },
  },
  CDBD: {
    address: '55bhM86E8brNHWy7sNz4chqtfQtrVGDvaeWyVczJs8RC',
    chainId: 101,
    decimals: 6,
    name: 'COINDASHBOARD',
    symbol: 'CDBD',
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://sapphire-lazy-macaw-504.mypinata.cloud/ipfs/QmdpPuYPshppAWLBSGHpWqzxQGaX2RnesTdqPeg4dE44Md',
    tags: ['community'],
  },
  AMMO: {
    address: 'ammoK8AkX2wnebQb35cDAZtTkvsXQbi82cGeTnUvvfK',
    chainId: 101,
    decimals: 0,
    name: 'Ammunition',
    symbol: 'AMMO',
    logoURI: 'https://storage.googleapis.com/nft-assets/items/AMMO.png',
    tags: ['community'],
  },
  KLGT: {
    address: '519W6ZZnduudeo6tp1YMgeWiNFeoq4kTVS4uMZggKE5r',
    chainId: 101,
    decimals: 9,
    name: 'Kleyne Gelt',
    symbol: 'KLGT',
    logoURI: 'https://raw.githubusercontent.com/klglt/Crypto/main/menorah.png',
    tags: ['old-registry'],
  },
  OSAK: {
    address: 'GoxLaNFQiqnV97p7aRGP4ghvLZ4GwJN9NUNPpozvJZCV',
    chainId: 101,
    decimals: 4,
    name: 'Osaka Protocol (Wormhole)',
    symbol: 'OSAK',
    logoURI:
      'https://raw.githubusercontent.com/osakaprotocol/assets/main/brand/OSAK_LOGO_300px.png',
    tags: ['community'],
  },
  SOLR: {
    address: '7j7H7sgsnNDeCngAPjpaCN4aaaru4HS7NAFYSEUyzJ3k',
    chainId: 101,
    decimals: 6,
    name: 'SolRazr',
    symbol: 'SOLR',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7j7H7sgsnNDeCngAPjpaCN4aaaru4HS7NAFYSEUyzJ3k/SOLR.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'solrazr' },
  },
  GMEOW: {
    address: 'A5LCTQ1vJECCQWSXJYs3rfCoexctbUgTCAEKDuNye8bZ',
    chainId: 101,
    decimals: 6,
    name: 'gmeow cat',
    symbol: 'GMEOW',
    logoURI:
      'https://node1.irys.xyz/ytwHExA-ApqQz4dgdDxBN5rkqw_NkwWveTR2qTGffuM',
    tags: ['community'],
    extensions: { coingeckoId: 'gmeow-cat' },
  },
  QDOT: {
    address: 'HCpyiCXvziKByTGoQMLUmooAWBkCLvZmzB9D6TyPdACR',
    chainId: 101,
    decimals: 8,
    name: 'QDOT',
    symbol: 'QDOT',
    logoURI:
      'https://www.arweave.net/qyhe_8Lmd82_NWRwxdfPX0fm_CItn18KXV1Skp-x6N0?ext=jpg',
    tags: ['old-registry'],
  },
  DPLN: {
    address: 'J2LWsSXx4r3pYbJ1fwuX5Nqo7PPxjcGPpUb2zHNadWKa',
    chainId: 101,
    decimals: 6,
    name: 'DePlan',
    symbol: 'DPLN',
    logoURI:
      'https://jprwutcw237zskmqfiyq72df2gx3o4r3imjdd2dx4ygfvyt524ua.arweave.net/S-NqTFbW_5kpkCoxD-hl0a-3cjtDEjHod-YMWuJ91yg',
    tags: ['community'],
    extensions: { coingeckoId: 'deplan' },
  },
  CWAR: {
    address: 'HfYFjMKNZygfMC8LsQ8LtpPsPxEJoXJx4M6tqi75Hajo',
    chainId: 101,
    decimals: 9,
    name: 'Cryowar Token',
    symbol: 'CWAR',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HfYFjMKNZygfMC8LsQ8LtpPsPxEJoXJx4M6tqi75Hajo/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'cryowar-token' },
  },
  SWOLE: {
    address: 'sio28ienC3iABUKJFzkikHknbR3xyhjzhJE34tipyDP',
    chainId: 101,
    decimals: 9,
    name: 'SWOLE',
    symbol: 'SWOLE',
    logoURI:
      'https://ipfs.io/ipfs/Qmf4Sb9NetKYYUAVGryfAxRXZcLdzkxEpKmoxjV5EvNz9N',
    tags: ['community'],
  },
  FC: {
    address: 'DAt9V3J3Cv959NRVmoENNfrqhTB68g2B6ovU6r9tajrG',
    chainId: 101,
    decimals: 6,
    name: 'Floki Cat',
    symbol: 'FC',
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://cf-ipfs.com/ipfs/QmSVuLQ7XvTWENqHSgufhQFW5vGYxkx8D2VzGCtXxjfBxf',
    tags: ['community'],
  },
  JASON: {
    address: '6SUryVEuDz5hqAxab6QrGfbzWvjN8dC7m29ezSvDpump',
    chainId: 101,
    decimals: 6,
    name: 'Jason Derulo',
    symbol: 'JASON',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d61366f715a6e57577a645379774a373846336378594a6b4537634c3535514e4a69656a756a35314b4b72314b',
    tags: ['community'],
    extensions: { coingeckoId: 'jason-derulo' },
  },
  BZONE: {
    address: '6bE23pRia6rvShfBhkFiWF5fRPWXyG6EYvoMvvLss91p',
    chainId: 101,
    decimals: 6,
    name: 'BANANA ZONE',
    symbol: 'BZONE',
    logoURI:
      'https://bafybeiffi5g5uinyxlq3ce2htpeiwb77lldkt5gcac7bhgolrlroipgaqi.ipfs.cf-ipfs.com/',
    tags: ['community'],
  },
  mockJUP: {
    address: 'JxxWsvm9jHt4ah7DT9NuLyVLYZcZLUdPD93PcPQ71Ka',
    chainId: 101,
    decimals: 9,
    name: 'mockJUP',
    symbol: 'mockJUP',
    logoURI:
      'https://i.pinimg.com/originals/cb/87/f6/cb87f6e8152961be45a5642ef72c391f.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'mockjup' },
  },
  MXM: {
    address: 'H53UGEyBrB9easo9ego8yYk7o4Zq1G5cCtkxD3E3hZav',
    chainId: 101,
    decimals: 6,
    name: 'MXM',
    symbol: 'MXM',
    logoURI: 'https://assets.mixmob.io/images/MXM_Token.png',
    tags: ['community'],
    extensions: { coingeckoId: 'mixmob' },
  },
  CHAD: {
    address: 'G7rwEgk8KgQ4RUTnMy2W2i7dRDq4hXHD4CSp9PSmSbRW',
    chainId: 101,
    decimals: 9,
    name: 'CHAD',
    symbol: 'CHAD',
    logoURI:
      'https://bafybeicl7hj53czkh3vqclya2tltuuj3f4r7acwbchwjjajfv3jvff62iu.ipfs.nftstorage.link/',
    tags: ['community', 'token-2022'],
    extensions: { coingeckoId: 'chad-on-solana' },
  },
  BSKT: {
    address: '6gnCPhXtLnUD76HjQuSYPENLSZdG8RvDB1pTLM5aLSJA',
    chainId: 101,
    decimals: 5,
    name: 'Basket',
    symbol: 'BSKT',
    logoURI:
      'https://gateway.irys.xyz/69o2b3_ekRo4nosnyyXc9piS7d7-8u-h7MNviNzXFYA',
    tags: ['community'],
  },
  MMA: {
    address: 'MMAx26JtJgSWv6yH48nEHCGZcVvRbf9Lt9ALa7jSipe',
    chainId: 101,
    decimals: 9,
    name: 'mma',
    symbol: 'MMA',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MMAx26JtJgSWv6yH48nEHCGZcVvRbf9Lt9ALa7jSipe/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  $SWTS: {
    address: '5SwxhEunuUUcWe4ojybdDwky6dpLxAehNmF4AA71STNh',
    chainId: 101,
    decimals: 5,
    name: 'SWEETS',
    symbol: '$SWTS',
    logoURI: 'https://arweave.net/TIiNLtJtWuC0yxXPXXQpXalXyGaUFp4rT7h8WG5EwH4',
    tags: ['community'],
    extensions: { coingeckoId: 'sweets' },
  },
  AKIRA: {
    address: 'FqSkp1BxNKEaLeyZPA7REd47aQMDUzz8fsg1f3rtKrJh',
    chainId: 101,
    decimals: 4,
    name: 'AKIRA',
    symbol: 'AKIRA',
    logoURI:
      'https://ipfs.io/ipfs/Qma1waVii5NahQHyNi1CGzhEvUtp5MAj5SiD9MKQhXL5tc?filename=photo_2024-05-04_19-23-34.jpg',
    tags: ['community'],
  },
  HYPE: {
    address: '8TQdiAzdZZEtkWUR8Zj1tqDYGPv9TR1XjPqrew39Vq9V',
    chainId: 101,
    decimals: 7,
    name: 'HypeProxy',
    symbol: 'HYPE',
    logoURI:
      'https://raw.githubusercontent.com/hypeproxy/hypeproxy/main/logo.png',
    tags: ['old-registry'],
  },
  MBC: {
    address: 'Ee1pKgTQmP5xjYQs76HmRM2c2YkqEdc9tk5mQbiGFigT',
    chainId: 101,
    decimals: 9,
    name: 'Mad Bears Club',
    symbol: 'MBC',
    logoURI: 'https://arweave.net/7jp6IaYj-kCiCDuHzk-zJLf2wRctrhIa1m_PO0rQuig',
    tags: ['community'],
    extensions: { coingeckoId: 'mad-bears-club-2' },
  },
  HOWIE: {
    address: '9CQLBCa7n6sf1LTB6rZHJxEmtVWfBk9cRxEWTq6ygQHG',
    chainId: 101,
    decimals: 6,
    name: 'Howerd Sturn',
    symbol: 'HOWIE',
    logoURI:
      'https://bafybeigimaxgtna56kxqbkoxjktpmcvtn4skziel434z7r4y3rcumquuya.ipfs.cf-ipfs.com',
    tags: ['community'],
  },
  hiji: {
    address: 'EjErrBoCw7eWYkMfimhPckaPSuBukyhUYwv2dLJYDWB7',
    chainId: 101,
    decimals: 6,
    name: 'hiji',
    symbol: 'hiji',
    logoURI:
      'https://bafybeiad4uvvie6qhyuo7qajzhdmx7dawela534l2kbgo4fbift37irsxm.ipfs.cf-ipfs.com/',
    tags: ['community'],
  },
  CHINGON: {
    address: 'BpFmEt9efz3ekb7g35DuWiywX3o9nfsRANX76D1g6fNk',
    chainId: 101,
    decimals: 6,
    name: 'Mexico Chingon',
    symbol: 'CHINGON',
    logoURI:
      'https://photos.pinksale.finance/file/pinksale-logo-upload/1713555096483-cdead6d4759893fd0452f1938b40f44f.png',
    tags: ['community'],
    extensions: { coingeckoId: 'mexico-chingon' },
  },
  croc: {
    address: '5V9qpFo8NMFyhYHbrqY2c1TJrvzZSnRJAdj4ryPVB18n',
    chainId: 101,
    decimals: 6,
    name: 'croc cat',
    symbol: 'croc',
    logoURI: 'https://i.imgur.com/An0PFAu.jpeg',
    tags: ['community'],
    extensions: { coingeckoId: 'croc-cat' },
  },
  NoHat: {
    address: '5BKTP1cWao5dhr8tkKcfPW9mWkKtuheMEAU6nih2jSX',
    chainId: 101,
    decimals: 6,
    name: 'DogWifNoHat',
    symbol: 'NoHat',
    logoURI:
      'https://ipfs.io/ipfs/QmYRzwG8GBcbYFgpXQxQm9Nrf3KTnU2EKVwMJktE1n4UPa',
    tags: ['community'],
    extensions: { coingeckoId: 'dogwifnohat' },
  },
  PIPI: {
    address: 'HmTZ1SFRhgp63kgoT64iAwKXZez9Wf3df8r3MFywN8mp',
    chainId: 101,
    decimals: 6,
    name: 'PIPI',
    symbol: 'PIPI',
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://cf-ipfs.com/ipfs/QmcrQpKsAmMHYmdmaxx6DgMZcFExnAMp5TrEn9hdfPGzSg',
    tags: ['community'],
    extensions: { coingeckoId: 'pipi' },
  },
  USDCet: {
    address: 'A9mUU4qviSctJVPJdBJWkb28deg915LYJKrzQ19ji3FM',
    chainId: 101,
    decimals: 6,
    name: 'USD Coin (Portal from Ethereum)',
    symbol: 'USDCet',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/USDCet_wh.png',
    tags: ['wormhole', 'old-registry'],
    extensions: { coingeckoId: 'usd-coin-wormhole-from-ethereum' },
  },
  CKC: {
    address: '8s9FCz99Wcr3dHpiauFRi6bLXzshXfcGTfgQE7UEopVx',
    chainId: 101,
    decimals: 6,
    name: 'ChikinCoin',
    symbol: 'CKC',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8s9FCz99Wcr3dHpiauFRi6bLXzshXfcGTfgQE7UEopVx/logo.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'chikincoin' },
  },
  MILKBAG: {
    address: '2ubuHGFS4VJVxSEpvV3kDwz6JiuXdaAoGMwrwYC87tp8',
    chainId: 101,
    decimals: 9,
    name: 'MILKBAG',
    symbol: 'MILKBAG',
    logoURI:
      'https://www.dextools.io/resources/tokens/logos/solana/2ubuHGFS4VJVxSEpvV3kDwz6JiuXdaAoGMwrwYC87tp8.png?1711033406653',
    tags: ['community'],
    extensions: { coingeckoId: 'milkbag' },
  },
  DATE: {
    address: 'Ce3PSQfkxT5ua4r2JqCoWYrMwKWC5hEzwsrT9Hb7mAz9',
    chainId: 101,
    decimals: 9,
    name: 'SolDate(DATE) Token',
    symbol: 'DATE',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ce3PSQfkxT5ua4r2JqCoWYrMwKWC5hEzwsrT9Hb7mAz9/DATE.svg',
    tags: ['old-registry', 'solana-fm'],
  },
  clBNB: {
    address: '6KnsFQzCrY1VdD9Qub1ZhRcFSnzQRqhMpT2Xn4X5P2cj',
    chainId: 101,
    decimals: 8,
    name: 'Cloned BNB',
    symbol: 'clBNB',
    logoURI: 'https://markets.clone.so/images/assets/on-bnb.svg',
    tags: ['community'],
    extensions: { coingeckoId: 'cloned-bnb' },
  },
  dainSOL: {
    address: '2LuXDpkn7ZWMqufwgUv7ZisggGkSE5FpeHCHBsRgLg3m',
    chainId: 101,
    decimals: 9,
    name: 'dainSOL',
    symbol: 'dainSOL',
    logoURI:
      'https://tca2vwit4gdsuwo3evencavzatf6sdit6e3xpiw374fmid45q55q.arweave.net/mIGq2RPhhypZ2yVI0QK5BMvpDRPxN3ei2_8KxA-dh3s',
    tags: ['community'],
  },
  STARS: {
    address: 'HCgybxq5Upy8Mccihrp7EsmwwFqYZtrHrsmsKwtGXLgW',
    chainId: 101,
    decimals: 6,
    name: 'StarLaunch',
    symbol: 'STARS',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HCgybxq5Upy8Mccihrp7EsmwwFqYZtrHrsmsKwtGXLgW/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'starlaunch' },
  },
  BOK: {
    address: '88tmABf1s3A6jbLD2yLR1tgbGjz68fz3EDrbeSB1JPPc',
    chainId: 101,
    decimals: 9,
    name: 'BONKLANA',
    symbol: 'BOK',
    logoURI:
      'https://gateway.irys.xyz/oJvtfp7SYicoLG72x16OgkU5YK0SXK-krvF5k8_q0jI',
    tags: ['community'],
    extensions: { coingeckoId: 'bonklana' },
  },
  AMC: {
    address: '9jaZhJM6nMHTo4hY9DGabQ1HNuUWhJtm7js1fmKMVpkN',
    chainId: 101,
    decimals: 9,
    name: 'AMC',
    symbol: 'AMC',
    logoURI:
      'https://bafkreic5kaqbao44hmgvcpa2ssah2rrmw7a4kwu7cnihbidoiqi6juiiai.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'amc' },
  },
  FOMO: {
    address: 'Cx9oLynYgC3RrgXzin7U417hNY9D6YB1eMGw4ZMbWJgw',
    chainId: 101,
    decimals: 9,
    name: 'FOMO',
    symbol: 'FOMO',
    logoURI:
      'https://bt2uza2xywhm2tm5kjhm35xlxhtcqb3le272fir7rpnijpfbsnlq.arweave.net/DPVMg1fFjs1NnVJOzfbrueYoB2smv6KiP4vahLyhk1c',
    tags: ['community'],
    extensions: { coingeckoId: 'fomo-2' },
  },
  WHC: {
    address: '9VLtWhS7Zope97RAJ1KBk2n8FHcNDu5QxqXWv1A3WMko',
    chainId: 101,
    decimals: 6,
    name: 'Whales Club',
    symbol: 'WHC',
    logoURI:
      'https://bafybeigielouskhwv5a7f324ngysg7oyitvhea4gntznaami7g6fwpc2wy.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'whales-club' },
  },
  stSOL: {
    address: '7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj',
    chainId: 101,
    decimals: 9,
    name: 'Lido Staked SOL',
    symbol: 'stSOL',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj/logo.png',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'lido-staked-sol' },
  },
  BAG: {
    address: 'Dnpy7ZkE1LvhyTC1hmTb8opJ6g9rgt2oRvN8z7y35WRJ',
    chainId: 101,
    decimals: 9,
    name: 'BIRKIN',
    symbol: 'BAG',
    logoURI:
      'https://bafkreigd6ctqmxdljfkneqijeauaysugvfu3fk2wcm72a54n4b55ptjl7u.ipfs.nftstorage.link',
    tags: ['community'],
  },
  MERTD: {
    address: 'DDRmuJ77t7d6pkBDXE47ZALjSZm3udVGJMgZjZBk41LH',
    chainId: 101,
    decimals: 9,
    name: 'Merted',
    symbol: 'MERTD',
    logoURI:
      'https://bafybeibpf5nm257i63j2ttxrnzsirmmavwn5fj5y24olh5a55ydlugekpe.ipfs.nftstorage.link',
    tags: ['community'],
  },
  WALTER: {
    address: 'FV56CmR7fhEyPkymKfmviKV48uPo51ti9kAxssQqTDLu',
    chainId: 101,
    decimals: 6,
    name: 'walter',
    symbol: 'WALTER',
    logoURI:
      'https://bafybeifzdagclwcdsxidg5giw7oyfaschdtb5atqow3emaqtkignosvpxu.ipfs.dweb.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'walter-dog-solana' },
  },
  CORE: {
    address: '2maDvG9nXGVstjdnsCZoSsNtjoda1SsZTLrHBVRgLR5F',
    chainId: 101,
    decimals: 9,
    name: 'CORE',
    symbol: 'CORE',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2x4fH7kWNgSLpBjCuFBKKKiNHMcJb6fHs8ahQZUWJj1p/Core.png',
    tags: ['old-registry', 'solana-fm'],
  },
  CCC: {
    address: 'E9bjYSAqabYAd2Zaev4qAMVNAiX7Z2Dp4Sn1JgsTn2b6',
    chainId: 101,
    decimals: 9,
    name: 'Chairman Chow',
    symbol: 'CCC',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E9bjYSAqabYAd2Zaev4qAMVNAiX7Z2Dp4Sn1JgsTn2b6/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  YUKI: {
    address: '53yANribNp1WzRsciY6upAN2VPY85waZEtADTeJhtQGN',
    chainId: 101,
    decimals: 6,
    name: 'Samurai Cat',
    symbol: 'YUKI',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d556e4e6d56454a383461444633396f6254787072525a553753785436677351716e4247596338444b38693276',
    tags: ['community'],
    extensions: { coingeckoId: 'samurai-cat' },
  },
  WNDO: {
    address: 'E8G4uo2i9d12aGnXDHXXcw6hU2fh2NytR5XR3qurTLBx',
    chainId: 101,
    decimals: 9,
    name: 'WNDO',
    symbol: 'WNDO',
    logoURI:
      'https://static1.squarespace.com/static/6252f1590fac8d79fb6d772e/t/627682f25295251e0967c29c/1651933939477/wndocoin.png',
    tags: ['old-registry', 'solana-fm'],
  },
  SD: {
    address: '8rEEAvq6kujW2ukjYHfutH5eToAwsK4aaJ8p1ApoMs9g',
    chainId: 101,
    decimals: 6,
    name: 'Shark Dog',
    symbol: 'SD',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmeAuc8Cf34rUnmSDq9RXm8gFUeSxwFu1hWS5RgDAfb7J6',
    tags: ['community'],
  },
  MusCat: {
    address: '6PPskLwejGjiRbsrdTvPdo8Ayf6rERxvSezcPDA53TAP',
    chainId: 101,
    decimals: 6,
    name: 'MusCat',
    symbol: 'MusCat',
    logoURI:
      'https://gateway.irys.xyz/G5wEXOp8IE0Dr5CCWOpN2XqsplXG3_mhTifkUk1tjOE',
    tags: ['community'],
  },
  LAPTOP: {
    address: 'HLwEJQVzs7SvjXuXpBTRHaLp5S6uWoWTUhLjJxBfy1c7',
    chainId: 101,
    decimals: 4,
    name: "Hunter Biden's Laptop",
    symbol: 'LAPTOP',
    logoURI: 'https://arweave.net/X_bM80bdYSZCCW_PaAZFO8JgVsHhq1jBYRUr60yME4o',
    tags: ['community'],
    extensions: { coingeckoId: 'hunter-biden-s-laptop' },
  },
  FOXY: {
    address: 'FoXyMu5xwXre7zEoSvzViRk3nGawHUp9kUh97y2NDhcq',
    chainId: 101,
    decimals: 0,
    name: 'Famous Fox Federation',
    symbol: 'FOXY',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FoXyMu5xwXre7zEoSvzViRk3nGawHUp9kUh97y2NDhcq/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'famous-fox-federation' },
  },
  MP: {
    address: '5zYbnE6UXTn6HMTPXCNPW61iA1vyCNTZLVQdUUnoazB',
    chainId: 101,
    decimals: 9,
    name: 'Maneko Pet',
    symbol: 'MP',
    logoURI: 'https://cdn.manekopet.xyz/token/mp/logo.png',
    tags: ['community'],
    extensions: { coingeckoId: 'maneko-pet' },
  },
  GREG: {
    address: 'zZRRHGndBuUsbn4VM47RuagdYt57hBbskQ2Ba6K5775',
    chainId: 101,
    decimals: 6,
    name: 'greg16676935420',
    symbol: 'GREG',
    logoURI:
      'https://bafybeig4mevx45iox5ehpztya4dqx4hsqwyshobed4rnx2no4pbvsqksae.ipfs.cf-ipfs.com/',
    tags: ['community'],
    extensions: { coingeckoId: 'greg16676935420' },
  },
  TAKI: {
    address: 'Taki7fi3Zicv7Du1xNAWLaf6mRK7ikdn77HeGzgwvo4',
    chainId: 101,
    decimals: 9,
    name: 'Taki',
    symbol: 'TAKI',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Taki7fi3Zicv7Du1xNAWLaf6mRK7ikdn77HeGzgwvo4/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'taki' },
  },
  'sFTT-9': {
    address: 'FTT9rBBrYwcHam4qLvkzzzhrsihYMbZ3k6wJbdoahxAt',
    chainId: 101,
    decimals: 9,
    name: 'Saber Wrapped Wrapped FTT (Sollet) (9 decimals)',
    symbol: 'sFTT-9',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTT9rBBrYwcHam4qLvkzzzhrsihYMbZ3k6wJbdoahxAt/icon.png',
    tags: ['old-registry'],
  },
  STREAM: {
    address: '54jVZGHyWURX5evBtZqUsJjwoKzcZJbVokDU93AUZf2h',
    chainId: 101,
    decimals: 9,
    name: 'Solstream',
    symbol: 'STREAM',
    logoURI: 'https://i.ibb.co/B6Qwtnb/LOGO-SOLSTREAM-100.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'solstream' },
  },
  CLUB: {
    address: 'GTgMjfKTBF9jVBogMnaiAVnHmvnF2wyGAv3zvtk5CBHq',
    chainId: 101,
    decimals: 9,
    name: 'CLUB',
    symbol: 'CLUB',
    logoURI: 'https://arweave.net/XSWZOeipKasgKjDKm54O2R0S3Rrld1B6gQV_rtIjfF4',
    tags: ['community'],
  },
  $FROG: {
    address: 'Frog8vt6gmuuUuuerd7ispP6yavssBUtMpEP5DCEuUgD',
    chainId: 101,
    decimals: 9,
    name: 'Frog',
    symbol: '$FROG',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Frog8vt6gmuuUuuerd7ispP6yavssBUtMpEP5DCEuUgD/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  TAONU: {
    address: '6ZT7hoeMNfYua5oJ67EQJbFJHUBVLuFBbCKduRuk1rXr',
    chainId: 101,
    decimals: 9,
    name: 'TAO INU',
    symbol: 'TAONU',
    logoURI:
      'https://bafkreifnmhquoardsg4reiatuemvxrvwy3m4f4nde6lzkg7uegw6zz7gae.ipfs.nftstorage.link',
    tags: ['community'],
  },
  POOR: {
    address: '2mqi8iLJf9WaVRHd7nhRkqvCafxfb3Us7wshaJR73ykS',
    chainId: 101,
    decimals: 5,
    name: 'Poor token',
    symbol: 'POOR',
    logoURI:
      'https://shdw-drive.genesysgo.net/AZgm3Vdo1QLf8bKUjyearDC9NygpRHmmtzPaBNQcF4jt/IMG_1640.JPG',
    tags: ['community'],
  },
  $YETI: {
    address: 'FDKBUXKxCdNQnDrqP7DLe8Kri3hzFRxcXyoskoPa74rk',
    chainId: 101,
    decimals: 9,
    name: 'YETI',
    symbol: '$YETI',
    logoURI: 'https://raw.githubusercontent.com/yet777/tokenlogo/main/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  OLUMPC: {
    address: '5WjrhXBcYsTcn2KvaJv4cuBA5WDuprQVA9YoHYTC2wbi',
    chainId: 101,
    decimals: 6,
    name: 'Olumpec Terch',
    symbol: 'OLUMPC',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmRu444fCdJQjxz5RiKLEbMZszaSnfnnQBhb4MJB5PDbY2',
    tags: ['community'],
    extensions: { coingeckoId: 'olumpec-terch' },
  },
  UPT: {
    address: '9CPWiCFL11DWr92dsTrzLf5cKz6zKhLz3xzRcYCQ3Nd2',
    chainId: 101,
    decimals: 6,
    name: 'UPTOBER',
    symbol: 'UPT',
    logoURI:
      'https://bafkreihgs65erm5f45s5vzl7y6wdl3nuqqqpcvlith3sotor5jcctft2wu.ipfs.nftstorage.link',
    tags: ['community', 'token-2022'],
  },
  SCOIN: {
    address: '5qKDWkBejLtRh1UGFV7e58QEkdn2fRyH5ehVXqUYujNW',
    chainId: 101,
    decimals: 9,
    name: 'Sdrive Coin',
    symbol: 'SCOIN',
    logoURI: 'https://arweave.net/O5kTUpi6mNl-FWY2zq01MqK0qkwRKy3O5cyaoosyJwM',
    tags: ['community'],
    extensions: { coingeckoId: 'sdrive-app' },
  },
  SPDR: {
    address: 'AT79ReYU9XtHUTF5vM6Q4oa9K8w7918Fp5SU7G1MDMQY',
    chainId: 101,
    decimals: 9,
    name: 'SpiderSwap',
    symbol: 'SPDR',
    logoURI: 'https://arweave.net/PAVQPYRQzq2g4O2X7hDzOniW4aBE9PJ19U56MRVpIfA',
    tags: ['community'],
    extensions: { coingeckoId: 'spiderswap' },
  },
  'sETH-8': {
    address: 'SL819j8K9FuFPL84UepVcFkEZqDUUvVzwDmJjCHySYj',
    chainId: 101,
    decimals: 8,
    name: 'Saber Wrapped Ethereum (Sollet) (8 decimals)',
    symbol: 'sETH-8',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SL819j8K9FuFPL84UepVcFkEZqDUUvVzwDmJjCHySYj/icon.png',
    tags: ['old-registry'],
  },
  KARMA: {
    address: '8qYH37jFCVbGSjQPdMsf8TDwp1JHTjU1McA8GoCCpump',
    chainId: 101,
    decimals: 6,
    name: 'Good Karma',
    symbol: 'KARMA',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmVyva7W1Ze2fmZKHhHZYcfxh2z1UJPx7hWwqteVGtSnbC',
    tags: ['community'],
  },
  SMORES: {
    address: 'smoEhMZMweWBnpd1QoU4ZjuVNBxMFchqy4NRMBbtW7V',
    chainId: 101,
    decimals: 6,
    name: 'SMORES',
    symbol: 'SMORES',
    logoURI:
      'https://shdw-drive.genesysgo.net/2iHNWQtfUsth1F7dy6Mtjr9dHmYPZN7TZS4W2GcEwNTE/smores.png',
    tags: ['community'],
  },
  BOBAOPPA: {
    address: 'bobaM3u8QmqZhY1HwAtnvze9DLXvkgKYk3td3t8MLva',
    chainId: 101,
    decimals: 6,
    name: 'Boba Oppa',
    symbol: 'BOBAOPPA',
    logoURI:
      'https://bafkreicrn3j6ausiwrw66jg22xxbrkypcgkjkux6xeaacw2wcxtj6t3lji.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'boba-oppa' },
  },
  picoSOL: {
    address: 'picobAEvs6w7QEknPce34wAE4gknZA9v5tTonnmHYdX',
    chainId: 101,
    decimals: 9,
    name: 'picoSOL',
    symbol: 'picoSOL',
    logoURI: 'https://arweave.net/zR2ZKXpwmG4hMpqhGXlvz-B7PMs34cXxVb2gC1DmlDo',
    tags: ['community'],
    extensions: { coingeckoId: 'pico-staked-sol' },
  },
  BAKED: {
    address: 'CQbXk942c6GPcRwtZ2WMFP5JcQ9NqbXtb8jUewBi7GoT',
    chainId: 101,
    decimals: 6,
    name: 'BAKED',
    symbol: 'BAKED',
    logoURI:
      'https://raw.githubusercontent.com/Baked-Resources/solana-token/main/baked_logo.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'baked' },
  },
  SUNNY: {
    address: 'SUNNYWgPQmFxe9wTZzNK7iPnJ3vYDrkgnxJRJm1s3ag',
    chainId: 101,
    decimals: 6,
    name: 'Sunny Governance Token',
    symbol: 'SUNNY',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SUNNYWgPQmFxe9wTZzNK7iPnJ3vYDrkgnxJRJm1s3ag/logo.svg',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'sunny-aggregator' },
  },
  SUPER: {
    address: '5nrAynqdsEyBc3GRc7hvM5QnDwjQVePNg9kn6bCvi4G3',
    chainId: 101,
    decimals: 5,
    name: 'SUPER TOKEN',
    symbol: 'SUPER',
    logoURI:
      'https://nftstorage.link/ipfs/bafkreibxcsqfuls3yiitufaid3wnjzrgx2gdswtteo33g6fojoxctob5na',
    tags: ['community'],
  },
  HorseMeat: {
    address: '2FprjEk4MTSY9CxpKuENbGDdy69R15GHhtHpG5Durdbq',
    chainId: 101,
    decimals: 9,
    name: 'Horse Meat',
    symbol: 'HorseMeat',
    logoURI: 'https://i.imgur.com/uKfGicj.jpeg',
    tags: ['community'],
  },
  HTO: {
    address: 'htoHLBJV1err8xP5oxyQdV2PLQhtVjxLXpKB7FsgJQD',
    chainId: 101,
    decimals: 9,
    name: 'HTO',
    symbol: 'HTO',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/htoHLBJV1err8xP5oxyQdV2PLQhtVjxLXpKB7FsgJQD/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'heavenland-hto' },
  },
  ADA: {
    address: 'E4Q5pLaEiejwEQHcM9GeYSQfMyGy8DJ4bPWgeYthn24v',
    chainId: 101,
    decimals: 9,
    name: 'Ada',
    symbol: 'ADA',
    logoURI:
      'https://gateway.irys.xyz/BFejxwOQrFxDnvG84t8kUf3PI_nCU3PEBrHBlu6g5ww',
    tags: ['community'],
    extensions: { coingeckoId: 'ada-the-dog' },
  },
  OVOL: {
    address: '4v3UTV9jibkhPfHi5amevropw6vFKVWo7BmxwQzwEwq6',
    chainId: 101,
    decimals: 2,
    name: 'Ovols',
    symbol: 'OVOL',
    logoURI: 'https://elixir-site.s3.amazonaws.com/ovols-logo.png',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'ovols-floor-index' },
  },
  RICH: {
    address: 'A2PVd9wmEk9Ek9MFbF6VVBm4UiGYK24TCmE5oR2WDWGH',
    chainId: 101,
    decimals: 6,
    name: 'RICH',
    symbol: 'RICH',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d51636a454534465138565457716a72634863716d703371537936564e704a5566786e5a4e554676416867577a',
    tags: ['community'],
    extensions: { coingeckoId: 'rich-on-sol' },
  },
  TAPES: {
    address: 'DxRbkqB2cEqa4rnXJwq4z3GuksgpL2U27PWBw8QBcNv2',
    chainId: 101,
    decimals: 2,
    name: "Trippin' Ape Tribe Floor Index",
    symbol: 'TAPES',
    logoURI: 'https://i.imgur.com/iFgvQva.png',
    tags: ['community'],
  },
  RRR: {
    address: '3zE4Qmh9hrC4inHynT3aSRzHcWhnxPCHLeN9WWqvoRVz',
    chainId: 101,
    decimals: 9,
    name: 'Race Raiders Rewards',
    symbol: 'RRR',
    logoURI: 'https://arweave.net/SH106hrChudKjQ_c6e6yd0tsGUbFIScv2LL6Dp-LDiI',
    tags: ['community'],
  },
  PLD: {
    address: '2cJgFtnqjaoiu9fKVX3fny4Z4pRzuaqfJ3PBTMk2D9ur',
    chainId: 101,
    decimals: 6,
    name: 'Plutonian DAO',
    symbol: 'PLD',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2cJgFtnqjaoiu9fKVX3fny4Z4pRzuaqfJ3PBTMk2D9ur/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'plutonian-dao' },
  },
  DASCH: {
    address: 'GTuDe5yneFKaWSsPqqKgu413KTk8WyDnUZcZUGxuNYsT',
    chainId: 101,
    decimals: 9,
    name: 'Dasch Coin',
    symbol: 'DASCH',
    logoURI: 'https://github.com/kennethoqs/dasch/blob/main/logo.png',
    tags: ['old-registry'],
  },
  DOLAN: {
    address: '4YK1njyeCkBuXG6phNtidJWKCbBhB659iwGkUJx98P5Z',
    chainId: 101,
    decimals: 6,
    name: 'Dolan Duck',
    symbol: 'DOLAN',
    logoURI:
      'https://pbs.twimg.com/profile_images/1749802424590798848/8Go1WkbK_400x400.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'dolan-duck' },
  },
  WORK: {
    address: '9tnkusLJaycWpkzojAk5jmxkdkxBHRkFNVSsa7tPUgLb',
    chainId: 101,
    decimals: 5,
    name: 'Worken',
    symbol: 'WORK',
    logoURI:
      'https://ipfs.io/ipfs/Qmb9qSYq9YNMg3gejXnN9pstauXEr5czRc1rpgBhEKPnxm',
    tags: ['community'],
    extensions: { coingeckoId: 'paidwork-worken' },
  },
  ALMC: {
    address: '7f94zk1EgfoeG57Vj5FtDDjMmPNHM4DYs7KRiyd2T4bA',
    chainId: 101,
    decimals: 9,
    name: 'AWKWARD LOOK MONKEY CLUB',
    symbol: 'ALMC',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f6261666b726569626775726336756865747668327179333579743770796f717a75783373626235786c3434716e3464356b7274747a7776626762792e697066732e6e667473746f726167652e6c696e6b',
    tags: ['community'],
    extensions: { coingeckoId: 'awkward-look-monkey-club' },
  },
  GOL: {
    address: '9sjyR4GrozeV8a9xM3ykKPGPXJYASy9AuufzefCyaCnP',
    chainId: 101,
    decimals: 9,
    name: 'GOLAZO',
    symbol: 'GOL',
    logoURI:
      'https://ipfs.io/ipfs/QmRoZTMPFznBFUjKqcmxe1zzgfKGckdVDrQhm5x3ihpJqJ',
    tags: ['community'],
    extensions: { coingeckoId: 'golazo-world' },
  },
  DANNY: {
    address: '5NHd3MsP6dXi9r1saPkeB2DoZyXLvPiqv4n9J54Cpump',
    chainId: 101,
    decimals: 6,
    name: 'Degen Danny',
    symbol: 'DANNY',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d56445563385a3455334e7174336544644238703533797051346f4e58774e6d7a74397434504e445657704370',
    tags: ['community'],
    extensions: { coingeckoId: 'degen-danny' },
  },
  ICE: {
    address: 'icex2Fy2KtXjfiAAUEHLPHu7XKDLvwiyVUPP9PNpSkF',
    chainId: 101,
    decimals: 9,
    name: 'Infinity Diamonds ICE',
    symbol: 'ICE',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/icex2Fy2KtXjfiAAUEHLPHu7XKDLvwiyVUPP9PNpSkF/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  CHB: {
    address: 'YtfMZ4jg2ubdz4GasY86iuGjHdo5rCPJnFqgSf8gxAz',
    chainId: 101,
    decimals: 9,
    name: 'Charactbit',
    symbol: 'CHB',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6D4aGWykMymkFaeVzDS6MiH6QEujsHUze2pPGbJEyh7y/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'charactbit' },
  },
  MIMO: {
    address: '9TE7ebz1dsFo1uQ2T4oYAKSm39Y6fWuHrd6Uk6XaiD16',
    chainId: 101,
    decimals: 9,
    name: 'Million Monke',
    symbol: 'MIMO',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9TE7ebz1dsFo1uQ2T4oYAKSm39Y6fWuHrd6Uk6XaiD16/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'million-monke' },
  },
  SIX: {
    address: 'HWSqJdwemji7TNiKQPudUj86LXyF3vGAtWm5ePk5KzgD',
    chainId: 101,
    decimals: 9,
    name: 'Solana Eco Index',
    symbol: 'SIX',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HWSqJdwemji7TNiKQPudUj86LXyF3vGAtWm5ePk5KzgD/logo.png',
    tags: ['old-registry'],
  },
  MSI: {
    address: '2e7yNwrmTgXp9ABUmcPXvFJTSrEVLj4YMyrb4GUM4Pdd',
    chainId: 101,
    decimals: 6,
    name: 'Matrix Solana Index',
    symbol: 'MSI',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2e7yNwrmTgXp9ABUmcPXvFJTSrEVLj4YMyrb4GUM4Pdd/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  Rock: {
    address: '5KxnfDmsXVBNkVHYhW4kztV7ZCCCbrkYxBVrqLWF3G7J',
    chainId: 101,
    decimals: 4,
    name: 'Rock',
    symbol: 'Rock',
    logoURI:
      'https://node2.irys.xyz/m0x31ZCuqG640Dvteo-GmiKaLvD7YvmLnV7WrT7Ugmo',
    tags: ['community'],
    extensions: { coingeckoId: 'rock-2' },
  },
  SOLCHAN: {
    address: 'ChanM2vka4gJ3ob1SejJXnxoNuoAXGGJxDMJRSLD7nzW',
    chainId: 101,
    decimals: 9,
    name: 'Solchan.org',
    symbol: 'SOLCHAN',
    logoURI:
      'https://raw.githubusercontent.com/Kuroshades/solchan-data/master/logo.png',
    tags: ['community'],
  },
  BNB: {
    address: '9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa',
    chainId: 101,
    decimals: 8,
    name: 'Binance Coin (Portal)',
    symbol: 'BNB',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/BNB_wh.png',
    tags: ['wormhole', 'old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'binance-coin-wormhole' },
  },
  $DAT: {
    address: 'DATiwd3NsbaRArGvmaFcFpc9jF8qciN7Fj4jRHAFUHFA',
    chainId: 101,
    decimals: 9,
    name: 'dats dat',
    symbol: '$DAT',
    logoURI:
      'https://shdw-drive.genesysgo.net/6zCgv4kJK2fyfexvXacePi5umYqFTCgN4nWjyqcpFgpY/token-logo.png',
    tags: ['community'],
  },
  FRENS: {
    address: 'HNm1VgnyhaMZZF71RjNFNiYLN76zyZTDcBZPjYveWFXX',
    chainId: 101,
    decimals: 6,
    name: 'Chimp Frens',
    symbol: 'FRENS',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HNm1VgnyhaMZZF71RjNFNiYLN76zyZTDcBZPjYveWFXX/chimp.png',
    tags: ['old-registry', 'solana-fm'],
  },
  PANDA: {
    address: 'Aw8qLRHGhMcKq7rxs5XBNCd9oe3BvoAhpNMVz7AdGmty',
    chainId: 101,
    decimals: 9,
    name: 'Panda Coin',
    symbol: 'PANDA',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Aw8qLRHGhMcKq7rxs5XBNCd9oe3BvoAhpNMVz7AdGmty/logo.png',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'panda-coin' },
  },
  CHILN: {
    address: '2DfEnQrC6EVnhA3wGXiQ8UoBtEEmStsW6oNT6izn6AeH',
    chainId: 101,
    decimals: 9,
    name: 'Calm Bear',
    symbol: 'CHILN',
    logoURI:
      'https://ipfs.io/ipfs/QmdaK6bURFJtqQte5sifNcWjdqNwz8hpLZvfH8us8SSQNN',
    tags: ['community'],
    extensions: { coingeckoId: 'calm-bear-on-solana' },
  },
  SWIFT: {
    address: '7ACrQshyNAULRWqZD7tQWpvUqV4pnpeRALoafXfsZVQa',
    chainId: 101,
    decimals: 6,
    name: 'SOLANA SUMMER',
    symbol: 'SWIFT',
    logoURI:
      'https://ipfs.io/ipfs/QmahJWZHWnPw2tB369HqsPi415DdEWw67ZWge7sz2iZSRu',
    tags: ['community'],
  },
  HAMMY: {
    address: '26KMQVgDUoB6rEfnJ51yAABWWJND8uMtpnQgsHQ64Udr',
    chainId: 101,
    decimals: 6,
    name: 'SAD HAMSTER',
    symbol: 'HAMMY',
    logoURI:
      'https://bafkreidnhl3agzjkay3ljojmvwid72amoumqftgaw7lks2keg27ph3efsu.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'sad-hamster' },
  },
  CLINTON: {
    address: 'AF7X9tJDkLDVApfMnKSkMUUsxxPhuSGUZbDECtrbvVu3',
    chainId: 101,
    decimals: 9,
    name: 'Bull Clinton',
    symbol: 'CLINTON',
    logoURI:
      'https://ipfs.io/ipfs/Qmc2dKNHLD66TY5h48qxKempFmupjVjSFNTySU2jDU795A',
    tags: ['community'],
  },
  GH0ST: {
    address: 'HbxiDXQxBKMNJqDsTavQE7LVwrTR36wjV2EaYEqUw6qH',
    chainId: 101,
    decimals: 9,
    name: 'GH0ST',
    symbol: 'GH0ST',
    logoURI:
      'https://bafybeievcwt2kef6olonvu4aaw2ud5tjezq567kqq7cijsquveim723fni.ipfs.nftstorage.link',
    tags: ['community', 'token-2022'],
    extensions: { coingeckoId: 'gh0stc0in' },
  },
  HOLY: {
    address: 'HezGWsxSVMqEZy7HJf7TtXzQRLiDruYsheYWqoUVnWQo',
    chainId: 101,
    decimals: 9,
    name: 'HolyGrails',
    symbol: 'HOLY',
    logoURI: 'https://arweave.net/TDvr4xgh3VxmHG8GglxruoSwm41wkYOLPF7EBrhBiBY',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'holygrails-io' },
  },
  'srenBTC-10': {
    address: 'BtX7AfzEJLnU8KQR1AgHrhGH5s2AHUTbfjhUQP8BhPvi',
    chainId: 101,
    decimals: 10,
    name: 'Saber Wrapped renBTC (10 decimals)',
    symbol: 'srenBTC-10',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BtX7AfzEJLnU8KQR1AgHrhGH5s2AHUTbfjhUQP8BhPvi/icon.png',
    tags: ['old-registry'],
  },
  BABS: {
    address: 'Ceqwd4CSGBZnW8PHEexBQCAV2NDPkBoSqheiKR7gzELV',
    chainId: 101,
    decimals: 5,
    name: 'BABS',
    symbol: 'BABS',
    logoURI: 'https://iili.io/HaOytTu.png',
    tags: ['community'],
  },
  POZZ: {
    address: '27nqFZqb2iPBeVA7bbE4KPZrJgi3dJdKV9VzhCguSy6Y',
    chainId: 101,
    decimals: 9,
    name: 'PozzCoin',
    symbol: 'POZZ',
    logoURI: 'https://raw.githubusercontent.com/dc215007/pozz1/main/logo1.png',
    tags: ['old-registry'],
  },
  NYAN: {
    address: '49ztDWXk7qEfz8Y1t8owmhLhewiA3ptVNPpRGAjRd8Wh',
    chainId: 101,
    decimals: 2,
    name: 'Nyan Cat',
    symbol: 'NYAN',
    logoURI:
      'https://bafkreigmutvlo3qvgkz2w4h7n6537thcubprh5dm7dyuvymga6eavapckq.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  BULL: {
    address: 'BULLa6g9e5UCuTXC5Z3Cf7s7CgvJhnJfY71DwipSmF8w',
    chainId: 101,
    decimals: 9,
    name: 'BULL',
    symbol: 'BULL',
    logoURI:
      'https://shdw-drive.genesysgo.net/GVxcsjC2PMpAS1E6aVp78YH7FqpfqxPY1ujeqNduhD1f/bull.png',
    tags: ['community'],
  },
  DAW: {
    address: '7cb67ev3jvBKaAH1tnVM8FL8WfQ96sa2TYByEXajqx8N',
    chainId: 101,
    decimals: 6,
    name: 'Dawkoins',
    symbol: 'DAW',
    logoURI:
      'https://bafkreibbgyr7sq5rnvievhrocn56kiwxw7yp5ul2gqi7sfs6purdmrq3gu.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'dawkoin' },
  },
  ROL: {
    address: 'RoLLn5qBN4juQ1D2KFpJyAcC7Deo3cYotXi4qDooHLU',
    chainId: 101,
    decimals: 6,
    name: 'ROL',
    symbol: 'ROL',
    logoURI: 'https://assets.blockstars.gg/static/ROL.svg',
    tags: ['old-registry', 'solana-fm'],
  },
  SCRAP: {
    address: '6naWDMGNWwqffJnnXFLBCLaYu1y5U9Rohe5wwJPHvf1p',
    chainId: 101,
    decimals: 3,
    name: 'SCRAP',
    symbol: 'SCRAP',
    logoURI: 'https://art.pixilart.com/bd1b1275fdc0ac1.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'scrap' },
  },
  $FLUFF: {
    address: 'CoRkC3r6MqYuTeMRc7D8JJF7UiUyFWurXGpYy1xQATNq',
    chainId: 101,
    decimals: 9,
    name: '$FLUFF',
    symbol: '$FLUFF',
    logoURI:
      'https://assets.pinit.io/F1UffyLY2mEc5zXTzC13MVkiUzquDRRpRkRFkf5XWiDW/207eee8d-eb98-4c35-969a-1876eee6b353/logo.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'fluffys' },
  },
  CRRT: {
    address: 'HkNokfCXG33eu5vCcS49mq3jZcKZeQSQCyta964YxxYg',
    chainId: 101,
    decimals: 0,
    name: 'Breezy Bunnies Carrot',
    symbol: 'CRRT',
    logoURI: 'https://breezybunnies.com/images/3-p-500.png',
    tags: ['old-registry', 'solana-fm'],
  },
  jeoing737: {
    address: 'H2ZpBXtzk1DaDTzsoXqMhjN6Bd3qzgefHjUZ1e2zuwAe',
    chainId: 101,
    decimals: 6,
    name: 'joeing737',
    symbol: 'jeoing737',
    logoURI:
      'https://bafybeidis3pytkjvznbizwgvo5iuikmq4r53hpzpepbje7splzm72k3t64.ipfs.cf-ipfs.com/',
    tags: ['community'],
    extensions: { coingeckoId: 'joeing737' },
  },
  GIKO: {
    address: '3WPep4ufaToK1aS5s8BL9inzeUrt4DYaQCiic6ZkkC1U',
    chainId: 101,
    decimals: 9,
    name: 'Giko Cat',
    symbol: 'GIKO',
    logoURI:
      'https://bafkreihtk4j4mjgragx3t2tqcjlhvq4w6mqn23bbapkakxmui4ghpuysdy.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'giko-cat' },
  },
  wDingocoin: {
    address: '6VYF5jXq6rfq4QRgGMG6co7b1Ev1Lj7KSbHBxfQ9e1L3',
    chainId: 101,
    decimals: 8,
    name: 'Wrapped Dingocoin',
    symbol: 'wDingocoin',
    logoURI: 'https://wrap.dingocoin.org/wDingocoin.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'dingocoin' },
  },
  EPIKO: {
    address: '6ep1sNB9UWni3nBZUX84USsRBhKRzMAxEmcia479cWTh',
    chainId: 101,
    decimals: 8,
    name: 'Epiko Regal (Wormhole)',
    symbol: 'EPIKO',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/28018.png',
    tags: ['community'],
    extensions: { coingeckoId: 'epiko' },
  },
  LIBRA: {
    address: 'Hz1XePA2vukqFBcf9P7VJ3AsMKoTXyPn3s21dNvGrHnd',
    chainId: 101,
    decimals: 9,
    name: 'Libra Protocol',
    symbol: 'LIBRA',
    logoURI:
      'https://bafkreie7gs73rnak3aqft5eipsbtd4rtam3locmddovgls6wdhfzsjbmqq.ipfs.nftstorage.link',
    tags: ['community', 'token-2022'],
    extensions: { coingeckoId: 'libra-protocol-2' },
  },
  SEI: {
    address: 'CRkwd2QedqDi5u6W2w6jeAViAUd1pR4AXs2aKvh7GW7M',
    chainId: 101,
    decimals: 6,
    name: 'Solanium Ecosystem Index',
    symbol: 'SEI',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CRkwd2QedqDi5u6W2w6jeAViAUd1pR4AXs2aKvh7GW7M/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  $MILK: {
    address: '8FktFPUvi73Ywf7ZivsbuQFyQCqZUwvqSQ51hNvRhR26',
    chainId: 101,
    decimals: 8,
    name: 'Milk',
    symbol: '$MILK',
    logoURI:
      'https://shdw-drive.genesysgo.net/Ck7ByKk3NZfC9EFTNiGBrLkvquKGyDL7KLk3h1rdW2P4/image.png',
    tags: ['community'],
  },
  Miku: {
    address: '7GNyprqrpvJHNwzWSj4KVkqw9cKyV5aR3ehM2HeE5pw',
    chainId: 101,
    decimals: 0,
    name: 'Mikuko Token',
    symbol: 'Miku',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7GNyprqrpvJHNwzWSj4KVkqw9cKyV5aR3ehM2HeE5pw/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  JUNKz: {
    address: 'AMzmwvDRKdt5AQ3m1m28tWjzBxmQNe1PsmHnYitVZwzp',
    chainId: 101,
    decimals: 9,
    name: 'JUNK',
    symbol: 'JUNKz',
    logoURI:
      'https://raw.githubusercontent.com/XAGBack/JUNKToken1/main/JunkToken.png',
    tags: ['old-registry', 'solana-fm'],
  },
  CLAY: {
    address: 'AYtg18TFRQs1mCQ29zWY3aSwxwTcW1uTdZ65dL3WiBQu',
    chainId: 101,
    decimals: 2,
    name: 'Claynosaurz',
    symbol: 'CLAY',
    logoURI: 'https://i.imgur.com/dd9tpCS.png',
    tags: ['community'],
  },
  FLIPGG: {
    address: 'VVWAy5U2KFd1p8AdchjUxqaJbZPBeP5vUQRZtAy8hyc',
    chainId: 101,
    decimals: 9,
    name: 'Flip.gg | #1 Lootbox Game',
    symbol: 'FLIPGG',
    logoURI:
      'https://imagedelivery.net/sefJhsAaMGuOxdU5A0aGQw/564a4563-6301-4c14-c1fa-fa0733fa9900/public',
    tags: ['community'],
  },
  obema: {
    address: 'BMQZzVJa1X5cBYc8fCRVbWuFC4czA1s5GE3HekWfuhjz',
    chainId: 101,
    decimals: 6,
    name: 'burek obema',
    symbol: 'obema',
    logoURI: 'https://i.imgur.com/Mu0fbpi.jpeg',
    tags: ['community'],
  },
  EDO: {
    address: '7sWMHQaJJPn1rkeizq41iPiW5gG7Ry7PXYAVHaUwY4qu',
    chainId: 101,
    decimals: 9,
    name: 'EDO',
    symbol: 'EDO',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7sWMHQaJJPn1rkeizq41iPiW5gG7Ry7PXYAVHaUwY4qu/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  PRCL: {
    address: '4LLbsb5ReP3yEtYzmXewyGjcir5uXtKFURtaEUVC2AHs',
    chainId: 101,
    decimals: 6,
    name: 'Parcl',
    symbol: 'PRCL',
    logoURI:
      'https://ipfs.filebase.io/ipfs/QmVDpnYjKMCBdmqGddQNyW8cc3tBU5cKZFiSV5y18J5YnK',
    tags: ['community'],
    extensions: { coingeckoId: 'parcl' },
  },
  ORCA: {
    address: 'orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE',
    chainId: 101,
    decimals: 6,
    name: 'Orca',
    symbol: 'ORCA',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'orca' },
  },
  HADES: {
    address: 'BWXrrYFhT7bMHmNBFoQFWdsSgA3yXoAnMhDK6Fn1eSEn',
    chainId: 101,
    decimals: 9,
    name: 'Hades',
    symbol: 'HADES',
    logoURI: 'https://arweave.net/dvKu5BgpSo6j-iGzQOyVXYZ8OU7iyfhHNpkkJ_8qkkQ',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'hades' },
  },
  THOL: {
    address: 'EKCW975DWdt1roK1NVQDf4QGfaGTcQPU5tFD1DMcMe9Q',
    chainId: 101,
    decimals: 7,
    name: 'Tholana',
    symbol: 'THOL',
    logoURI: 'https://arweave.net/qOW4Inn_tDPZI3rQ-OtRa8gSZ0d5pgGV6vRY9D1T5zU',
    tags: ['community'],
    extensions: { coingeckoId: 'tholana' },
  },
  meesa: {
    address: 'DaYYrQTtKqGCBiPtnXSUT5d8nDXBrtW5LoJHsg4opump',
    chainId: 101,
    decimals: 6,
    name: 'meesa',
    symbol: 'meesa',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d5751714868687536477a667462576644633978626366593669386e424b316b4b63446b4d657143656d5a5a57',
    tags: ['community'],
  },
  DWAKE: {
    address: '9oUXhgFmW2HWqWHds1NoV3DKLY3AAtNevA3dP7PtyEbr',
    chainId: 101,
    decimals: 6,
    name: 'Dwake',
    symbol: 'DWAKE',
    logoURI:
      'https://ipfs.io/ipfs/Qmb2uN7ErbvjxXdKJzWuqkmgeCauoYaUZX2UdHHQ61DBwV',
    tags: ['community'],
    extensions: { coingeckoId: 'dwake-on-sol' },
  },
  NEON: {
    address: 'NeonTjSjsuo3rexg9o6vHuMXw62f9V7zvmu8M8Zut44',
    chainId: 101,
    decimals: 9,
    name: 'Neon EVM',
    symbol: 'NEON',
    logoURI:
      'https://raw.githubusercontent.com/neonevm/neon-evm.docs/main/static/icons/token.svg',
    tags: ['community'],
    extensions: { coingeckoId: 'neon' },
  },
  DMME: {
    address: 'CG9WyPmf9EUQWGFUsaPAnFKuAi4eNZGGgCh7mrvAFm8p',
    chainId: 101,
    decimals: 6,
    name: 'DMME.me',
    symbol: 'DMME',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmerLhPZW1bXecBtGxFoFs7wByH3A2TCBkujjrSycu1Sys',
    tags: ['community'],
  },
  RODAI: {
    address: 'GdbyLsNKHKLXTZVEo8QrGKVmrexEeZUYvhpSfuZ9TdkC',
    chainId: 101,
    decimals: 5,
    name: 'ROD.AI',
    symbol: 'RODAI',
    logoURI: 'https://www.rodai.meme/logo.png',
    tags: ['community'],
    extensions: { coingeckoId: 'rod-ai' },
  },
  aeDAI: {
    address: '9w6LpS7RU1DKftiwH3NgShtXbkMM1ke9iNU4g3MBXSUs',
    chainId: 101,
    decimals: 9,
    name: 'Wrapped DAI (Allbridge from Ethereum)',
    symbol: 'aeDAI',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1/logo.png',
    tags: ['old-registry'],
  },
  COC: {
    address: 'cocvP5K8DsDYbJkRGasSg69xWFenrVVdBLLKjJKPJSo',
    chainId: 101,
    decimals: 5,
    name: 'COC',
    symbol: 'COC',
    logoURI:
      'https://shdw-drive.genesysgo.net/B7zYUoJVxzhi25zRWPniu4keY4ogmswMRnra1SQMsBnw/coc-logo.png',
    tags: ['community'],
    extensions: { coingeckoId: 'coc' },
  },
  LSTAR: {
    address: 'C6qep3y7tCZUJYDXHiwuK46Gt6FsoxLi8qV1bTCRYaY1',
    chainId: 101,
    decimals: 6,
    name: 'Learning Star',
    symbol: 'LSTAR',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D3yigvdWq6qobhAwQL6UiSide5W9W7zcJbuVvKa3T231/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'learning-star' },
  },
  ZEX: {
    address: 'ZEXy1pqteRu3n13kdyh4LwPQknkFk3GzmMYMuNadWPo',
    chainId: 101,
    decimals: 6,
    name: 'Zeta',
    symbol: 'ZEX',
    logoURI:
      'https://raw.githubusercontent.com/zetamarkets/brand/master/assets/zeta.png',
    tags: ['community'],
    extensions: { coingeckoId: 'zeta' },
  },
  wDAI_v1: {
    address: 'FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1',
    chainId: 101,
    decimals: 9,
    name: 'Dai Stablecoin (Wormhole v1)',
    symbol: 'wDAI_v1',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1/logo.png',
    tags: ['old-registry'],
  },
  $DOH: {
    address: 'Dsad47jgGNoJ8p4LJQ8x4yCFRMHBL7kd1Js3TJj7X52C',
    chainId: 101,
    decimals: 9,
    name: 'DOUGHGE',
    symbol: '$DOH',
    logoURI:
      'https://node1.irys.xyz/QxoBrhjZC5hOnau83euabD0VyjjYldTXBaHSRwQp7k8',
    tags: ['community'],
    extensions: { coingeckoId: 'doughge' },
  },
  HOTTO: {
    address: 'Bqd2ujCTEzpKzfjb1FHL7FKrdM6n1rZSnRecJK57EoKz',
    chainId: 101,
    decimals: 9,
    name: 'HottoShotto',
    symbol: 'HOTTO',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bqd2ujCTEzpKzfjb1FHL7FKrdM6n1rZSnRecJK57EoKz/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  Solala: {
    address: 'HTPHknF5rBNCuZ1qK3muJA6Yo1HEhZj722oSESJnPBiw',
    chainId: 101,
    decimals: 9,
    name: 'Solala',
    symbol: 'Solala',
    logoURI:
      'https://ipfs.io/ipfs/QmepHfogAYtwvEsryDjRBUNRPGhVSGpFQNkYiXfdU4MmM2',
    tags: ['community'],
    extensions: { coingeckoId: 'solala' },
  },
  phaseSOL: {
    address: 'phaseZSfPxTDBpiVb96H4XFSD8xHeHxZre5HerehBJG',
    chainId: 101,
    decimals: 9,
    name: 'Phase Labs SOL',
    symbol: 'phaseSOL',
    logoURI:
      'https://raw.githubusercontent.com/phaselabscrypto/.github/main/profile/public/phaseSOL.png',
    tags: ['community'],
    extensions: { coingeckoId: 'phase-labs-staked-sol' },
  },
  PIP: {
    address: 'HHjoYwUp5aU6pnrvN4s2pwEErwXNZKhxKGYjRJMoBjLw',
    chainId: 101,
    decimals: 9,
    name: 'PIP',
    symbol: 'PIP',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HHjoYwUp5aU6pnrvN4s2pwEErwXNZKhxKGYjRJMoBjLw/logo.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'pip' },
  },
  GAY: {
    address: '9GnU66c8qtMmj8rZ481N8Ums1Zmqdv5HgtQJyRVXt8MS',
    chainId: 101,
    decimals: 9,
    name: 'Gay Coin',
    symbol: 'GAY',
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://bafkreiawnonresayudw43hu67b37ga3dlfkbgq2oicfhc3vtmbnwzukdcu.ipfs.nftstorage.link',
    tags: ['community'],
  },
  WGC: {
    address: '4Eb7ReeT936hX25KMBfYtyP1RWDdd9iixEwGJroACeLC',
    chainId: 101,
    decimals: 6,
    name: 'Wild Goat Coin',
    symbol: 'WGC',
    logoURI:
      'https://bafkreidmmciiwrpkgvaan764bph2xeor2kju2dx3l7gjxd52i6xpomc5sy.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'wild-goat-coin-2' },
  },
  CHAT: {
    address: '947tEoG318GUmyjVYhraNRvWpMX7fpBTDQFBoJvSkSG3',
    chainId: 101,
    decimals: 9,
    name: 'Solchat',
    symbol: 'CHAT',
    logoURI:
      'https://bafybeigqw6dl5tiexa7utfhcsgldalhrelvjnea23jckqsgo65i2uoln5i.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'solchat' },
  },
  CUFF: {
    address: '2VYVwrwSNM8WxbFdPU4KQpZUB9FWCenFFoDqvpHQ7rZE',
    chainId: 101,
    decimals: 6,
    name: 'Jail Cat',
    symbol: 'CUFF',
    logoURI:
      'https://ipfs.io/ipfs/QmPt7Jdh3JMVVdehGq2cnLN8mEqNnByThcuSadmuoYcSzt',
    tags: ['community'],
    extensions: { coingeckoId: 'jail-cat' },
  },
  $TEST: {
    address: 'D8TFo4FsDF9CnejkezEZtwdhdmwaiNgLRDAQUTwWQuBU',
    chainId: 101,
    decimals: 6,
    name: 'TEST',
    symbol: '$TEST',
    logoURI:
      'https://bafkreia7lr4higfpv6xc5owc3m2vjgcq2zzb7nqoaeut2lqcfy4pplpy5u.ipfs.nftstorage.link',
    tags: ['community'],
  },
  DOUG: {
    address: 'BavuJ8bntC79A8aHTxQi1EUhcCnqvEU8KSBE4sVCAaHc',
    chainId: 101,
    decimals: 6,
    name: 'Doug The Duck',
    symbol: 'DOUG',
    logoURI:
      'https://node1.irys.xyz/KLkYev5kg9Dg26f9w_pkcjotYh-3eeckUmru22vEVsw',
    tags: ['community'],
    extensions: { coingeckoId: 'duck-the-doug' },
  },
  SMURF: {
    address: 'EArkn8uVf8YLfpF2eCdkCvDaPYpQuJzKXxaCnyxXc2P7',
    chainId: 101,
    decimals: 6,
    name: 'Real Smurf Cat-шайлушай',
    symbol: 'SMURF',
    logoURI:
      'https://bafybeigz2m3cfgsmc4q4z6jfqz3t6uzrjg3a5gonza64yw3mjf7vl6oip4.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'real-smurf-cat-2' },
  },
  IVRY: {
    address: '2MtPZqwNKTNsBoFCwm4ZTWk3ySz4LSd82ucDGeTk7VNu',
    chainId: 101,
    decimals: 2,
    name: 'Portals',
    symbol: 'IVRY',
    logoURI:
      'https://dl.airtable.com/.attachmentThumbnails/102af36cc30fec0b05ebb45406f20971/126d0320',
    tags: ['community', 'solana-fm'],
  },
  ladyboy: {
    address: 'AmCKJCFZfq7Lwy6MnDgHGcnKcqAi5ftHxAMzYRDwogTF',
    chainId: 101,
    decimals: 6,
    name: 'Ladyboy',
    symbol: 'ladyboy',
    logoURI:
      'https://ipfs.io/ipfs/QmW53fqyNG3Lij8YM1VhnQPwrLSKf2BihHjKFhZKY2XHoM',
    tags: ['community'],
  },
  BUD: {
    address: 'BUD1144GGYwmMRFs4Whjfkom5UHqC9a8dZHPVvR2vfPx',
    chainId: 101,
    decimals: 6,
    name: 'BunnyDucky Token',
    symbol: 'BUD',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BUD1144GGYwmMRFs4Whjfkom5UHqC9a8dZHPVvR2vfPx/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  RIN: {
    address: 'E5ndSkaB17Dm7CsD22dvcjfrYSDLCxFcMd6z8ddCk5wp',
    chainId: 101,
    decimals: 9,
    name: 'Aldrin',
    symbol: 'RIN',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E5ndSkaB17Dm7CsD22dvcjfrYSDLCxFcMd6z8ddCk5wp/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'aldrin' },
  },
  Mami: {
    address: '62mALBEzUQWS3r8EzjnX1C2ricdTy9hkv8gs7mLtpump',
    chainId: 101,
    decimals: 6,
    name: 'Mami',
    symbol: 'Mami',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmSmM6GzEdgFSXtgKBNGckrHbBq8abNy5HJpGE2VxEpyzv',
    tags: ['community'],
    extensions: { coingeckoId: 'mami' },
  },
  HODI: {
    address: 'HodiZE88VH3SvRYYX2fE6zYE6SsxPn9xJUMUkW1Dg6A',
    chainId: 101,
    decimals: 9,
    name: 'Cat in Hoodie',
    symbol: 'HODI',
    logoURI:
      'https://bafkreigipb4wge4gffvfkfftprfscu26n3oqufpfavpxh6v7eaw6n7yq4q.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'cat-in-hoodie' },
  },
  SBR: {
    address: 'Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1',
    chainId: 101,
    decimals: 6,
    name: 'Saber Protocol Token',
    symbol: 'SBR',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1/logo.svg',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'saber' },
  },
  HOOD: {
    address: 'AGyUuFvYGnUUXWG6GUKga4B3MGmBuEZ9hGqY73n76XpJ',
    chainId: 101,
    decimals: 8,
    name: 'wagmicatgirlkanye420etfmoon1000x',
    symbol: 'HOOD',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/HOOD_wh.png',
    tags: ['wormhole'],
    extensions: { coingeckoId: 'wagmicatgirlkanye420etfmoon1000x' },
  },
  PONK: {
    address: 'HeqCcMjmuV5s25J49YiJyT6bD5qWLkP88YPajBySniaV',
    chainId: 101,
    decimals: 5,
    name: 'Ponk',
    symbol: 'PONK',
    logoURI:
      'https://bafkreieyllpkega3ytsmq5gdwx2p44roka5pnlclw2an4xivqmbq7prmge.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'ponk' },
  },
  CHINU: {
    address: 'FLrgwxXaX8q8ECF18weDf3PLAYorXST5orpY34d8jfbm',
    chainId: 101,
    decimals: 9,
    name: 'Chinu',
    symbol: 'CHINU',
    logoURI:
      'https://chinu.vip/wp-content/uploads/2024/01/chinuHeadLogo100.png',
    tags: ['community'],
    extensions: { coingeckoId: 'chinu-2' },
  },
  KNOB: {
    address: '5ritAPtFPqQtEFHcHVqNjR5oFNUJqcmgKtZyPd2AyLLy',
    chainId: 101,
    decimals: 9,
    name: 'KNOB$',
    symbol: 'KNOB',
    logoURI: 'https://i.postimg.cc/hPd4QCX6/knob-logo.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'knob' },
  },
  WINR: {
    address: 'CsxCtA8usvWKdRhe7KhLU5GgwzYaLkoHsz1MKBVZ4W3M',
    chainId: 101,
    decimals: 8,
    name: 'WINR (Wormhole)',
    symbol: 'WINR',
    logoURI: 'https://jbassets.fra1.cdn.digitaloceanspaces.com/winr-logo.png',
    tags: ['community'],
    extensions: { coingeckoId: 'winr-protocol' },
  },
  BMBO: {
    address: '5sM9xxcBTM9rWza6nEgq2cShA87JjTBx1Cu82LjgmaEg',
    chainId: 101,
    decimals: 9,
    name: 'Bamboo',
    symbol: 'BMBO',
    logoURI:
      'https://raw.githubusercontent.com/rishkumaria/bamboo/main/bamboo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'bamboo-coin' },
  },
  SNAP: {
    address: 'SNApmcWQqj3Ny2YFkQmkELQnNgaXRu6KmnYSPiFZcLn',
    chainId: 101,
    decimals: 6,
    name: 'SolSnap',
    symbol: 'SNAP',
    logoURI:
      'https://bafkreibzsiecgo2477x6fnfe24xt2bxzko35m43beexfnqvlqd4e4hgcqu.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'solsnap' },
  },
  Māo: {
    address: 'xgWKZ2h4tnstP3NzGyVFgA6Adu3Q7uCBtiYt7cErPA9',
    chainId: 101,
    decimals: 6,
    name: '猫',
    symbol: 'Māo',
    logoURI:
      'https://bafkreiezfqv3zjh5jouiaqg4ihs6hlsmxph6vulvggq2ugrimm6lxqiace.ipfs.nftstorage.link',
    tags: ['community'],
  },
  ACF: {
    address: '2cZv8HrgcWSvC6n1uEiS48cEQGb1d3fiowP2rpa4wBL9',
    chainId: 101,
    decimals: 2,
    name: 'ACF Game',
    symbol: 'ACF',
    logoURI: 'https://shop.alienchickenfarm.com/logos/acf-token.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'alien-chicken-farm' },
  },
  WIFOUT: {
    address: '2ioyweEeV4xJCkFJvh868X9iP3L6Q31MVCawfbJLRTHq',
    chainId: 101,
    decimals: 9,
    name: 'DOGWIFOUTHAT',
    symbol: 'WIFOUT',
    logoURI: 'https://i.imgur.com/3642zZD.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'dogwifouthat' },
  },
  $dicki: {
    address: '8EHC2gfTLDb2eGQfjm17mVNLWPGRc9YVD75bepZ2nZJa',
    chainId: 101,
    decimals: 9,
    name: 'dicki',
    symbol: '$dicki',
    logoURI:
      'https://node1.irys.xyz/p3DocXjrB0pcccnhu40Rs-cW8OsFeTOb-VFe1EHduoM',
    tags: ['community'],
    extensions: { coingeckoId: 'dicki' },
  },
  sols: {
    address: '2wme8EVkw8qsfSk2B3QeX4S64ac6wxHPXb3GrdckEkio',
    chainId: 101,
    decimals: 9,
    name: 'sols',
    symbol: 'sols',
    logoURI:
      'https://arweave.net/DjDIitc-424x1UlvJLElsmhfIK3QMuFlPnJKT3CVkWY?ext=png',
    tags: ['community'],
    extensions: { coingeckoId: 'sols' },
  },
  JOEL: {
    address: 'JACSU5f2fCsQSCDNz1VX2Se4vmQyj8k5EYigD4RppvGV',
    chainId: 101,
    decimals: 9,
    name: 'Joel',
    symbol: 'JOEL',
    logoURI:
      'https://nftstorage.link/ipfs/bafkreifmylk4pzymdeshz27ui7h53gjaeiamu4oqs5fhacw66ub6uxcbda',
    tags: ['community'],
    extensions: { coingeckoId: 'joel' },
  },
  soETH: {
    address: '2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk',
    chainId: 101,
    decimals: 6,
    name: 'Wrapped Ethereum (Sollet)',
    symbol: 'soETH',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'wrapped-ethereum-sollet' },
  },
  wCAPS_v1: {
    address: 'FeLoyXk8ac2AYVmDhAWEKNWWT63Z9TczeidYbpDvxF3T',
    chainId: 101,
    decimals: 8,
    name: 'Wrapped Capsule Coin (Wormhole v1)',
    symbol: 'wCAPS_v1',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeLoyXk8ac2AYVmDhAWEKNWWT63Z9TczeidYbpDvxF3T/logo.svg',
    tags: ['old-registry', 'solana-fm'],
  },
  BHST: {
    address: 'BHSTv1nsz57fsXZzcsYWXhEygyqAaQu63DUJd3Rcp5Jo',
    chainId: 101,
    decimals: 9,
    name: 'Bit Harvest Studio Token',
    symbol: 'BHST',
    logoURI:
      'https://gitlab.com/bitharveststudio/bhs_governance/-/raw/main/Luna-token.png',
    tags: ['community'],
  },
  HIKO: {
    address: 'Dr9s6hHSrSDom1zhwtJ7vp3RsJ3AX2y7ja2ZYEHQiC7f',
    chainId: 101,
    decimals: 6,
    name: 'Hiko The Meow',
    symbol: 'HIKO',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmQ6iHysNeKGUouXNtz78SzXby95m4Tyo13Kv7x7W48vLv',
    tags: ['community'],
  },
  VONSPEED: {
    address: 'DVzrCErBzydh92bBzSJX1dKwVvb4omwhrvNz8CwRyxxV',
    chainId: 101,
    decimals: 6,
    name: 'Andrea Von Speed',
    symbol: 'VONSPEED',
    logoURI:
      'https://i.ibb.co/bmZ6pZP/8338-C1-BC-0745-4201-BE73-D39-ADCCE129-F.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'andrea-von-speed' },
  },
  $BEER: {
    address: 'AujTJJ7aMS8LDo3bFzoyXDwT3jBALUbu4VZhzZdTZLmG',
    chainId: 101,
    decimals: 6,
    name: 'BEER',
    symbol: '$BEER',
    logoURI: 'https://arweave.net/VLGzUkx9E4TBr3rGWehMfR_f42xa92VxA-JvkAIqzw0',
    tags: ['community'],
    extensions: { coingeckoId: 'beercoin-2' },
  },
  WCC: {
    address: 'BykkD9369RvXuEDbR7pTRz49b7cfLRTzHgSVoqK8gc15',
    chainId: 101,
    decimals: 6,
    name: 'White Coffee Cat',
    symbol: 'WCC',
    logoURI:
      'https://bafybeigm6bjva5igzsz56xb24gbiq3ynu5l2tuxnkbi437hplbw3fu5vxq.ipfs.cf-ipfs.com',
    tags: ['community'],
    extensions: { coingeckoId: 'white-coffee-cat' },
  },
  white: {
    address: 'FUCKuTfQVT9yCe3jPXdejBPhcPJpnceQy17uvcT9cLx8',
    chainId: 101,
    decimals: 9,
    name: 'White Solana',
    symbol: 'white',
    logoURI:
      'https://raw.githubusercontent.com/loufly1/Birdies/main/photo1697130653.jpeg',
    tags: ['community'],
  },
  tooker: {
    address: '9EYScpiysGnEimnQPzazr7Jn9GVfxFYzgTEj85hV9L6U',
    chainId: 101,
    decimals: 9,
    name: 'tooker kurlson',
    symbol: 'tooker',
    logoURI:
      'https://drive.google.com/file/d/1wcQL0K3apUAOrf0QgIvkgjwnDB9s6yHc/view',
    tags: ['community'],
    extensions: { coingeckoId: 'tooker-kurlson' },
  },
  PAINT: {
    address: '8x9c5qa4nvakKo5wHPbPa5xvTVMKmS26w4DRpCQLCLk3',
    chainId: 101,
    decimals: 9,
    name: 'MS PAINT',
    symbol: 'PAINT',
    logoURI: 'https://i.imgur.com/t7CeL6E.png',
    tags: ['community'],
    extensions: { coingeckoId: 'ms-paint' },
  },
  CHONKY: {
    address: 'H7ed7UgcLp3ax4X1CQ5WuWDn6d1pprfMMYiv5ejwLWWU',
    chainId: 101,
    decimals: 6,
    name: 'CHONKY',
    symbol: 'CHONKY',
    logoURI:
      'https://f4xsxwnaxid37ojcze5qpc7p3ydfaj5dhynszhcjseeizelsa4mq.arweave.net/Ly8r2aC6B7-5Isk7B4vv3gZQJ6M-GyycSZEIjJFyBxk',
    tags: ['community'],
    extensions: { coingeckoId: 'chonky' },
  },
  FORA: {
    address: 'ForaXiBD8K3a7C1PwxV1xqDHs5aV8y8nWRmHebafdkes',
    chainId: 101,
    decimals: 9,
    name: 'Fora',
    symbol: 'FORA',
    logoURI: 'imagepng',
    tags: ['old-registry', 'solana-fm'],
  },
  Cheese: {
    address: 'AbrMJWfDVRZ2EWCQ1xSCpoVeVgZNpq1U2AoYG98oRXfn',
    chainId: 101,
    decimals: 6,
    name: 'Cheese',
    symbol: 'Cheese',
    logoURI:
      'https://bafybeidkucqooosed5ksrfetexnedmf4kts5yo3ioocallzktffa3gjm3y.ipfs.cf-ipfs.com',
    tags: ['community'],
    extensions: { coingeckoId: 'cheese-2' },
  },
  CATMAN: {
    address: 'EavJDLh8cYTAnt3QDitpKGMsPL2hq1My5g9R2P6at6Lc',
    chainId: 101,
    decimals: 9,
    name: 'CATMAN',
    symbol: 'CATMAN',
    logoURI:
      'https://bafkreieawm4zgovoxfbgkifdyiyagsieaa6cns4jb4jafxolc5z3lqysju.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'catman' },
  },
  vBLSH: {
    address: 'FNMLmBPkhh7nBFyGHsdrmCuvgEf6ygpaVqFejimHEx9V',
    chainId: 101,
    decimals: 9,
    name: 'vBLSH',
    symbol: 'vBLSH',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FNMLmBPkhh7nBFyGHsdrmCuvgEf6ygpaVqFejimHEx9V/logo.png',
    tags: ['community', 'solana-fm'],
  },
  FUX: {
    address: 'pz8KjM9AHRu8MvjEbxHjZ6RwxQjTgxvTtRsaQtCUyQf',
    chainId: 101,
    decimals: 6,
    name: 'Fux Nous',
    symbol: 'FUX',
    logoURI:
      'https://pump.mypinata.cloud/ipfs/QmZ6U95NQDey6atv55J4TCdcpTc7yZWwZmeiNwNg4LouYU',
    tags: ['community'],
  },
  IMSO: {
    address: 'imsoru6juztJ4pTJvPQaTnY766uEmCVc4AQJ8V1Yuvs',
    chainId: 101,
    decimals: 9,
    name: 'Intergalactic',
    symbol: 'IMSO',
    logoURI:
      'https://shdw-drive.genesysgo.net/BNhknYkm4djh4V7c5jYHrRWBYvsH3wngsjd5MM3q7Ho9/interImage.jpeg',
    tags: ['community'],
  },
  JOWNES: {
    address: '8m9fjYycXAFva1kScttQgsESVZT7yELhjZASqfHBuMa5',
    chainId: 101,
    decimals: 6,
    name: 'alux jownes',
    symbol: 'JOWNES',
    logoURI:
      'https://bafkreig7kpsty6rokwswaysqfjq576eell335236tzztmpv6p536mvfida.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'alux-jownes' },
  },
  CORN: {
    address: '6DSqVXg9WLTWgz6LACqxN757QdHe1sCqkUfojWmxWtok',
    chainId: 101,
    decimals: 7,
    name: 'Solanacorn',
    symbol: 'CORN',
    logoURI:
      'https://assets.coingecko.com/coins/images/33100/standard/soly_logo_final.jpg?1707405222',
    tags: ['community'],
    extensions: { coingeckoId: 'solanacorn' },
  },
  abBUSD: {
    address: '6nuaX3ogrr2CaoAPjtaKHAoBNWok32BMcRozuf32s2QF',
    chainId: 101,
    decimals: 9,
    name: 'Wrapped BUSD (Allbridge from BSC)',
    symbol: 'abBUSD',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AJ1W9A9N9dEMdVyoDiam2rV44gnBm2csrPDP7xqcapgX/logo.png',
    tags: ['old-registry'],
  },
  SKYH: {
    address: 'J9hBnna1TMySX9hA3FgiC5xYph5pYHmcLVq2Sp36hDkz',
    chainId: 101,
    decimals: 6,
    name: 'Sky Hause',
    symbol: 'SKYH',
    logoURI: 'https://i.imgur.com/68qAjC0.png',
    tags: ['community'],
    extensions: { coingeckoId: 'sky-hause' },
  },
  HAPPI: {
    address: 'aBvs3Zv9JYmvUCKqRJvGctDQSCt6R7NAMELid3FeqsQ',
    chainId: 101,
    decimals: 6,
    name: 'happi cat',
    symbol: 'HAPPI',
    logoURI:
      'https://bafybeiemgqcqgeu4mqvrmdncrlch7zhwbvb2xlsfnovuqcp4hb4jhsjsfm.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'happi-cat' },
  },
  FRA: {
    address: 'F9mv7XXbrXZb1sP2JUoswbCB3WHQM4QGMFDTVfnRZMnP',
    chainId: 101,
    decimals: 6,
    name: 'France Coin',
    symbol: 'FRA',
    logoURI:
      'https://ipfs.io/ipfs/QmUL5LYoepWExyeNzhbPLQSTuECcLnNmXqD1n4TTM7bubg',
    tags: ['community'],
    extensions: { coingeckoId: 'france-coin' },
  },
  SILLY: {
    address: '7EYnhQoR9YM3N7UoaKRoA44Uy8JeaZV3qyouov87awMs',
    chainId: 101,
    decimals: 9,
    name: 'Silly Dragon',
    symbol: 'SILLY',
    logoURI:
      'https://gateway.irys.xyz/quusRf1O-_WcCbrFwHkbIZ26vxQ1xJZeQdsaKRITVNQ',
    tags: ['community'],
    extensions: { coingeckoId: 'silly-dragon' },
  },
  ySOL: {
    address: 'yso11zxLbHA3wBJ9HAtVu6wnesqz9A2qxnhxanasZ4N',
    chainId: 101,
    decimals: 9,
    name: 'Synatra Staked SOL',
    symbol: 'ySOL',
    logoURI:
      'https://shdw-drive.genesysgo.net/76HCWYyF9Sps1TnRdZB1CtHbj5Ji7trzqi4tnxfCabXE/ysol-logo.png',
    tags: ['community'],
    extensions: { coingeckoId: 'synatra-staked-sol' },
  },
  COPE: {
    address: '8HGyAAB1yoM1ttS7pXjHMa3dukTFGQggnFFH3hJZgzQh',
    chainId: 101,
    decimals: 6,
    name: 'COPE',
    symbol: 'COPE',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8HGyAAB1yoM1ttS7pXjHMa3dukTFGQggnFFH3hJZgzQh/logo.png',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'cope' },
  },
  LUNY: {
    address: '7a4cXVvVT7kF6hS5q5LDqtzWfHfys4a9PoK6pf87RKwf',
    chainId: 101,
    decimals: 9,
    name: 'Luna Yield',
    symbol: 'LUNY',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7a4cXVvVT7kF6hS5q5LDqtzWfHfys4a9PoK6pf87RKwf/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  wFTT_v1: {
    address: 'GbBWwtYTMPis4VHb8MrBbdibPhn28TSrLB53KvUmb7Gi',
    chainId: 101,
    decimals: 9,
    name: 'FTT (Wormhole v1)',
    symbol: 'wFTT_v1',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GbBWwtYTMPis4VHb8MrBbdibPhn28TSrLB53KvUmb7Gi/logo.png',
    tags: ['old-registry'],
  },
  LILY: {
    address: 'LiLyT885cG9xZKYQk9x6VWMzmcui4ueV9J1uzPDDajY',
    chainId: 101,
    decimals: 6,
    name: 'Solily Protocol Coin',
    symbol: 'LILY',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LiLyT885cG9xZKYQk9x6VWMzmcui4ueV9J1uzPDDajY/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  MAPS: {
    address: 'MAPS41MDahZ9QdKXhVa4dWB9RuyfV4XqhyAZ8XcYepb',
    chainId: 101,
    decimals: 6,
    name: 'MAPS',
    symbol: 'MAPS',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MAPS41MDahZ9QdKXhVa4dWB9RuyfV4XqhyAZ8XcYepb/logo.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'maps' },
  },
  laineSOL: {
    address: 'LAinEtNLgpmCP9Rvsf5Hn8W6EhNiKLZQti1xfWMLy6X',
    chainId: 101,
    decimals: 9,
    name: 'Laine Stake',
    symbol: 'laineSOL',
    logoURI:
      'https://shdw-drive.genesysgo.net/4DUkKJB966oMk8zq57KkAUxqg9HpuWtZ3BKobhmYph39/laineSOL.webp',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'laine-stake' },
  },
  BIRB: {
    address: '2EBjqPYGLUExdWwJJRLqtGPawzb2aMjE1wTpUYKhy2UQ',
    chainId: 101,
    decimals: 9,
    name: 'birb',
    symbol: 'BIRB',
    logoURI: 'https://arweave.net/qkyn_nVLFuJx1mphiPH5pb5jSnnRc52ATLZXzwmqXGY',
    tags: ['community'],
    extensions: { coingeckoId: 'birb-3' },
  },
  LEDGER: {
    address: '4gkvFD2SFKjR266heEc4XxWd7oPVxesLtAh5uRXdN2vt',
    chainId: 101,
    decimals: 6,
    name: 'go to bed old man',
    symbol: 'LEDGER',
    logoURI:
      'https://bafybeifnbiagywmqvya4bcibehebgizq5nuzzh363svrzc4pcakjfdafjy.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  SHARBI: {
    address: '8D1nUMJQam54o34Kj2knFhSTaWoehEr4mBc7LfiDdCqq',
    chainId: 101,
    decimals: 8,
    name: 'Sharbi',
    symbol: 'SHARBI',
    logoURI:
      'https://bafkreigwjy43gzwjqlfpottif2pmn3i5fyk7z6p5txvtoceljr4iqxvozq.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'sharbi' },
  },
  KOI: {
    address: '6fdCC8xfrXNy6PmNaVcxdEY5XNCTAha2V54zYYnmBCey',
    chainId: 101,
    decimals: 6,
    name: 'KOI',
    symbol: 'KOI',
    logoURI:
      'https://bafybeienmtuhyl7nhi47psrnap7glxyx4wgo462man4l3nyfrgs5lavsae.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'koi' },
  },
  MESSI: {
    address: 'HiRx4TWNmuPK6VKxGKPSw8T7dZ9NuCrakpNpRoiCEyrA',
    chainId: 101,
    decimals: 6,
    name: 'Lionel Messi',
    symbol: 'MESSI',
    logoURI:
      'https://bafybeidgb7gr3avdzs2c5zc7oi4at3bjrcw5k6c3fgzk6lw2ewpfmy43jy.ipfs.nftstorage.link',
    tags: ['community'],
  },
  GOTTI: {
    address: 'FoAnSCG6CcqTq2rsTi58yyYBNk1HgsbLzS6b1kTP2ACL',
    chainId: 101,
    decimals: 9,
    name: 'GOTTI',
    symbol: 'GOTTI',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f697066732e696f2f697066732f516d623259554476394a316d54394b3157326e535432366b3455527862774353747171356435767167396e4c3443',
    tags: ['community'],
    extensions: { coingeckoId: 'gotti-token' },
  },
  CROWN: {
    address: 'GDfnEsia2WLAW5t8yx2X5j2mkfA74i5kwGdDuZHt7XmG',
    chainId: 101,
    decimals: 9,
    name: 'CROWN Token',
    symbol: 'CROWN',
    logoURI:
      'https://shdw-drive.genesysgo.net/AwJ6W2rRaYCGXimceFuLm5td14fhN1VFEfSYg566RxMD/image.png',
    tags: ['community'],
    extensions: { coingeckoId: 'crown-by-third-time-games' },
  },
  GEN: {
    address: '1F1sRpidpKBQjmCnHAu8vJpmktLVK3ZjKyxVwEy8XpC',
    chainId: 101,
    decimals: 6,
    name: 'SolGen',
    symbol: 'GEN',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmVNexv8GWNZ39We31Wk9fHZN2Aeu3HLmRn6KgAXC7qJAc',
    tags: ['community'],
  },
  ZAP: {
    address: 'HxPoEHMt1vKeqjKCePcqTj6yYgn6Xqq1fKTY3Pjx4YrX',
    chainId: 101,
    decimals: 8,
    name: 'ZAP Token',
    symbol: 'ZAP',
    logoURI: 'https://www.zap.org/static/zapSplash.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'zap' },
  },
  BLZE: {
    address: 'BLZEEuZUBVqFhj8adcCFPJvPVCiCyVmh3hkJMrU8KuJA',
    chainId: 101,
    decimals: 9,
    name: 'Blaze',
    symbol: 'BLZE',
    logoURI: 'https://solblaze.org/assets/blze.png',
    tags: ['community'],
    extensions: { coingeckoId: 'solblaze' },
  },
  CIF: {
    address: 'G3vWvAaXPHCnncnyAbq6yBRXqfRtEV3h7vExzasZeT6g',
    chainId: 101,
    decimals: 6,
    name: 'CATWIFHAT',
    symbol: 'CIF',
    logoURI: 'https://i.postimg.cc/hGBgdxxj/Cif200x200.png',
    tags: ['community'],
    extensions: { coingeckoId: 'catwifhat' },
  },
  prtSOL: {
    address: 'BdZPG9xWrG3uFrx2KrUW1jT4tZ9VKPDWknYihzoPRJS3',
    chainId: 101,
    decimals: 9,
    name: 'prtSOL (Parrot Staked SOL)',
    symbol: 'prtSOL',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BdZPG9xWrG3uFrx2KrUW1jT4tZ9VKPDWknYihzoPRJS3/logo.svg',
    tags: ['old-registry'],
  },
  $TIPS: {
    address: 'AK87oZM8ovFU14bq8mu4ufi5zsCPDbqqVvh5b6WHbUdL',
    chainId: 101,
    decimals: 9,
    name: 'Just The Tip',
    symbol: '$TIPS',
    logoURI:
      'https://justthetipvip.com/wp-content/uploads/2024/02/comp-header.png',
    tags: ['community', 'token-2022'],
    extensions: { coingeckoId: 'just-the-tip' },
  },
  rogen: {
    address: 'DtgDZb83TqywcuBuWE89jx4k5Y7b6nQ4GYJq3Wd61JQQ',
    chainId: 101,
    decimals: 6,
    name: 'jeo rogen',
    symbol: 'rogen',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmZ5WeZCweWhbKbJ7Ux1ewxVVMrwz5dYL6szeBdT59f4AF',
    tags: ['community'],
  },
  ELMNT: {
    address: '9xYeZDHEwyuqJmqrTourbFRaxN2qhkYesnz3iQ3FPz4r',
    chainId: 101,
    decimals: 5,
    name: 'ELMNT',
    symbol: 'ELMNT',
    logoURI:
      'https://pbs.twimg.com/profile_images/1656328676768620549/ynTCpCiz_400x400.jpg',
    tags: ['community'],
  },
  MRX: {
    address: '8yQuj5v4s72UqZi3sYZL5rAD4NPV4ueUwBKzChBDWMVf',
    chainId: 101,
    decimals: 9,
    name: 'Maars',
    symbol: 'MRX',
    logoURI:
      'https://raw.githubusercontent.com/maarstoken/crypto/main/maars-800x800.png',
    tags: ['old-registry'],
  },
  RING: {
    address: '7SEsxCsiNiYqCpYG16wx4c9u2YGLZphnEFTAU9ENAizD',
    chainId: 101,
    decimals: 9,
    name: 'RING',
    symbol: 'RING',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7SEsxCsiNiYqCpYG16wx4c9u2YGLZphnEFTAU9ENAizD/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  GODEX: {
    address: 'GoDCaGctsLDnkV8T6jBAfTeMj8DesxT71CkZekV5ZXcf',
    chainId: 101,
    decimals: 9,
    name: 'GUARD OF DECENT',
    symbol: 'GODEX',
    logoURI: 'https://godex.world/meta.png',
    tags: ['community', 'token-2022'],
    extensions: { coingeckoId: 'guard-of-decent' },
  },
  puten: {
    address: '6y56NYaUtKtjT5LKrVvZ3rsLnKyQ96phU3ckSARUkNax',
    chainId: 101,
    decimals: 9,
    name: 'vledemer puten',
    symbol: 'puten',
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://bafkreiensk7owgg2bfywm4ecqafcnea75km65bc6oxysnkxep5v5lpuc2m.ipfs.nftstorage.link',
    tags: ['community'],
  },
  Chud: {
    address: '6yjNqPzTSanBWSa6dxVEgTjePXBrZ2FoHLDQwYwEsyM6',
    chainId: 101,
    decimals: 6,
    name: 'Chudjak',
    symbol: 'Chud',
    logoURI:
      'https://ipfs.io/ipfs/QmXRB5ujAAVNERmTPcmPT5aZaySVgNeAWVuXQNShWW2d37',
    tags: ['community'],
    extensions: { coingeckoId: 'chudjak' },
  },
  $GRW: {
    address: 'FPymkKgpg1sLFbVao4JMk4ip8xb8C8uKqfMdARMobHaw',
    chainId: 101,
    decimals: 9,
    name: 'GrowSol',
    symbol: '$GRW',
    logoURI:
      'https://bafybeiaf23s4jadgatgzrmad3u3ahic4wdpsgcubl4ka2a2mleuq7j4eya.ipfs.nftstorage.link/',
    tags: ['community', 'token-2022'],
    extensions: { coingeckoId: 'growsol' },
  },
  ju: {
    address: 'AbBEjBdz31yCb9gTFfWgEi7uprCzjTEKL58xqHVbbjhu',
    chainId: 101,
    decimals: 9,
    name: 'ju rugan',
    symbol: 'ju',
    logoURI:
      'https://images-ext-1.discordapp.net/external/ZyWti0yhEdpFyY5I9S-VQre58AJTrdj2_PIYxf1Dn0E/https/cdn1.cdn-telegram.org/file/bDQ-A_GvHC004mCLWnnbu5kTzG6cBL_nfuOc7W9l7cQ8RjEBmrHEw-A7hhaP-CicO5kzfnr7mCg1YJoVb-J6_FMTUwFUUCJompZ5ukIO0EJqcEAivRAgkV8TqGiXMquevuQW_laMDU-4u4Mjd8OQ535-QPWfh2KIh2PKyO68GU0nc2NE4fUTMqN2Nao2CIgnspgy7HdzCOYY8HqEZLf0r3OCCGCiOlkiG73wrZ57WN6wWIY0820HPkHLC2jNF7gNoAHFOBdO8_xB5PdK2IKC3mJHmVuMjEx_QDOEDLF_Y-IkJoj3kAUeX_lw-sED_wupa2aLW4H79K-UMh6snkDIlA.jpg?format=webp',
    tags: ['community'],
    extensions: { coingeckoId: 'ju-rugan' },
  },
  GU: {
    address: '5KV2W2XPdSo97wQWcuAVi6G4PaCoieg4Lhhi61PAMaMJ',
    chainId: 101,
    decimals: 9,
    name: 'Kugle GU',
    symbol: 'GU',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5KV2W2XPdSo97wQWcuAVi6G4PaCoieg4Lhhi61PAMaMJ/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'gu' },
  },
  PICA: {
    address: '966vsqwoS3ZBrHesTyAvE7esFV2kaHaDFLLXs4asPdLJ',
    chainId: 101,
    decimals: 9,
    name: 'Picasso',
    symbol: 'PICA',
    logoURI:
      'https://arweave.net/ZwYqSsMDWenrJd20uNAj-lGrgoNF0rGuYSIF_EYgwxg?ext=png',
    tags: ['community'],
    extensions: { coingeckoId: 'picasso' },
  },
  KIT: {
    address: 'AUgdt7wjBifF9vZpde7BjU6HLroCYh4SUHYc7yhheECW',
    chainId: 101,
    decimals: 9,
    name: 'Kitsune',
    symbol: 'KIT',
    logoURI:
      'https://bafkreialxzf3b3bghbqantzfohxw4n35hpoqgbsi2ffg4lfise7vg7jxju.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'kitsune' },
  },
  tub: {
    address: 'Vm4ZLJ9WsCVPqdy6ubq7NECRZfvdLY4yLGERDnjEuB9',
    chainId: 101,
    decimals: 6,
    name: 'tub',
    symbol: 'tub',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f63662d697066732e636f6d2f697066732f516d5a6856544276796d4a5a666665516771367451735a50316d6e64336e57426b436a627262636145314e364859',
    tags: ['community'],
    extensions: { coingeckoId: 'tub' },
  },
  HASUKI: {
    address: 'DMvsB8cjKXDQJs8cvoDtKxX7KEMVeZ31KzycszuinJUY',
    chainId: 101,
    decimals: 2,
    name: 'Hasuki',
    symbol: 'HASUKI',
    logoURI:
      'https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://creator-hub-prod.s3.us-east-2.amazonaws.com/hasuki_pfp_1664008129191.png',
    tags: ['community'],
  },
  COL: {
    address: 'B4cYZYVYeHgLc3W1pCduCYkoS75G6roPaPdPoBCFweNJ',
    chainId: 101,
    decimals: 9,
    name: 'Colana',
    symbol: 'COL',
    logoURI:
      'https://www.arweave.net/hj3JN-bVGRs0ytJmxtXkL3vswXnPHfRAGOUNTNyyg8g',
    tags: ['community'],
    extensions: { coingeckoId: 'dogecola' },
  },
  ACHI: {
    address: '4rUfhWTRpjD1ECGjw1UReVhA8G63CrATuoFLRVRkkqhs',
    chainId: 101,
    decimals: 9,
    name: 'achi',
    symbol: 'ACHI',
    logoURI:
      'https://bafybeif4dmihdltj7kn2z2ky752sze73u7azeic43qquleqiga55mdi3ie.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'achi' },
  },
  DIRTY: {
    address: 'AuWLSEuDRJi6hVcXbeez9WVbpeRsiTvvbZG1svzeBaxf',
    chainId: 101,
    decimals: 6,
    name: 'Dirty Street Cats',
    symbol: 'DIRTY',
    logoURI:
      'https://bafybeie3ubx4vj6twdrsofkhfenktcfksm3ce2pfoeeahahxkl6d3s5sza.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'dirty-street-cats' },
  },
  LOST: {
    address: '4riQPy1yXQ2d1FfzgLy9eJy6yoUdZuQkMj7JsVKr4bGu',
    chainId: 101,
    decimals: 5,
    name: 'Lost',
    symbol: 'LOST',
    logoURI:
      'https://bafybeigqh27u3ug2pui56t3h6bhu7pkojozoqzwamnnj7rdix5avtujse4.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'lost' },
  },
  trumpie: {
    address: 'HC2KyVkPK9Mc9NEFPUi43okhTYPa5fStk6a3Ci3cDbQS',
    chainId: 101,
    decimals: 6,
    name: 'trumpie',
    symbol: 'trumpie',
    logoURI:
      'https://ipfs.io/ipfs/QmYFY4tHPVNZ2sLYnktG4t4axSwXNhFxQHjdgyy39tLvdx',
    tags: ['community'],
    extensions: { coingeckoId: 'trumpie' },
  },
  CWR: {
    address: '3hkAy2qcNJaxS3KebjNdugYbgakLvUZHRGZyRmGjSsbm',
    chainId: 101,
    decimals: 9,
    name: 'Capo Was Right',
    symbol: 'CWR',
    logoURI:
      'https://bafkreihp7yxlafl54d3tjjlvoyspd7sccwyzro4xj5mcqw7o6nysvoq74m.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'capo-was-right' },
  },
  RatSol: {
    address: 'HNRrr5YBYgBz3orDGVc4pGQsc5mnLtyVtfKaMt4WNDMz',
    chainId: 101,
    decimals: 6,
    name: 'Rats on SOL',
    symbol: 'RatSol',
    logoURI:
      'https://ipfs.io/ipfs/QmX5wDBzYMmFhfFbrSQ5hg2Z6HArCYi3uYMNg5hX5fqxH3',
    tags: ['community'],
  },
  ISKT: {
    address: 'isktkk27QaTpoRUhwwS5n9YUoYf8ydCuoTz5R2tFEKu',
    chainId: 101,
    decimals: 2,
    name: 'Rafkróna',
    symbol: 'ISKT',
    logoURI:
      'https://raw.githubusercontent.com/rafmyntasjodur/iskt-metadata/main/logo.png',
    tags: ['community'],
  },
  MOAI: {
    address: '7NQSHjuEGENZDWfSvPZz7oP2D6c5Jc3LjFC6uh179ufr',
    chainId: 101,
    decimals: 9,
    name: ':moyai:(MOAI)',
    symbol: 'MOAI',
    logoURI:
      'https://node2.irys.xyz/z-DMnyxOt4FgHKdQq-05tIJOyarmt7Tg1QUqUeEOdew',
    tags: ['community'],
    extensions: { coingeckoId: 'moai' },
  },
  PPT: {
    address: 'Fb2DefbdjKFPfmTnq4xnuZ9xdtVyXGsEAetoFmGLZQcc',
    chainId: 101,
    decimals: 8,
    name: 'PAPA Trump',
    symbol: 'PPT',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f706170617472756d702e7669702f77702d636f6e74656e742f75706c6f6164732f323032322f30372f6c6f676f2d343830783438302e706e67',
    tags: ['community'],
    extensions: { coingeckoId: 'papa-trump' },
  },
  BOTMAN: {
    address: '2adqrK24G2iu2mmEijzKPKg69DUwGk3fUWAQx7Y1kSSK',
    chainId: 101,
    decimals: 6,
    name: 'botman',
    symbol: 'BOTMAN',
    logoURI: 'https://thebotman.xyz/images/botman_pfp.png',
    tags: ['community'],
  },
  CATO: {
    address: '5p2zjqCd1WJzAVgcEnjhb9zWDU7b9XVhFhx4usiyN7jB',
    chainId: 101,
    decimals: 9,
    name: 'CATO',
    symbol: 'CATO',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5p2zjqCd1WJzAVgcEnjhb9zWDU7b9XVhFhx4usiyN7jB/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'cato' },
  },
  PbP: {
    address: '3f7wfg9yHLtGKvy75MmqsVT1ueTFoqyySQbusrX1YAQ4',
    chainId: 101,
    decimals: 9,
    name: 'Pixel By Pixel',
    symbol: 'PbP',
    logoURI:
      'https://www.arweave.net/oM5esNEOkJrw6rElkNeg8I_ayWnf_ScNC8P9cgqNsms',
    tags: ['community'],
  },
  MOLA: {
    address: 'AMdnw9H5DFtQwZowVFr4kUgSXJzLokKSinvgGiUoLSps',
    chainId: 101,
    decimals: 9,
    name: 'MOONLANA',
    symbol: 'MOLA',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AMdnw9H5DFtQwZowVFr4kUgSXJzLokKSinvgGiUoLSps/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'moonlana' },
  },
  YAKU: {
    address: 'AqEHVh8J2nXH9saV2ciZyYwPpqWFRfD2ffcq5Z8xxqm5',
    chainId: 101,
    decimals: 9,
    name: 'Yaku',
    symbol: 'YAKU',
    logoURI: 'https://arweave.net/vBuoW86uFsjvQjilInux-5bL8rNI5sbehVBY16KmU5I',
    tags: ['community'],
    extensions: { coingeckoId: 'yaku' },
  },
  GMT: {
    address: '7i5KKsX2weiTkry7jA4ZwSuXGhs5eJBEjY8vVxR4pfRx',
    chainId: 101,
    decimals: 9,
    name: 'GMT',
    symbol: 'GMT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7i5KKsX2weiTkry7jA4ZwSuXGhs5eJBEjY8vVxR4pfRx/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'stepn' },
  },
  HPOW: {
    address: 'H865QN9mXUjDgSds2HtNrmTrRg2Z28oHgBQjJXfAWoQm',
    chainId: 101,
    decimals: 6,
    name: 'HydroCryptoPower',
    symbol: 'HPOW',
    logoURI:
      'https://bafkreicbzvhz6yk3dfz2wfbn4ejkyweoui5apfdo2fityqc2doyxnftqvy.ipfs.nftstorage.link',
    tags: ['community'],
  },
  SOLPAKA: {
    address: 'BDHqX9YfJE3M6caox3obUX5YpWHz2cjnGFiZJtRghdCo',
    chainId: 101,
    decimals: 9,
    name: 'Solpaka',
    symbol: 'SOLPAKA',
    logoURI: 'https://www.solpaka.com/solpaka-logo-transparent.png',
    tags: ['community'],
    extensions: { coingeckoId: 'solpaka' },
  },
  Milady: {
    address: 'HB2pjBPuquh1XGEVC1GCKepdh4daoJnkJiHFmaorfBWu',
    chainId: 101,
    decimals: 9,
    name: 'Milady',
    symbol: 'Milady',
    logoURI:
      'https://bafkreiancqnp4k3varzynj4xuaigl3674r7ok4rkgwherzwkwqyevq23mi.ipfs.nftstorage.link',
    tags: ['community'],
  },
  clAPT: {
    address: 'HHncifGW3yJyaW2fRRfBYAawnD9ogbsWM5PccFA4GHSx',
    chainId: 101,
    decimals: 8,
    name: 'Cloned Aptos',
    symbol: 'clAPT',
    logoURI: 'https://markets.clone.so/images/assets/on-apt.svg',
    tags: ['community'],
    extensions: { coingeckoId: 'cloned-aptos' },
  },
  HAT: {
    address: 'HrqgFZipMFHXvN5nKvTUaCwuA3Tp2UGqcQzArRGAyQ22',
    chainId: 101,
    decimals: 9,
    name: 'HAT',
    symbol: 'HAT',
    logoURI:
      'https://dd.dexscreener.com/ds-data/tokens/solana/HrqgFZipMFHXvN5nKvTUaCwuA3Tp2UGqcQzArRGAyQ22.png?size=lg&key=43fdcc',
    tags: ['community'],
    extensions: { coingeckoId: 'hat-solana' },
  },
  gomu: {
    address: 'Bx74hpFiaiBbSonrjyqxjGfAA7gRxM2CeKiy31uN6biR',
    chainId: 101,
    decimals: 6,
    name: 'gomu gator',
    symbol: 'gomu',
    logoURI:
      'https://ipfs.io/ipfs/QmSHQy78zSk1eaG8cFGiLEeoyWAEB2ro3S76WpJZ1FfjKW',
    tags: ['community'],
    extensions: { coingeckoId: 'gomu-gator' },
  },
  TOWL: {
    address: '9j3vEmBng3JhcL7LPaAx2CoLqE46AYmGPbbFgHKZVKWZ',
    chainId: 101,
    decimals: 6,
    name: 'towl',
    symbol: 'TOWL',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmSEaM7SkjfCG6NkkPNDyHjvwWwMGX3SbFFkK8pD152hvU',
    tags: ['community'],
  },
  DJCAT: {
    address: 'A5gVj5j16U4vCTMXQYYrhkmWgx6gej4nAeZRNQMLUAfy',
    chainId: 101,
    decimals: 6,
    name: 'DJCAT',
    symbol: 'DJCAT',
    logoURI:
      'https://bafybeiemccydtdx56wyyb3nfkyqezoqguvk5vew2av2lquqchodixzxa7y.ipfs.cf-ipfs.com/',
    tags: ['community'],
    extensions: { coingeckoId: 'djcat' },
  },
  OTK: {
    address: 'octo82drBEdm8CSDaEKBymVn86TBtgmPnDdmE64PTqJ',
    chainId: 101,
    decimals: 9,
    name: 'Octokn',
    symbol: 'OTK',
    logoURI:
      'https://storage.googleapis.com/the-octos-nfts/icon-otk-big-filled.png',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'octo-gaming' },
  },
  ANONN: {
    address: 'GreXYjqvAaEtqBsizrojtwHTNqXraVGZiJpCk5qtj1zC',
    chainId: 101,
    decimals: 9,
    name: 'Anonymous',
    symbol: 'ANONN',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f697066732e696f2f697066732f6261666b726569686a3365657174363677616d6479626462726e763772776b6d6471747932326b6376666e6b626463336a74363777326833617369',
    tags: ['community'],
  },
  DEA: {
    address: 'HxtRWx83K61KjsNu1iCB4uhX9cbUtcSizRjLXXSZyyEm',
    chainId: 101,
    decimals: 6,
    name: 'Dog Enforcement Agency',
    symbol: 'DEA',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f6261666b726569656568726a776d69723272736132366e767633667633787877346935797a64706268627076376f6f79716c7a646d7235656677792e697066732e6e667473746f726167652e6c696e6b',
    tags: ['community'],
  },
  bunk: {
    address: '2nhjjqSkA8FYCUdJvQhYjbtZdPjZbNo8VtNKTkJ3hncb',
    chainId: 101,
    decimals: 6,
    name: 'bunkee',
    symbol: 'bunk',
    logoURI:
      'https://dd.dexscreener.com/ds-data/tokens/solana/2nhjjqSkA8FYCUdJvQhYjbtZdPjZbNo8VtNKTkJ3hncb.png?size=lg&key=78937d',
    tags: ['community'],
    extensions: { coingeckoId: 'bunkee' },
  },
  FALX: {
    address: 'Afo4NumBNHDXc7m7p6qjZ1pF3LbqYfG5k1CNrGve8rVu',
    chainId: 101,
    decimals: 9,
    name: 'FalconX',
    symbol: 'FALX',
    logoURI:
      'https://purple-causal-bass-212.mypinata.cloud/ipfs/QmSoDedAeG3CMHL89FGgZrCwH9ubEtUm2w9WRUtnrBz4Rr',
    tags: ['community'],
    extensions: { coingeckoId: 'falx' },
  },
  doben: {
    address: 'EaiD1TvwaFavVTi9eroMDcovp1ok1dpRpbrKJmzeHfXM',
    chainId: 101,
    decimals: 4,
    name: 'dark boden',
    symbol: 'doben',
    logoURI:
      'https://bafybeigb654o3eagfyycscjgnwwnznpzvk6uvlt262s2yqvozpaivf5i7y.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  FOFAR: {
    address: '6Hfwp6Fht1pEtusLsDqKuovBisPbFVoA7jZQYeK1aMmG',
    chainId: 101,
    decimals: 9,
    name: 'FOFAR',
    symbol: 'FOFAR',
    logoURI:
      'https://ipfs.io/ipfs/QmQvUphAXe5DdZBZL5RBZY4vdKn2cAjDkyo3LhEaHsPipB',
    tags: ['community'],
  },
  HARIBO: {
    address: 'CYobWQ73DYiyERWcvgLkYMSzkXn5Vy18JKxidjeqM6oy',
    chainId: 101,
    decimals: 9,
    name: 'Haribo',
    symbol: 'HARIBO',
    logoURI:
      'https://bafkreige4xbv3mefdb4zvjzays47jpz3kqei7pciulislnxb6sqhdx7taq.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  VIVAION: {
    address: '2jw1uFmc1hhfJH3EqGhaE2rfZMMC2YBpxkZcdUbPppMn',
    chainId: 101,
    decimals: 9,
    name: 'Vivaion Token',
    symbol: 'VIVAION',
    logoURI: 'https://arweave.net/Xz7ErS3TZYmBnoh3OPkIQM_O6TPotyEJb6H5WFOe_qc',
    tags: ['old-registry', 'solana-fm'],
  },
  BELUGA: {
    address: 'E63CDwLy9Dwr3EptAzopV9RuWoQnn5ZVYEjLWnJX8dCw',
    chainId: 101,
    decimals: 9,
    name: 'Beluga Cat',
    symbol: 'BELUGA',
    logoURI:
      'https://bafybeid6xz4mx75na6wmjhn3axywvgs3d3gceie2evbg3koharbz4eptry.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'beluga-cat' },
  },
  HEGE: {
    address: 'ULwSJmmpxmnRfpu6BjnK6rprKXqD5jXUmPpS1FxHXFy',
    chainId: 101,
    decimals: 9,
    name: 'Hege',
    symbol: 'HEGE',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f62616679626569636869373579337664777432686d6c346363797064647835746f7534796668707934327a336b346c686a3634766a3268696e6f612e697066732e6e667473746f726167652e6c696e6b',
    tags: ['community'],
    extensions: { coingeckoId: 'hege' },
  },
  SUITE: {
    address: '4h41QKUkQPd2pCAFXNNgZUyGUxQ6E7fMexaZZHziCvhh',
    chainId: 101,
    decimals: 6,
    name: 'The Suites Token',
    symbol: 'SUITE',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4h41QKUkQPd2pCAFXNNgZUyGUxQ6E7fMexaZZHziCvhh/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  WHO: {
    address: 'A8eYjpNQF6QYEE7DnM4F514MErFzV1pxVT69d7mdSsMk',
    chainId: 101,
    decimals: 9,
    name: 'Who',
    symbol: 'WHO',
    logoURI:
      'https://bafybeiaahcxwjk6ooyja3xzobfr7gteou6yxsxksyvb7qs7wlhp3mezxty.ipfs.nftstorage.link',
    tags: ['community', 'token-2022'],
  },
  SB: {
    address: '8twuNzMszqWeFbDErwtf4gw13E6MUS4Hsdx5mi3aqXAM',
    chainId: 101,
    decimals: 9,
    name: 'Solbank',
    symbol: 'SB',
    logoURI:
      'https://gateway.irys.xyz/niSLX8FhcEagTaPapRW4gEbi_u2y675Gy1isLB6wAuM',
    tags: ['community'],
    extensions: { coingeckoId: 'solbank' },
  },
  LTX: {
    address: 'LTXH7nCGXz5TBZ57H8oZu7YwmDSVfSqWViW4B28yg8X',
    chainId: 101,
    decimals: 8,
    name: 'Tanox',
    symbol: 'LTX',
    logoURI:
      'https://shdw-drive.genesysgo.net/6EcEYWtMEjonup7RcqStXtfTnYYKavBizgFDGoJoVPZL/image.png',
    tags: ['community'],
  },
  KOW: {
    address: '3WoatNLfMffazu8aCSmRSdWFngpJQ4GUMFJxtSzoDqau',
    chainId: 101,
    decimals: 6,
    name: 'Kowalski',
    symbol: 'KOW',
    logoURI:
      'https://bafkreihdkgipc7nho7lix35i54gupf56hpnazx6d5jbqtxtmg34iifefga.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  monk: {
    address: 'FYa25XnBsXQXAdTnsyKBKd5gZ1VZhChBRF57CqfRxJZX',
    chainId: 101,
    decimals: 6,
    name: 'monkeyhaircut',
    symbol: 'monk',
    logoURI:
      'https://bafybeifx7lchopsihh6qhw5nvq4tjbwl4wf2wy745mugvhdq5krk2bwsmi.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'monkeyhaircut' },
  },
  GSOL: {
    address: 'gso1xA56hacfgTHTF4F7wN5r4jbnJsKh99vR595uybA',
    chainId: 101,
    decimals: 9,
    name: 'Sunrise gSOL',
    symbol: 'GSOL',
    logoURI: 'https://arweave.net/46QNhUyat533ptVC2myiNVb5BvSP7PAFzM5ILCZTrGU',
    tags: ['community', 'solana-fm'],
  },
  KIRA: {
    address: 'kiraZUmSnzgfVfhrdvNj6hxHFaPFTTUk8ioY98cbh6G',
    chainId: 101,
    decimals: 9,
    name: 'KIRA',
    symbol: 'KIRA',
    logoURI:
      'https://www.arweave.net/71SbFojNuoFeQ_n4cfMYKzX87xPvsQvNr59R_ZM7ucQ?ext=png',
    tags: ['community'],
  },
  $WIFI: {
    address: 'AcfKwAf4UQLA5DLRQD2eQxW6pLv79VKzVso38WMfTxGZ',
    chainId: 101,
    decimals: 6,
    name: 'WIFI',
    symbol: '$WIFI',
    logoURI:
      'https://ipfs.io/ipfs/QmU35RWaYbjMHWmxZjDCtDwwMGxLs29MEE7ostKj2F3iDb',
    tags: ['community'],
    extensions: { coingeckoId: 'spottie-wifi' },
  },
  CHAI: {
    address: '3jsFX1tx2Z8ewmamiwSU851GzyzM2DJMq7KWW5DM8Py3',
    chainId: 101,
    decimals: 8,
    name: 'CHAI (Portal)',
    symbol: 'CHAI',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/CHAI_wh.png',
    tags: ['wormhole'],
  },
  AOC: {
    address: 'GfkfESc5A4EkxCGWsymNY4ZfkCDdvtB7ajCybLfYL1Qq',
    chainId: 101,
    decimals: 6,
    name: 'Alickshundra Occasional-Cortex',
    symbol: 'AOC',
    logoURI:
      'https://ipfs.filebase.io/ipfs/bafybeicxg4smser27vzwm3zigmup6rvsqegpn4raptjp75xkufoyg64auq',
    tags: ['community'],
    extensions: { coingeckoId: 'alickshundra-occasional-cortex' },
  },
  BROKIE: {
    address: '4neSyzJmcSWQF58DKHdo7FNzJDDKSgaaQqrzuSXS5U6g',
    chainId: 101,
    decimals: 6,
    name: 'BROKIEINU',
    symbol: 'BROKIE',
    logoURI:
      'https://bafybeihdzznprzu25kp2psnn455m5qyp25tw46istuuvovdijigjnzgde4.ipfs.dweb.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'brokieinu' },
  },
  DAI: {
    address: 'EjmyN6qEC1Tf1JxiG1ae7UTJhUxSwk1TCWNWqxWV4J6o',
    chainId: 101,
    decimals: 8,
    name: 'DAI (Portal)',
    symbol: 'DAI',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/DAI_wh.png',
    tags: ['wormhole', 'old-registry'],
  },
  COZY: {
    address: 'cozyLxNaoJvQ3KB5dCJdu7MoZiBpwBWGdvc4dkMXnqA',
    chainId: 101,
    decimals: 6,
    name: 'Cozy Pepe',
    symbol: 'COZY',
    logoURI:
      'https://arweave.net/FZA5wylVpTtGf5B9mI9hk3xUB3CMVYvVlIOwbTlJiL8?ext=png',
    tags: ['community'],
    extensions: { coingeckoId: 'cozy-pepe' },
  },
  MONKES: {
    address: 'FmoKY2ERGmE9NzrYphAJcqH5BPRy2Hs4VomRfu8Qgt7Y',
    chainId: 101,
    decimals: 2,
    name: 'Solana Monkey Business',
    symbol: 'MONKES',
    logoURI:
      'https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://i.imgur.com/bMH6qNc.png',
    tags: ['community', 'solana-fm'],
  },
  SDOGE: {
    address: '8ymi88q5DtmdNTn2sPRNFkvMkszMHuLJ1e3RVdWjPa3s',
    chainId: 101,
    decimals: 0,
    name: 'SolDoge',
    symbol: 'SDOGE',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8ymi88q5DtmdNTn2sPRNFkvMkszMHuLJ1e3RVdWjPa3s/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'soldoge' },
  },
  DCAP: {
    address: 'BQXDYWZdtXqeLXFWYeRhLrGh8gcTmDQZQc92ENMaXSry',
    chainId: 101,
    decimals: 2,
    name: 'Doge Capital',
    symbol: 'DCAP',
    logoURI:
      'https://dl.airtable.com/.attachmentThumbnails/5e07dff05059715ef2478b80cfee0c99/41bbd847',
    tags: ['community'],
  },
  GuryVee: {
    address: 'Agqc1Dm7DD7va5RRwbEZoX1CtYBKEBwASw83KbhjKBPn',
    chainId: 101,
    decimals: 6,
    name: 'Gury Vee',
    symbol: 'GuryVee',
    logoURI:
      'https://bafybeihn5yrbcngaepotamwsp2lbcgeuo437runejrhduwor3g7ebfg6zy.ipfs.cf-ipfs.com',
    tags: ['community'],
  },
  ITA: {
    address: 'GuVoE2qAS3DHaAGSeuZfBkbLjFXfP46DFbogbrVJNHfN',
    chainId: 101,
    decimals: 6,
    name: 'Italian Coin',
    symbol: 'ITA',
    logoURI:
      'https://ipfs.io/ipfs/QmTNo8RjG6gUcnYUh6d1AsN46kbofDB5RXK4xgtkXYqCai',
    tags: ['community'],
    extensions: { coingeckoId: 'italian-coin' },
  },
  TOPG: {
    address: '8NH3AfwkizHmbVd83SSxc2YbsFmFL4m2BeepvL6upump',
    chainId: 101,
    decimals: 6,
    name: 'TOP G',
    symbol: 'TOPG',
    logoURI:
      'https://ipfs.io/ipfs/QmSoStGEqkQ3oRJtChNoqiLH7iBBmSv5xZFfrYcJvmG3Fr',
    tags: ['community'],
    extensions: { coingeckoId: 'top-g' },
  },
  SAMOWIF: {
    address: 'GS1VjXDZmDFsiqzBFYoACgRQBmXYuvdPJ88NQcXxg3qM',
    chainId: 101,
    decimals: 6,
    name: 'samo wif hat',
    symbol: 'SAMOWIF',
    logoURI:
      'https://bafybeigf4vhdjusff3au3piubpdiz2xq5lya67vt3ofp4afg5sfl3wweoe.ipfs.nftstorage.link',
    tags: ['community'],
  },
  BATT: {
    address: 'BzjsQpgKjxZedFawUV9GZMExhr7VbvsasQv12v2PVxSt',
    chainId: 101,
    decimals: 8,
    name: 'BAT',
    symbol: 'BATT',
    logoURI:
      'https://batt.finance/static/media/logo.6284c2cd472e1bacd3e5c370044f6a0e.svg',
    tags: ['community'],
  },
  HBIT: {
    address: 'Hd8crL1e3KnYEWvHBx7B2TSsadkQuFr52CwXXxZJyCv1',
    chainId: 101,
    decimals: 6,
    name: 'HashBit',
    symbol: 'HBIT',
    logoURI:
      'https://hashbit.org/public/frontend/images/site-section/8eb8b623-457f-4e8f-be12-40a80cdc0111.webp',
    tags: ['community'],
    extensions: { coingeckoId: 'hashbit-2' },
  },
  FREE: {
    address: 'Fcvn3f5BTChSH53ixtURMmF5Vqj9yGsSYrRjFkf3wCX2',
    chainId: 101,
    decimals: 9,
    name: 'FreeSpeech',
    symbol: 'FREE',
    logoURI: 'https://arweave.net/Vx8TeG4z7LEvrRDnG8OCPzZuFC0aUbHvrgSbds4og80',
    tags: ['community'],
  },
  Bazinga: {
    address: 'C3JX9TWLqHKmcoTDTppaJebX2U7DcUQDEHVSmJFz6K6S',
    chainId: 101,
    decimals: 6,
    name: 'Bazinga',
    symbol: 'Bazinga',
    logoURI:
      'https://bafybeif7h5brb4gb5p5nh5eox7xfqqrujsgcrigh325j22luq5bogucmtu.ipfs.cf-ipfs.com',
    tags: ['community'],
    extensions: { coingeckoId: 'bazinga-2' },
  },
  KKO: {
    address: 'kiNeKo77w1WBEzFFCXrTDRWGRWGP8yHvKC9rX6dqjQh',
    chainId: 101,
    decimals: 9,
    name: 'KKO Protocol',
    symbol: 'KKO',
    logoURI:
      'https://www.arweave.net/lxCJWYgYzilFtAEzqbdvS9xN98-9cG4EvizAkgx1dCY?ext=png',
    tags: ['old-registry', 'solana-fm'],
  },
  VEUR: {
    address: 'C4Kkr9NZU3VbyedcgutU6LKmi6MKz81sx6gRmk5pX519',
    chainId: 101,
    decimals: 9,
    name: 'VNX Euro',
    symbol: 'VEUR',
    logoURI:
      'https://4183046207-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2FsAJThQfNCJePE5h9iplX%2Fuploads%2FaZb0qZfNGNXHulNZRIko%2FVEUR%20256x256.png?alt=media&token=b6bad541-cee9-427d-8498-d4a4ea423c79',
    tags: ['community'],
    extensions: { coingeckoId: 'vnx-euro' },
  },
  xSTEP: {
    address: 'xStpgUCss9piqeFUk2iLVcvJEGhAdJxJQuwLkXP555G',
    chainId: 101,
    decimals: 9,
    name: 'Staked Step',
    symbol: 'xSTEP',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xStpgUCss9piqeFUk2iLVcvJEGhAdJxJQuwLkXP555G/logo.svg',
    tags: ['old-registry', 'solana-fm'],
  },
  Badger: {
    address: '9V4x6ikFm9XKsnh3TiYJWPwQfFkJZDjifu7VSUqg3es1',
    chainId: 101,
    decimals: 9,
    name: 'Badger',
    symbol: 'Badger',
    logoURI:
      'https://bafybeid32j2uhizizt77t5au2xekwprplqn2k4thirubwkrvnb2cgadije.ipfs.nftstorage.link',
    tags: ['community', 'token-2022'],
  },
  PUMPR: {
    address: 'PumPRGmZ56t3Vngxo6fCP7ZJQ14oUg3biKxXrEyQBSf',
    chainId: 101,
    decimals: 6,
    name: 'Pumpr',
    symbol: 'PUMPR',
    logoURI: 'https://arweave.net/Drsc75CkUoUY-4c_6iYXTUl2YCMt_0rz6xs8M9Mduq0',
    tags: ['community'],
    extensions: { coingeckoId: 'pumpr' },
  },
  $HONEY: {
    address: 'HonyeYAaTPgKUgQpayL914P6VAqbQZPrbkGMETZvW4iN',
    chainId: 101,
    decimals: 6,
    name: 'HONEY',
    symbol: '$HONEY',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HonyeYAaTPgKUgQpayL914P6VAqbQZPrbkGMETZvW4iN/logo.svg',
    tags: ['old-registry', 'solana-fm'],
  },
  PENG: {
    address: 'A3eME5CetyZPBoWbRUwY3tSe25S6tb18ba9ZPbWk9eFJ',
    chainId: 101,
    decimals: 6,
    name: 'Peng',
    symbol: 'PENG',
    logoURI:
      'https://bafkreibmv7tbmuqqhm2foemzuy4o4bxqd677r3obw6igrtlmpe5k3j4oge.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'peng' },
  },
  GARI: {
    address: 'CKaKtYvz6dKPyMvYq9Rh3UBrnNqYZAyd7iF4hJtjUvks',
    chainId: 101,
    decimals: 9,
    name: 'Gari',
    symbol: 'GARI',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CKaKtYvz6dKPyMvYq9Rh3UBrnNqYZAyd7iF4hJtjUvks/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'gari-network' },
  },
  BOFB: {
    address: '45wdSjpSqZCk9mkqmq5Nh7beCEqqUJMJcVduwYCip5eq',
    chainId: 101,
    decimals: 8,
    name: 'BofB',
    symbol: 'BOFB',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/45wdSjpSqZCk9mkqmq5Nh7beCEqqUJMJcVduwYCip5eq/logo.svg',
    tags: ['old-registry', 'solana-fm'],
  },
  SOLPAD: {
    address: 'GfJ3Vq2eSTYf1hJP6kKLE9RT6u7jF9gNszJhZwo5VPZp',
    chainId: 101,
    decimals: 9,
    name: 'Solpad Finance',
    symbol: 'SOLPAD',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GfJ3Vq2eSTYf1hJP6kKLE9RT6u7jF9gNszJhZwo5VPZp/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'solpad-finance' },
  },
  BIRDS: {
    address: '9m9fmqJ2s7iUXZhFMsrdes1UcAtu3JyZXK3ZXfW3KPEw',
    chainId: 101,
    decimals: 5,
    name: 'BIRDIES',
    symbol: 'BIRDS',
    logoURI:
      'https://raw.githubusercontent.com/ArashiSol/Birdies/main/photo1695208363.jpeg',
    tags: ['community'],
    extensions: { coingeckoId: 'birdies' },
  },
  clOP: {
    address: '4cwVHQtwiK7r9aAUsu4cetk1JtLWPymopWcpTSsihCdL',
    chainId: 101,
    decimals: 8,
    name: 'Cloned Optimism',
    symbol: 'clOP',
    logoURI: 'https://markets.clone.so/images/assets/on-op.svg',
    tags: ['community'],
    extensions: { coingeckoId: 'clone-protocol-clop' },
  },
  GST: {
    address: 'AFbX8oGjGpmVFywbVouvhQSRmiW2aR1mohfahi4Y2AdB',
    chainId: 101,
    decimals: 9,
    name: 'GST',
    symbol: 'GST',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AFbX8oGjGpmVFywbVouvhQSRmiW2aR1mohfahi4Y2AdB/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'green-satoshi-token' },
  },
  D2X: {
    address: 'GyuP7chtXSRB6erApifBxFvuTtz94x3zQo3JdWofBTgy',
    chainId: 101,
    decimals: 3,
    name: 'D2',
    symbol: 'D2X',
    logoURI: 'https://scpri.me/files/logo.png',
    tags: ['community'],
    extensions: { coingeckoId: 'd2' },
  },
  wHUSD_v1: {
    address: 'BybpSTBoZHsmKnfxYG47GDhVPKrnEKX31CScShbrzUhX',
    chainId: 101,
    decimals: 8,
    name: 'HUSD Stablecoin (Wormhole v1)',
    symbol: 'wHUSD_v1',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BybpSTBoZHsmKnfxYG47GDhVPKrnEKX31CScShbrzUhX/logo.png',
    tags: ['old-registry'],
  },
  USDH: {
    address: 'USDH1SM1ojwWUga67PGrgFWUHibbjqMvuMaDkRJTgkX',
    chainId: 101,
    decimals: 6,
    name: 'USDH Hubble Stablecoin',
    symbol: 'USDH',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/USDH1SM1ojwWUga67PGrgFWUHibbjqMvuMaDkRJTgkX/usdh.svg',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'usdh' },
  },
  JIN: {
    address: 'GgzJWhQWTpv2VHqvJnx5uEWQzNkfQSncDEWUdgQk4fvh',
    chainId: 101,
    decimals: 9,
    name: 'Jinpeng',
    symbol: 'JIN',
    logoURI:
      'https://ipfs.io/ipfs/Qmf31Z7fTrzr7XQYAYstDhixZoG9ePWDJQCqev2JEfWUDS',
    tags: ['community'],
  },
  LOON: {
    address: 'GDjFyK4umeTvfVu9r5pkdyaxzD2KtMhmBirwnJoKA7WW',
    chainId: 101,
    decimals: 6,
    name: 'Balloon Cat',
    symbol: 'LOON',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmTbEKh5WhxfiQfwPKUsdgrNJQrQ89a5ekkDciLimwqdcL',
    tags: ['community'],
  },
  HXRO: {
    address: 'HxhWkVpk5NS4Ltg5nij2G671CKXFRKPK8vy271Ub4uEK',
    chainId: 101,
    decimals: 8,
    name: 'Hxro (Portal)',
    symbol: 'HXRO',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/HXRO_wh.png',
    tags: ['wormhole', 'old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'hxro' },
  },
  PIF: {
    address: '8vRnnknwERunJEEDtEFoxzaxbxGnEY61FPbx8uTVKsUD',
    chainId: 101,
    decimals: 6,
    name: 'pepewifhat',
    symbol: 'PIF',
    logoURI:
      'https://bafkreibicwzofriyvt5up33rhejeqspemtamuwgvg3hg6wfkcf4najoxb4.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'pepewifhat' },
  },
  HAMWTR: {
    address: 'HSwR3tZuxQKq8ddz9YxR4BX65Znm6MNdWGURw3x5FJQi',
    chainId: 101,
    decimals: 9,
    name: 'Ham Water',
    symbol: 'HAMWTR',
    logoURI:
      'https://node1.irys.xyz/tYbgasKcMQhtUNQ-wjrpGLrD2C6ylEzkuqN-vzTJ-L4',
    tags: ['community'],
  },
  VIBEZ: {
    address: 'FyNuYGBBry5LAtPEkh8Y73izjTUNT2td2J3sGCK7E9Ju',
    chainId: 101,
    decimals: 9,
    name: 'VIBEZ',
    symbol: 'VIBEZ',
    logoURI:
      'https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/FyNuYGBBry5LAtPEkh8Y73izjTUNT2td2J3sGCK7E9Ju/logo.png?raw=true',
    tags: ['old-registry', 'solana-fm'],
  },
  SOLCAT: {
    address: 'E99fN4tCRb1tQphXK1DU7prXji6hMzxETyPNJro19Fwz',
    chainId: 101,
    decimals: 6,
    name: 'CatSolHat',
    symbol: 'SOLCAT',
    logoURI: 'https://i.imgur.com/6dJWbbL.jpeg',
    tags: ['community'],
    extensions: { coingeckoId: 'catsolhat' },
  },
  ZACK: {
    address: '8vCAUbxejdtaxn6jnX5uaQTyTZLmXALg9u1bvFCAjtx7',
    chainId: 101,
    decimals: 6,
    name: 'ZACK MORRIS',
    symbol: 'ZACK',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmPe1csXRHKPF7Yn5PGzrGk9EXi9yNs1keB2c7sqsHkHNN',
    tags: ['community'],
    extensions: { coingeckoId: 'zack-morris' },
  },
  POOWEL: {
    address: 'BHcPVARUJEV3rCAmbLgRm7QPmZotsCcHcKWwzvCSAHJi',
    chainId: 101,
    decimals: 9,
    name: 'JORAM POOWEL',
    symbol: 'POOWEL',
    logoURI:
      'https://ipfs.io/ipfs/QmTsKAzWkoGfqgD6TUdzB843RzVsdTKG5JAxEreJ97dByN',
    tags: ['community'],
    extensions: { coingeckoId: 'joram-poowel' },
  },
  CSTR: {
    address: 'G7uYedVqFy97mzjygebnmmaMUVxWHFhNZotY6Zzsprvf',
    chainId: 101,
    decimals: 9,
    name: 'CoreStarter',
    symbol: 'CSTR',
    logoURI:
      'https://raw.githubusercontent.com/CoreStarter/token-logo/main/corestarter_logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'corestarter' },
  },
  COBAN: {
    address: '7udMmYXh6cuWVY6qQVCd9b429wDVn2J71r5BdxHkQADY',
    chainId: 101,
    decimals: 3,
    name: 'COBAN',
    symbol: 'COBAN',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7udMmYXh6cuWVY6qQVCd9b429wDVn2J71r5BdxHkQADY/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'coban' },
  },
  POWSCHE: {
    address: '8CkiSHHJDHJV4LUoiRMLUhqG58cUkbyJRtcP4Z3mCXNf',
    chainId: 101,
    decimals: 9,
    name: 'POWSCHE',
    symbol: 'POWSCHE',
    logoURI:
      'https://dd.dexscreener.com/ds-data/tokens/solana/8CkiSHHJDHJV4LUoiRMLUhqG58cUkbyJRtcP4Z3mCXNf.png',
    tags: ['community'],
  },
  smole: {
    address: '9Ttyez3xiruyj6cqaR495hbBkJU6SUWdV6AmQ9MvbyyS',
    chainId: 101,
    decimals: 6,
    name: 'smolecoin',
    symbol: 'smole',
    logoURI:
      'https://ipfs.io/ipfs/QmcXAf2KasPyu8UtLnafsj3MR3YSjPH4huRc4NQ8YSanoX',
    tags: ['community'],
    extensions: { coingeckoId: 'smolecoin' },
  },
  xLFNTY: {
    address: 'xLfNTYy76B8Tiix3hA51Jyvc1kMSFV4sPdR7szTZsRu',
    chainId: 101,
    decimals: 6,
    name: 'xLifinity',
    symbol: 'xLFNTY',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xLfNTYy76B8Tiix3hA51Jyvc1kMSFV4sPdR7szTZsRu/logo.svg',
    tags: ['old-registry', 'solana-fm'],
  },
  PESHI: {
    address: '5LwseQRo8fsz4S3y7jbqqe5C7tZTz5PwhXNCHj13jLBi',
    chainId: 101,
    decimals: 6,
    name: 'PESHI',
    symbol: 'PESHI',
    logoURI:
      'https://bafkreidobd4eiplmvff42dnutldmwmjihkgbti6rpzuxz6p3c425e6qx6q.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'peshi' },
  },
  BANANA: {
    address: 'GAbRt1gE7Y5nBhhe95bxE2TaRySm8hMA6syjiUQ7q1Er',
    chainId: 101,
    decimals: 4,
    name: 'BANANA',
    symbol: 'BANANA',
    logoURI:
      'https://raw.githubusercontent.com/filthyrichmoneky/fym-banana-token/main/BananaLogo-256x256.png',
    tags: ['community', 'token-2022'],
  },
  DUKO: {
    address: 'HLptm5e6rTgh4EKgDpYFrnRHbjpkMyVdEeREEa2G7rf9',
    chainId: 101,
    decimals: 6,
    name: 'Duko',
    symbol: 'DUKO',
    logoURI:
      'https://bafkreicncfoowhhwrvtkajpivg6lbi5qsktrsfhs3x264k4kgdil5hv26q.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'duko' },
  },
  RNT: {
    address: '2fUFhZyd47Mapv9wcfXh5gnQwFXtqcYu9xAN4THBpump',
    chainId: 101,
    decimals: 6,
    name: 'REAL NIGGER TATE',
    symbol: 'RNT',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmUnhx14xmKQnyRk3Bqj31a5xKJNVkDH2UzFAizBRPYw2r',
    tags: ['community'],
    extensions: { coingeckoId: 'real-nigger-tate' },
  },
  piin: {
    address: 'piinKrjeiUVwhnXzsgNCV2aVGsBc4GwHQ9eCtkAgcTw',
    chainId: 101,
    decimals: 6,
    name: 'piin',
    symbol: 'piin',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f746f6b656e2d6e646c6c6d7772686d2e70616765732e6465762f7069696e2f69636f6e2e706e67',
    tags: ['community'],
  },
  OXY: {
    address: 'z3dn17yLaGMKffVogeFHQ9zWVcXgqgf3PQnDsNs2g6M',
    chainId: 101,
    decimals: 6,
    name: 'Oxygen Protocol',
    symbol: 'OXY',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/z3dn17yLaGMKffVogeFHQ9zWVcXgqgf3PQnDsNs2g6M/logo.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'oxygen' },
  },
  SPEND: {
    address: '3mp1MN5v7zdGXTvvcC9zUMoszMrh9pNdaCDkAQKc7Fec',
    chainId: 101,
    decimals: 5,
    name: 'SolSpend',
    symbol: 'SPEND',
    logoURI:
      'https://bafkreic4r56jg7fvkthkfzmmfy2z6ypfltqgowjvs44ghf32nh2nz77tva.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'solspend' },
  },
  EPIK: {
    address: '3BgwJ8b7b9hHX4sgfZ2KJhv9496CoVfsMK2YePevsBRw',
    chainId: 101,
    decimals: 6,
    name: 'TEH EPIK DUCK',
    symbol: 'EPIK',
    logoURI:
      'https://pump.mypinata.cloud/ipfs/QmWqYXSxZGu2AzKLb91eJR9RF68UST8uqZyMj6WHJcogkL',
    tags: ['community'],
    extensions: { coingeckoId: 'teh-epik-duck' },
  },
  $POPO: {
    address: '96QoNkZLTKH7Gecf6dKMRXBQXW1wqh378uF9pnTejgAw',
    chainId: 101,
    decimals: 9,
    name: "Popo Pepe's Dog",
    symbol: '$POPO',
    logoURI:
      'https://gateway.irys.xyz/NsqnWfvg7r-SmICp9Gy5fIEBFWYlshxcSiV92H39_k4',
    tags: ['community'],
    extensions: { coingeckoId: 'popo-pepe-s-dog' },
  },
  PRM: {
    address: 'prmmgF5GJCSDNEcwZe2HWb5DsDsFngxTetZ95C4VKxX',
    chainId: 101,
    decimals: 6,
    name: 'PRM Token',
    symbol: 'PRM',
    logoURI:
      'https://shdw-drive.genesysgo.net/4vcTsRNEvNjaG5JLLvURuEM5V4y9RdBF1BVR4YrGNpem/PRM.png',
    tags: ['community'],
    extensions: { coingeckoId: 'prm-token' },
  },
  AUDIO: {
    address: '9LzCMqDgTKYz9Drzqnpgee3SGa89up3a247ypMj2xrqM',
    chainId: 101,
    decimals: 8,
    name: 'Audius (Portal)',
    symbol: 'AUDIO',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/AUDIO_wh.png',
    tags: ['wormhole', 'old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'audius-wormhole' },
  },
  PRT: {
    address: 'PRT88RkA4Kg5z7pKnezeNH4mafTvtQdfFgpQTGRjz44',
    chainId: 101,
    decimals: 6,
    name: 'PRT (Parrot Protocol)',
    symbol: 'PRT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PRT88RkA4Kg5z7pKnezeNH4mafTvtQdfFgpQTGRjz44/logo.svg',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'parrot-protocol' },
  },
  AMADEUS: {
    address: 'Eq9xBLGnBc2B6wkdoZW6v1aCC4evtSaNPkSFKaDNQNFr',
    chainId: 101,
    decimals: 9,
    name: 'AMADEUS',
    symbol: 'AMADEUS',
    logoURI:
      'https://bafybeify7x2y6ojvzhtiafc4ovk7eh5vzu2iksyg3p4b7lom657dyym6e4.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  GUUFY: {
    address: 'inLbkByUvP9WGsLfMy1w9QX5pvaab5mkvLcz48DbkmF',
    chainId: 101,
    decimals: 9,
    name: 'GUUFY',
    symbol: 'GUUFY',
    logoURI:
      'https://bafybeiaispk3n6iclp4o4kjvwnrzze2jvlqkmw6slkkhrrbclblc7mxhvm.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'guufy' },
  },
  GOD: {
    address: '9kt93AW5QMjFL6ZxomnSq3FbWiU5ibNeTSgBz9UDFSB6',
    chainId: 101,
    decimals: 6,
    name: 'GOD',
    symbol: 'GOD',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9kt93AW5QMjFL6ZxomnSq3FbWiU5ibNeTSgBz9UDFSB6/logo.png',
    tags: ['old-registry'],
  },
  wHEX_v1: {
    address: 'ELSnGFd5XnSdYFFSgYQp7n89FEbDqxN4npuRLW4PPPLv',
    chainId: 101,
    decimals: 8,
    name: 'HEX (Wormhole v1)',
    symbol: 'wHEX_v1',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ELSnGFd5XnSdYFFSgYQp7n89FEbDqxN4npuRLW4PPPLv/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  ZEUS: {
    address: 'ZEUS1aR7aX8DFFJf5QjWj2ftDDdNTroMNGo8YoQm3Gq',
    chainId: 101,
    decimals: 6,
    name: 'ZEUS',
    symbol: 'ZEUS',
    logoURI:
      'https://raw.githubusercontent.com/ZeusNetworkHQ/zeus-metadata/master/logo-v1.png',
    tags: ['community'],
    extensions: { coingeckoId: 'zeus-network' },
  },
  DOGGO: {
    address: 'Doggoyb1uHFJGFdHhJf8FKEBUMv58qo98CisWgeD7Ftk',
    chainId: 101,
    decimals: 5,
    name: 'DOGGO',
    symbol: 'DOGGO',
    logoURI:
      'https://shdw-drive.genesysgo.net/BBwXjBB6LtWGjKW5GveUSau5z9KZv2CK5AWmb7FCDF6K/doggies_coin.png',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'doggo' },
  },
  Genie: {
    address: '3Jjt8QhbqNoYfSQYHWf8ZsTJwE2CyvmUrzgzJD5Jpump',
    chainId: 101,
    decimals: 6,
    name: 'Genie',
    symbol: 'Genie',
    logoURI:
      'https://pbs.twimg.com/profile_images/1801011420525465600/2ywH00FN_400x400.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'genie-2' },
  },
  DEFI: {
    address: 'Fefecfi5DhahY51XqQTP2qjFGhnuoSjrVivL6k9Ercw6',
    chainId: 101,
    decimals: 4,
    name: 'DegenerateFinance69Inu',
    symbol: 'DEFI',
    logoURI:
      'https://github.com/0xViva/public-assets/blob/main/assets/DefiDogLogo.png?raw=true',
    tags: ['community'],
  },
  MDS: {
    address: '9TVjnzpF3X8DHsfVqYWoCGphJxtGYh1PDCFN5QmsHW5t',
    chainId: 101,
    decimals: 9,
    name: 'Midas',
    symbol: 'MDS',
    logoURI: 'https://arweave.net/DcmraYTxxarO5sZ1fIKdPIo1f76lRMZB_-q7y1d3Qqw',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'midas-token' },
  },
  '21X': {
    address: '6r4PCVaX4rYN9WdbXwVWAQL4djFoUaeBMsq8Cxc6NApZ',
    chainId: 101,
    decimals: 9,
    name: '21X',
    symbol: '21X',
    logoURI:
      'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f6261666b7265696671643577616536356f337a6c6b687772756e33726a6a6772656c6f6e356836343277727364736379636c75686770757a3771792e697066732e6e667473746f726167652e6c696e6b2f',
    tags: ['community'],
    extensions: { coingeckoId: '21x' },
  },
  BOJI: {
    address: '2oMYg3aPHjFjxg1PRYLwvdRQayexUS8N4CjLXJ64GkLq',
    chainId: 101,
    decimals: 9,
    name: 'BOJI',
    symbol: 'BOJI',
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://ipfs.io/ipfs/QmejPmDXiuqJ3EG9zyNanLLjYWgycLwTso7EAWiPgFwUNF',
    tags: ['community'],
  },
  STBOT: {
    address: '2x8o3hA5S5fBxCSE9hzVTf3RohcMWHqkDNKNEPuzprD5',
    chainId: 101,
    decimals: 9,
    name: 'SolTradingBot',
    symbol: 'STBOT',
    logoURI:
      'https://yzevocek3mrl6hm2lz4m4nhskyu4knc4d5xissdmnss7wkecrodq.arweave.net/xklXCIrbIr8dml54zjTyVinFNFwfbolIbGyl-yiCi4c',
    tags: ['community'],
    extensions: { coingeckoId: 'soltradingbot' },
  },
  HUND: {
    address: '2XPqoKfJitk8YcMDGBKy7CMzRRyF2X9PniZeCykDUZev',
    chainId: 101,
    decimals: 9,
    name: 'HUND',
    symbol: 'HUND',
    logoURI:
      'https://ipfs.io/ipfs/QmVMuMjyfKkuVnb8rHFXYor1ezFp9xrecatMH7TizPWdKV',
    tags: ['community'],
    extensions: { coingeckoId: 'hund' },
  },
  LONG: {
    address: 'AYABiqKuTh9Va5Aqc6AujFevHwDGmECGQiFmKW5g3K4Z',
    chainId: 101,
    decimals: 5,
    name: 'Nobiko Coin',
    symbol: 'LONG',
    logoURI:
      'https://bafkreiayokuebuh72owe7axyonjlea2o7kfnjnftzoidyvghlql4igxpea.ipfs.nftstorage.link/',
    tags: ['community'],
    extensions: { coingeckoId: 'nobiko-coin' },
  },
  CDT: {
    address: 'Ak3ovnWQnAxPSFoSNCoNYJLnJtQDCKRBH4HwhWkb6hFm',
    chainId: 101,
    decimals: 8,
    name: 'CheckDot',
    symbol: 'CDT',
    logoURI: 'https://arweave.net/0qQ9rLiXpfs3G2bcilHsDTYOtHXExocm1VBjV3BvypY',
    tags: ['community'],
    extensions: { coingeckoId: 'checkdot' },
  },
  COK: {
    address: 'Dnb9dLSXxAarXVexehzeH8W8nFmLMNJSuGoaddZSwtog',
    chainId: 101,
    decimals: 6,
    name: 'cok the cat',
    symbol: 'COK',
    logoURI: 'https://arweave.net/50WjWGHJ--0JOMACDrGkQVEp_wuZgURMbkYlaFSDG_Q',
    tags: ['community'],
    extensions: { coingeckoId: 'catownkimono' },
  },
  CHP: {
    address: 'DHVUoxNqv3D7EgktBxUsxFF2Wx83hVDmD2wBBpUaw3jn',
    chainId: 101,
    decimals: 9,
    name: 'Crypto Health Plus',
    symbol: 'CHP',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DHVUoxNqv3D7EgktBxUsxFF2Wx83hVDmD2wBBpUaw3jn/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  UNVAXSPERM: {
    address: '9iQBkyLKtqRXvb6ARGvQydt1LYgGcTtVe3PTDpWhdPze',
    chainId: 101,
    decimals: 6,
    name: 'Unvaxxed Sperm',
    symbol: 'UNVAXSPERM',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmbW2tns5aWQyvz8DZgVPt3WzWq5DRyoUNjcArKodLScZj',
    tags: ['community'],
    extensions: { coingeckoId: 'unvaxxed-sperm-2' },
  },
  NEO: {
    address: 'NEo3D6MXRXf2iAfaqvZYqSmFkfutLvNjm86xmfGWNh5',
    chainId: 101,
    decimals: 9,
    name: 'NEO3D TOKEN',
    symbol: 'NEO',
    logoURI:
      'https://raw.githubusercontent.com/minh-git/docusaurus-2/main/static/img/neo3d-logo.jpg',
    tags: ['old-registry', 'solana-fm'],
  },
  FUM: {
    address: 'EZF2sPJRe26e8iyXaCrmEefrGVBkqqNGv9UPGG9EnTQz',
    chainId: 101,
    decimals: 9,
    name: 'FUMoney',
    symbol: 'FUM',
    logoURI:
      'https://raw.githubusercontent.com/FUMoney801/token-list/main/assets/mainnet/EZF2sPJRe26e8iyXaCrmEefrGVBkqqNGv9UPGG9EnTQz/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  $FLY: {
    address: '4h4LvS6NsVjZ87uBwrYyTeppTm1ii5PtRN9A6Ld2kZjw',
    chainId: 101,
    decimals: 6,
    name: 'StayFly',
    symbol: '$FLY',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4h4LvS6NsVjZ87uBwrYyTeppTm1ii5PtRN9A6Ld2kZjw/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  ELSA: {
    address: 'G7Dg4UU9zTULPe44Um9JjLSsSDZFDiVy8aLhzQuGjPsW',
    chainId: 101,
    decimals: 1,
    name: 'ELSA',
    symbol: 'ELSA',
    logoURI: 'https://metadata.runemine.com/thetickeriselsa.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'the-ticker-is-elsa' },
  },
  DOGGS: {
    address: 'HzF4L5A6Y4y3jpwvvsZXW51uUki851ctRYJ2GYK4dp7g',
    chainId: 101,
    decimals: 9,
    name: 'Doggensnout',
    symbol: 'DOGGS',
    logoURI:
      'https://ipfs.io/ipfs/QmRAmNbHCpX6kQq4Qfg4i2bQEUn914ZRDfsnLcCDmEdrfv?filename=DoggenSnout.jpeg',
    tags: ['community'],
    extensions: { coingeckoId: 'doggensnout' },
  },
  SNTR: {
    address: 'SENBBKVCM7homnf5RX9zqpf1GFe935hnbU4uVzY1Y6M',
    chainId: 101,
    decimals: 9,
    name: 'Sentre',
    symbol: 'SNTR',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SENBBKVCM7homnf5RX9zqpf1GFe935hnbU4uVzY1Y6M/logo.png',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'sentre' },
  },
  MNGO: {
    address: 'MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac',
    chainId: 101,
    decimals: 6,
    name: 'Mango',
    symbol: 'MNGO',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac/token.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'mango-markets' },
  },
  buni: {
    address: '7y1TrdzE1cEeCgBvgdNB9DViMYdQ7UU2FKhnPDLYa7ae',
    chainId: 101,
    decimals: 6,
    name: 'buni',
    symbol: 'buni',
    logoURI:
      'https://bafybeihm5euh7zdt7q32l3ogxauoun3syb474fj644cbikfkgcb25g3ct4.ipfs.cf-ipfs.com',
    tags: ['community'],
    extensions: { coingeckoId: 'bunicoin' },
  },
  'sUSDT-8': {
    address: 'T8KdT8hDzNhbGx5sjpEUxepnbDB1TZoCa7vtC5JjsMw',
    chainId: 101,
    decimals: 8,
    name: 'Saber Wrapped USDT (8 decimals)',
    symbol: 'sUSDT-8',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/T8KdT8hDzNhbGx5sjpEUxepnbDB1TZoCa7vtC5JjsMw/icon.png',
    tags: ['old-registry'],
  },
  FATALITY: {
    address: 'DXCoKQ7iLpux398fNHewQn6djfGobzFuPiR5o8hrVHAb',
    chainId: 101,
    decimals: 9,
    name: 'Fatality Coin',
    symbol: 'FATALITY',
    logoURI:
      'https://bafkreie3tk2pnz2tap2onh2eqf5cvg4uda3ec3m7x5mxb5frp2lim2mbbm.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'fatality-coin' },
  },
  WAG: {
    address: '5tN42n9vMi6ubp67Uy4NnmM5DMZYN8aS8GeB3bEDHr6E',
    chainId: 101,
    decimals: 9,
    name: 'Waggle Network',
    symbol: 'WAG',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5tN42n9vMi6ubp67Uy4NnmM5DMZYN8aS8GeB3bEDHr6E/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'waggle-network' },
  },
  MADx: {
    address: 'Cj2NAxiDDVvY79PLBdVQK3F3bjoHp7hvZv4kLL1vgtV3',
    chainId: 101,
    decimals: 2,
    name: 'Mad Lads',
    symbol: 'MADx',
    logoURI:
      'https://creator-hub-prod.s3.us-east-2.amazonaws.com/mad_lads_pfp_1682211343777.png',
    tags: ['community'],
  },
  wHAPI: {
    address: '6VNKqgz9hk7zRShTFdg5AnkfKwZUcojzwAkzxSH3bnUm',
    chainId: 101,
    decimals: 9,
    name: 'Wrapped HAPI',
    symbol: 'wHAPI',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6VNKqgz9hk7zRShTFdg5AnkfKwZUcojzwAkzxSH3bnUm/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'hapi' },
  },
  HORNT: {
    address: '7nbJrQMK3FwJDMia93EpESHqqJiEdQqvuwnGMiwnWMJ5',
    chainId: 101,
    decimals: 6,
    name: 'HORNT',
    symbol: 'HORNT',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmVrfS5YJggyGvF2mmHusQWgVdCjEqpPnTWtGc6FrkgJtA',
    tags: ['community'],
    extensions: { coingeckoId: 'hornt' },
  },
  HUHCAT: {
    address: '6Ra49aqZTbEurJB1UQgAj1TjvbqajGP5um7gsTym8tWm',
    chainId: 101,
    decimals: 9,
    name: 'huh cat',
    symbol: 'HUHCAT',
    logoURI:
      'https://media.discordapp.net/attachments/1226868639815438386/1227643314057117756/huh_cat.png?ex=662926ee&is=6616b1ee&hm=bff710efabde7ee7387b4c9de572bd44afe221f2f0bcd70e85ec92fe5b90ee3c&=&format=webp&quality=lossless&width=1352&height=1352',
    tags: ['community'],
    extensions: { coingeckoId: 'huh-cat' },
  },
  BRAH: {
    address: '6CBm8B8uoCUZWjs9n32BNy4z6LjGj4etBH2X48JWVhzf',
    chainId: 101,
    decimals: 6,
    name: 'BRAH SUPPS INTL.',
    symbol: 'BRAH',
    logoURI: 'https://brahsupps.com/res/misc/icon.png',
    tags: ['community'],
  },
  SPORE: {
    address: '6Cw88QKMxS7LzAucXbYDcUEXPq1TvsojYPV9xWwJPt7r',
    chainId: 101,
    decimals: 8,
    name: 'Spore',
    symbol: 'SPORE',
    logoURI:
      'https://bafkreidodmcqrprsgoelphtr5vyqintuqscwixqfh6ndaqurvp2mnnx2h4.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  HXD: {
    address: '3dgCCb15HMQSA4Pn3Tfii5vRk7aRqTH95LJjxzsG2Mug',
    chainId: 101,
    decimals: 9,
    name: 'Honeyland',
    symbol: 'HXD',
    logoURI: 'https://arweave.net/NHZyHFvooDi_LKgs1BaHVPMsuRSOoHpE-J26fp1uHGQ',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'honeyland-honey' },
  },
  WOOSH: {
    address: '29377Uz8eu6CeXmnMXJJge3gdaycyCbrubnLiUva75AA',
    chainId: 101,
    decimals: 6,
    name: 'NOIKE',
    symbol: 'WOOSH',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmUDcsoaS3VmgCZetHeTMkP6xQKyZaJSv5Q8uz3vrmxQC5',
    tags: ['community'],
    extensions: { coingeckoId: 'noike' },
  },
  Wojak: {
    address: '4MPA8WbyYtKiufXDSdckEoMPdN5XK1Xw9S9LSLMjK5Y4',
    chainId: 101,
    decimals: 9,
    name: 'Wojak',
    symbol: 'Wojak',
    logoURI: 'https://i.bb.co/PDkqhfK/wojak-logo.png',
    tags: ['community'],
  },
  CDC: {
    address: 'ASNR8RxZ3wK8QiNxmVEvxXtFGCXCHRD8oiAaNihHJvLq',
    chainId: 101,
    decimals: 9,
    name: 'Cat Driving a Car',
    symbol: 'CDC',
    logoURI:
      'https://bafkreibckqkthhqv4kmbgpe564agczidyfncwk2wsplztizdppdnob3ipi.ipfs.nftstorage.link',
    tags: ['community'],
  },
  OPN: {
    address: 'J1YnyKzmxBwFkPftvZexnHTm4Am7JnmWydhxtXdwEmMv',
    chainId: 101,
    decimals: 8,
    name: 'Open Ticketing Ecosystem',
    symbol: 'OPN',
    logoURI: 'https://arweave.net/4t-p2fZBHNMv7_9mwfFgN1ZWzbHGAUTdIJr399nRCgQ',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'open-ticketing-ecosystem' },
  },
  BITXBIT: {
    address: 'DK6PWMyuZ4NMjsm9AWNCTMKrajQYrtfMjMJ3QauX2UH5',
    chainId: 101,
    decimals: 9,
    name: 'BITXBIT',
    symbol: 'BITXBIT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DK6PWMyuZ4NMjsm9AWNCTMKrajQYrtfMjMJ3QauX2UH5/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  FAB: {
    address: 'EdAhkbj5nF9sRM7XN7ewuW8C9XEUMs8P7cnoQ57SYE96',
    chainId: 101,
    decimals: 9,
    name: 'FABRIC',
    symbol: 'FAB',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EdAhkbj5nF9sRM7XN7ewuW8C9XEUMs8P7cnoQ57SYE96/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'fabric' },
  },
  DRAGY: {
    address: '3JoKpqE4kowVTR3Po3gr3sxzLF6vKCvjGx48g8DRx9oN',
    chainId: 101,
    decimals: 9,
    name: 'Dragy',
    symbol: 'DRAGY',
    logoURI: 'https://imgur.com/3XfriZN',
    tags: ['community'],
    extensions: { coingeckoId: 'dragy' },
  },
  abUSDT: {
    address: 'E77cpQ4VncGmcAXX16LHFFzNBEBb2U7Ar7LBmZNfCgwL',
    chainId: 101,
    decimals: 9,
    name: 'Wrapped USDT (Allbridge from BSC)',
    symbol: 'abUSDT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BQcdHdAQW1hczDbBi9hiegXAR7A98Q9jx3X3iBBBDiq4/logo.png',
    tags: ['old-registry'],
  },
  BURD: {
    address: 'Qikhhhg9Ta3Jg7WoDFbSYuCAE14hx9hPvdz1zVp3zUw',
    chainId: 101,
    decimals: 9,
    name: 'tudaBirds Token',
    symbol: 'BURD',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Qikhhhg9Ta3Jg7WoDFbSYuCAE14hx9hPvdz1zVp3zUw/logo.png',
    tags: ['old-registry', 'solana-fm'],
  },
  CRP: {
    address: 'DubwWZNWiNGMMeeQHPnMATNj77YZPZSAz2WVR5WjLJqz',
    chainId: 101,
    decimals: 9,
    name: 'CropperFinance',
    symbol: 'CRP',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DubwWZNWiNGMMeeQHPnMATNj77YZPZSAz2WVR5WjLJqz/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'cropperfinance' },
  },
  STAN: {
    address: 'CQSzJzwW5H1oyWrp6QhfUKYYwyovbSiVDKnAxNfb1tJC',
    chainId: 101,
    decimals: 5,
    name: 'Stanley Cup Coin',
    symbol: 'STAN',
    logoURI: 'https://arweave.net/urnvj3NcnKmwEWNLDQJetp2OFP_nu3tB8HPdXwSFg7U',
    tags: ['community'],
    extensions: { coingeckoId: 'stanley-cup-coin' },
  },
  REAL: {
    address: 'AD27ov5fVU2XzwsbvnFvb1JpCBaCB5dRXrczV9CqSVGb',
    chainId: 101,
    decimals: 9,
    name: 'Realy Token',
    symbol: 'REAL',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AD27ov5fVU2XzwsbvnFvb1JpCBaCB5dRXrczV9CqSVGb/logo.svg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'realy-metaverse' },
  },
  $PTRUMP: {
    address: 'Gk2kRrwNMBU4Dn9JhC1Dks8G5X9nqi4ZE5jMvK6bdgEd',
    chainId: 101,
    decimals: 6,
    name: 'PepeTrump',
    symbol: '$PTRUMP',
    logoURI:
      'https://bafybeihfyvc65n6lk7zh72ge6xuhdnjd6hyak6vzu2wabe7lttwv3njde4.ipfs.cf-ipfs.com',
    tags: ['community'],
    extensions: { coingeckoId: 'pepe-trump' },
  },
  DGLN: {
    address: 'E6UU5M1z4CvSAAF99d9wRoXsasWMEXsvHrz3JQRXtm2X',
    chainId: 101,
    decimals: 9,
    name: 'Dogelana',
    symbol: 'DGLN',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E6UU5M1z4CvSAAF99d9wRoXsasWMEXsvHrz3JQRXtm2X/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'dogelana' },
  },
  zSOL: {
    address: 'So111DzVTTNpDq81EbeyKZMi4SkhU9yekqB8xmMpqzA',
    chainId: 101,
    decimals: 9,
    name: 'zSOL',
    symbol: 'zSOL',
    logoURI:
      'https://raw.githubusercontent.com/LP-Finance-Inc/token-image/main/zSOL.png',
    tags: ['community', 'solana-fm'],
  },
  Orys: {
    address: 'Fa96VxtHsUwGbi8DtjXFjsUjAP6rm18AWw5ezsTN4sxw',
    chainId: 101,
    decimals: 9,
    name: 'ORYS',
    symbol: 'Orys',
    logoURI: 'https://arweave.net/biihR7IHkBTFIqirTaskPxbkUYfyYL0rUYMAgPhETeE',
    tags: ['community'],
  },
  PEOPLE: {
    address: 'CobcsUrt3p91FwvULYKorQejgsm5HoQdv5T8RUZ6PnLA',
    chainId: 101,
    decimals: 8,
    name: 'ConstitutionDAO (Portal)',
    symbol: 'PEOPLE',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/PEOPLE_wh.png',
    tags: ['wormhole', 'old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'constitutiondao-wormhole' },
  },
  PIZZA: {
    address: 'DZvuAtqMdWzDHMGDpTkRmW2QBxstjCNTcobMDMpVuRDa',
    chainId: 101,
    decimals: 8,
    name: 'COSTCO PIZZA',
    symbol: 'PIZZA',
    logoURI:
      'https://gateway.irys.xyz/DMy8cbi1T5k-1dMUyT2TaGGmV_UHvz5D9whcItR706I',
    tags: ['community'],
  },
  ATLAS: {
    address: 'ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx',
    chainId: 101,
    decimals: 8,
    name: 'Star Atlas',
    symbol: 'ATLAS',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'star-atlas' },
  },
  sunSBR: {
    address: 'sunSBR8hRLZy13HCHdrT3hjJZVRpkVfXF3QaaJYjxZ2',
    chainId: 101,
    decimals: 6,
    name: 'sunSBR',
    symbol: 'sunSBR',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sunSBR8hRLZy13HCHdrT3hjJZVRpkVfXF3QaaJYjxZ2/logo.svg',
    tags: ['community'],
  },
  BCOQ: {
    address: 'coqRkaaKeUygDPhuS3mrmrj6DiHjeQJc2rFbT2YfxWn',
    chainId: 101,
    decimals: 5,
    name: 'BLACK COQINU',
    symbol: 'BCOQ',
    logoURI: 'https://arweave.net/nU1lZL13C5Kf4nTGqUYsqdWy43mXP9WeP7eOag61ZK4',
    tags: ['community'],
    extensions: { coingeckoId: 'bcoq-inu' },
  },
  WIPE: {
    address: '9ae76zqD3cgzR9gvf5Thc2NN3ACF7rqqnrLqxNzgcre6',
    chainId: 101,
    decimals: 9,
    name: 'WipeMyAss',
    symbol: 'WIPE',
    logoURI: 'https://cdn.jsdelivr.net/gh/rxrxrxrx/WipeMyAss/wipemyass.jpg',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'wipemyass' },
  },
  'sBTC-8': {
    address: 'SBTCB6pWqeDo6zGi9WVRMLCsKsN6JiR1RMUqvLtgSRv',
    chainId: 101,
    decimals: 8,
    name: 'Saber Wrapped Bitcoin (Sollet) (8 decimals)',
    symbol: 'sBTC-8',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SBTCB6pWqeDo6zGi9WVRMLCsKsN6JiR1RMUqvLtgSRv/icon.png',
    tags: ['old-registry'],
  },
  DRAKO: {
    address: 'drakduQWnTS89CdTUdgHmZzEkN6reBLYqrk8rzVhU53',
    chainId: 101,
    decimals: 5,
    name: 'Drako',
    symbol: 'DRAKO',
    logoURI:
      'https://shdw-drive.genesysgo.net/BKajV6Kxs53iWNuBZW23CXKv2TWwHMtYYRLrTgUVXL6U/drako.jpg',
    tags: ['community'],
    extensions: { coingeckoId: 'drako' },
  },
  $SNOOPY: {
    address: '4LP5JKsyKC5pSAoodwcZnDCSK2ggsMcZvHKoo7HCPDCV',
    chainId: 101,
    decimals: 9,
    name: 'Snoopy',
    symbol: '$SNOOPY',
    logoURI:
      'https://bafkreih5phb36g46nyomg3gx6274wyhbgh4pyhax7noxpbhinirxtzcyum.ipfs.nftstorage.link',
    tags: ['community'],
  },
  GOBI: {
    address: 'MarcoPaG4dV4qit3ZPGPFm4qt4KKNBKvAsm2rPGNF72',
    chainId: 101,
    decimals: 6,
    name: 'Gobi',
    symbol: 'GOBI',
    logoURI:
      'https://arweave.net/uscHuZUd7GsiPIJz2zS_et6_boil-DhtF0zJdrX8hM8?ext=png',
    tags: ['community', 'solana-fm'],
  },
  POU: {
    address: 'PouWaap6bHZv1oT7T7rzRgQHTBD4nsWQRHGbB5hfDA6',
    chainId: 101,
    decimals: 6,
    name: 'POU',
    symbol: 'POU',
    logoURI:
      'https://bafkreibhyfedw4nxarux6eewzsk2mg7ofe6uklqq3hojzyuu2pgornv42q.ipfs.nftstorage.link',
    tags: ['community'],
    extensions: { coingeckoId: 'pou' },
  },
  WYAC: {
    address: 'BEgBsVSKJSxreiCE1XmWWq8arnwit7xDqQXSWYgay9xP',
    chainId: 101,
    decimals: 6,
    name: 'WOMAN YELLING AT CAT',
    symbol: 'WYAC',
    logoURI:
      'https://ipfs.io/ipfs/QmPC48TdRtFuYSkBWxE7E5D93x7aDNFdECF5tkngxwYU7p',
    tags: ['community'],
    extensions: { coingeckoId: 'woman-yelling-at-cat' },
  },
  apUSDT: {
    address: 'DNhZkUaxHXYvpxZ7LNnHtss8sQgdAfd1ZYS1fB7LKWUZ',
    chainId: 101,
    decimals: 6,
    name: 'Wrapped USDT (Allbridge from Polygon)',
    symbol: 'apUSDT',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BQcdHdAQW1hczDbBi9hiegXAR7A98Q9jx3X3iBBBDiq4/logo.png',
    tags: ['old-registry'],
  },
  AURY: {
    address: 'AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP',
    chainId: 101,
    decimals: 9,
    name: 'Aurory',
    symbol: 'AURY',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'aurory' },
  },
  AFSeX: {
    address: 'FbUy1NubUtCfoif7TAr6wAtJVJFapYUVWxRGmUwvKNyS',
    chainId: 101,
    decimals: 2,
    name: "𝝠ndre's Fleet Share",
    symbol: 'AFSeX',
    logoURI: 'https://arweave.net/l5d0WrtUcpUEqwknqjvxlo2QyGryLL3SDsRbigT9HA0',
    tags: ['community'],
  },
  WEC: {
    address: '6y8W5YwAuzostqrS4YDJufBvksosfSi47Pd8U4A5vrBC',
    chainId: 101,
    decimals: 9,
    name: 'Whole Earth Coin',
    symbol: 'WEC',
    logoURI:
      'https://ipfs.moralis.io:2053/ipfs/QmStqd3JLmEA9bfcUzt2CWF2iPzt7tgPmnkgou7JxauUkz',
    tags: ['community', 'solana-fm'],
    extensions: { coingeckoId: 'whole-earth-coin' },
  },
  FURBY: {
    address: '34pxeud2ajwJaq7XZrYdvUUGHKJP2dFrAg6k8zvSsigt',
    chainId: 101,
    decimals: 9,
    name: 'FURBY',
    symbol: 'FURBY',
    logoURI:
      'https://dd.dexscreener.com/ds-data/tokens/solana/34pxeud2ajwJaq7XZrYdvUUGHKJP2dFrAg6k8zvSsigt.png?size=lg&key=108564',
    tags: ['community'],
  },
  POLYGONE: {
    address: 'J9nsngni1Pavf4ijP4R9QBaD1yEzKzzUQ1vVgcDQT18J',
    chainId: 101,
    decimals: 5,
    name: 'Polygone on SOL',
    symbol: 'POLYGONE',
    logoURI:
      'https://bafkreidlnj7ne4bnygpn45x2k464vw7xzudib3vtecqwkczo4adbcnn2sm.ipfs.nftstorage.link/',
    tags: ['community'],
  },
  pre: {
    address: 'G6mc7tiVSym3zrmmxekF3HYSc9c2hiKnGk7idoHqHTUK',
    chainId: 101,
    decimals: 9,
    name: 'pre',
    symbol: 'pre',
    logoURI: 'https://arweave.net/pLKQdikCbGGkmHQ2y4ePS3M2czrLNJB2OgSRg8K71TQ',
    tags: ['community'],
    extensions: { coingeckoId: 'pre' },
  },
  SAND: {
    address: '49c7WuCZkQgc3M4qH8WuEUNXfgwupZf1xqWkDQ7gjRGt',
    chainId: 101,
    decimals: 8,
    name: 'The Sandbox (Portal)',
    symbol: 'SAND',
    logoURI:
      'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/SAND_wh.png',
    tags: ['wormhole', 'old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'the-sandbox-wormhole' },
  },
  PEW: {
    address: '5zgTYTDK836G2Fc4ZLQp4rsyi78pAbuDr4qaQUE1pump',
    chainId: 101,
    decimals: 6,
    name: 'PEW for BOME and PEPE',
    symbol: 'PEW',
    logoURI:
      'https://ipfs.io/ipfs/QmaTn8rE2b2kf1tz5AJHGKokLkxQe4BZaqJRwYXQStkPxz',
    tags: ['community'],
    extensions: { coingeckoId: 'pew4sol' },
  },
  pussyinbio: {
    address: 'E9pBR4xjscYLPqFZ4YM4gUkczqz7MHpB6dk4sfSQTnJD',
    chainId: 101,
    decimals: 6,
    name: 'Pussy In Bio',
    symbol: 'pussyinbio',
    logoURI:
      'https://cf-ipfs.com/ipfs/QmdVwp45yBtPgTu1afbZqbr5JvC6HvtYLUUyVJ32vqxnD4',
    tags: ['community'],
  },
  abETH: {
    address: 'EyrnrbE5ujd3HQG5PZd9MbECN9yaQrqc8pRwGtaLoyC',
    chainId: 101,
    decimals: 9,
    name: 'Wrapped ETH (Allbridge from BSC)',
    symbol: 'abETH',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeGn77dhg1KXRRFeSwwMiykZnZPw5JXW6naf2aQgZDQf/logo.png',
    tags: ['old-registry'],
  },
  acUSD: {
    address: 'EwxNF8g9UfmsJVcZFTpL9Hx5MCkoQFoJi6XNWzKf1j8e',
    chainId: 101,
    decimals: 9,
    name: 'Wrapped CUSD (Allbridge from Celo)',
    symbol: 'acUSD',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7236.png',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'wrapped-cusd-allbridge-from-celo' },
  },
  compassSOL: {
    address: 'Comp4ssDzXcLeu2MnLuGNNFC4cmLPMng8qWHPvzAMU1h',
    chainId: 101,
    decimals: 9,
    name: 'Compass SOL',
    symbol: 'compassSOL',
    logoURI: 'https://solanacompass.com/compass.png',
    tags: ['community'],
    extensions: { coingeckoId: 'solana-compass-staked-sol' },
  },
  wUST_v1: {
    address: 'CXLBjMMcwkc17GfJtBos6rQCo1ypeH6eDbB82Kby4MRm',
    chainId: 101,
    decimals: 9,
    name: 'Wrapped UST (Wormhole v1)',
    symbol: 'wUST_v1',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CXLBjMMcwkc17GfJtBos6rQCo1ypeH6eDbB82Kby4MRm/logo.png',
    tags: ['old-registry'],
    extensions: { coingeckoId: 'wrapped-ust' },
  },
  UPS: {
    address: 'EwJN2GqUGXXzYmoAciwuABtorHczTA5LqbukKXV1viH7',
    chainId: 101,
    decimals: 6,
    name: 'UPS token (UPFI Network)',
    symbol: 'UPS',
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EwJN2GqUGXXzYmoAciwuABtorHczTA5LqbukKXV1viH7/logo.png',
    tags: ['old-registry', 'solana-fm'],
    extensions: { coingeckoId: 'upfi-network' },
  },
  Speero: {
    address: 'C7K4Tot6fnnNwhWpqw9H277QPcP56vHAEeXubRHDyCo9',
    chainId: 101,
    decimals: 6,
    name: 'Speero',
    symbol: 'Speero',
    logoURI:
      'https://assets.coingecko.com/coins/images/34252/standard/speeroLOGO.png',
    tags: ['community'],
    extensions: { coingeckoId: 'speero' },
  },
} as const;
