import { env } from '@/env';
import { Chain, defineChain } from 'viem';
import {
  arbitrum,
  mainnet as viemMainnet,
  polygon,
  base,
  bsc,
  avalanche,
} from 'viem/chains';
import { NetworkType } from '@prisma/client';

export const LLM_MODEL = 'gpt-3.5-turbo';
export const DEFAULT_CHAIN_ID = 1151111081099710;
export const WALLET_ADDRESS_COOKIE_KEY = 'PREFERRED_WALLET_ADDRESS';

export const erc20DecimalsAbi = [
  {
    constant: true,
    inputs: [],
    name: 'decimals',
    outputs: [
      {
        name: '',
        type: 'uint8',
      },
    ],
    payable: false,
    type: 'function',
  },
] as const;

export const solana = defineChain({
  id: 1151111081099710,
  name: 'Solana',
  testnet: false,
  rpcUrls: {
    public: { http: [env.NEXT_PUBLIC_SOLANA_RPC_URL] },
    default: { http: [env.NEXT_PUBLIC_SOLANA_RPC_URL] },
  },
  nativeCurrency: { name: 'Solana', symbol: 'SOL', decimals: 9 },
  blockExplorers: {
    default: {
      name: 'Solana',
      url: 'https://solscan.io',
    },
  },
  custom: {
    nativeCurrencyAddress: '11111111111111111111111111111111',
  },
});

export const mainnet = {
  ...viemMainnet,
  rpcUrls: {
    default: { http: [env.NEXT_PUBLIC_ETH_RPC] },
  },
};

const statusChain = (c: Chain, disabled = true) => {
  return { ...c, disabled } as const;
};

export const supportedChains = [
  statusChain(mainnet, false),
  statusChain(polygon, false),
  statusChain(solana),
  statusChain(arbitrum),
  statusChain(base),
  statusChain(bsc),
  statusChain(avalanche),
] as const;

export type SupportedChainIds = (typeof supportedChains)[number]['id'];

export const supportedNetworks = supportedChains.map(e => e.id);

export const ChainId = {
  ethereum: 1,
  arbitrum: 42161,
  polygon: 137,
  bsc: 56,
  avalanche: 43114,
  fantom: 250,
  optimism: 10,
  base: 8453,
  solana: 1151111081099710, // Same as LiFi SDK
} as const;

export const chainIdToNetworkType: { [key: number]: NetworkType } = {
  [ChainId.ethereum]: 'Evm',
  [ChainId.arbitrum]: 'Evm',
  [ChainId.polygon]: 'Evm',
  [ChainId.bsc]: 'Evm',
  [ChainId.avalanche]: 'Evm',
  [ChainId.fantom]: 'Evm',
  [ChainId.optimism]: 'Evm',
  [ChainId.base]: 'Evm',
  [ChainId.solana]: 'Solana',
};

export const feeCollector = {
  [ChainId.arbitrum]: '0x757483386f531Ef434750d222793EBAe9199E54f',
  [ChainId.optimism]: '0x757483386f531Ef434750d222793EBAe9199E54f',
};

export const percentageFee = 1;
