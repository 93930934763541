import { Chain } from '@covalenthq/client-sdk';
import { Chain as ViemChain } from 'viem';
import { arbitrum, polygon, base, bsc, avalanche } from 'viem/chains';
import { ChainId, mainnet, solana } from '@/lib/constants';
import { env } from '@/env';

export const idToCovalentChain: { [key: number]: Chain } = {
  [ChainId.ethereum]: 'eth-mainnet',
  [ChainId.bsc]: 'bsc-mainnet',
  [ChainId.polygon]: 'matic-mainnet',
  [ChainId.arbitrum]: 'arbitrum-mainnet',
  [ChainId.avalanche]: 'avalanche-mainnet',
  [ChainId.solana]: 'solana-mainnet',
  [ChainId.base]: 'base-mainnet',
};

export const idToViemChain: { [id: number]: ViemChain } = {
  [ChainId.ethereum]: mainnet,
  [ChainId.bsc]: bsc,
  [ChainId.polygon]: polygon,
  [ChainId.arbitrum]: arbitrum,
  [ChainId.avalanche]: avalanche,
  [ChainId.solana]: solana,
  [ChainId.base]: base,
};

const iconBaseUrl = 'https://icons.llamao.fi/icons';
export const getStableTokenIcon = (e: string) =>
  `${iconBaseUrl}/pegged/${e}.jpg`;
export const getNetworkIcon = (e: string) =>
  `${iconBaseUrl}/chains/rsz_${e}.jpg`;

const getDexScreenerTokenIcon = (chainName: string, tokenAddress: string) =>
  `https://dd.dexscreener.com/ds-data/tokens/${chainName}/${tokenAddress}.png`;

export const getTokenIcon = (
  token: string,
  chainName?: string,
  tokenAddress?: string,
) => {
  const predefinedIcon = tokenToSrc[token];
  if (predefinedIcon) return predefinedIcon;

  if (chainName && tokenAddress) {
    return getDexScreenerTokenIcon(chainName, tokenAddress);
  }
  return null;
};

export const tokenToSrc: { [token: string]: string } = {
  DAI: getStableTokenIcon('dai'),
  ETH: getNetworkIcon('ethereum'),
  MATIC: getNetworkIcon('polygon'),
  POL: getNetworkIcon('polygon'),
  USDC: getStableTokenIcon('usd-coin'),
  USDT: getStableTokenIcon('tether'),
  WETH: getNetworkIcon('ethereum'),
  SOL: getNetworkIcon('solana'),
  BNB: getNetworkIcon('bsc'),
  AVAX: getNetworkIcon('avalanche'),
};

export { solanaTokenToData } from './jupiterTokens';

export const Chains: {
  [chainId: number]: {
    name: string;
    rpc: string;
    symbol: string;
    decimals: number;
    defaultAmount: string;
    tokenUri?: string;
  };
} = {
  [ChainId.ethereum]: {
    name: 'Ethereum',
    rpc: 'https://rpc.ankr.com/eth',
    symbol: 'ETH',
    decimals: 18,
    defaultAmount: '0.1',
    tokenUri: tokenToSrc['ETH'],
  },
  [ChainId.arbitrum]: {
    name: 'Arbitrum',
    rpc: 'https://arbitrum-one.publicnode.com',
    symbol: 'ETH',
    decimals: 18,
    defaultAmount: '0.1',
  },
  [ChainId.polygon]: {
    name: 'Polygon',
    rpc: 'https://polygon-bor.publicnode.com',
    symbol: 'MATIC',
    decimals: 18,
    defaultAmount: '100',
    tokenUri: tokenToSrc['MATIC'],
  },
  [ChainId.bsc]: {
    name: 'Binance',
    rpc: 'https://bsc.publicnode.com',
    symbol: 'BNB',
    decimals: 18,
    defaultAmount: '10',
  },
  [ChainId.avalanche]: {
    name: 'Avalanche',
    rpc: 'https://avalanche-c-chain.publicnode.com',
    symbol: 'AVAX',
    decimals: 18,
    defaultAmount: '10',
  },
  [ChainId.fantom]: {
    name: 'Fantom',
    rpc: 'https://fantom.publicnode.com',
    symbol: 'FTM',
    decimals: 18,
    defaultAmount: '10',
  },
  [ChainId.optimism]: {
    name: 'Optimism',
    rpc: 'https://optimism.publicnode.com',
    symbol: 'ETH',
    decimals: 18,
    defaultAmount: '0.1',
  },
  [ChainId.base]: {
    name: 'BASE',
    rpc: 'https://base.publicnode.com',
    symbol: 'ETH',
    decimals: 18,
    defaultAmount: '0.1',
  },
  [ChainId.solana]: {
    name: 'Solana',
    rpc: env.NEXT_PUBLIC_SOLANA_RPC_URL,
    symbol: 'SOL',
    decimals: 9,
    defaultAmount: '1',
  },
};

export const chainNameMapping: { [key: string]: string } = {
  'eth-mainnet': 'Ethereum',
  'matic-mainnet': 'Polygon',
  'bsc-mainnet': 'Binance Smart Chain',
  'arbitrum-mainnet': 'Arbitrum',
};
